<template>

    <v-card flat>
        <v-card-text>
            <template>
                <div class="text-center">
                    <v-text-field
                            class="my-4"
                            v-model="types"
                            label="Types"
                            required
                    ></v-text-field>
                    <v-btn :loading="getStatus.searching" :disabled="getStatus.searching" color="grey" class="white--text" @click="startSearch">
                        Datensätze in Knowledge Graph suchen
                        <v-icon right dark>search</v-icon>
                    </v-btn>
                    <p class="my-4" v-if="foundCount !== null && !getStatus.searching">{{ foundCount + " Datensätze gefunden." }}</p>
                    <div>
                    <v-btn v-if="foundCount !== null && !getStatus.writing && !getStatus.searching" :disabled="getStatus.writing" color="success" class="white--text my-4" @click="startWrite">
                        Datensätze übernehmen
                        <v-icon right dark>cloud_download</v-icon>
                    </v-btn>
                    </div>
                    <v-progress-linear
                            v-if="getStatus.writing"
                            color="teal"
                            class="mt-4"
                            buffer-value="0"
                            :value="(getStatus.written/getEntities.length)*100"
                            stream
                    ></v-progress-linear>
                    <p v-if="getStatus.writing" class="text-caption mb-5">{{ getStatus.written + "/" + getEntities.length }}</p>
                </div>
            </template>
        </v-card-text>
    </v-card>

</template>

<script>

    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: "EntriesSync",
        data() {
            return {
                foundCount: null,
                types: "",
            }
        },
        computed: {
            ...mapGetters(['getStatus', 'getEntities'])
        },
        methods: {
            ...mapActions(['searchEntities', 'writeEntities']),
            startSearch() {

            },
            startWrite() {

            }
        }
    }

</script>

<style scoped>

</style>
