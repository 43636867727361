<template>

    <div>

        <v-list max-width="500" elevation="1" class="py-0 mt-3">

            <draggable v-model="statusList" v-bind="dragOptions" handle=".handle" @start="drag=true" @end="drag=false">

                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <template v-for="(status,index) in statusList">

                        <v-list-item :key="index" class="my-0 listing-item">
                            <template>

                                <v-list-item-avatar style="cursor: grab" class="handle">
                                    <v-icon>
                                        drag_handle
                                    </v-icon>
                                </v-list-item-avatar>

                                <!-- DATA COMPONENT -->
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon :color="status.color" class="mr-2">circle</v-icon>
                                        <span style="vertical-align: middle">{{ status.label }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class='ml-9'>
                                        {{ status.value }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-row>
                                    <DeleteConfirmation @yes="deleteStatus(index)">
                                        <template v-slot:default="{ on, attrs }">
                                            <v-btn color="error" fab depressed x-small v-on='on' v-bind='attrs' class="mr-3">
                                                <v-icon color="white" size="16">close</v-icon>
                                            </v-btn>
                                        </template>
                                    </DeleteConfirmation>
                                    <v-menu :close-on-content-click="false" :nudge-width="200" offset-x max-width='800'>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="black" fab depressed x-small v-on='on' v-bind='attrs' class="mr-3">
                                                <v-icon color="white" size="16">edit</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-card class='py-3 px-2'>

                                            <v-text-field label='Name'
                                                          @input="change(status, index, 'label', $event)"
                                                          :value='status.label'
                                            >
                                            </v-text-field>

                                            <v-text-field label='Wert in XML'
                                                          @input="change(status, index, 'value', 'custom_' + $event)"
                                                          :value='(status.value || "").replaceAll("custom_", "")'
                                            >
                                            </v-text-field>

                                            <v-checkbox label='Status sperrt'
                                                        class='my-0'
                                                        @change="change(status, index, 'lock', $event)"
                                                        v-model='status.lock'
                                            >
                                            </v-checkbox>
                                            <v-checkbox label='Schwarzer Text'
                                                        @change="change(status, index, 'blackText', $event)"
                                                        class='my-0'
                                                        v-model='status.blackText'
                                            >
                                            </v-checkbox>

                                            <v-color-picker label='Farbe'
                                                            hide-mode-switch
                                                            :mode.sync="colorPickerMode"
                                                            @input="change(status, index, 'color', $event)"
                                                            :value='status.color'
                                            >
                                            </v-color-picker>

                                        </v-card>
                                    </v-menu>
                                    </v-row>
                                </v-list-item-action>
                            </template>
                        </v-list-item>


                    </template>
                </transition-group>
            </draggable>

        </v-list>



        <v-btn @click="addStatus" class="ml-4 mt-3" fab depressed small color="success">
            <v-icon>add</v-icon>
        </v-btn>

    </div>



</template>

<script>

import draggable from 'vuedraggable';
import DeleteConfirmation from "../utility/DeleteConfirmation";

export default {
    name: "StatusList.vue",
    components: {
        DeleteConfirmation,
        draggable
    },
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            drag: false,
            colorPickerMode: "hexa",
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "statuslist",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        statusList: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        addStatus() {
            this.$emit('input', [
                ...this.value,
                {
                    label: "Neuer Status",
                    value: "custom_" + Math.round(Math.random() * 10000),
                    color: "#ff4354",
                    blackText: false,
                }
            ])
        },
        deleteStatus(index) {
            this.$emit('input', [
                ...this.value.slice(0, index),
                ...this.value.slice(index + 1, this.value.length)
            ]);
        },
        change(status, index, key, value) {
            this.$emit('input', [
                ...this.value.slice(0, index),
                {
                    ...status,
                    [key]: value
                },
                ...this.value.slice(index + 1, this.value.length)
            ]);
            console.log({
                ...status,
                [key]: value
            },)
        }
    }
}
</script>

<style scoped>

</style>
