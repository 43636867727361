<template>

    <div class="my-5" v-if="group">

        <v-row class="mx-2 mb-5">
            <v-spacer></v-spacer>
            <v-btn class="mr-3" depressed color="success" fab small @click="save()">
                <v-icon>
                    save
                </v-icon>
            </v-btn>
            <DeleteConfirmation  @yes="remove">
            </DeleteConfirmation>
        </v-row>

        <v-text-field v-model="group.name" class="ml-5" label="Name" style="max-width: 800px;" filled/>

        <v-autocomplete :items="users"
                        style="max-width: 500px;"
                        placeholder=""
                        item-text="name"
                        item-value="id"
                        label="Mitglieder hinzufügen..."
                        class="ml-5 mt-10"
                        multiple
                        :loading="$store.getters['users/getStatus'] === 'loading'"
                        filled
                        chips
                        v-model="group.users"
                        deletable-chips
        >
            <template v-slot:selection="{ item, index }">
            </template>
        </v-autocomplete>

        <p class="ml-5 mt-2 text-caption mb-0">Mitglieder</p>
        <p class="text-center my-3" v-if="selectedUsers.length === 0">Keine Mitglieder</p>
        <v-list class="ml-5 mb-5" dense style="max-width: 500px;">
            <v-list-item v-for="user in selectedUsers" :key="user.id">
                <v-list-item-avatar>
                    <v-avatar v-if="user" size="40">
                        <UserImage :user="user" alt="profile image" />
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-action>
                    <DeleteConfirmation @yes="removeUser(user.id)" :title="`${user.name} aus dieser Gruppe entfernen?`">
                        <template v-slot:default="{ on, attrs }">
                            <v-btn fab depressed x-small color="error" v-on="on" :bind="attrs">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </template>
                    </DeleteConfirmation>
                </v-list-item-action>
            </v-list-item>
        </v-list>

    </div>


</template>

<script>
import _ from "lodash";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
import UserImage from "@/components/users/UserImage";

export default {
    name: "GroupEdit",
    components: {
        UserImage,
        DeleteConfirmation,
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        groupID: function() {
            return this.$router.currentRoute.params.groupID;
        },
        group: {
            get() {
                return this.$store.getters['groups/getAll'].find(r => r._id.toString() === this.groupID);
            },
        },
        users: function () {
            return this.$store.getters['users/getAll']?.filter(() => true)?.sort((a, b) => a.name.localeCompare(b.name));
        },
        selectedUsers: function () {
            return this.$store.getters['users/getAll']?.filter((u) => this.group?.users?.includes(u.id))?.sort((a, b) => a.name.localeCompare(b.name));
        }
    },
    watch: {
        group: {
            handler(val, oldVal) {
                // if (oldVal && val) this.save();
            },
            deep: true,
        }
    },
    methods: {
        clone() {
            this.$store.dispatch('groups/addGroup', {
                group: {
                    ...this.group,
                    name: this.group.name + " - Kopie",
                    _id: undefined
                }
            }).then(() => {
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "Rolle kopiert." });
            });
        },
        removeUser(id) {
            let index = this.group.users.findIndex(u => u === id);
            console.log(id, index);
            if (index !== -1) {
                this.group.users.splice(index, 1);
            }
        },
        remove() {
            this.loading = true;
            this.$store.dispatch('groups/removeGroup', { id: this.groupID }).then(() => {
                this.loading = false;
                this.$router.push({
                    name: "AdminGroups"
                })
            });
        },
        save: _.debounce(function() {
            this.loading = true;
            this.$store.dispatch('groups/updateGroup', { id: this.groupID, group: this.group }).then(() => {
                this.loading = false;
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "gespeichert" });
            })
        }, 50)
    },
    created() {
        this.$store.dispatch('users/fetchAll');
    }
}
</script>

<style>
</style>
