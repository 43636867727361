<template>

    <div>

        <v-row>
            <v-col cols="12" sm="6" class="pt-0">
                <v-select class="pt-0"
                          v-model="catalog.status"
                          :items="workflow.steps"
                          menu-props="auto"
                          item-text="label"
                          item-value="keyword"
                          label="bitte wählen..."
                          hide-details
                          single-line
                          prepend-icon="donut_large"
                          @change="updateWorkflow(catalog)"
                ></v-select>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar" :timeout="timeout">
            Status aktualisiert!
            <template v-slot:action="{ attrs }">
                <v-btn color="success" text v-bind="attrs" @click="snackbar = false">
                    OK
                </v-btn>
            </template>
        </v-snackbar>

    </div>


</template>

<script>

    import { mapActions } from 'vuex';
    // import VueApexCharts from 'vue-apexcharts';

    export default {
        name: "CatalogWorkflow",
        props: ['catalog'],
        components: {
            //apexchart: VueApexCharts
        },
        data() {
            return {
                workflow: {
                    steps: [
                        {
                            keyword: 'scheduled',
                            label: 'Geplant'
                        },
                        {
                            keyword: 'configured',
                            label: 'Gastgeber geprüft'
                        },
                        {
                            keyword: 'acquisitions_sent',
                            label: 'Akquisen versandt'
                        },
                        {
                            keyword: 'releases_sent',
                            label: 'Freigaben versandt',
                        },
                        {
                            keyword: 'releases_confirmed',
                            label: 'Freigaben bestätigt',
                        },
                        {
                            keyword: 'printing',
                            label: 'In Druckerei',
                        },
                        {
                            keyword: 'completed',
                            label: 'Abgeschlossen',
                        },
                    ],
                },
                timeout: 2000,
                snackbar: false,
            }
        },
        methods: {
            ...mapActions(['updateCatalog']),
            updateWorkflow (catalog) {
                this.updateCatalog(catalog).then(() => {
                    this.snackbar = true;
                })
            }
        }
    }

</script>

<style scoped>

</style>