<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-autocomplete
                :readonly="isLocked"
                :value="enumData"
                :items="items"
                @input="change($event)"
                filled
                chips
                :item-text="`label.${$store.getters['general/language']}`"
                item-value="value"
                :multiple="(definition.config || {}).multiSelect"
        >
            <template v-slot:selection="data">
                <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :close="(definition.config || {}).multiSelect"
                        @click="data.select"
                        @click:close="remove(data.item)"
                >
                    <v-avatar left v-if="data.item.unicode">
                        <span class="font--tinpi" style="font-size: 17px" v-html="getUnicodeEntity(data.item.unicode)"></span>
                    </v-avatar>
                    {{ data.item.label[$store.getters['general/language']] }}
                </v-chip>
            </template>
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                    <v-list-item-avatar v-if="data.item.unicode">
                        <v-avatar size="40">
                            <span class="font--tinpi" style="font-size: 28px" v-html="getUnicodeEntity(data.item.unicode)"></span>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.label['de-DE']"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.value"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>

    import _ from 'lodash';
    import TranslationService from "../../../services/TranslationService";
    import LabelComponent from "./style-components/CockpitLabelComponent";
    import FormComponentMixin from "../../mixins/form-component";
    import CockpitFormComponentMixin from "@/components/mixins/cockpit-form-component";

    export default {
        name: "CockpitEnumerationComponent",
        components: {
            LabelComponent
        },
        props: [
            'value',
            'definition',
            'isLocked'
        ],
        mixins: [
            FormComponentMixin,
            CockpitFormComponentMixin
        ],
        data() {
            return {

            }
        },
        created() {
            console.log("cp prop", this.cpDefinition);
        },
        computed: {
            items: function() {
                let items = this.cpDefinition.value;
                if (Array.isArray(items)) {
                    // "enum"
                    return items.map((item) => {
                        return {
                            label: item.description,
                            value: item.label,
                        }
                    });
                } else {
                    // "tree"
                    return items?.values?.map((item) => {
                        return {
                            label: {
                                'de-DE': item.description,
                            },
                            value: item.value,
                        }
                    }) || [];
                }
            },
            mulitSelect: function() {
                let items = this.cpDefinition.value;
                if (Array.isArray(items)) {
                    // "tree"
                    return true;
                } else {
                    // "enum"
                    return false;
                }
            },
            enumData: function () {
                let items = this.cpDefinition.value;
                if (Array.isArray(items)) {
                    // "enum"
                    return this.value;
                } else {
                    // "tree"
                    return this.value?.[0];
                }
            },
        },
        methods: {
            change(value) {
                if (!this.definition?.config?.multiSelect) {
                    this.$emit('input', [value]);
                } else {
                    this.$emit('input', value);
                }
            },
            getUnicodeEntity(unicode) {
                return "&#x" + unicode + ";";
            },
            remove (item) {
                const index = this.value.indexOf(item.value)
                if (index >= 0) this.value.splice(index, 1)
            },
            async save() {
                return _.compact(this.value) || [];
            }
        }
    }
</script>

<style scoped>

    .font--tinpi {
        font-family: "Tinpi";
    }

</style>
