<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-text-field
                dense filled
                hide-details="auto"
                :value="value"
                @input="change($event)"
        ></v-text-field>
    </div>
</template>

<script>

    import _ from 'lodash';
    import TranslationService from "../../../services/TranslationService";
    import LabelComponent from "./style-components/CockpitLabelComponent";

    import FormComponentMixin from "../../mixins/form-component"
    import CockpitFormComponentMixin from "../../mixins/cockpit-form-component"
    import FormRuleProvider from "@/services/form-rule-provider";

    export default {
        name: "CockpitStringComponent",
        components: {
            LabelComponent
        },
        props: [
            'value',
            'definition',
            'isLocked'
        ],
        mixins: [
            CockpitFormComponentMixin
        ],
        data() {
            return {

            }
        },
        computed: {

        },
        methods: {
            change(event) {
                this.$emit('input', event ?? '');
            },
            async save() {
                return this.value;
            }
        }
    }
</script>

<style scoped>

</style>
