<template>

    <div>
        <v-dialog
            persistent
            v-model="dialog"
            max-width="900"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="white"
                       fab x-small class="mr-5"
                       depressed
                       v-bind="attrs"
                       v-on="on"
                >
                    <v-badge color="info"
                             :value="false"
                             dot
                    >
                        <v-icon color="grey darken-4" size="24">
                            newspaper
                        </v-icon>
                    </v-badge>
                </v-btn>
            </template>

            <v-card>

                <v-card-title class="text-h5 font-weight-bold pb-10 pt-5">
                    <span>
                        Update-Neuigkeiten
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn text depressed fab small @click="dialog = false" class="mr-3">
                        <v-icon color="error">
                            close
                        </v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>

                    <v-progress-linear indeterminate
                                       v-if="$store.getters['changelog/getStatus'] === 'loading'"
                    ></v-progress-linear>
                    <template v-if="$store.getters['changelog/getRelevant'] !== 'loading'">
                        <template v-for="(item, index) in changelogs">
                            <div :key="index">
                                <p class="text-h6 mb-0">
                                    <span>{{ item.title }}</span>
                                    <v-chip v-if="item.new" class="ml-2" small color="success">neu</v-chip>
                                </p>
                                <p class="grey--text">{{ getDate(item.date) }}</p>
                                <div v-html="item.body.replaceAll('<a ', '<a target=\'_blank\' ')"></div>
                            </div>
                            <v-divider
                                class="my-5"
                                insets
                                v-if="index < changelogs.length - 1"
                                :key="index"
                            ></v-divider>
                        </template>
                    </template>

                </v-card-text>

                <!--div v-if="notifications.length === 0" class="text-center py-5">
                    <v-icon size="48" class="py-5" color="success">done_all</v-icon>
                    <p class="py-5">Keine Benachrichtigungen.</p>
                </div-->

            </v-card>
        </v-dialog>
    </div>

</template>

<script>

import TimeAgo from 'javascript-time-ago';
import de from 'javascript-time-ago/locale/de.json';
import {DateTime} from "luxon";
TimeAgo.addLocale(de);


export default {
    name: "Changelog",
    data() {
        return {
            lastOpened: new Date(),
            dialog: false,
            showBadge: true,
            timeAgo: new TimeAgo('de-DE')
        }
    },
    computed: {
        changelogs: function() {
            return (this.$store.getters['changelog/getRelevant'] || []).map((log) => {
                return {
                    ...log,
                    new: Boolean((new Date(log.date)) > this.lastOpened)
                }
            });
        },
        unreadNotifications: function () {
            return this.changelogs.filter(n => !n.read) || [];
        }
    },
    methods: {
        getDate(date) {
            return DateTime.fromISO(date).toFormat('dd.LL.yyyy');
        }
    },
    watch: {
        dialog:  {
            handler()  {
                this.unreadNotifications.map((n) => {

                })
            }
        }
    },
    created() {
        console.log("created");
        this.$store.dispatch('changelog/fetchRelevant').then(() => {
            let lastOpened = new Date("1990-01-01");
            let lastOpenedString = this.$settings.getUserSettings('changelog.last_opened');
            if (lastOpenedString) lastOpened = new Date(lastOpenedString);
            console.log("user has last opened at ", lastOpenedString)
            this.lastOpened = lastOpened;
            if (this.changelogs.filter((log) => {
                return (new Date(log.date)) > lastOpened;
            }).length > 0) {
                this.dialog = true;
                this.$settings.setUserSettings('changelog.last_opened', (new Date()).toISOString());
            }
        });
    }
}
</script>

<style scoped>

</style>
