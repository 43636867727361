<template>

    <div>
        <p class="text-h6 mb-4 grey--text">E-Mail Templates</p>

        <v-container class="my-5">

        </v-container>

    </div>

</template>

<script>

    export default {
        name: 'MailTemplates',
        components: {

        },
        data() {
          return {
              code: "<% if (user) { %>\n" +
                  "  <h2><%= user.name %></h2>\n" +
                  "<% } %>"
          }
        },
        methods: {

        },
    }
</script>

<style>
/* required class */
.my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 15px 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
    outline: none;
}
</style>
