<template>

    <div>

        <v-navigation-drawer v-model="opened" fixed temporary right width="600" color="">
            <v-toolbar top absolute width="100%" height="auto" color="white" dense>
                <v-list-item color="white">
                    <v-list-item-avatar color="grey">
                        <v-icon color="white">chat</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>Kommentare</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn text fab @click="closeChat" small>
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-toolbar>


            <v-container ref="messageList" class="message-scroll-container">
                <div v-for="(msg, index) in messageList"
                     :item="msg"
                     :index="index"
                     :key="index"
                >
                    <p class="mx-3 mb-0 mt-3 text--grey text-caption" v-if="(msg.author.id !== user.email)">{{ msg.author.name }} schrieb:</p>
                    <v-card
                            outlined class="py-1 px-3 mb-3 message"
                            max-width="80%"
                            width="auto"
                            flat
                            :class="(msg.author.id === user.email ? 'my-message' : '')"
                    >
                        <!--v-avatar color="red">
                            <span class="white--text headline" v-text="">{{ msg.author.name }}</span>
                        </v-avatar-->
                        <small class="ma-0" v-html="msg.data.text.replaceAll('\n', '<br>')"></small>
                    </v-card>

                </div>

            </v-container>


            <v-toolbar bottom absolute width="100%" height="auto" color="white">

                <v-textarea
                        @keydown.enter="sendMessage"
                        v-model="message"
                        label="Nachricht eingeben"
                        auto-grow
                        outlined
                        :rows="rows"
                        row-height="15"
                        class="my-2"
                        hide-details
                ></v-textarea>

                <v-btn fab text color="white" class="my-2" @click="sendMessage">
                    <v-icon color="grey">send</v-icon>
                </v-btn>

            </v-toolbar>

        </v-navigation-drawer>


        <v-btn fab fixed right bottom color="primary" dark @click="openChat">
            <v-badge
                    :content="newMessagesCount"
                    :value="newMessagesCount"
                    color="green"
            >
                <v-icon>chat</v-icon>
            </v-badge>
        </v-btn>


    </div>

</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import io from 'socket.io-client';
    import gravatar from "gravatar";
    import Vue from 'vue';

    export default {
        name: 'app',
        data() {
            return {
                socket: {},
                rows: 1,
                userID: 0,
                user: {},
                message: "",
                opened: false,
                participants: [
                    {
                        id: 'user1',
                        name: 'Matteo',
                        imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
                    },
                    {
                        id: 'user2',
                        name: 'Support',
                        imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
                    }
                ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
                titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
                messageList: [],
                newMessagesCount: 0,
            }
        },
        methods: {
            sendMessage (e) {
                if (!e.shiftKey) {
                    e.preventDefault();
                    if (this.message !== "") {
                        e.preventDefault();
                        console.log(this.user);
                        this.socket.emit('message', {
                            author: {
                                id: this.user.email,
                                email: this.user.email,
                                name: this.user.name,
                                img: this.user.profile_img,
                            },
                            type: 'text',
                            data: {
                                text: this.message
                            }
                        });
                        this.message = "";
                    }
                }
            },
            onMessageReceived (message) {
                if (!this.opened) this.newMessagesCount++;

                message.author.id = "bla";

                this.messageList = [ ...this.messageList, message ];

                if (message.author.id === this.user.email) {
                    Vue.nextTick(() => {
                        this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight;
                    })
                }
            },
            openChat () {
                this.newMessagesCount = 0;
                this.opened = true;
            },
            closeChat () {
                this.opened = false;
            },
        },
        created() {
            this.socket = io("https://" + window.location.hostname + "/", {
                transports: ['polling'],
            });
            this.socket.on('message', (message) => {
                this.onMessageReceived(message);
            });
            this.socket.on('error', (reason) => {
                console.log('socker.io connection failed', reason);
            });
            this.userID = Math.random();
        }
    }

</script>

<style lang="scss" scoped>
    .message {
        margin-left: 10px;
        margin-right: auto;
        border-radius: 0 15px 15px 15px;
        border-color: lightgray;
        &.my-message {
            border-radius: 15px 0 15px 15px;
            border-color: lightblue;
            margin-left: auto;
            margin-right: 10px;
        }
    }
    .message-scroll-container {
        padding-top: 70px;
        padding-bottom: 100px;
        max-height: 100%;
        overflow-y: scroll;
    }
</style>