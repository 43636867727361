import BasicDataProvider from '../../services/shared/utility/basic-data-provider';

const mixin = {
    computed: {
        salutations: function () {
            return BasicDataProvider.getSalutations();
        },
    },
    methods: {
        getSalutationLabel(contact) {
            return (
                this.salutations.find((s) => s.value === contact?.salutation)
                    ?.text || ''
            );
        },
        getDisplayName(contact, fallback = '') {
            if (contact.firstName && contact.lastName) {
                return [contact.lastName, contact.firstName].join(' ');
            } else if (contact.salutation === 'familie') {
                return 'Familie ' + (contact.lastName || '???');
            } else if (contact.organization) {
                return contact.organization;
            }
            return fallback;
        },
    },
};

export default mixin;
