<template>
  <v-app id="venus__pp">

    <NavBar v-if="$store.getters['auth/isLoggedIn']" :app-mode="appMode"></NavBar>

    <v-main :class="($vuetify.theme.dark ? 'main-content--dark ' : 'main-content ') + ' pb-5'">
      <v-container fluid class="py-4 px-5">
        <router-view :key="$route.name + ($route.params.projectID || '')" :activeUser="activeUser"></router-view>
      </v-container>
    </v-main>

    <notifications group="main" position="bottom right"/>


  </v-app>
</template>

<script>

import NavBar from "./components/navigation/NavBar";
import gravatar from "gravatar";
import {mapActions} from "vuex";
import router from "@/router";
import store from "@/store";

export default {
  name: 'App',
  data: () => ({
    activeUser: null,
    appMode: "pp",
  }),
  components: {
    NavBar
  },
  watch: {
    '$route': function () {
      console.log('loading route.')
      this.setAppMode();
        // load config and prepare theme
        this.$store.dispatch('system-config/fetch').then(() => {
            this.$vuetify.theme.themes.light.primary = this.$store.getters['system-config/getConfig']?.appearance?.colors?.primary_light;
            this.$vuetify.theme.themes.light.secondary = this.$store.getters['system-config/getConfig']?.appearance?.colors?.secondary_light;
            this.$vuetify.theme.themes.dark.primary = this.$store.getters['system-config/getConfig']?.appearance?.colors?.primary_dark;
            this.$vuetify.theme.themes.dark.secondary = this.$store.getters['system-config/getConfig']?.appearance?.colors?.secondary_dark;
        });
        this.$store.dispatch('properties/fetchAll');
        this.$store.dispatch('projects/fetchAll').then(() => {
        this.$store.dispatch('projects/setCurrent', this.$route.params.projectID);
        this.$store.dispatch('channels/fetchForProject', {projectID: this.$route.params.projectID});
      });
    }
  },
  computed: {},
  methods: {
    ...mapActions(['fetchUsers']),
    setAppMode() {
      if (this.$route.matched.some(({name}) => name === 'Admin')) {
        this.appMode = "admin";
      } else {
        this.appMode = "pp";
      }
    }
  },
  async created() {

    this.setAppMode()

    if (this.$store.getters['auth/isLoggedIn']) {
      await this.$store.dispatch('user/fetchPermissions');
      await this.$store.dispatch('user/fetchSettings');
    }

  },

};

</script>

<style lang="scss">

@import "assets/styles/custom";

#venus__pp {
  .v-application--wrap {
    min-height: 100vh !important;
  }
}

.main-content {
  background-color: #efefef;
}

.main-content--class {
  background-color: #2d2d2d;
}

</style>
