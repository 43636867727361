<template>
    <div>

        <v-card class="overflow-y-auto" style="max-height: 80vh;">

            <v-text-field
                          v-model="search"
                          prepend-icon="search"
                          class="ml-5"
            ></v-text-field>

            <v-list
                subheader
            >
                <v-list-item-group v-if="projects.length > 0">

                    <v-list-item
                        v-for="project in projects"
                        :key="project._id"
                        router :to="{
                            name: 'AdminProjectEdit',
                            params: {
                                projectID: project._id,
                            }
                        }"
                    >
                        <v-list-item-avatar>
                            <v-icon>
                                public
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{project.name}}</v-list-item-title>
                            <v-list-item-subtitle>{{project.identifier}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-chip color="" small class="px-2">#{{project.id}}</v-chip>
                        </v-list-item-action>

                    </v-list-item>

                </v-list-item-group>

            </v-list>

        </v-card>

    </div>
</template>

<script>

    export default {
        name: 'ProjectList',
        components: {

        },
        computed: {
            projects: function() {
                return this.$store.getters['projects/getAll'].filter((r) => {
                    return r.name.toLowerCase().includes(this.search.toLowerCase());
                }).sort((a, b) => a.name.localeCompare(b.name)) || [];
            }
        },
        methods: {

        },
        data() {
            return {
                search: "",
            }
        },
        created() {
            this.$store.dispatch('projects/fetchAll');
        }
    }
</script>
