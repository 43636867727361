import axios from 'axios';
import router from '../../router';
import PPSettings from '@/services/pp-settings';
import vuetify from '../../plugins/vuetify';

const store = {
    state: {
        permissions_status: '',
        settings_status: '',
        permissions: {},
        settings: {},
    },

    getters: {
        getPermissionsStatus: (state) => state.permissions_status,
        getPermissions: (state) => state.permissions,
        getSettingsStatus: (state) => state.settings_status,
        getSettings: (state) => state.settings,
    },

    actions: {
        async fetchPermissions({ commit }) {
            return new Promise((resolve, reject) => {
                commit('perm_request');

                axios
                    .get('/api/v2/users/self/permissions')
                    .then((res) => {
                        if (res.data) {
                            commit('perm_success', { perm: res.data });
                            resolve(res);
                        } else {
                            commit('perm_error');
                            reject('no_data');
                        }
                    })
                    .catch(function (err) {
                        commit('perm_error');
                        reject('response_error');
                    });
            });
        },

        async fetchSettings({ commit }) {
            return new Promise((resolve, reject) => {
                commit('settings_request');

                axios
                    .get('/api/v2/users/self/settings')
                    .then((res) => {
                        if (res.data) {
                            commit('settings_success', {
                                settings: { ...res.data, loaded: true },
                            });
                            resolve(res);
                        } else {
                            commit('settings_error');
                            reject('no_data');
                        }
                    })
                    .catch(function (err) {
                        console.error(err);
                        commit('settings_error');
                        reject('response_error');
                    });
            });
        },

        async setSettings({ commit, dispatch }, { key, content }) {
            return new Promise((resolve, reject) => {
                commit('settings_request');

                axios
                    .post('/api/users/settings', {
                        key,
                        content,
                    })
                    .then((res) => {
                        dispatch('user/fetchSettings', {}, { root: true });
                    })
                    .catch(function (err) {
                        commit('settings_error');
                        reject('response_error');
                    });
            });
        },
    },

    mutations: {
        perm_request(state) {
            state.permissions_status = 'loading';
        },
        perm_success(state, { perm }) {
            state.permissions_status = 'success';
            state.permissions = perm;
        },
        perm_error(state) {
            state.permissions_status = 'error';
        },
        settings_request(state) {
            state.settings_status = 'loading';
        },
        settings_success(state, { settings }) {
            state.settings_status = 'success';
            state.settings = settings;
        },
        settings_error(state) {
            state.settings_status = 'error';
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
