<template>
    <span>
        <form
            :action="editorURL"
            method="post"
            target="ixedit"
            style="display: none"
        >
            <input type="hidden" name="path" :value="path" />
            <input
                type="hidden"
                name="metainfos"
                :value="JSON.stringify(metainfos)"
            />
            <input type="hidden" name="token" :value="token" />
            <button type="submit" ref="formSubmit" />
        </form>

        <iframe name="ixedit" class="ixedit-iframe"></iframe>

        <div v-if="loading" class="loader">
            <div class="mx-5">
                <LoadingSpinner
                    color="#720a21"
                    :size="80"
                    style="margin: auto !important"
                />
            </div>
            <p class="text-center mt-5 text-h5 font-weight-light">
                Editor wird vorbereitet...
            </p>
        </div>
    </span>
</template>

<script>
import axios from 'axios';
import LoadingSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner';

export default {
    name: 'InDesignEditor',
    props: ['side', 'entry', 'language'],
    components: {
        LoadingSpinner,
    },
    data() {
        return {
            previewLink: '',
            loading: false,
            failed: false,
            dialog: false,
            metainfos: {
                id: this.entry._id.toString(),
                token: localStorage.getItem('access_token'),
                onlyEditStory: Boolean(
                    process.env.VUE_APP_IEXDIT_ONLY_EDIT_STORY
                ),
                setShowAllFrames: true,
                allowAddFrames: false,
                allowUploadImages: false,
                languageKey: this.language,
                lang: this.language,
                setShowNavigation: Boolean(
                    process.env.VUE_APP_IEXDIT_SHOW_NAVIGATION
                ),
            },
            path: '',
            token: 'S29uemVwdC1pWA==',
        };
    },
    computed: {
        editorURL: function () {
            return (
                process.env.VUE_APP_IXEDIT_URL ||
                'https://edit.we2p.de/we2p/web/iXEdit/we2p_editor.jsp'
            );
        },
    },
    watch: {
        dialog: function (newVal, oldVal) {
            if (newVal && this.previewLink === '') {
                //this.loadPreview();
            }
        },
    },
    methods: {
        receiveMessage(event) {
            if (event.data === 'ixedit.close') {
                this.$emit('close'); // to close dialog
                this.$emit('save'); // to increase __v
            }
        },
        async startEditor() {
            // load metadata

            // generate indd
            await axios
                .post(`/api/comet/indd?entryId=${this.entry._id.toString()}`, {
                    languages: [this.language],
                    side: this.side || 'left',
                })
                .then((res) => {
                    this.path = res.data;
                })
                .catch((e) => {
                    console.log(e);
                });

            // submit to iframe
            setTimeout(() => {
                this.$refs.formSubmit?.click();
                this.loading = false;
            }, 200);
        },
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.startEditor();
        window.addEventListener('message', this.receiveMessage);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.receiveMessage);
    },
};
</script>

<style scoped>
.ixedit-iframe {
    height: 100%;
    width: 100%;
    border: none;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
