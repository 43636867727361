<template>
    <draggable
        class="dragArea"
        tag="div"
        :list="items"
        :group="{ name: 'g1' }"
        :bind="dragOptions"
        @start="drag = true"
        @end="endDrag"
        :options="{ swapThreshold: 0.8 }"
    >
        <template v-for="(item, index) in items">
            <v-expansion-panels
                multiple
                accordion
                tile
                :key="item.name + index"
            >
                <v-expansion-panel>
                    <!-- ENTRY: -->
                    <v-expansion-panel-header class="px-4">
                        <p class="my-0" style="line-height: 1.5">
                            <v-menu
                                bottom
                                min-width="200px"
                                rounded
                                offset-y
                                nudge-left="12px"
                                nudge-bottom="4px"
                                open-on-hover
                                transition="slide-y-transition"
                            >
                                <template v-slot:activator="{ on }">
                                    <span
                                        v-if="
                                            ($settings.getValue(
                                                'language.available'
                                            ).length || []) > 1
                                        "
                                    >
                                        <v-avatar
                                            size="14"
                                            class="mr-2"
                                            v-if="item.lang"
                                            v-on="on"
                                        >
                                            <v-img
                                                :src="`https://webimages.we2p.de/static/flags/svg/${item.lang.slice(
                                                    -2
                                                )}.svg`"
                                            ></v-img>
                                        </v-avatar>
                                    </span>
                                </template>
                                <v-card class="py-1 pl-3 pr-5">
                                    <template
                                        v-for="(name, index) in getNames(
                                            $store.getters[
                                                'entries/allEntries'
                                            ].find(
                                                (e) =>
                                                    e._id.toString() ===
                                                    item._id
                                            ) || {},
                                            item.lang
                                        )"
                                    >
                                        <span :key="index">
                                            <span
                                                v-if="
                                                    ($settings.getValue(
                                                        'language.available'
                                                    ).length || []) > 1
                                                "
                                            >
                                                <v-avatar
                                                    size="14"
                                                    class="mr-2"
                                                    v-if="item.lang"
                                                >
                                                    <v-img
                                                        :src="`https://webimages.we2p.de/static/flags/svg/${name.lang.slice(
                                                            -2
                                                        )}.svg`"
                                                    ></v-img>
                                                </v-avatar>
                                            </span>
                                            <span>
                                                {{ name.value }}
                                            </span>
                                            <br />
                                        </span>
                                    </template>
                                </v-card>
                            </v-menu>

                            <!-- name -->
                            <span
                                :class="`${
                                    !$store.getters['entries/allEntries'].find(
                                        (e) => e._id.toString() === item._id
                                    )
                                        ? 'error--text'
                                        : ''
                                }`"
                                >{{ item.name }}</span
                            >

                            <v-dialog
                                persistent
                                max-width="1800"
                                scrollable
                                v-model="item.dialogOpened"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        depressed
                                        x-small
                                        text
                                        fab
                                        v-on="on"
                                        v-bind="attrs"
                                        class="ml-2"
                                    >
                                        <v-icon> edit </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar elevation="1">
                                        <v-card-title class="headline">
                                            {{ item.name }}
                                        </v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mr-0"
                                            color="red"
                                            icon
                                            @click="item.dialogOpened = false"
                                        >
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <EntryEdit
                                            @save="$emit('entrySave')"
                                            :minimal="true"
                                            :entry-id="item._id"
                                        ></EntryEdit>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>

                            <!-- warning if not found -->
                            <v-tooltip
                                bottom
                                v-if="
                                    !$store.getters['entries/allEntries'].find(
                                        (e) => e._id.toString() === item._id
                                    )
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="ml-2"
                                        color="error"
                                        size="20"
                                        v-on="on"
                                        v-bind="attrs"
                                    >
                                        warning
                                    </v-icon>
                                </template>
                                <span>Der Eintrag existiert nicht mehr.</span>
                            </v-tooltip>

                            <span class="grey--text text-caption"
                                >Seite: {{ item.page.firstPage }} ({{
                                    getSide(item) === 'left'
                                        ? 'links'
                                        : 'rechts'
                                }})</span
                            >

                            <template v-if="!hideTemplates">
                                <br /><span class="grey--text caption">{{
                                    (
                                        $store.getters['templates/getAll'].find(
                                            (t) =>
                                                t._id.toString() ===
                                                item.templateID
                                        ) || {}
                                    ).name
                                }}</span>
                            </template>
                        </p>

                        <img
                            v-if="!hideTemplates"
                            style="
                                max-height: 50px;
                                max-width: 33%;
                                object-fit: contain;
                                object-position: right;
                            "
                            class="mr-5"
                            :src="`https://cc.we2p.de/${$settings.getValue(
                                'cometConnection.client'
                            )}/INSTANCE1/pageitems/preview/${
                                (
                                    $store.getters['templates/getAll'].find(
                                        (t) =>
                                            t._id.toString() === item.templateID
                                    ) || {}
                                ).id
                            }.gif`"
                        />

                        <template v-slot:actions>
                            <v-dialog
                                v-model="item.cloneDialog"
                                width="400"
                                v-if="
                                    ($settings.getValue('language.available')
                                        .length || []) > 1
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="transparent"
                                        v-bind="attrs"
                                        depressed
                                        v-on="on"
                                        fab
                                        x-small
                                        class="mr-1"
                                    >
                                        <v-icon style="transform: none">
                                            content_copy
                                        </v-icon>
                                    </v-btn>
                                </template>

                                <v-card>
                                    <v-card-title
                                        class="text-h5 grey lighten-2"
                                    >
                                        Duplizieren?
                                    </v-card-title>

                                    <v-card-text class="mt-5">
                                        In welche Sprache duplizieren:
                                        <v-radio-group
                                            v-model="duplicateTargetLangCode"
                                            mandatory
                                        >
                                            <v-radio
                                                v-for="lang in $settings.getValue(
                                                    'language.available'
                                                )"
                                                :key="lang"
                                                :label="lang"
                                                :value="lang"
                                            >
                                                <template v-slot:label>
                                                    <v-avatar
                                                        size="18"
                                                        class="ml-2"
                                                    >
                                                        <v-img
                                                            :src="`https://webimages.we2p.de/static/flags/svg/${lang.slice(
                                                                -2
                                                            )}.svg`"
                                                        ></v-img>
                                                    </v-avatar>
                                                    <span class="ml-2">{{
                                                        getLangName(lang)
                                                    }}</span>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-btn
                                            color="error"
                                            text
                                            @click="item.cloneDialog = false"
                                        >
                                            Abbrechen
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="success"
                                            text
                                            @click="
                                                copyItem(
                                                    item,
                                                    index,
                                                    duplicateTargetLangCode
                                                )
                                            "
                                        >
                                            Duplizieren
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <CometPreview
                                v-if="nextLevel > 1"
                                :entry-id="item._id"
                                :languages="[item.lang]"
                            >
                                <template v-slot:default="{ on, attrs }">
                                    <v-btn
                                        v-on="on"
                                        :bind="attrs"
                                        depressed
                                        fab
                                        x-small
                                        class="mr-2"
                                    >
                                        <v-icon
                                            style="transform: none"
                                            color="info"
                                        >
                                            remove_red_eye
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </CometPreview>
                            <CometPreview
                                :urls="(item.printStatus || {}).buildPaths"
                                v-if="
                                    ((item.printStatus || {}).buildPaths || [])
                                        .length > 0 &&
                                    (item.printStatus || {}).buildHash ===
                                        item.currentHash
                                "
                            >
                                <template v-slot:default="{ on, attrs }">
                                    <v-btn
                                        v-on="on"
                                        :bind="attrs"
                                        depressed
                                        fab
                                        x-small
                                    >
                                        <v-icon style="transform: none">
                                            remove_red_eye
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </CometPreview>
                            <DeleteConfirmation
                                :title="`Katalog-Segment generieren?`"
                                text="Das Segement wird generiert und für das Gesamtdokument gespeichert."
                                v-if="nextLevel === 1"
                                @yes="buildConfirmation(index)"
                            >
                                <template v-slot:default="{ on, attrs }">
                                    <v-btn
                                        class="ml-2"
                                        fab
                                        x-small
                                        depressed
                                        text
                                        :bind="attrs"
                                        v-on="on"
                                        :loading="loading[index]"
                                    >
                                        <v-icon
                                            v-if="
                                                (item.printStatus || {})
                                                    .built &&
                                                (item.printStatus || {})
                                                    .buildHash ===
                                                    item.currentHash
                                            "
                                            color="success"
                                            style="transform: none"
                                        >
                                            check
                                        </v-icon>
                                        <v-icon
                                            v-if="
                                                (item.printStatus || {})
                                                    .built &&
                                                (item.printStatus || {})
                                                    .buildHash !==
                                                    item.currentHash
                                            "
                                            color="warning"
                                            style="transform: none"
                                        >
                                            warning
                                        </v-icon>
                                        <v-icon
                                            v-if="
                                                !(item.printStatus || {}).built
                                            "
                                            color="info"
                                            style="transform: none"
                                            size="24"
                                        >
                                            build_circle
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </DeleteConfirmation>
                            <DeleteConfirmation
                                :title="`${
                                    item.children.length > 0
                                        ? 'Die Gruppe'
                                        : 'Den Eintrag'
                                } aus der Planung entfernen?`"
                                @yes="remove(index)"
                            >
                                <template v-slot:default="{ on, attrs }">
                                    <v-btn
                                        fab
                                        x-small
                                        depressed
                                        text
                                        :bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon
                                            color="error"
                                            style="transform: none"
                                            size="20"
                                        >
                                            delete_forever
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </DeleteConfirmation>
                            <v-btn fab x-small depressed text class="mr-2">
                                <v-badge
                                    :content="item.children.length"
                                    :value="item.children.length > 0"
                                    color="grey"
                                >
                                    <v-icon> $expand </v-icon>
                                </v-badge>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <sort-definition-nested
                            @move="$emit('move')"
                            @entrySave="$emit('entrySave')"
                            :items="item.children"
                            :level="nextLevel"
                            :hide-templates="hideTemplates"
                            @save="save"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>
    </draggable>
</template>
<script>
import draggable from 'vuedraggable';
import Vue from 'vue';
import DeleteConfirmation from '../../components/utility/DeleteConfirmation';
import axios from 'axios';
import md5 from 'md5';
import CometPreview from '../entries/CometPreview';
import TranslationService from '../../services/TranslationService';
import EntryEdit from '../../views/PrintSuite/EntryEdit';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import BasicDataProvider from '../../services/shared/utility/basic-data-provider';

export default {
    props: ['items', 'level', 'hideTemplates'],
    components: {
        EntryEdit,
        CometPreview,
        DeleteConfirmation,
        draggable,
    },
    data() {
        return {
            duplicateTargetLangCode: 'de-DE',
            drag: false,
            expanded: [],
            loading: [],
            entryDialog: false,
        };
    },
    name: 'sort-definition-nested',
    methods: {
        getSide(item) {
            let even,
                odd = ''; // left or right

            if (
                Boolean(
                    !this.$settings.getValue(
                        'cometConnection.firstPageWithEntries'
                    ) % 2
                ) ||
                parseInt(
                    this.$settings.getValue(
                        'cometConnection.firstPageWithEntries'
                    )
                ) === 1
            ) {
                // first page number is odd
                if (
                    this.$settings.getValue('cometConnection.sideOfPageOne') ===
                    'l'
                ) {
                    odd = 'left';
                    even = 'right';
                } else {
                    odd = 'right';
                    even = 'left';
                }
            } else {
                // first page number is even
                if (
                    this.$settings.getValue('cometConnection.sideOfPageOne') ===
                    'l'
                ) {
                    even = 'left';
                    odd = 'right';
                } else {
                    even = 'right';
                    odd = 'left';
                }
            }

            console.log('even ', even, ' odd ', odd);

            return item.page?.firstPage % 2 || item.page?.firstPage === 1
                ? odd
                : even;
        },
        save() {
            this.$emit('save');
        },
        endDrag() {
            console.log('drag ended.');
            this.drag = false;
            this.$emit('move');
        },
        getNames(entry = {}, lang) {
            return (
                entry.languages
                    ?.filter((l) => l.code !== lang)
                    ?.map((l) => {
                        return {
                            value: TranslationService.getValue(
                                entry?.content?.name,
                                l.code
                            ),
                            lang: l.code,
                        };
                    }) || []
            );
        },
        remove(index) {
            this.items.splice(index, 1);
            // emit 'move' to trigger sort definition re-evaluation
            this.$emit('move');
        },
        copyItem(item, index, lang) {
            let clone = this.makeClone(item, lang);
            this.items.splice(index + 1, 0, clone);
            item.cloneDialog = false;
            this.$emit('entrySave');
        },
        makeClone(item, lang) {
            return {
                ...item,
                lang,
                id: this.generateSortID(),
                cloneDialog: false,
                children: item?.children?.length
                    ? item.children.map((child) => this.makeClone(child, lang))
                    : [],
                currentHash: '',
                printStatus: {
                    built: false,
                    buildHash: '',
                    buildPaths: [],
                },
            };
        },
        getLangName(lang) {
            return (
                BasicDataProvider.getAllLanguages()?.find(
                    (l) => l.value === lang
                )?.text || 'Unbekannte Sprache'
            );
        },
        generateSortID() {
            return uuidv4();
        },
        buildConfirmation(index) {
            this.save();
            this.loading[index] = true;
            let sortItem = this.items[index];
            axios
                .post(
                    '/api/comet/render',
                    {
                        catalogID: this.$router.currentRoute.params.catalogID,
                        entryID: sortItem._id,
                        options: {
                            side: this.getSide(sortItem),
                            sortBy: this.$router.currentRoute.params.sortID,
                            sortStart: sortItem.id,
                            languages: [sortItem.lang],
                        },
                    },
                    {
                        timeout: 120 * 1000,
                    }
                )
                .then((res) => {
                    console.log('comet sent response: ', res.data);
                    let buildHash = this.generateBuildHash(sortItem);
                    // refresh index, as the order could have been changed meanwhile
                    let freshIndex = this.items.findIndex(
                        (i) => i.id === sortItem.id
                    );
                    if (freshIndex === -1) {
                        this.$notify({
                            group: 'main',
                            type: 'error',
                            duration: 5000,
                            title: 'Der Sortier-Eintrag wurde nicht mehr gefunden.',
                        });
                        return;
                    }
                    this.items.splice(freshIndex, 1, {
                        ...sortItem,
                        currentHash: buildHash,
                        printStatus: {
                            built: true,
                            buildTime: null,
                            buildHash,
                            buildPaths: res.data || [],
                        },
                    });
                    this.save();
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    this.loading.splice(index, 1, false);
                });
        },
        generateBuildHash(sortItem) {
            let hashes = [];
            let entry = this.$store.getters['entries/allEntries'].find(
                (e) => e._id.toString() === sortItem._id
            );
            if (entry) {
                hashes.push(md5(JSON.stringify(entry)));
                for (const item of sortItem.children) {
                    hashes.push(this.generateBuildHash(item));
                }
            }
            return md5(JSON.stringify(hashes));
        },
    },
    computed: {
        pageInfo: function () {
            return '1';
        },
        nextLevel: function () {
            return (this.level ?? 0) + 1;
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            };
        },
    },
};
</script>
<style scoped>
.dragArea {
    min-height: 50px;
    outline: 1px dashed grey;
    background: repeating-linear-gradient(
        45deg,
        #f6f6f6,
        #f6f6f6 10px,
        #fff 10px,
        #fff 20px
    );
}

.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.sortable-ghost {
    height: 5px;
    background: dodgerblue !important;
    overflow: hidden;
}
.sortable-ghost * {
    background: transparent !important;
}

.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
</style>
