<template>
    <div v-if="$store.getters['properties/getStatus'] !== 'loading'">
        <LabelComponent :definition="definition" :is-locked="isLocked"/>

        <v-sheet :class="`grey ${$vuetify.theme.isDark ? 'darken-4' : 'lighten-5'} pa-4`">

            <v-row class="pa-3">
                <v-spacer/>
                <ColumnConfig v-if="$store.getters['user/getSettingsStatus'] === 'success'"
                              :properties="definition.properties"
                              :column-config="columnConfig"
                              @apply="generateHeader"
                />

            </v-row>

            <v-data-table
                class="transparent"
                :disable-sort="true"
                :disable-pagination="true"
                item-key="tableHelperIndex"
                :headers="headers"
                :items="tableData"
                :footer-props="{
                  'items-per-page-options': [-1]
              }"
            >
                <template v-slot:item.controls="props">

                    <v-dialog
                        v-model="dialog[props.item.tableHelperIndex]"
                        scrollable
                        max-width="1600px"
                    >
                        <!-- EDIT -->
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" fab depressed class="mr-3 my-2" x-small>
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.name="{ item }" >
                            {{ item }}
                            {{ translate(item) }}
                        </template>
                        <v-card>
                            <v-card-title>
                                <span>{{ definition.label }}</span>
                                <v-spacer/>
                            </v-card-title>
                            <v-divider></v-divider>
                            <!-- todo - caution when upgrading to vue 3, ref handling might affect this!!! https://v3.vuejs.org/guide/migration/array-refs.html -->
                            <v-card-text style="max-height: 90vh">

                                <v-row>
                                    <v-col cols="12">
                                        <v-form ref="FormValidation">
                                            <EntryEditForm :ref="props.item.tableHelperIndex"
                                                           :language="language"
                                                           :hide-non-multilang="false"
                                                           v-model="data[data.findIndex(row => row.tableHelperIndex === props.item.tableHelperIndex)]"
                                                           :columns="columns"
                                                           :definition="definition.properties"
                                                           :synced-mode="false"
                                                           :is-locked="isLocked"
                                                           @updateEntity="entity = $event"
                                                           @importItinerary="requestImportItinerary()"
                                            />
                                        </v-form>
                                    </v-col>
                                </v-row>

                                <!-- FORM -->
                                <!--v-row>
                                    <v-col :cols="12 / columns.length"
                                           class="border-rig"
                                           v-for="(column,index) in columns"
                                           :key="index"
                                    >

                                            LANG SWITCH todo pass correct languages from EntryEdit.vue
                                        <div class="ml-6" v-if="false">
                                            <div class="mt-5">
                                                <LangSwitch :available="$settings.getValue('language.available')" v-schemas="(column || {})['lang']" />
                                            </div>
                                        </div>

                                        <v-form ref="FormValidation">
                                            <EntryEditForm :ref="props.item.tableHelperIndex"
                                                           :language="(column || {})['lang'] || 'de-DE'"
                                                           :hide-non-multilang="false"
                                                           v-schemas="data[data.findIndex(row => row.tableHelperIndex === props.item.tableHelperIndex)]"
                                                           :columns="columns"
                                                           :definition="definition.properties"
                                                           :synced-mode="false"
                                                           :is-locked="isLocked"
                                                           @updateEntity="entity = $event"
                                                           @importItinerary="requestImportItinerary()"
                                            />
                                        </v-form>
                                    </v-col>
                                </v-row>-->

                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="info" text @click="finishEditing(props.item.tableHelperIndex)">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <!-- DELETE -->
                    <DeleteConfirmation @yes="removeRow(props.item.tableHelperIndex)">
                        <template v-slot:default="{ on, attrs }">
                            <v-btn v-on="on" v-bind="attrs" class="mr-3 my-2" fab color="error" depressed x-small router append :disabled="isLocked">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                    </DeleteConfirmation>

                    <!-- SORT down -->
                    <v-btn class="mr-3 my-2" @click="moveRow('down', props.item.tableHelperIndex)" fab depressed x-small router append :disabled="!(data.findIndex(x => x.tableHelperIndex === props.item.tableHelperIndex) < data.length - 1) || isLocked">
                        <v-icon>keyboard_arrow_down</v-icon>
                    </v-btn>
                    <!-- SORT up -->
                    <v-btn class="mr-3 my-2" @click="moveRow('up', props.item.tableHelperIndex)" fab depressed x-small router append :disabled="!(data.findIndex(x => x.tableHelperIndex === props.item.tableHelperIndex) !== 0) || isLocked">
                        <v-icon>keyboard_arrow_up</v-icon>
                    </v-btn>

                </template>

            </v-data-table>


            <!-- ADD BUTTON -->
            <v-row class="pb-3">
                <v-spacer></v-spacer>
                <v-btn fab color="success" small depressed @click="addRow" class="ml-3" :disabled="isLocked">
                    <v-icon>add</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>

        </v-sheet>

    </div>
</template>

<script>

import _ from 'lodash';
import Vue from 'vue';

import TranslationService from "../../../services/TranslationService";
import LabelComponent from "./LabelComponent";
import ColumnConfig from "../ColumnConfig";
import { v4 as uuidv4 } from 'uuid';
import DeleteConfirmation from "../../utility/DeleteConfirmation";
import TableDataHelper from "../../../services/TableDataHelper";
import FormComponentMixin from "../../mixins/form-component";

export default {
    name: "TableComponent",
    components: {
        DeleteConfirmation,
        EntryEditForm: () => import('../EntryEditForm'),
        LabelComponent,
        ColumnConfig
    },
    props: [
        'value',
        'entities',
        'definition',
        'isLocked',
        'columns',
        'language',
        'syncedMode'
    ],
    mixins: [
        FormComponentMixin
    ],
    data() {
        return {
            linkedEntity: {},
            dialog: {},
            search: '',
            resolvedEntities: [],
            createDialog: false,
            // get skeleton to initially set watch behavior on computed property
            // this.getSkeleton(this.definition),
            headers: [],
            newInCockpitHeaders: [
                { text: '', value: 'controls', sortable: false, width: "40" },
                { text: 'Name', value: 'name', sortable: false, width: "300" },
            ]
        }
    },
    watch: {
        entities: {
            async handler() {
                this.resolvedEntities = [];

                if (this.definition.linkName) {

                    const prop = this.$store.getters['properties/getAll'].find(p => p.name === this.definition.linkName);

                    if (!prop) return;

                    switch (prop.type) {
                        case "Link": {

                            for (const idIndex in this.entities) {
                                let id = this.entities[idIndex].replace("entity_", "");
                                console.log(id, this.definition.linkName)
                                await this.$store.dispatch('entities/fetch', { id });
                                let entity = this.$store.getters['entities/getAll'].find((e) => e._id.toString() === id);
                                if (entity) {
                                    this.resolvedEntities.push(entity);
                                }
                            }
                            break;
                        }
                        case "Table":
                            this.resolvedEntities = _.cloneDeep(this.entities);
                            break;
                    }
                }

            },
            deep: true,
        }
    },
    computed: {
        data: {
            get() {
                return this.value || [];
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        // returns entities/rows that have not yet been synced into pp
        newInCockpitEntities: function() {
            return this.resolvedEntities?.filter((entity) => {

                console.log(entity);

                // check if _id matches with any linkedEntityId
                // or if uuid matches with any linkedRowId

                if (entity._id) {
                    return this.data.findIndex(row => row.linkedEntityId === entity?._id?.toString()) === -1
                } else {
                    return this.data.findIndex(row => row.linkedRowId === entity?.uuid?.toString()) === -1
                }

            })?.map((entity) => {

                // get a meaningful name for every entity
                return {
                    uniqueID: uuidv4(),
                    ...entity,
                    name: TranslationService.getValue(entity?.name, "de-DE"),
                }

            }) || [];
        },
        tableData: function() {

            let tableData = _.cloneDeep(this.data);

            for (const tableIndex in tableData) {

                let entry = tableData[tableIndex];

                let typeDefinition = this.definition;
                let propDefinitions = [];

                if (typeof typeDefinition !== 'undefined') {
                    propDefinitions = typeDefinition.properties;
                }

                if (propDefinitions.length > 0) {
                    for (const propIndex in entry) {
                        // convert each property to "table format"
                        entry[propIndex] = TableDataHelper.convertToTableFormat(entry[propIndex], propIndex, propDefinitions, this.language);
                    }
                }
            }

            return tableData;

        },
        columnSaveKey: function() {
            return `${this.$router.currentRoute.params.projectID}_${this.$router.currentRoute.params.channelID}_tables_${ this.definition.properties.map(x => x.name).join('__') }`
        },
        columnConfig: function() {
            return this.$settings.getUserSettings(
                this.columnSaveKey
            ) || this.definition.properties.filter(p => !['divider', 'headline', 'info'].includes(p.type)).map(x => x.name).slice(0, 10);
        },
    },
    methods: {
        getLinkStatus(row) {
            console.log("checking link status", row);
            if (row.linkedRowId) {
                if (this.entities?.find(e => e.uuid === row.linkedRowId)) {
                    return "uuid_linked";
                }
            } else if (row.linkedEntityId) {
                if (this.entities?.find(e => e.replace('entity_', '') === row.linkedEntityId)) {
                    return "entity_linked";
                }
            }
            return "no_link";
        },
        translate(data) {
            return TranslationService.getValue(data, "de-DE");
        },
        async updateEntity(entity) {

            const prop = this.$store.getters['properties/getAll'].find(p => p.name === this.definition.linkName);
            if (!prop) return;
            const uuid = uuidv4();

            switch (prop.type) {
                case "Link":
                    this.linkedEntity = entity;
                    break;
                case "Table": {
                    let entities = _.cloneDeep(this.entities);
                    let index = entities.findIndex(e => e.uuid === entity.uuid);
                    entities[index] = entity;
                    console.log("index", index)
                    this.$emit('updateEntities', entities);
                    break;
                }

            }
        },
        linkRow(entity) {

            console.log("linking...", entity)

            if (entity._id) {
                this.addRow({
                    linkedEntityId: entity._id.toString(),
                })
            } else {
                this.addRow({
                    linkedRowId: entity.uuid,
                })
            }

        },
        addRow(object = {}) {

            const index = uuidv4()
            this.data.push({
                // temporary id
                tableHelperIndex: index,
                ...object,
            });
            // open dialog
            Vue.set(this.dialog, index, true);

        },
        addToGraph(row) {

            const prop = this.$store.getters['properties/getAll'].find(p => p.name === this.definition.linkName);
            if (!prop) return;

            const uuid = uuidv4();

            switch (prop.type) {
                case "Link":

                    this.$store.dispatch('entities/create', {
                        entity: {
                            types: [prop?.options?.[0] || "Thing"],
                            project_identifier: this.$store.getters['projects/getCurrent']?.identifier,
                            channels: [ this.$store.getters['channels/getCurrent']?.id ]
                        }
                    }).then((res) => {
                        this.$notify({ group: 'main',  type: 'success', duration: 2000, title: 'Graph-Entität gespeichert! (Table)'})
                        Vue.set(row, "linkedEntityId", res._id.toString());
                        console.log("new linkedField", [
                            ...(this.entities || []),
                            "entity_" + res._id.toString()
                        ])
                        this.$emit('updateEntities', [
                            ...(this.entities || []),
                            "entity_" + res._id.toString()
                        ])
                        this.$emit('saveEntity');
                    }).catch((e) => {
                        console.error(e);
                        this.$notify({ group: 'main',  type: 'error', duration: 5000, title: 'Speichern im Graph fehlgeschlagen! (Table)'})
                    })
                    break;
                case "Table":

                    this.$emit('updateEntities', [
                        ...(this.entities || []),
                        {
                            uuid
                        }
                    ]);
                    this.$emit('saveEntity');

                    Vue.set(row, "linkedRowId", uuid);

                    break;
            }

        },
        moveRow(direction, id) {

            const index = this.data.findIndex(x => x.tableHelperIndex === id);

            let targetIndex;
            if (direction === 'up') {
                targetIndex = index - 1;
            } else {
                targetIndex = index + 1;
            }

            if(targetIndex < 0 || targetIndex > this.data.length - 1) {
                console.error('tried to move row to an index that does\'t exit');
                return false;
            }

            if (direction === 'up') {
                this.data.splice(targetIndex, 2, this.data[index], this.data[targetIndex])
            } else {
                this.data.splice(index, 2, this.data[targetIndex], this.data[index])
            }

        },
        removeRow(id) {
            let index = this.data.findIndex(x => x.tableHelperIndex === id)
            this.data.splice(index, 1);
        },
        finishEditing(id) {
            this.dialog[id] = false;
        },
        generateHeader(config) {

            // save to user settings
            if (config.join('|') !== this.columnConfig.join('|')) {
                this.$settings.setUserSettings(
                    this.columnSaveKey,
                    config
                );
            }


            this.headers = [
                { text: 'Aktionen', value: 'controls', sortable: false, width: "260" },
            ];

            this.headers = this.headers.concat(
                config.map((column) => {
                    return {
                        text: this.definition.properties.find(prop => prop.name === column)?.label,
                        value: column,
                        width: "300",
                    }
                })
            );

        },

    },
    created() {
        // assign temporary ids to data array
        this.data = this.data?.map(
            (item) => ({
                tableHelperIndex: uuidv4(),
                ...item,
            })
        );
    }
}
</script>

<style scoped>

</style>
