<template>
    <div class="routerview">
        <router-view v-bind='{ entryId }'></router-view>
    </div>
</template>

<script>
    export default {
        name: 'PlainRouterView',
        props: [
            'entryId',
        ]
    }
</script>
