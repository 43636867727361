<template>

    <v-dialog
            v-model="dialog"
            max-width="500"
            persistent
    >

        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs">
                <v-btn fab depressed class="ml-3" color="red" small v-bind="attrs" v-on="on" v-if="!hidden">
                    <v-icon color="white">
                        delete
                    </v-icon>
                </v-btn>
            </slot>
        </template>

        <v-card class="pb-3">
            <v-card-text class="py-5">
                <span class="text-h5">{{ title || 'Wirklich löschen?' }}</span>
                <br>
                <br>
                <span v-html="msg"></span>
            </v-card-text>
            <v-row class="py-5 px-5 mx-1">
                <v-btn color="success" ref="yes" large rounded depressed @click.stop="yes()">
                    Ja
                </v-btn>
                <v-spacer/>
                <v-btn color="error" ref="no" large rounded depressed @click.stop="no()">
                    Nein
                </v-btn>
            </v-row>
        </v-card>

    </v-dialog>

</template>

<script>

    export default {
        name: 'DeleteConfirmation',
        components: {

        },
        props: ['title', 'msg', 'hidden'],
        data() {
            return {
                dialog: false,
            }
        },
        computed: {

        },
        watch: {

        },
        methods: {
            open() {
                this.dialog = true;
            },
            close() {
                this.dialog = false;
            },
            yes() {
                this.dialog = false;
                this.$emit('yes');
            },
            no() {
                this.dialog = false;
                this.$emit('no');
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
