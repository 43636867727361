<template>

    <div class="my-5" v-if="role">

        <v-row class="mx-2 mb-5">
            <v-spacer></v-spacer>
            <v-btn class="mr-3" color="success" depressed fab small @click="save()">
                <v-icon>
                    save
                </v-icon>
            </v-btn>
            <DeleteConfirmation  @yes="remove">
            </DeleteConfirmation>
        </v-row>


        <v-text-field v-model="role.name" class="ml-5" label="Name" style="max-width: 800px;" filled/>

        <v-tabs
            v-model="tab" background-color="transparent" color="basil" grow
        >
            <v-tab>
                Knowledge Graph
            </v-tab>
            <v-tab>
                Publication Planner
            </v-tab>
            <v-tab>
                Media Collection
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <h3 class="mt-10">cockpit permissions</h3>
            </v-tab-item>
            <v-tab-item>
                <PermissionTree></PermissionTree>
            </v-tab-item>
            <v-tab-item>

            </v-tab-item>
        </v-tabs-items>


    </div>


</template>

<script>
import PermissionTree from "@/components/users/PermissionTree";
import _ from "lodash";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";

export default {
    name: "RoleEdit",
    components: {
        DeleteConfirmation,
        PermissionTree
    },
    data() {
        return {
            tab: 0,
            loading: false,
        }
    },
    computed: {
        roleID: function() {
            return this.$router.currentRoute.params.roleID;
        },
        role: {
            get() {
                return this.$store.getters['roles/getAll'].find(r => r._id.toString() === this.roleID);
            },
            set() {

            }
        }
    },
    watch: {
        role: {
            handler(val, oldVal) {
                // if (oldVal && val) this.save();
            },
            deep: true,
        }
    },
    methods: {
        clone() {
            this.$store.dispatch('roles/addRole', {
                role: {
                    ...this.role,
                    name: this.role.name + " - Kopie",
                    _id: undefined
                }
            }).then(() => {
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "Rolle kopiert." });
            });
        },
        remove() {
            this.loading = true;
            this.$store.dispatch('roles/removeRole', { id: this.roleID }).then(() => {
                this.loading = false;
                this.$router.push({
                    name: "AdminRoles"
                })
            });
        },
        save: _.debounce(function() {
            this.$store.dispatch('roles/updateRole', { id: this.roleID, role: this.role }).then(() => {
                this.loading = false;
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "gespeichert" });
            })
        }, 50)
    }
}
</script>

<style scoped>

</style>
