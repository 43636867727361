import axios from 'axios';
import router from '../../router';

const api = '/api/v2/roles';

const store = {
    state: {
        status: '',
        roles: [],
    },

    getters: {
        getStatus: (state) => state.status,
        getAll: (state) => state.roles,
    },

    actions: {
        async fetchRoles({ commit }) {
            commit('start_loading');
            await axios.get(`${api}`).then((response) => {
                commit('end_loading');
                commit('set_roles', response.data);
            });
        },
        async addRole({ commit, dispatch }, { role }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .post(`${api}`, role)
                    .then(async (res) => {
                        await dispatch('fetchRoles');
                        resolve(res.data);
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
        async updateRole({ commit, dispatch }, { role, id }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .put(`${api}/${id}`, role)
                    .then(async (res) => {
                        resolve();
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
        async removeRole({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .delete(`${api}/${id}`)
                    .then(async (res) => {
                        await dispatch('fetchRoles');
                        resolve();
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
    },

    mutations: {
        set_roles(state, roles) {
            state.roles = roles || [];
        },
        start_loading(state) {
            state.status = 'loading';
        },
        end_loading(state) {
            state.status = 'success';
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
