<template>

    <div>
        <v-sheet>

            <v-row class="ml-4 mb-4">
                <v-avatar color="primary" tile size="80" style="margin-top: -20px;">
                    <v-icon size="50" color="white">filter_alt</v-icon>
                </v-avatar>
                <p class="text-h5 ml-4 mt-3">Filter</p>
            </v-row>


            <!--h3 class="text-h5 pa-5 font-weight-bold">
                <v-icon size="32" class="mr-3">filter_alt</v-icon>
                <span style="vertical-align: bottom">Filter</span>
            </h3-->

            <v-row class="mx-4 mt-5 pb-4">
                <v-col cols="12" class="px-5 py-0 mb-5">
                    <v-switch label="Versteckte Datensätze anzeigen"
                              hint="Zeigt Wohneinheiten und Kontakte an"
                              persistent-hint
                              v-model="value.showHidden"
                    ></v-switch>
                </v-col>

                <!-- TYPES -->
                <v-col cols="3" class="px-5 py-0">
                    <p class="my-0 overline font-weight-bold">Types</p>
                    <v-autocomplete
                        filled dense
                        placeholder="Types wählen..."
                        v-model="value.types"
                        multiple
                        deletable-chips
                        small-chips
                        chips
                        item-value="value"
                        item-text="label"
                        :items="processedTypes"
                    ></v-autocomplete>
                </v-col>
                <!-- CHANNELS -->
                <v-col cols="3" class="px-5 py-0">
                    <p class="my-0 overline font-weight-bold">Channels</p>
                    <v-autocomplete
                        filled dense
                        placeholder="Channels wählen..."
                        v-model="value.channels"
                        multiple
                        small-chips
                        deletable-chips
                        chips
                        item-value="id"
                        item-text="label"
                        :items="processedChannels"
                    ></v-autocomplete>
                </v-col>
                <!-- FEATURES -->
                <v-col cols="3" class="px-5 py-0">
                    <p class="my-0 overline font-weight-bold">Ausstattungsmerkmale</p>
                    <v-autocomplete
                        filled dense
                        placeholder="Merkmal wählen..."
                        v-model="value.topics"
                        multiple
                        small-chips
                        deletable-chips
                        chips
                        item-value="value"
                        item-text="label"
                    ></v-autocomplete>
                </v-col>
                <!-- QUALITY LABELS -->
                <v-col cols="3" class="px-5 py-0">
                    <p class="my-0 overline font-weight-bold">Qualitätssiegel</p>
                    <v-autocomplete
                        filled dense
                        placeholder="Siegel wählen..."
                        v-model="value.topics"
                        multiple
                        small-chips
                        deletable-chips
                        chips
                        item-value="value"
                        item-text="label"
                    ></v-autocomplete>
                </v-col>
                <!-- TOPICS -->
                <v-col cols="3" class="px-5 py-0">
                    <p class="my-0 overline font-weight-bold">Themen</p>
                    <v-autocomplete
                        filled dense
                        placeholder="Themen wählen..."
                        v-model="value.topics"
                        multiple
                        small-chips
                        deletable-chips
                        chips
                        item-value="value"
                        item-text="label"
                        :items="processedTopics"
                    ></v-autocomplete>
                </v-col>
                <!-- CITIES -->
                <v-col cols="3" class="px-5 py-0">
                    <p class="my-0 overline font-weight-bold">Orte</p>
                    <v-autocomplete
                        filled dense
                        placeholder="Orte wählen..."
                        v-model="value.cities"
                        multiple
                        small-chips
                        deletable-chips
                        chips
                        item-value="value"
                        item-text="label"
                    ></v-autocomplete>
                </v-col>
            </v-row>

            <v-btn color="error" class="ml-5 mb-5" depressed rounded @click="change({})">
                <v-icon class="mr-2">
                    clear_all
                </v-icon>
                Filter zurücksetzen
            </v-btn>

        </v-sheet>
    </div>

</template>

<script>
export default {
    name: "EntityListFilter",
    components: {

    },
    props: [
        "value",
        "loading"
    ],
    data() {
        return {

        }
    },
    computed: {
        processedChannels: function () {
            return this.$store.getters['channels/getAll']?.[this.$router.currentRoute.params.projectID]?.map((c) => {
                return {
                    ...c,
                    label: c.labels['de-DE'] + " [" + c.id + "]",
                }
            });
        },
        processedTypes: function () {
            return this.$store.getters['properties/getAll']
                ?.find(p => p.name === 'types')?.["value"]
                //?.filter(v => res.data.includes(v.label))
                ?.map((v) => {
                    return {
                        value: v.label,
                        label: v.description['de-DE'] + " (" + v.label + ")"
                    }
                }).sort((a, b) => a.label.localeCompare(b.label));
        },
        processedTopics: function () {
            return this.$store.getters['properties/getAll']
                ?.find(p => p.name === 'topics')?.["value"]
                //?.filter(v => res.data.includes(v.label))
                ?.map((v) => {
                    return {
                        value: v.label,
                        label: v.description['de-DE'] + " (" + v.label + ")"
                    }
                }).sort((a, b) => a.label.localeCompare(b.label));
        },
    },
    methods: {
        change(value) {
            this.$emit('input', value);
        },
    }
}
</script>

<style scoped>

</style>
