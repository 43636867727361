class TranslationService {
    static getValue(data, lang) {
        data = this.checkStructure(data);

        const obj = data.find((x) => x.lang === lang);
        if (typeof obj !== 'undefined') {
            return obj.value;
        }

        return '';
    }

    static setValue(data, value, lang) {
        data = this.checkStructure(data);

        const index = data.findIndex((x) => x.lang === lang);

        if (index !== -1) {
            data[index]['value'] = value;
            return data;
        } else {
            data.push({
                lang: lang,
                value: value,
            });
            return data;
        }
    }

    static checkStructure(data) {
        if (data === null) {
            // console.info("TranslationService got null and returned blank Array.", data);
            // silence is golden
            return [];
        } else if (typeof data === 'object' && !(data instanceof Array)) {
            console.warn(
                'TranslationService got type object and returned blank Array.',
                data
            );
            return [];
        } else if (typeof data === 'string') {
            console.warn(
                'TranslationService got type string and returned blank Array.'
            );
            return [];
        } else if (typeof data === 'number') {
            console.warn(
                'TranslationService got type number and returned blank Array.'
            );
            return [];
        } else if (typeof data === 'undefined') {
            console.warn(
                'TranslationService got undefined and returned blank Array.'
            );
            return [];
        } else {
            return data;
        }
    }
}

export default TranslationService;
