<template>
    <div class="d-inline-block">
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    rounded
                    v-on="on"
                    v-bind="attrs"
                    color="blue darken-3"
                    @click="sendToEditor"
                    class="mr-3 white--text"
                    :disabled="isLocked"
                >
                    <v-icon left>image</v-icon>
                    <v-icon left>text_fields</v-icon>
                    InDesign
                </v-btn>
            </template>
            <v-card tile>
                <v-toolbar elevation="2">
                    <v-toolbar-title>
                        <img :src="logoURL" height="20" class="mt-3" />
                        <span
                            class="ml-2 font-weight-light"
                            style="vertical-align: text-bottom"
                            >InDesign Editor</span
                        >
                    </v-toolbar-title>
                    <v-avatar size="20" class="ml-3 my-auto" v-if="language">
                        <v-img
                            :src="`https://webimages.we2p.de/static/flags/svg/${currentLanguage.slice(
                                -2
                            )}.svg`"
                        ></v-img>
                    </v-avatar>
                    <v-spacer></v-spacer>
                    <!-- refresh -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                text
                                color="info"
                                class="mr-1"
                                @click="forceReload"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Editor neu laden</span>
                    </v-tooltip>
                    <!-- close -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <DeleteConfirmation
                                @yes="dialog = false"
                                msg="Nicht gespeicherte Änderungen gehen eventuell verloren."
                                title="Wirklich beenden?"
                            >
                                <template v-slot:default="{ on, attrs }">
                                    <div>
                                        <v-btn
                                            icon
                                            text
                                            class="mr-0"
                                            color="error"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </DeleteConfirmation>
                        </template>
                        <span>Schließen ohne Speichern</span>
                    </v-tooltip>
                </v-toolbar>

                <div
                    style="height: calc(100vh - 80px)"
                    v-if="template && template.sideAware && !chosenSide"
                >
                    <div
                        style="
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            position: absolute;
                        "
                    >
                        <p class="">
                            Die Darstellung des Templates ist abhängig davon, ob
                            es mit einer linken oder rechten Seite beginnt.
                            Bitte wählen Sie, in welcher Darstellung Sie das
                            Template bearbeiten wollen.
                        </p>
                        <v-btn-toggle v-model="chosenSide" borderless>
                            <v-btn value="left" x-large>
                                <span class="hidden-sm-and-down">Links</span>
                                <v-icon class="mr-0" right>
                                    auto_stories
                                </v-icon>
                            </v-btn>
                            <v-btn value="right" x-large>
                                <v-icon
                                    right
                                    class="mr-2 ml-0"
                                    style="transform: scaleX(-1)"
                                >
                                    auto_stories
                                </v-icon>
                                <span class="hidden-sm-and-down">Rechts</span>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </div>

                <div
                    style="height: calc(100vh - 80px)"
                    v-if="template && (!template.sideAware || chosenSide)"
                >
                    <InDesignEditor
                        :key="componentKey"
                        :entry="entry"
                        :side="chosenSide"
                        @close="close"
                        @save="$emit('save')"
                        :language="currentLanguage"
                    />
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import InDesignEditor from './InDesignEditor';
import DeleteConfirmation from '@/components/utility/DeleteConfirmation';

export default {
    name: 'InDesignEditorDialog',
    props: ['entry', 'isLocked', 'language'],
    components: {
        DeleteConfirmation,
        InDesignEditor,
        // LoadingSpinner
    },
    data() {
        return {
            componentKey: 0,
            dialog: false,
            forceReloadOnNextOpen: false,
            chosenSide: '',
        };
    },
    computed: {
        logoURL: function () {
            return process?.env?.VUE_APP_LOGO_URL;
        },
        currentLanguage: function () {
            return this.language || 'de-DE';
        },
        template: function () {
            return this.$store.getters['templates/getAll'].find(
                (t) => t._id.toString() === this.entry.templateID
            );
        },
    },
    watch: {
        dialog: function (newVal, oldVal) {
            // if (newVal && this.forceReloadOnNextOpen) { <- unwanted behaviour - should be reloaded now
            if (newVal) {
                this.forceReloadOnNextOpen = false;
                this.forceReload();
            }
        },
    },
    methods: {
        close() {
            this.dialog = false;
            this.forceReloadOnNextOpen = true;
            this.chosenSide = '';
        },
        forceReload() {
            this.chosenSide = '';
            this.componentKey++;
        },
        sendToEditor() {},
    },
    created() {
        this.$store.dispatch('templates/fetchAll');
    },
};
</script>

<style scoped></style>
