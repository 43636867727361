<template>
    <div class="my-5">

        <v-alert outlined type="info" text :color="(definition.config || {}).color || 'info'">
            <span class="font-weight-bold mb-0">{{ definition.label }}</span>
            <br>
            <span>{{ definition.description }}</span>
        </v-alert>

    </div>
</template>

<script>

    import _ from 'lodash';
    import LabelComponent from "../LabelComponent";

    export default {
        name: "InfoComponent",
        components: {

        },
        props: [
            'definition',
            'content'
        ],
        data() {
            return {
                data: _.cloneDeep(this.content)
            }
        },
    }
</script>

<style scoped>

</style>
