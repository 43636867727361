<template>
  <div>

    <v-dialog
        v-model="dialog"
        width="1000"
        scrollable
        :fullscreen="fullscreen"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="darkgrey" v-on="on" v-bind="attrs" depressed rounded dark>
          <v-icon class="mr-2">compare_arrows</v-icon>
          vergleichen
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline text-h4">
          Snapshot:
          <v-spacer></v-spacer>
          <v-btn class="mr-0" icon text color="error" @click="dialog = false" large>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-switch label="Vollbild"
                  v-model="fullscreen"
                  class="ml-5"
        />


        <v-divider></v-divider>

        <v-card-text v-html="diff(newData, oldData)" class="my-5">
          <!--EntryEditForm :definition="entryTypeDefinition" :content="newData['content']" /-->
        </v-card-text>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import EntryEditForm from "./EntryEditForm";

import * as jsondiffpatch from 'jsondiffpatch';

export default {
  name: 'EntryHistoryCompare',
  props: [
    'newData',
    'oldData',
    'entryTypeDefinition'
  ],
  components: {
    //EntryEditForm
  },
  methods: {
    diff(newData, oldData) {
      let delta = jsondiffpatch.diff(oldData, newData);
      return jsondiffpatch.formatters.html.format(delta, newData);
    }
  },
  data() {
    return {
      fullscreen: false,
      dialog: false,
    }
  },
}
</script>

<style scoped>
@import '../../../../../node_modules/jsondiffpatch/dist/formatters-styles/html.css';
</style>
