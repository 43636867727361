import _ from 'lodash';
import Vue from 'vue';
import store from "../store";
import SocketIO from "socket.io-client";

let socket;

class SocketAdapter {

    static init() {

        socket = SocketIO(window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/", {
            transports: ['polling'],
            query: "userID=123",
        })
        this.addListeners();

        socket.emit('store_client', { userID: store.getters['auth/getUser'].id });

    }

    static addListeners() {
        socket.on('hello', (payload) => {
            console.log('hello', payload)
        })
        socket.on('refresh_notifications', () => {
            store.dispatch('notifications/fetchAll');
        })
        socket.on('entries:refresh_single', ({ entryID }) => {
            store.dispatch("entries/fetchSingleEntry", { id: entryID }).then((res) => {})
            Vue.notify({
                group: 'main',
                type: 'info',
                duration: 5000,
                title: "Daten aktualisiert!",
                text: "Der Server hat neue Daten gesendet."
            });
        })
        socket.on('progress', ({ uuid, total, current, message, finished }) => {
            store.dispatch("progress/setProgress", { uuid, total, current, message, finished });
        })
    }

    static joinRoom(name)  {
        socket.emit('join_room', { name })
    }

    static socket() {
        return socket;
    }

}

export default SocketAdapter;
