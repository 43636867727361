<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-text-field
                dense filled
                :readonly="isLocked"
                :rules="rules"
                :value="data"
                @input="change($event, currentLang)"
                hide-details="auto"
        >
            <template slot="append">
                <v-avatar size="20">
                    <v-img :src="`https://webimages.we2p.de/static/flags/svg/${ currentLang.slice('-2') }.svg`"></v-img>
                </v-avatar>
            </template>
        </v-text-field>
    </div>
</template>

<script>

    import _ from 'lodash';
    import TranslationService from "../../../services/TranslationService";
    import LabelComponent from "./LabelComponent";

    import FormComponentMixin from "../../mixins/form-component"
    import FormRuleProvider from "@/services/form-rule-provider";

    export default {
        name: "InputComponent",
        components: {
            LabelComponent
        },
        props: [
            'value',
            'definition',
            'isLocked',
        ],
        mixins: [
            FormComponentMixin
        ],
        data() {
            return {

            }
        },
        computed: {
            rules: function() {
                return FormRuleProvider.getRulesForComponent(this.definition);
            },
            data: function() {
                return TranslationService.getValue(this.value, this.currentLang);
            }
        },
        methods: {
            change(value, lang) {
                this.$emit('input', TranslationService.setValue(this.value, value, lang));
            },
            async save() {
                return _.cloneDeep(this.value);
            }
        }
    }
</script>

<style scoped>

</style>
