<template>


            <v-btn key="1" fab fixed right bottom color="gray" @click="scrollUp" v-if="scroll > 400">
                <v-icon>arrow_upward</v-icon>
            </v-btn>


</template>

<script>
export default {
    name: "BackToTopButton",
    data() {
        return {
            scroll: 0
        }
    },
    methods: {
        scrollUp() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        handleScroll(event) {
            this.scroll = window.scrollY;
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style scoped>

</style>
