<template>

    <v-autocomplete class="pt-0"
                    v-if="!loading"
                    clearable
                    v-model="userId"
                    :items="users"
                    item-value="id"
                    :loading="loading"
                    item-text="name"
                    :label="loading ? 'Wird geladen...' : 'Person wählen...'"
                    single-line
    >
        <template v-slot:selection="{ item, index }">
            <UserChip :user="currentUser"></UserChip>
        </template>
    </v-autocomplete>

</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import UserChip from "./UserChip";
    import axios from "axios";

    export default {
        name: "UserSelect",
        components: {
            UserChip
        },
        props: ["uuid"],
        data() {
            return {
                loading: false,
                users: [],
            }
        },
        computed: {
            userId: {
                get: function () {
                    return this.uuid;
                },
                // setter
                set: function (newValue) {
                    this.$emit('change', newValue)
                }
            },
            currentUser() {
                return this.users.find(x => x.id === this.uuid);
            },
        },
        methods: {
        },
        created() {

            console.log(this.uuid);

            this.loading = true;

            axios.get(`/api/users/project/${this.$router.currentRoute.params.projectID}`).then((res) => {
                this.users = res.data;
                this.loading = false;
            }).catch((e) => {
                this.loading = true;
                console.error(e);
            })


        }
    }


</script>

<style scoped>

</style>
