<template>
    <div class="channels">
        <p class="text-caption mb-0 ml-5 mt-5">Projekt</p>
        <h1 class="mx-5 display-2">{{ $store.getters['projects/getCurrent'].name }}</h1>

        <v-spacer style="height: 30px"></v-spacer>

        <!-- GRAPH -->
        <router-link v-if="false" router :to="{
            name: 'GraphEntities',
        }" style="text-decoration: none; max-width: 500px; display: block">
            <v-card class="mt-5 mx-5">
                <v-img :src="`https://i1.wp.com/chelseatroy.com/wp-content/uploads/2017/12/graph-db.png?fit=1000%2C745&ssl=1`"
                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
                       class="white--text align-end"
                       height="300px"
                >
                    <v-card-title>
                        <span class="ml-3 text-h4 font-weight-bold" style="word-break: normal">Knowledge Graph</span>
                    </v-card-title>
                </v-img>

                <v-card-actions>
                    <v-btn text>
                        <v-icon>subdirectory_arrow_right</v-icon>
                        <span>Alle Datensätze</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </router-link>


        <v-spacer style="height: 40px"></v-spacer>


        <!-- CHANNELS -->
        <v-container class="mx-3">
            <h3 class="text-h3">Channels</h3>
            <ChannelGrid></ChannelGrid>
        </v-container>

    </div>
</template>

<script>

import ChannelGrid from "../components/dashboard/ChannelGrid";

export default {
    components: {
        ChannelGrid
    },
    name: 'Channels',
    props: ['activeUser'],
    created() {

    }
}

</script>
