<template>
    <v-dialog
        v-model="dialog"
        width="1000"
        persistent
        scrollable
    >
        <template v-slot:activator="scope">
            <slot name="activator" v-bind="scope"></slot>
        </template>

        <v-card>

            <v-card-title class="text-h4 mb-3 py-5">
                <span>Konfiguration</span>
                <v-spacer></v-spacer>

                <template v-if="!create">
                    <DeleteConfirmation v-slot="{ attrs, on }"
                                        v-if="!unlockCreateFields"
                                        @yes="unlockCreateFields = true"
                                        title="Wirklich entsperren?"
                                        msg="Name und Typ eines Datenfelds sollten nur geändert werden, wenn noch nichts gepfelgt wurde. Falls bereits Einträge gepfelgt wurden, bitte vor der Konvertierung mit einem Programmierer besprechen."
                    >
                        <v-btn text v-on="on" v-bind="attrs">
                            <v-icon>lock</v-icon>
                        </v-btn>
                    </DeleteConfirmation>
                    <v-btn text v-if="unlockCreateFields" @click="unlockCreateFields = false">
                        <v-icon>lock_open</v-icon>
                    </v-btn>
                </template>

            </v-card-title>

            <v-card-text style="max-height: 90vh">

                <v-form ref="form">

                    <v-select style="max-width: 300px"
                              :items="propertyTypes"
                              item-text="label"
                              item-value="value"
                              v-model="prop.type"
                              label="Datenfeld Typ"
                              :rules="typeRules"
                              :disabled="isLocked"
                    ></v-select>

                    <v-text-field
                        v-model="prop.name"
                        label="Name"
                        :rules="nameRules"
                        prepend-icon="code"
                        v-if="isDataComponent"
                        :disabled="isLocked"
                    ></v-text-field>

                    <v-checkbox
                        v-model="prop.hidden"
                        label="In Maske ausblenden"
                        :prepend-icon="prop.hidden ? 'visibility_off' : 'visibility'"
                        v-if="isDataComponent"
                    ></v-checkbox>

                    <v-text-field
                        v-model="prop.label"
                        label="Label"
                        prepend-icon="edit"
                    ></v-text-field>

                    <v-textarea
                        v-model="prop.description"
                        label="Beschreibung"
                        prepend-icon="info"
                        rows="1"
                        auto-grow
                    ></v-textarea>

                    <!-- VALIDATORS -->
                    <div v-if="isDataComponent">
                        <v-autocomplete
                            prepend-icon="rule"
                            deletable-chips
                            chips
                            multiple
                            style="max-width: 500px;"
                            v-model="prop.validators"
                            v-if="((validators.find(v => v.componentType === prop.type) || {}).validators || []).length > 0"
                            :items="(validators.find(v => v.componentType === prop.type) || {}).validators"
                            item-text="label"
                            item-value="name"
                            label="Validierung"
                        ></v-autocomplete>
                        ´                        </div>

                    <!-- DATA LINK to GRAPH -->
                    <template v-if="isDataComponent">
                        <div>
                            <v-autocomplete
                                prepend-icon="link"
                                clearable
                                auto-select-first
                                v-model="prop.linkName"
                                :items="getLinkProps"
                                style="max-width: 500px;"
                                :filter="customFilter"
                                item-text="name"
                                :loading="$store.getters['properties/getStatus'] === 'loading'"
                                item-value="name"
                                label="Verlinkte Knowledge Graph Daten"
                            ></v-autocomplete>
                        </div>
                        <div v-if="prop.linkName" class="ml-5">
                            <p class="mb-0 text-caption">Sync-Richtung</p>
                            <v-btn-toggle v-model="prop.linkLock" mandatory>
                                <v-btn title="beide">
                                    <v-icon>swap_vert</v-icon>
                                </v-btn>
                                <v-btn title="nur Graph -> PP">
                                    <v-icon>south</v-icon>
                                </v-btn>
                                <v-btn title="nur PP -> Graph">
                                    <v-icon>north</v-icon>
                                </v-btn>
                                <v-btn title="Sync sperren">
                                    <v-icon>lock</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </template>

                    <v-divider class="mb-5 mt-2"></v-divider>

                    <!-- additional config -->
                    <div v-if="typeof prop.config !== 'undefined'">

                        <!-- INFO COLOR -->
                        <div v-if="prop.type === 'info'">
                            <v-subheader>Farbe</v-subheader>
                            <v-color-picker
                                :mode.sync="colorPickerMode"
                                class="mx-5"
                                dot-size="25"
                                hide-mode-switch
                                swatches-max-height="200"
                                v-model="prop.config.color"
                                show-swatches
                                :swatches="colorPickerSwatches"
                            ></v-color-picker>
                        </div>

                        <!-- TEXT-AREA -->
                        <div v-if="prop.type === 'textarea'">
                            <v-subheader>Max. Anzahl Zeichen</v-subheader>
                            <v-text-field class="my-0 py-0 ml-4"
                                          dense
                                          type="number"
                                          v-model="prop.config.charCount"
                            />
                            <v-subheader>Initiale Anzahl Zeilen</v-subheader>
                            <v-text-field class="my-0 py-0 ml-4"
                                          dense
                                          type="number"
                                          v-model="prop.config.rowCount"
                            />
                            <v-subheader>Dynmaische Anpassung der Höhe</v-subheader>
                            <v-switch class="my-0 py-0 ml-4"
                                      dense
                                      color="success"
                                      v-model="prop.config.autoGrow"
                            ></v-switch>
                        </div>

                        <!-- Table -->
                        <div v-if="prop.type === 'table'">
                            <v-subheader>we2p® Types:</v-subheader>
                            <v-text-field class="my-0 py-0 ml-4"
                                          dense
                                          persistent-hint
                                          hint="Es werden nur Datensätze mit diesen Types (oder deren Subtypes) in das Datenfeld importiert."
                                          v-model="prop.config.types"
                            />
                        </div>

                        <!-- ENUMERATION -->
                        <div v-if="prop.type === 'enumeration'">
                            <v-row>
                                <v-col cols="12">
                                    <v-subheader>Abweichender Name im XML Export</v-subheader>
                                    <v-text-field class="my-0 py-0 ml-4"
                                                  dense
                                                  v-model="prop.config.alternativeNodeName"
                                    />
                                    <v-subheader>Piktogramm-Schriftart</v-subheader>
                                    <v-autocomplete class="my-0 py-0 ml-4"
                                                    style='max-width: 280px'
                                                    :items='pictoFonts'
                                                    dense
                                                    v-model="prop.config.pictoFont"
                                    />
                                    <v-subheader>Mehrfach-Auswahl zulässig</v-subheader>
                                    <v-switch class="my-0 py-0 ml-4"
                                              dense
                                              style='max-width: 200px'
                                              color="success"
                                              v-model="prop.config.multiSelect"
                                    ></v-switch>
                                    <!-- todo
                                    <v-subheader>Als Checkboxen darstellen (coming soon)</v-subheader>
                                    <v-switch class="my-0 py-0 ml-4"
                                              dense
                                              color="success"
                                              v-schemas="prop.config.checkboxLayout"
                                    ></v-switch>
                                    -->
                                    <v-subheader>Mögliche Werte für Enumeration</v-subheader>
                                    <!--v-text-field v-schemas="searchEnum"
                                                  clearable
                                                  class="ml-5"
                                                  style="max-width: 500px"
                                                  append-outer-icon="search"
                                                  label="Durchsuchen"
                                    ></v-text-field-->
                                    <v-virtual-scroll
                                        :items="prop.config.values"
                                        height="500"
                                        item-height="132"
                                    >
                                        <template v-slot:default="{ item, index }">
                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-btn color="error" depressed fab x-small @click="deleteEnumValue(index)" style="margin-top: -20px;margin-right: -20px">
                                                        <v-icon>delete_forever</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-row dense class="ma-0 pa-0">
                                                        <v-col>
                                                            <v-text-field
                                                                dense
                                                                v-model="item.value"
                                                                label="Wert"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                dense
                                                                v-model="item.label['de-DE']"
                                                                label="Label"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col sm="2">
                                                            <v-text-field
                                                                dense
                                                                v-model="item.unicode"
                                                                label="HEX Unicode"
                                                            >
                                                                <template v-slot:prepend>
                                                                    <span :style="`font-size: 24px;transform: translateY(-5px); font-family: '${ currentPictoFont }';`" v-html="getUnicodeEntity(item.unicode)"></span>
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense class="ma-0 pa-0" v-if="availableLanguages.length > 1 && currentLanguage">
                                                        <v-col class="text-right">
                                                            <LangSwitch :dense="true" class="ml-auto" :available="availableLanguages" v-model="currentLanguage"/>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                dense
                                                                v-model="item.label[currentLanguage]"
                                                                :label="`Label (${ currentLanguage })`"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col sm="2">
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                    <v-btn color="success" @click="addEnumValue()" fab small>
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-btn v-if="prop.linkName" class="ml-3" color="blue" @click="importEnumValues()" fab small>
                                        <v-icon color="white">download</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                    </div>


                </v-form>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="cancelEditing">
                    Abbrechen
                </v-btn>
                <v-btn color="success" @click="finishEditing">
                    OK
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import FormRuleProvider from "@/services/form-rule-provider";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
import LangSwitch from "@/components/utility/LangSwitch";

import colors from 'vuetify/lib/util/colors';
import BasicDataProvider from '../../services/shared/utility/basic-data-provider';

export default {
    name: "PropertyEdit",
    components: {
        LangSwitch,
        DeleteConfirmation
    },
    emits: [
        'done'
    ],
    props: [
        "property",
        "create"
    ],
    data() {
        return {
            lang: "",
            searchEnum: "",
            unlockCreateFields: false,
            initialProp: _.cloneDeep(this.property),
            colorPickerMode: "hexa",
            colorPickerSwatches: [
                [
                    "#4caf50",
                    "#2196f3",
                    "#fb8c00",
                    "#ff5252",
                ]
            ],
            prop: _.defaultsDeep(
                _.cloneDeep(this.property),
                {
                    config: {
                        rowCount: 3,
                        multiSelect: true,
                        autoGrow: true,
                        checkboxLayout: false,
                        alternativeNodeName: "",
                        color: "#2196f3",
                        values: []
                    }
                }
            ),
            dialog: false,
            typeRules: [
                (v) => {
                    if (!v) {
                        return 'Typ erforderlich!'
                    }
                    return true;
                }
            ],
            nameRules: [
                (v) => {
                    if (this.isDataComponent) {
                        if(!v) {
                            return "Name erforderlich!";
                        } else if(["label", "parentID", "_id", "template", "status"].includes(v)) {
                            return `${v} ist ein reservierter Name!`;
                        }
                    }
                    return true;
                }
            ],
            validators: FormRuleProvider.getAllRules(),
            propertyTypes: [
                {
                    label: "String",
                    value: "input"
                },
                {
                    label: "Nummer",
                    value: "number"
                },
                {
                    label: "Checkbox",
                    value: "boolean"
                },
                {
                    label: "Text (mit Umbrüchen)",
                    value: "textarea"
                },
                {
                    label: "Enumeration",
                    value: "enumeration"
                },
                {
                    label: "Tabelle",
                    value: "table"
                },
                {
                    label: "Adresse",
                    value: "address"
                },
                {
                    label: "Touren-Editor",
                    value: "trailEditor"
                },
                {
                    label: "Überschrift",
                    value: "headline"
                },
                {
                    label: "Hinweis",
                    value: "info"
                },
                {
                    label: "Trenner",
                    value: "divider"
                },
            ]
        }
    },
    computed: {
        ...mapGetters('properties', {getLinkProps: 'getAll'}),
        availableLanguages: function () {
            return this.$settings.getValue('language.available') || [];
        },
        isLocked() {
            return !(this.create || this.unlockCreateFields);
        },
        isDataComponent() {
            return !['divider', 'headline', 'info', 'trailEditor'].includes(this.prop.type);
        },
        currentLanguage: {
            get() {
                return this.lang || this.availableLanguages[1] || null;
            },
            set(value) {
                this.lang = value;
            }
        },
        pictoFonts: function() {
            return BasicDataProvider.getPictoFonts();
        },
        currentPictoFont: function() {
            return this.prop?.config?.pictoFont || "tinpi";
        }
    },
    methods: {
        ...mapActions('properties', { fetchLinkProps: 'fetchAll' }),
        isHex(h) {
            let a = parseInt(h,16);
            return (a.toString(16).padStart(4, '0') === h.toLowerCase())
        },
        getUnicodeEntity(unicode) {
            if (unicode?.length === 4 && this.isHex(unicode)) {
                return "&#x" + unicode + ";";
            } else {
                return "<span style='font-family: sans-serif;'>?</span>"
            }
        },
        addEnumValue() {
            if (typeof this.prop.config.values === "undefined") {
                this.prop.config.values = [];
            }
            this.prop.config.values.push(
                {
                    value: "",
                    label: {
                        "de-DE": ""
                    },
                }
            );
        },
        importEnumValues() {

            const cockpitConf = this.getLinkProps;
            let values = [];
            let mode = "multi"; // or "single"

            const cockpitProperty = cockpitConf.find(x => x.name === this.prop.linkName);

            if(typeof cockpitProperty !== "undefined") {
                values = cockpitProperty.value;
            }
            // for single-select
            if(Object.prototype.hasOwnProperty.call(values, 'values')) {
                values = values.values;
                mode = "single";
            }

            values = values.filter((x) => {
                return this.prop.config.values.find(y => y.value === x[( mode==="single" ? "value" : "label" )]) === undefined;
            });

            values = values.map((x) => {
                return {
                    label: (mode === "single") ? { "de-DE": x.description } : x.description,
                    value: (mode === "single") ? x.value : x.label
                }
            })


            this.prop.config.values = this.prop.config.values.concat(values);


        },
        deleteEnumValue(index) {
            this.prop.config.values.splice(index, 1);
        },
        finishEditing() {

            if (!this.$refs.form.validate()) {
                return;
            }

            this.dialog = false;

            // name acts as identifier in parent, so we need to send the old name if it changes
            let oldName = "";
            if (this.initialProp.name !== this.prop.name) {
                oldName = this.initialProp.name;
            }

            this.initialProp = _.cloneDeep(this.prop);

            if (this.create && !this.isDataComponent) {
                this.prop.name = uuidv4();
            }

            this.$emit('done', this.prop, oldName);

            // reset the component if a new property was added
            if (this.create) {
                this.prop = {};
                this.initialProp = {};
            }
        },
        cancelEditing() {
            this.dialog = false;
            this.prop = _.cloneDeep(this.initialProp);
        },
        customFilter (item, queryText, itemText) {
            const textOne = item.name.toLowerCase()
            const textTwo = item.label.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
    },
    async created() {
        if(typeof this.prop.config === 'undefined') {
            this.prop.config = {};
        }

        await this.fetchLinkProps();

    }
}

</script>

<style scoped>

</style>
