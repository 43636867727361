<template>
    <div class="users">

        <p class="text-h5">Benutzer</p>

        <p class="body-1 my-5">Benutzer müssen über das Graph-Backend angelegt/gelöscht werden.</p>

        <v-text-field
                v-model="search"
                class="my-4"
                append-icon="mdi-magnify"
                label="Suchen"
                single-line
                hide-details
                style="max-width: 300px;"
        ></v-text-field>

        <v-data-table
                :headers="headers"
                :items="$store.getters['users/getAll']"
                sort-by="name"
                :items-per-page="100"
                class="elevation-1"
                :loading="$store.getters['users/getStatus'] === 'loading'"
                :search="search"
        >
            <template v-slot:item.controls="props">
                <v-btn class="mr-3 my-2" :to="`${props.item.id}`" fab depressed x-small router append>
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn class="mr-3 my-2"
                       @click="$store.dispatch('auth/actAs', { userID: props.item.id })"
                       color="info" fab depressed x-small
                       title="Identität übernehmen"
                >
                    <v-icon>badge</v-icon>
                </v-btn>
            </template>
        </v-data-table>

    </div>
</template>

<script>

    import Chat from "../../../components/utility/Chat";
    import axios from "axios";
    import UserService from "../../../services/UserService";

    export default {
        name: 'Users',
        components: {
            // Chat
        },
        data() {
            return {
                search: "",
                headers: [
                    { text: 'Aktionen', value: 'controls', sortable: false, width: "120" },
                    { text: 'Name', value: 'name'},
                    { text: 'Login-Name', value: 'login'},
                    { text: 'Firma', value: 'firmation'},
                ]
            }
        },
        computed: {

        },
        methods: {

        },
        created() {
            this.$store.dispatch('users/fetchAll')
        }
    }
</script>
