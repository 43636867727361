import axios from 'axios';
import router from "../../router";

const store = {

    state: {
        status: '',
        data: []
    },

    getters: {
        getStatus: (state) => state.status,
        getAll: (state) => state.data,
    },

    actions: {
        async fetchAll({ commit }) {
            commit('start_loading');
            await axios.get(`/api/notifications`).then((response) => {
                commit('end_loading');
                commit('set_data', response.data?.data);
            });
        },
        async update({ commit, dispatch }, { payload, id }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios.put(`/api/notifications/${id}`, payload).then(async (res) => {
                    resolve();
                }).catch((e) => {
                    reject();
                    console.error(e);
                }).finally(() => {
                    commit('end_loading');
                });
            });
        },
        async remove({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios.delete(`/api/notifications/${id}`).then(async (res) => {
                    await dispatch('fetchAll');
                    resolve();
                }).catch((e) => {
                    reject();
                    console.error(e);
                }).finally(() => {
                    commit('end_loading');
                });
            });
        },
    },

    mutations: {
        set_data(state, data) {
            state.data = data || [];
        },
        start_loading(state) {
            state.status = "loading";
        },
        end_loading(state) {
            state.status = "success";
        },
    }

}


export default {
    ...store,
    namespaced: true
}
