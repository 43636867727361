<template>
  <div class="my-5">
    <v-row class="mx-2 mb-5">
      <v-spacer></v-spacer>
      <v-btn class="mr-3" color="success" depressed fab small @click="save"
             :loading="$store.getters['projects/getStatus'] === 'loading'">
        <v-icon>
          save
        </v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ChannelEdit",
  methods: {
    save() {
      console.log('save');
    }
  }
}
</script>

<style scoped>

</style>
