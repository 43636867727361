import { render, staticRenderFns } from "./EntryTypeCreate.vue?vue&type=template&id=6ec0f210&scoped=true"
import script from "./EntryTypeCreate.vue?vue&type=script&lang=js"
export * from "./EntryTypeCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec0f210",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/print-suite/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ec0f210')) {
      api.createRecord('6ec0f210', component.options)
    } else {
      api.reload('6ec0f210', component.options)
    }
    module.hot.accept("./EntryTypeCreate.vue?vue&type=template&id=6ec0f210&scoped=true", function () {
      api.rerender('6ec0f210', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/entrytypes/EntryTypeCreate.vue"
export default component.exports