<template>
    <v-container class="entrytypeedit">

        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

        <h1>{{ entrytype.name }}</h1>

        <v-row>
            <v-col sm="12" md="6">
                <v-text-field label="Name" v-model="entrytype.name"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="12" md="6">
                <v-text-field label="we2p® Type" v-model="entrytype.type"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="12" md="6">
                <v-select
                        :menu-props="{ offsetY: true }"
                        v-model="entrytype.templates"
                        :items="getAllTemplates"
                        item-value="_id"
                        :item-text="templateName"
                        label="Templates"
                        multiple
                        chips
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip>{{ templateName(item) }}</v-chip>
                    </template>
                </v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="12" md="6">
                <v-checkbox style="max-width: 200px"
                            label="IXEdit deaktivieren"
                            v-model="entrytype.disableIXEdit"
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="12">
                <h3>Datenfelder</h3>
            </v-col>
            <PropertyList v-if="!loading" :props="entrytype.properties" ref="properties" @update="savingRequired = true"/>
        </v-row>

        <v-btn fab x-large fixed :color="saveColor" bottom right @click="save" :disabled="!savingRequired">
            <v-icon v-if="!saving">
                save
            </v-icon>
            <v-progress-circular indeterminate v-if="saving"/>
        </v-btn>

    </v-container>

</template>

<script>

    import Vue from 'vue';
    import axios from "axios";
    import draggable from "vuedraggable";
    import {mapActions, mapGetters} from "vuex";
    import PropertyList from "../../components/entrytypes/PropertyList";
    import _ from "lodash";

    export default {
        name: 'EntryTypeEdit',
        components: {
            PropertyList
        },
        data() {
            return {
                entrytype: {},
                initialEntryType: "",
                loading: false,
                saving: false,
                savingRequired: false
            }
        },
        computed: {
            ...mapGetters("templates", {'getAllTemplates': 'getAll'}),
            saveColor() {
                if (this.savingRequired) {
                    return "warning";
                } else {
                    return "success";
                }
            }
        },
        methods: {
            ...mapActions("templates", {'fetchAllTemplates': 'fetchAll'}),
            ...mapActions("entrytypes", {'updateEntryType': 'update'}),
            templateName: item => item.name,
            async save() {
                this.saving = true;

                this.entrytype.properties = await this.$refs.properties.save();

                this.updateEntryType(this.entrytype).then(() => {
                    this.saving = false;
                    // update version
                    this.entrytype.__v++;
                    // update latest saved version
                    this.initialEntryType = JSON.stringify(this.entrytype);
                    this.savingRequired = false;
                }).catch(() => {
                    alert('Speichern fehlgeschlagen.')
                })
            }
        },
        watch: {
            entrytype: {
                handler: function (val, oldVal) {
                    if (this.initialEntryType !== JSON.stringify(val)) {
                        this.savingRequired = true;
                    } else {
                        this.savingRequired = false;
                    }
                },
                deep: true
            }
        },
        beforeRouteLeave (to, from, next) {
            if (!this.savingRequired) {
                next();
            } else {
                const answer = window.confirm('Alle ungespeicherten Änderungen gehen verloren. Seite verlassen?')
                if (answer) {
                    next();
                } else {
                    next(false);
                }
            }
        },
        async created() {

            await this.fetchAllTemplates();

            this.loading = true;
            const id = this.$router.currentRoute.params.entryTypeID;
            const response = await axios.get(`/api/entrytypes/${id}`);

            this.loading = false;
            this.entrytype = response.data;

            this.initialEntryType = JSON.stringify(response.data);
        }
    }
</script>

<style scoped>
    .ghost {
        opacity: 0.2;
        background: #c8ebfb;
    }

    .button {
        margin-top: 35px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
</style>
