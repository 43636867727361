import axios from 'axios';
import router from '../../router';

const state = {
    catalogs: [],
    currentCatalog: {
        assignedAdmin: '0',
        assignedCustomer: '0',
    },
};

const getters = {
    allCatalogs: (state) => state.catalogs,
    currentCatalog: (state) => state.currentCatalog,
};

const actions = {
    async fetchCatalogsByChannel({ commit }, channelID = '') {
        const response = await axios.get(
            `/api/v2/catalogs/?projectId=${router.currentRoute.params.projectID}&channelId=${router.currentRoute.params.channelID}`
        );
        commit('setCatalogs', response.data);
    },
    async fetchCurrentCatalog({ commit }, catalogID) {
        const response = await axios.get(`/api/v2/catalogs/${catalogID}/`);
        commit('setCurrentCatalog', response.data || {});
    },
    async addCatalog({ commit, dispatch }, catalog) {
        const response = await axios.post('/api/v2/catalogs/', catalog);
        await dispatch('fetchCatalogsByChannel', catalog.channelID);
    },
    async cloneCatalog({ commit, dispatch }, { catalogId, newName }) {
        const response = await axios.post('/api/v2/catalogs/clone', {
            catalogId,
            newName,
        });
        await dispatch(
            'fetchCatalogsByChannel',
            router.currentRoute.params.channelID
        );
    },
    async updateCatalog({ commit, dispatch, rootState }, catalog) {
        const response = await axios.put(`/api/v2/catalogs/${catalog._id}`, {
            projectID: router.currentRoute.params.projectID,
            ...catalog,
        });
        if (catalog._id === state.currentCatalog._id) {
            await dispatch('fetchCurrentCatalog', catalog._id);
        }
        if (state.catalogs.length > 0) {
            await dispatch(
                'fetchCatalogsByChannel',
                router.currentRoute.params.channelID
            );
        }
    },
    async removeCatalog({ commit, dispatch, rootState }, id) {
        const response = await axios.delete(`/api/v2/catalogs/${id}/`);
        await dispatch(
            'fetchCatalogsByChannel',
            router.currentRoute.params.channelID
        );
    },
};

const mutations = {
    setCurrentCatalog: (state, catalog) => {
        state.currentCatalog = catalog;
    },
    setCatalogs: (state, catalogs) => {
        state.catalogs = catalogs;
    },
    addCatalog: (state, catalog) => state.catalogs.unshift(catalog),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
