<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-textarea
                counter
                :rules="rules"
                :readonly="isLocked"
                :rows="(definition.config || {}).rowCount || 3"
                :auto-grow="(definition.config || {}).autoGrow"
                filled
                @input="change($event, currentLang)"
                :value="data"
                hide-details="auto"
        >
            <template slot="append-outer">
                <v-avatar size="20">
                    <v-img :src="`https://webimages.we2p.de/static/flags/svg/${ currentLang.slice('-2') }.svg`"></v-img>
                </v-avatar>
            </template>
        </v-textarea>
    </div>
</template>

<script>

    import _ from 'lodash';
    import TranslationService from "../../../services/TranslationService";
    import LabelComponent from "./LabelComponent";
    import FormComponentMixin from "../../mixins/form-component";
    import FormRuleProvider from '../../../services/form-rule-provider';

    export default {
        name: "TextareaComponent",
        components: {
            LabelComponent,
        },
        props: [
            'value',
            'definition',
            'isLocked'
        ],
        mixins: [
            FormComponentMixin
        ],
        data() {
            return {

            }
        },
        computed: {
            rules: function() {
                let maxChars = this.definition?.config?.charCount;
                if (maxChars) {
                    return FormRuleProvider.getMaxCharRule(maxChars);
                }
                return [];
            },
            data: function() {
                return TranslationService.getValue(this.value, this.currentLang);
            }
        },
        methods: {
            change(value, lang) {
                this.$emit('input', TranslationService.setValue(this.value, value, lang));
            },
            async save() {
                return this.data;
            }
        }
    }
</script>

<style scoped>

</style>
