<template>

    <span>

        <form :action="editorURL" method ="post" target="traileditor" style="display: none">
            <!--input type="hidden" name="path" :value="path" />
            <input type="hidden" name="metainfos" :value="JSON.stringify(metainfos)" />
            <input type="hidden" name="token" :value="token" /-->
            <button type="submit" ref="formSubmit"/>
        </form>

        <iframe name="traileditor" class="traileditor-iframe" allowfullscreen></iframe>

        <div v-if="loading" class="loader">
            <div class="mx-5">
                 <LoadingSpinner color="#720a21" :size="80" style="margin: auto !important;"/>
            </div>
            <p class="text-center mt-5 text-h5 font-weight-light">Editor wird vorbereitet...</p>
        </div>

    </span>
</template>

<script>

    import axios from 'axios';
    import LoadingSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

    export default {
        name: 'TrailEditor',
        props: [
            'entry'
        ],
        components: {
            LoadingSpinner
        },
        data() {
            return {
                previewLink: '',
                loading: false,
                failed: false,
                dialog: false,
                token: "",
                // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd2ViMi50b3VyaW5mcmEuY29tXC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjIwODAzNDQxLCJleHAiOjE2MjA4ODk4NDEsIm5iZiI6MTYyMDgwMzQ0MSwianRpIjoiSVJUNUFGR0x4czY4R3ZEeCIsInN1YiI6MzI1NSwicHJ2IjoiNGFkNGFmYzk4ODQ2OGJjNGVmM2EzODg4ZTE3YzZmMDNmMGU5YWQxZiJ9.O0RtoB9Crkja2XpBUzqVfaqy-L8zhxKmmhTJjSxnh6A"
            }

        },
        computed: {
            editorURL: function() {
                return `https://www.tourinfra.com/tools/strecken-editor/v2/index.html?id=${this.entityID}&proxy=${process?.env?.VUE_APP_TRAILEDITOR_PROXY || "venus"}&token=` + this.token;
            },
            entityID: function() {
                return this.entry?.parentID || "";
            }
        },
        watch: {
            dialog: function (newVal, oldVal){
                if (newVal && this.previewLink === '') {
                    //this.loadPreview();
                }
            }
        },
        methods: {
            receiveMessage(event) {
                if (event.origin === "https://www.tourinfra.com") {
                    console.log(event);
                    if (event.data?.elevation_url) {
                        this.uploadByURL('ElevationProfile.svg', event.data?.elevation_url);
                    }
                    if (event.data?.map_url) {
                        this.uploadByURL('Map.png', event.data?.map_url);
                    }
                    console.log(event);
                    this.$emit("close", event.data); // to close dialog
                }
            },
            async startEditor() {

                // get gs token
                await axios.get("/api/auth/tourinfra").then((res) => {
                    this.token = res.data?.accessToken;
                }).catch((e) => {
                    console.log(e);
                    alert('Tourinfra AUTH fehlgeschlagen.');
                });

                // submit to iframe
                setTimeout(() => {
                    this.$refs.formSubmit?.click();
                    this.loading = false;
                }, 200);

            },
            uploadByURL(name, url) {
                axios.post('/api/media', {
                        entryID: this.entry?._id,
                        projectID: this.$router.currentRoute.params.projectID,
                        imageURL: url,
                        duplicateStrategy: 'REWRITE',
                        fileName: name,
                    }
                ).then((res) => {
                    this.$notify({
                        group: 'main',
                        type: 'success',
                        text: 'Bild synchronisiert.',
                    })
                }).catch((e) => {
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        text: 'Fehler bei Bildübertragung.'
                    })
                }).finally(() => {

                })
            },
        },
        created() {
            this.loading = true;
        },
        mounted() {
            this.startEditor();
            window.addEventListener('message', this.receiveMessage)
        },
        beforeDestroy () {
            window.removeEventListener('message', this.receiveMessage)
        }
    }
</script>

<style scoped>

    .traileditor-iframe {
        height: 100%;
        width: 100%;
        border: none;
    }

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

</style>
