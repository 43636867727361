<template>
    <v-dialog v-model="dialog" persistent max-width="800px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                depressed
                color="lightgrey"
                small
                v-bind="attrs"
                v-on="on"
            >
                <v-icon color="black"> edit </v-icon>
            </v-btn>

            <v-snackbar v-model="snackbar" :timeout="2000">
                Änderungen gespeichert.
                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="success"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        <v-icon>check</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
                <v-card-title>
                    <span class="headline mt-5">{{ template.name }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Name"
                                    :rules="rules"
                                    required
                                    v-model="template.name"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="ID"
                                    :rules="rules"
                                    required
                                    v-model="template.id"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    type="number"
                                    label="Anzahl Bilder"
                                    v-model="template.imageCount"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <!-- logic for fully customizable sizes -->
                                <!--div>
                                    <label>Anzeigengröße</label>
                                    <v-text-field class="d-inline-block text-center" style='max-width: 40px;' type="number" :value="(template.size || {}).numerator" @input="template.size = { ...template.size, numerator: $event }"></v-text-field>
                                    <span class="text-h6">/</span>
                                    <v-text-field class="d-inline-block text-center ml-2" style='max-width: 40px;' type="number" :value="(template.size || {}).denominator" @input="template.size = { ...template.size, denominator: $event }"></v-text-field>
                                    <span class="ml-2">Seiten</span>
                                </div-->
                                <v-select
                                    label="Anzeigengröße"
                                    v-model="template.size"
                                    :items="templateSizes"
                                    item-text="description"
                                    item-value="value"
                                    style=""
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-checkbox
                                    label="Links/Rechts Varianten"
                                    v-model="template.sideAware"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    style="max-width: 150px"
                                    append-outer-icon="euro"
                                    type="number"
                                    label="Anzeigenpreis"
                                    v-model="template.price"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="dialog = false"
                        >Abbrechen</v-btn
                    >
                    <v-btn
                        color="success"
                        depressed
                        @click="submit"
                        :disabled="!valid"
                        >Speichern</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'TemplateEdit',
    props: ['template'],
    data() {
        return {
            valid: true,
            dialog: false,
            snackbar: false,
            templateSizes: this.$store.getters['templates/getOptions'],
            rules: [(value) => !!value || 'Erforderliches Feld.'],
        };
    },
    methods: {
        ...mapActions('templates', ['update', 'fetchAll']),
        setSize() {},
        async submit() {
            await this.validate();
            if (this.valid) {
                this.update(this.template).then(() => {
                    /*this.fetchAll({
                            projectID: this.$router.currentRoute.params.projectID,
                            channelID: this.$router.currentRoute.params.channelID
                        });*/
                    this.dialog = false;
                    this.snackbar = true;
                });
            }
        },
        validate() {
            this.$refs.form.validate();
        },
    },
};
</script>

<style scoped></style>
