import { render, staticRenderFns } from "./RoleEdit.vue?vue&type=template&id=376f4068&scoped=true"
import script from "./RoleEdit.vue?vue&type=script&lang=js"
export * from "./RoleEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376f4068",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/print-suite/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('376f4068')) {
      api.createRecord('376f4068', component.options)
    } else {
      api.reload('376f4068', component.options)
    }
    module.hot.accept("./RoleEdit.vue?vue&type=template&id=376f4068&scoped=true", function () {
      api.rerender('376f4068', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Admin/roles/RoleEdit.vue"
export default component.exports