import axios from 'axios';
import router from '../../router';

const endpoint = '/api/printconfigs';

const store = {
    state: {
        status: '',
        settings: {},
    },

    getters: {
        getStatus: (state) => state.status,
        getSettings: (state) => state.settings,
    },

    actions: {
        async fetchSettings({ commit }, { projectID, channelID }) {
            return new Promise((resolve, reject) => {
                commit('settings_request');

                axios
                    .get(
                        `${endpoint}?projectID=${projectID}&channelID=${channelID}`
                    )
                    .then((res) => {
                        if (res.data) {
                            commit('settings_success', {
                                settings: res.data || {},
                            });
                            resolve(res);
                        } else {
                            console.log(res);
                            commit('settings_error');
                            reject('no_data');
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                        commit('settings_error');
                        reject('response_error');
                    });
            });
        },
    },

    mutations: {
        settings_request(state) {
            state.tatus = 'loading';
        },
        settings_success(state, { settings }) {
            state.status = 'success';
            state.settings = settings;
        },
        settings_error(state) {
            state.status = 'error';
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
