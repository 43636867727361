<template>
    <div class="users">
        <p class="text-h5">{{ (user || {}).name }}</p>

        <v-row>
            <v-col class="col-12 col-sm-6 col-md-4">
                <v-autocomplete
                    v-model="projectID"
                    label="Projekt"
                    :items="projects"
                    :loading="loadingProjects"
                    item-value="id"
                    item-text="name"
                    auto-select-first
                >
                </v-autocomplete>
            </v-col>

            <v-col class="col-12 col-sm-6 col-md-4">
                <v-autocomplete
                    v-model="channelID"
                    label="Channel"
                    :items="processedChannels"
                    :loading="loadingProjects"
                    item-value="id"
                    item-text="label"
                    auto-select-first
                >
                </v-autocomplete>
            </v-col>

            <v-col class="col-12 col-sm-6 col-md-4">
                <v-autocomplete
                    v-model="role"
                    label="Rolle"
                    :items="roles"
                    item-value="value"
                    item-text="label"
                    auto-select-first
                >
                </v-autocomplete>
            </v-col>
        </v-row>

        <v-btn
            color="success"
            :loading="loadingAddPermission"
            depressed
            fab
            @click="addPermission"
            :disabled="!projectID || !channelID || !role"
        >
            <v-icon>add</v-icon>
        </v-btn>

        <v-card class="mx-auto" max-width="800" tile>
            <v-list-item
                v-for="(project, index) in permissions"
                :key="index"
                class="pt-2"
            >
                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-h5"
                        >{{ (project || {}).name }} [{{
                            (project || {}).id
                        }}]</v-list-item-title
                    >
                    <v-list-item-content>
                        <v-list-item
                            v-for="(channel, channelIndex) in project.channels"
                            :key="channelIndex"
                        >
                            <v-list-item-content>
                                <v-list-item-title
                                    v-if="(channel || {}).id !== '*'"
                                    class="font-weight-bold"
                                    >{{ (channel || {}).name }} [{{
                                        (channel || {}).id
                                    }}]</v-list-item-title
                                >
                                <v-list-item-title class="success--text" v-else
                                    >Alle Channels</v-list-item-title
                                >
                                <v-list-item-content>
                                    <v-chip-group>
                                        <v-chip
                                            @click:close="
                                                removePermission(
                                                    project.id,
                                                    channel.id,
                                                    role
                                                )
                                            "
                                            close
                                            color="light"
                                            v-for="role in channel.roles"
                                            :key="role"
                                            >{{
                                                roles.find(
                                                    (r) => r.value === role
                                                ).label
                                            }}</v-chip
                                        >
                                    </v-chip-group>
                                </v-list-item-content>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-content>
                </v-list-item-content>
            </v-list-item>
        </v-card>
    </div>
</template>

<script>
import Chat from '../../../components/utility/Chat';
import axios from 'axios';
import UserService from '../../../services/UserService';

export default {
    name: 'UserEdit',
    components: {
        // Chat
    },
    data() {
        return {
            loadingUser: false,
            loadingAddPermission: false,
            loadingProjects: false,
            loadingChannels: false,
            user: {
                pp: {
                    roles: {},
                },
            },
            projects: [],
            channels: [],
            projectID: '',
            channelID: '',
            role: '',
            roles: [
                {
                    value: 'STATIC_EDITOR',
                    label: 'Redakteur',
                },
                {
                    value: 'STATIC_KOMPASS_EDITOR',
                    label: 'Autor (Kompass)',
                },
                {
                    value: 'STATIC_NVR_EDITOR',
                    label: 'Autor (NVR)',
                },
                {
                    value: 'STATIC_ADMIN',
                    label: 'Administrator',
                },
                {
                    value: 'STATIC_PRODUCT_MANAGER',
                    label: 'Produktmanager',
                },
                {
                    value: 'STATIC_KOMPASS_PRODUCT_MANAGER',
                    label: 'Produktmanager (Kompass)',
                },
                {
                    value: 'STATIC_COMMENT_ADMIN',
                    label: 'Kommentar-Administrator (für Kommentarsystem)',
                },
            ],
        };
    },
    watch: {
        projectID: function (newVal) {
            // load projects
            this.loadChannels(newVal);
        },
    },
    computed: {
        permissions: function () {
            let resolvedPermissions = [];
            let perm = this.user?.pp?.roles?.projects || [];
            for (const project in perm) {
                let projectPerm = {
                    id: project,
                    name: this.projects?.find((p) => p.id === project)?.name,
                    channels: [],
                };
                for (const channel in perm[project]['channels']) {
                    let channelPerm = {
                        id: channel,
                        name: this.channels?.find((c) => c.id === channel)
                            ?.labels?.['de-DE'],
                        roles: perm[project]['channels'][channel],
                    };
                    // otherwise irrelevant
                    if (channelPerm.roles.length > 0) {
                        projectPerm.channels.push(channelPerm);
                    }
                }
                // otherwise irrelevant
                if (projectPerm.channels.length > 0) {
                    resolvedPermissions.push(projectPerm);
                }
            }
            return resolvedPermissions;
        },
        processedChannels: function () {
            let channels = this.channels.map((c) => {
                return {
                    ...c,
                    label: c?.labels['de-DE'] + ' [' + c.id + ']',
                };
            });
            return [
                // wildcard option
                {
                    id: '*',
                    label: 'Alle Channels',
                },
            ].concat(
                channels.filter((c) => c.projects?.includes(this.projectID))
            );
        },
    },
    methods: {
        removePermission(projectID, channelID, role) {
            axios
                .post('/api/users/permissions/remove', {
                    userID: this.user.id,
                    projectID: projectID,
                    channelID: channelID,
                    role: role,
                })
                .then((res) => {
                    this.loadUser();
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {});
        },
        addPermission() {
            this.loadingAddPermission = true;
            axios
                .post('/api/users/permissions', {
                    userID: this.user.id,
                    projectID: this.projectID,
                    channelID: this.channelID,
                    role: this.role,
                })
                .then((res) => {
                    this.channelID = '';
                    this.loadUser();
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.loadingAddPermission = false;
                });
        },
        loadUser() {
            // load user
            this.loadingUser = true;
            axios
                .get(
                    `/api/users/${this.$router.currentRoute.params.userID}`,
                    {}
                )
                .then((res) => {
                    this.user = res.data?.[0];
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    this.loadingUser = false;
                });
        },
        loadProjects() {
            // load projects
            this.loadingProjects = true;
            axios
                .get(`/api/projects`, {})
                .then((res) => {
                    this.projects = res.data;
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    this.loadingProjects = false;
                });
        },
        loadChannels(projectID) {
            // load channels
            this.loadingChannels = true;
            axios
                .get(`/api/v2/channels`, {})
                .then((res) => {
                    this.channels = res.data;
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    this.loadingChannels = false;
                });
        },
    },
    created() {
        this.loadUser();
        this.loadProjects();
        this.loadChannels();
    },
};
</script>
