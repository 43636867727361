import Dashboard from '../../views/Admin/Dashboard';
import PlainRouterView from '../../views/PlainRouterView';
import Projects from '../../views/Admin/projects/Projects';
import Users from '../../views/Admin/users/Users';
import Settings from '../../views/Admin/Settings';
import Roles from '../../views/Admin/roles/Roles';
import Groups from '../../views/Admin/groups/Groups';
import ProjectEdit from '../../views/Admin/projects/ProjectEdit';
import UserEdit from '../../views/Admin/users/UserEdit';
import RoleEdit from '@/views/Admin/roles/RoleEdit';
import GroupEdit from '@/views/Admin/groups/GroupEdit';
import Import from '@/views/Admin/Import';
import Changelog from '../../views/Admin/changelog/Changelog';
import ChangelogEdit from '../../views/Admin/changelog/ChangelogEdit';
import System from '../../views/Admin/System';
import Channels from "@/views/Admin/channels/Channels";
import ChannelEdit from "@/views/Admin/channels/ChannelEdit";

const routes = [
    {
        path: '',
        name: 'AdminDashboard',
        component: Dashboard,
    },
    {
        path: 'system',
        name: 'AdminSystem',
        component: System,
    },
    {
        path: 'projects',
        name: 'AdminProjects',
        component: Projects,
        children: [
            {
                path: ':projectID',
                name: 'AdminProjectEdit',
                component: ProjectEdit,
            },
        ],
    },
    {
        path: 'channels',
        name: 'AdminChannels',
        component: Channels,
        children: [
            {
                path: ':channelID',
                name: 'AdminChannelEdit',
                component: ChannelEdit,
            },
        ],
    },
    {
        path: 'users',
        name: 'AdminUsers',
        component: PlainRouterView,
        children: [
            {
                path: '',
                name: 'AdminUsersList',
                component: Users,
            },
            {
                path: ':userID',
                name: 'AdminUserEdit',
                component: UserEdit,
            },
        ],
    },
    {
        path: 'roles',
        name: 'AdminRoles',
        component: Roles,
        children: [
            {
                path: ':roleID',
                name: 'AdminRoleEdit',
                component: RoleEdit,
            },
        ],
    },
    {
        path: 'changelog',
        name: 'AdminChangelog',
        component: Changelog,
        children: [
            {
                path: ':changelogID',
                name: 'AdminChangelogEdit',
                component: ChangelogEdit,
            },
        ],
    },
    {
        path: 'groups',
        name: 'AdminGroups',
        component: Groups,
        children: [
            {
                path: ':groupID',
                name: 'AdminGroupEdit',
                component: GroupEdit,
            },
        ],
    },
    {
        path: 'import',
        name: 'AdminImport',
        component: PlainRouterView,
        children: [
            {
                path: '',
                name: 'AdminImportList',
                component: Import,
            },
        ],
    },
    {
        path: 'settings',
        name: 'AdminSettings',
        component: Settings,
    },
];

export default routes;
