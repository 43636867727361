<template>
    <div class="mt-5">
        <div style="width: 70px; height: 5px; position: relative; margin-left: -20px;" class="primary mb-5">
            <div style="width: 5px; height: 30px; position: absolute" class="primary"></div>
        </div>
        <p class="text-h4 font-weight-bold mb-0" v-text="definition.label"/>
        <p class="" v-text="definition.description"></p>
    </div>
</template>

<script>

    import _ from 'lodash';
    import LabelComponent from "../LabelComponent";

    export default {
        name: "HeadlineComponent",
        components: {

        },
        props: [
            'definition',
            'content'
        ],
        data() {
            return {
                data: _.cloneDeep(this.content)
            }
        },
    }
</script>

<style scoped>

</style>