<template>
    <v-dialog v-model="dialog" width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" class="white--text" fab depressed small v-bind="attrs" v-on="on">
                <v-icon>
                    edit_note
                </v-icon>
            </v-btn>
        </template>
        
        <v-card>
            <v-card-title>
                <v-row class='my-4 mx-0'>
                    <span class='text-h5 font-weight-bold'>Informationen</span>
                    <v-spacer></v-spacer>
                    <v-btn class='mr-3' @click='$emit("save")' depressed color='success' small fab>
                        <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn @click='dialog = false' depressed color='error' text small fab>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>
            <v-card-text>
                
                <v-text-field type='number'
                              class='align-right mb-0'
                              v-model='meta.price'
                              label='Anzeigenpreis'
                              style='max-width: 300px'
                              append-outer-icon='euro'
                              :hint='template.price ? "wenn abweichend vom Template-Preis" : ""'
                              persistent-hint
                ></v-text-field>
                <p class='mt-1' v-if='template.price'>Standard-Preis für dieses Template: <span class='blue--text'>{{ `${ template.price } €` }}</span></p>
                
                <p class='mt-8 mb-0 font-wseight-bold text-h6'>Rechnungskontakt</p>
                <v-row class='mx-0 mt-0'>
                    <v-autocomplete v-model='meta.billingContact'
                                    style='max-width: 400px'
                                    :label='getSalutationLabel(billingContact)'
                                    :loading='$store.getters["contacts/getStatus"] !== "success"'
                                    :items='contacts'
                    ></v-autocomplete>
                    <v-dialog transition="dialog-top-transition" max-width="800" persistent v-if='billingContact' v-model='billingContactDialog'>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn x-small depressed fab color='grey' v-on='on' v-bind='attrs' class='my-auto ml-3'>
                                <v-icon color='white'>edit</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-card-title>
                                    <v-row class='mx-0 my-2'>
                                        <span>{{ getDisplayName(billingContact, 'Neuer Kontakt') }}</span>
                                        <v-spacer></v-spacer>
                                        <v-btn text color='red' fab small @click="dialog.value = false" :loading='savingContact'>
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                    <ContactEditForm v-model='billingContact' @input='saveBillingContact'></ContactEditForm>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-dialog>
                    <v-btn x-small depressed fab color='success' :loading='creatingContact' class='my-auto ml-3' @click='createNewContact'>
                        <v-icon color='white'>add</v-icon>
                    </v-btn>
                </v-row>
                
                <template v-if='billingContact'>
                    <p class='my-0'>Bank: <span class='font-weight-bold'>{{ billingContact.bank }}</span></p>
                    <p class='my-0'>IBAN: <span class='font-weight-bold'>{{ billingContact.iban }}</span></p>
                    <p class='my-0' v-if='billingContact.bic'>BIC: <span class='font-weight-bold'>{{ billingContact.bic }}</span></p>
                </template>
                
                <v-textarea v-model='meta.billingNotice'
                            class='mt-5'
                            label='Informationen zur Rechnungsstellung'
                ></v-textarea>
            
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import iban from 'iban';
import ContactUtils from '../mixins/contact-utils';
import ContactEditForm from '../users/ContactEditForm';
import _ from 'lodash';
import Vue from 'vue';

export default {
    name: 'EntryMetaInfoEdit',
    components: { ContactEditForm },
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                }
            }
        },
        template: {
            type: Object,
            default: () => {
                return {
                    billingContactDialog: false,
                }
            }
        }
    },
    mixins: [
        ContactUtils
    ],
    data() {
        return {
            dialog: false,
            creatingContact: false,
            savingContact: false,
            billingContactDialog: false,
        }
    },
    methods: {
        createNewContact() {
            this.creatingContact = true;
            this.$store.dispatch('contacts/add', {
                payload: {
                    projects: [ this.$router.currentRoute.params.projectID ],
                },
            }).then((res) => {
                Vue.set(this.meta, "billingContact", res?._id?.toString());
                this.billingContactDialog = true;
            }).finally(() => {
                this.creatingContact = false;
            })
        },
        saveBillingContact() {
            this.savingContact = true
            this.saveBillingContactDebounced();
        },
        saveBillingContactDebounced: _.debounce(function() {
            this.$store.dispatch('contacts/update', { id: this.billingContact._id.toString(), payload: this.billingContact }).then(() => {
                this.savingContact = false;
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "Kontakt gespeichert." });
            })
        }, 1000)
    },
    computed: {
        meta: {
            get() {
                return this.value;
            }
        },
        billingContact: function() {
            let contacts = this.$store.getters['contacts/getAll'].filter(c => c.projects?.includes(this.$router.currentRoute.params.projectID))
            let contact = contacts.find(c => this.meta.billingContact === c?._id?.toString());
            return contact;
        },
        contacts: function() {
            return this.$store.getters['contacts/getAll']
                .filter(c => c.projects?.includes(this.$router.currentRoute.params.projectID))
                .map((c) => {
                    return {
                        value: c._id.toString(),
                        text: this.getDisplayName(c, "Neuer Kontakt"),
                    }
                })
        }
    },
    watch: {
        meta: {
            handler(val) {
                this.$emit('input', val);
            },
            deep: true,
        }
    },
    created() {
        this.$store.dispatch('contacts/fetchByProject');
    }
};
</script>

<style scoped>

</style>
