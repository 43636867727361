import axios from 'axios';
import router from "../../router";

const store = {

    state: {
        status: '',
        users: []
    },

    getters: {
        getStatus: (state) => state.status,
        getAll: (state) => state.users,
    },

    actions: {
        async fetchAll({commit}) {
            commit('start_loading');
            await axios.get(`/api/users`).then((response) => {
                commit('end_loading');
                commit('set_roles', response.data?.data);
            });
        },
    },
    mutations: {
        set_roles(state, users) {
            state.users = users || [];
        },
        start_loading(state) {
            state.status = "loading";
        },
        end_loading(state) {
            state.status = "success";
        },
    }

}


export default {
    ...store,
    namespaced: true
}
