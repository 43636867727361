<template>
  <div>
    <DeleteConfirmation title="Wiederherstellen?"
                        msg="Wollen Sie diesen Stand wirklich wiederherstellen? Wenn der Stand zu weit zurück liegt, könnte es zu Problemen aufgrund von Inkompatibiltät zur aktuellen Konfiguration kommen."
                        @yes="restore">
      <template v-slot:default="{ on, attrs }">
        <v-btn class="ml-3" color="info" v-on="on" v-bind="attrs" depressed dark small fab
               @mouseover="tooltip = true" @mouseleave="tooltip = false">
          <v-tooltip right transition="slide-x-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" style="transform: translateX(-1px)">restore</v-icon>
            </template>
            <span>Version wiederherstellen</span>
          </v-tooltip>
        </v-btn>
      </template>
    </DeleteConfirmation>
  </div>
</template>

<script>

import axios from "axios";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";

export default {
  name: 'EntryHistoryRestore',
  props: [
    'entryId',
    'versionId',
  ],
  components: {
    DeleteConfirmation
    //EntryEditForm
  },
  methods: {
    restore() {

      axios.put('/api/entries/history/restore', {
        entryID: this.entryId,
        versionID: this.versionId,
      }).then(() => {
        this.$notify({group: 'main', type: 'success', duration: 2000, title: 'Version wiederhergestellt.', text: ''})
        this.$emit('restore');
      }).catch(() => {
        this.$notify({
          group: 'main',
          type: 'error',
          duration: 5000,
          title: 'Wiederherstellung fehlgeschlagen!',
          text: ''
        })
      });

    }
  },
  data() {
    return {
      tooltip: false
    }
  },
}
</script>

<style scoped>
@import '../../../../../node_modules/jsondiffpatch/dist/formatters-styles/html.css';
</style>
