<template>
    <div class="users">

        <p class="text-h5">Changelog</p>

        <v-row>

            <v-col style="max-width: 450px">
                <ChangelogList />
            </v-col>

            <v-col style="max-height: 80vh;" v-if="$router.currentRoute.params.changelogID">
                <v-card class="overflow-y-auto pa-5" style="max-height: 80vh;">
                    <router-view :key="$router.currentRoute.params.changelogID"></router-view>
                </v-card>
            </v-col>

        </v-row>

        <v-btn fab fixed bottom right color="success" @click="$store.dispatch('changelog/add', { data: { title: 'Neues Update', date: (new Date()).toISOString() } })">
            <v-icon>
                add
            </v-icon>
        </v-btn>

    </div>
</template>

<script>

    import Chat from "../../../components/utility/Chat";
    import axios from "axios";
    import ChangelogList from "../../../components/admin/ChangelogList";

    export default {
        name: 'Changelog',
        components: {
            ChangelogList,
            // Chat
        },
        data() {
            return {
                loading: false,
                search: "",
            }
        },
        computed: {

        },
        methods: {

        },
        created() {

        }
    }
</script>
