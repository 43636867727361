<template>

    <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn @click="sendToEditor" v-bind="attrs" v-on="on" class="white--text ml-3" small depressed fab dark color="blue">
                <v-icon>low_priority</v-icon>
            </v-btn>
        </template>
        <v-card tile>

            <v-btn icon text fab fixed top right class="mr-0" color="error" @click="dialog = false">
                <v-icon>close</v-icon>
            </v-btn>

            <CatalogPlannerEditor :catalog-id="catalogId" :key="componentKey" @close="close"/>

        </v-card>
    </v-dialog>

</template>

<script>

    import InDesignEditor from "./InDesignEditor";
    import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
    import CatalogPlannerEditor from "@/components/entries/fullscreen/CatalogPlannerEditor";

    export default {
        name: 'CatalogPlannerEditorDialog',
        props: [
            "catalogId"
        ],
        components: {
            CatalogPlannerEditor,
            // LoadingSpinner
        },
        data() {
            return {
                componentKey: 0,
                dialog: false,
                forceReloadOnNextOpen: false,
            }
        },
        computed: {
            logoURL: function() {
                return process?.env?.VUE_APP_LOGO_URL;
            },
        },
        watch: {
            dialog: function (newVal, oldVal){
                // if (newVal && this.forceReloadOnNextOpen) { <- unwanted behaviour - should be reloaded now
                if (newVal) {
                    this.forceReloadOnNextOpen = false;
                    this.forceReload();
                }
            }
        },
        methods: {
            close() {
                this.dialog = false;
                this.forceReloadOnNextOpen = true;
            },
            forceReload() {
                this.componentKey++;
            },
            sendToEditor() {

            }
        },
        created() {

        }
    }
</script>

<style scoped>

</style>
