<template>

    <v-container>

        <p>Version: {{ sysInfo.self.node.appVersion }}</p>

        <p class="text-h6 mb-0">System</p>

        <p class="text-caption mb-5">
            <span>{{ sysInfo.self.os.platform }}</span>
            <span class="mx-2">|</span>
            <span>{{ sysInfo.self.os.distro }}</span>
        </p>

        <p class="text-h6 mb-0">Node.js</p>

        <p class="text-caption mb-5">
            <span>Version: {{ sysInfo.self.node.version }}</span>
            <span class="mx-2">|</span>
            <span>NODE_ENV: {{ sysInfo.self.node.env }}</span>
        </p>

        <p class="text-h6 mb-1">Memory</p>

        <v-progress-linear
            rounded
            style="max-width: 1000px;background-color: darkgrey"
            :rotate="90"
            :size="100"
            background-color="red"
            background-opacity="1"
            :value="(sysInfo.self.ram.used / sysInfo.self.ram.total * 100) - (sysInfo.self.ram.usedProcess.heapTotal / sysInfo.self.ram.total * 100)"
            :width="15"
            :buffer-value="sysInfo.self.ram.used / sysInfo.self.ram.total * 100"
            color="success"
        >
            <!--{{ toGB(sysInfo.self.ram.used) }}G-->
        </v-progress-linear>

        <p class="text-caption mt-2">
            <span>Used (Process): {{ toGB(sysInfo.self.ram.usedProcess.heapTotal) * 1000 }} MB</span>
            <span class="mx-2">|</span>
            <span>Used (total): {{ toGB(sysInfo.self.ram.used) }} GB</span>
            <span class="mx-2">|</span>
            <span>Free: {{ toGB(sysInfo.self.ram.free) }} GB</span>
            <span class="mx-2">|</span>
            <span>Total: {{ toGB(sysInfo.self.ram.total) }} GB</span>
        </p>
    
        <p class="text-h6 mb-1">
            <span>Cluster</span>
        </p>
        
        <v-row class='my-5 ml-3'>
            <div class='mr-10'>
                <v-btn fab large color='info' depressed ></v-btn>
                <p class='caption text-center mt-2'>Master</p>
            </div>
            <template v-for='(node,index) in workerNodes'>
                <div :key='index' class='mr-10'>
                    <v-btn fab large :color='node.active ? "success" : "grey"' depressed >
                        <v-icon color='white' v-if='node.active'>check</v-icon>
                        <v-icon color='white' v-else>bedtime</v-icon>
                    </v-btn>
                    <p class='caption text-center mt-2' v-if='node.pid'>PID: {{ node.pid }}</p>
                </div>
            </template>
        </v-row>
        
        
        <p class="text-h6 mb-1">
            <span>Datamodel</span>
        </p>

        <v-progress-linear
            rounded
            style="max-width: 1000px;"
            :rotate="90"
            :size="100"
            :value="(sysInfo.remote.datamodel.cache.types.cacheCurrent / sysInfo.remote.datamodel.cache.types.cacheTarget * 100)"
            :width="15"
            :color="(sysInfo.remote.datamodel.cache.types.cacheCurrent === sysInfo.remote.datamodel.cache.types.cacheTarget) ? 'success' : 'info'"
        >
            <!--{{ toGB(sysInfo.self.ram.used) }}G-->
        </v-progress-linear>

        <p class="text-caption mt-2" v-if="sysInfo.remote.datamodel.cache.types.cacheTarget === 0">
            <span>Cache disabled.</span>
        </p>
        <p class="text-caption mt-2" v-else-if="sysInfo.remote.datamodel.cache.types.cacheCurrent === sysInfo.remote.datamodel.cache.types.cacheTarget">
            <span class="success--text">Cache stored.</span>
        </p>
        <p class="text-caption mt-2" v-else>
            <span>Local: {{ sysInfo.remote.datamodel.cache.types.cacheCurrent }}</span>
            <span class="mx-2">|</span>
            <span>Remote: {{ sysInfo.remote.datamodel.cache.types.cacheTarget }}</span>
        </p>

        <DeleteConfirmation title="Cache neu aufbauen?"
                            msg="Während der Cache neu aufgebaut wird, kann es zu kleineren Performance-Problemen kommen."
                            v-if="sysInfo.remote.datamodel.cache.types.cacheCurrent === sysInfo.remote.datamodel.cache.types.cacheTarget"
                            @yes="resetCache"
        >
            <template v-slot:default="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small color="info" depressed rounded>
                    <v-icon size="18">
                        cached
                    </v-icon>
                    <span>purge cache</span>
                </v-btn>
            </template>
        </DeleteConfirmation>

    </v-container>

</template>

<script>
import axios from "axios";
import DeleteConfirmation from "../../components/utility/DeleteConfirmation";

export default {
    name: "System",
    components: {DeleteConfirmation},
    data() {
        return {
            sysInfo: {}
        }
    },
    computed: {
        workerNodes: function() {
            let nodes = [...Array(this.sysInfo.cluster.maxWorkers).keys()];
            return nodes.map((item,index) => {
                return {
                    active: this.sysInfo.cluster.workers[index]?.active ?? false,
                    pid: this.sysInfo.cluster.workers[index]?.pid ?? "",
                }
            })
        }
    },
    methods: {
        /**
         * fetch sysInfo (repeat)
         */
        fetchData() {
            axios.get('/api/v2/system/health').then((res) => {
                this.sysInfo = res.data;
                setTimeout(() => {
                    this.fetchData();
                }, 5000);
            }).catch((e) => {
                console.error(e);
            })
        },
        resetCache() {
            this.$store.dispatch('properties/flush');
            axios.post('/api/v2/system/cache/reset').then((res) => {

            }).catch((e) => {
                console.error(e);
            })
        },
        toGB(val) {
            return Math.round(val / (1000 * 1000 * 1000) * 100) / 100;
        }
    },
    created() {
        this.fetchData();
    }
}
</script>

<style scoped>

</style>
