import axios from 'axios';
import router from '../../router';

const api = '/api/v2/projects';

const store = {
    state: {
        status: '',
        data: [],
        current: '',
    },

    getters: {
        getAll: (state) => state.data,
        getStatus: (state) => state.status,
        getCurrent: (state) =>
            state.data?.find((p) => p.id === state.current) || {},
    },

    actions: {
        setCurrent({ commit }, id) {
            if (!id) {
                if (
                    router.currentRoute.matched.some(
                        ({ name }) => name === 'Projects'
                    )
                ) {
                    commit('set_current_default');
                }
            } else {
                commit('set_current', id);
            }
        },
        async fetchAll({ commit, getters }, { flush } = {}) {
            return new Promise((resolve, reject) => {
                if (getters.getAll.length > 0 && !flush) {
                    resolve();
                    return;
                }

                commit('request_sent');

                axios
                    .get(`${api}`)
                    .then((res) => {
                        // todo remove when new auth system was deployed
                        if (res.data.statusCode === 401) {
                            commit('request_error');
                            reject();
                            return;
                        }
                        commit('request_success', res.data);
                        resolve();
                    })
                    .catch((e) => {
                        console.log(e);
                        commit('request_error');
                        reject(e);
                    });
            });
        },
        async add({ commit, dispatch }, { payload }) {
            return new Promise((resolve, reject) => {
                commit('request_sent');
                axios
                    .post(`${api}`, {
                        ...payload,
                    })
                    .then(async (res) => {
                        await dispatch('fetchAll', { flush: true });
                        resolve(res.data);
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
        async update({ commit, dispatch }, { payload, id }) {
            return new Promise((resolve, reject) => {
                commit('request_sent');
                axios
                    .put(`${api}/${id}`, payload)
                    .then(async (res) => {
                        await dispatch('fetchAll', { flush: true });
                        resolve();
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
        async remove({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                commit('request_sent');
                axios
                    .delete(`${api}/${id}`)
                    .then(async (res) => {
                        await dispatch('fetchAll', { flush: true });
                        resolve();
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
    },

    mutations: {
        set_current(state, id) {
            state.current = id;
        },
        set_current_default(state, id) {
            let firstID = state.data[0]?.id || 0;
            state.current = firstID;
            router.push(`/projects/${firstID}/channels`);
        },
        request_sent(state) {
            state.status = 'loading';
        },
        request_success(state, data) {
            state.status = 'success';
            console.log('me is the data', data);
            state.data = data;
        },
        request_error(state) {
            state.status = 'error';
        },
        end_loading(state) {
            state.status = 'success';
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
