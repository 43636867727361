<template>
    <div>

        <v-btn :to="{
                    name: 'EntryEdit',
                    params: {
                        entryID: this.entry._id.toString(),
                    }
                }"
               class="mb-5 mt-2"
               text depressed router
        >
            <v-icon class="mr-3">arrow_back</v-icon>
            zurück zum Eintrag
        </v-btn>


        <v-card class="pa-5">

            <div v-if="loadingEntry || loadingEntryType || loadingEntryHistory">
                <v-progress-linear indeterminate/>
            </div>

            <div v-if="!loadingEntry && !loadingEntryType && !loadingEntryHistory">

                <p class="text-body-1 grey--text mt-4 mb-0 text-lowercase">{{ (entry || {})._id }}</p>
                <p class="text-h3 font-weight-bold mb-0">Verlauf</p>
                <p class="text-h5">{{ getLabel }}</p>

                <v-divider/>

                <v-timeline dense>
                    <v-timeline-item v-for="(version, index) in versions"
                                     fill-dot
                                     :key="index"
                                     :color="version.color"
                                     :icon="version.icon"
                    >
                        <div class="py-4">
                            <p class="mb-0">
                                <span class="text-h6 font-weight-bold">{{ getDateTimeString(version.t) }} Uhr</span>
                                <span class="mx-5">-</span>
                                <span>{{ (version.d.lastEditedBy || {}).fullName || "Keine Info zum Nutzer verfügbar" }}</span>
                                <v-tooltip bottom v-if="(version.d.lastEditedBy || {}).comment">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            class="ml-3"
                                            color="success"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            small
                                        >comment</v-icon>
                                    </template>
                                    <span>{{ (version.d.lastEditedBy || {}).comment }}</span>
                                </v-tooltip>
                            </p>
                            <p v-if="index === 0" class="my-1 green--text">
                                <v-icon left small color="green">
                                    check
                                </v-icon>
                                Aktueller Stand
                            </p>
                            <ul class="" v-if="version.changes">
                                <li v-for="(change, changeIndex) in version.changes" v-html="change" :key="changeIndex"></li>
                            </ul>
                            <div class="mt-5 ml-2">
                                <v-row>
                                    <EntryHistoryCompare :new-data="version.d" :old-data="(versions[index+1] || {}).d" :entry-type-definition="entryTypeDefinition" />
                                    <EntryHistoryRestore :entry-id="entry._id" :version-id="version._id" @restore="loadHistory" v-if="index !== 0"/>
                                </v-row>
                            </div>
                        </div>
                    </v-timeline-item>
                </v-timeline>

                <v-btn @click="displayCount += 30" color="success" v-if="displayCount <= versions.length">
                    ältere anzeigen...
                </v-btn>


            </div>

        </v-card>

    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import _ from 'lodash';
import axios from "axios";
import {DateTime} from "luxon";

import TranslationService from "../../services/TranslationService";
import EntryStatusService from "../../services/EntryStatusService";

import EntryHistoryCompare from "../../components/entries/EntryHistoryCompare";
import EntryHistoryRestore from "@/components/entries/EntryHistoryRestore";

export default {
    name: 'EntryHistory',
    components: {
        EntryHistoryRestore,
        EntryHistoryCompare

    },
    props: [
        "entryId"
    ],
    data() {
        return {
            displayCount: 30,
            loadingEntry: false,
            loadingEntryType: false,
            loadingTemplates: false,
            loadingEntryHistory: false,
            saving: false,
            entry: {
                _id: "",
            },
            entryHistory: [],
            templates: [],
            entryTypes: [],
            dialog: false,
        }
    },
    computed: {
        ...mapGetters("entries", ['allEntries']),
        cockpitAPI: function() {
            return process.env.VUE_APP_COCKPIT_URL || "https://c2.we2p.de";
        },
        versions: function() {
            return this.entryHistory.slice(0, this.displayCount).map((version,index) => {
                const prevVersion = this.entryHistory?.[index+1] || {};

                // CREATE
                if (version.o === 'i') return {
                    ...version,
                    icon: 'add',
                    color: 'success',
                    changes: ['Eintrag wurde erstellt.'],
                };

                // RESTORE
                if (version?.d?.lastEditedBy?.action === 'restore') return {
                    ...version,
                    icon: 'settings_backup_restore',
                    color: 'red',
                    changes: ['Alter Stand wurde wiederhergestellt.'],
                };

                // get difference to prev version
                let diff = _.reduce(version.d, function(result, value, key) {
                    return _.isEqual(value, prevVersion?.d?.[key]) ?
                        result : result.concat(key);
                }, []);
                // filter irrelevant properties
                diff = diff.filter(x => !['updatedAt', 'lastEditedBy'].includes(x));

                let icon = '';
                let color = '';


                let changes = diff.map((prop) => {
                    switch (prop) {
                        case 'status':
                            icon = 'nat';
                            color = 'blue';
                            return 'Status von <span class="">"' +
                                EntryStatusService.getTranslation(prevVersion?.d?.status) +
                                '"</span> zu <span class="blue--text">"' +
                                EntryStatusService.getTranslation(version?.d?.status) +
                                '"</span> geändert.';
                        case 'templateID':
                            icon = 'view_quilt';
                            color = 'secondary';
                            return 'Template von <span class="">"' +
                                this.templateName(
                                    this.templates.find(template => template._id.toString() === prevVersion.d?.templateID) ||
                                    {
                                        _id: "",
                                        name: "Kein Template",
                                        imageCount: 0,
                                    }
                                ) +
                                '"</span> zu <span class="blue--text">"' +
                                this.templateName(
                                    this.templates.find(template => template._id.toString() === version?.d?.templateID) ||
                                    {
                                        _id: "",
                                        name: "Kein Template",
                                        imageCount: 0,
                                    }
                                ) +
                                '"</span> geändert.';
                        case 'type':
                            icon = 'error';
                            color = 'warning';
                            return 'Eintrags-Typ gewechselt.';
                        case 'ixedit':
                            icon = 'picture_in_picture';
                            color = '#E749A0';
                            return 'Änderung im InDesignEditor.';
                        case 'content':
                            icon = 'save';
                            color = 'info';
                            return 'Änderung an Daten in der Pflegemaske.';
                        default:
                            icon = 'save';
                            color = 'info';
                            return 'Sonstige Änderungen.';
                    }
                })

                changes = [...new Set(changes)];

                // CHANGES
                if (changes.length) return {
                    ...version,
                    icon: icon,
                    color: color,
                    changes,
                };

                // NO CHANGE
                return {
                    ...version,
                    icon: 'save',
                    color: 'grey',
                    changes: ['Speichern ohne Änderung.'],
                };

            })
        },
        templateOptionsEnum: function() {
            return [
                ...this.templateOptions,
                {
                    _id: "",
                    name: "Kein Template",
                    imageCount: 0,
                }
            ]
        },
        templateOptions() {

            let currentType = this.entryTypes.find(x => x._id === this.entry?.type);

            if (currentType !== undefined) {
                return this.templates.filter(template => currentType.templates.includes(template._id));
            } else {
                return [];
            }


        },
        entryTypeDefinition() {

            if (typeof this.entry?.type !== "undefined") {
                let currentType = this.entryTypes.find(x => x._id === this.entry?.type);
                return currentType?.['properties'];
            } else {
                return [];
            }

        },
        getLabel() {
            if (typeof this.entry?.content !== 'undefined' && (this.entry?.content.name||'' !== '')) {
                return TranslationService.getValue(this.entry?.content?.name, 'de-DE');
            } else {
                return `Eintrag (${ this.entry?._id })`
            }
        }
    },
    methods: {
        getDateTimeString(time) {
            let dt = new DateTime.fromISO(time);
            return dt.setLocale('de').toLocaleString(DateTime.DATETIME_SHORT)
        },
        ...mapActions("entries", ['createEntry','fetchEntriesByCatalog']),
        templateName: item => item?.name,
        loadHistory() {
            // load entryHistory
            this.loadingEntryHistory = true;
            axios.get(`/api/entries/history/${ this.entryId }`, {}).then((res) => {
                this.entryHistory = _.reverse(res.data);
            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                this.loadingEntryHistory = false;
            });
        }
    },
    created() {

        // load entry
        this.loadingEntry = true;
        axios.get(`/api/entries/${ this.entryId }`, {}).then((res) => {
            this.entry = res.data;
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            this.loadingEntry = false;
        });


        this.loadHistory();


        // load entrytype definition
        this.loadingEntryType = true;

        // other entrytypes
        axios.get(`/api/entrytypes/?projectID=${ this.$router.currentRoute.params.projectID }&channelID=${ this.$router.currentRoute.params.channelID }`, {}).then((res) => {
            this.entryTypes = res.data;
            this.loadingEntryType = false;
        }).catch((e) => {
            console.error(e);
            this.loadingEntryType = false;
        });


        // load templates
        this.loadingTemplates = true;

        axios.get(`/api/templates/?projectID=${ this.$router.currentRoute.params.projectID }&channelID=${ this.$router.currentRoute.params.channelID }`, {}).then((res) => {
            this.templates = res.data;
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            this.loadingTemplates = false;
        });

    }
}
</script>
