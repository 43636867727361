
let columns = ["address", "contactPoint"];

class UserService {


    static getColumns() {
        return columns;
    }

    static setColumns(arr) {
        columns = arr;
    }

}

export default UserService;