<template>
  <div style="padding-bottom: 70px">

    <v-btn class="mb-5 mt-2" text depressed router v-if='!minimal'
           :to="`/projects/${$router.currentRoute.params.projectID}/channels/${$router.currentRoute.params.channelID}/print/catalogs/${$router.currentRoute.params.catalogID}`">
      <v-icon class="mr-3">arrow_back</v-icon>
      zurück zur Übersicht
    </v-btn>

    <!-- warning when switching typings -->
    <v-dialog
        v-model="switchWarnDialog.opened"
        persistent
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">Achtung!</v-card-title>
        <v-card-text>
          <p>Diese Änderung beeinflusst die Datenstruktur. Bitte beachten Sie folgende Konsequenzen:</p>
          <ol>
            <li>Es gehen möglicherweise Daten verloren.</li>
            <li>Die Synchronisierung mit dem Knowledge-Graph-Datensatz wird beendet.</li>
            <li>Das Template ist eventuell beim neuen Typ nicht verfügbar.</li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="resetTypeSwitch" text>zurück</v-btn>
          <v-btn color="error" @click="switchWarnDialog.opened = false">Ich weiß, was ich tue!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- warning when saving invalid form -->
    <v-dialog
        v-model="invalidSaveDialog.opened"
        persistent
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">Achtung!</v-card-title>
        <v-card-text>
          <p>Einige Felder sind noch nicht gültig ausgefüllt.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="invalidSaveDialog.opened = false" text>zurück</v-btn>
          <v-btn color="success" @click="saveContent">Trotzdem speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="loadingEntry || loadingEntryType">
      <v-progress-circular indeterminate/>
    </div>

    <template v-if="!loadingEntry && !loadingEntryType">

      <v-sheet class="px-2 pt-0">

        <!-- header -->
        <div class="mx-4">

          <v-spacer class="py-3"></v-spacer>
          <p class="text-body-1 grey--text mt-4 mb-0">ID: {{ entry._id }}</p>
          <p class="text-body-1 grey--text mb-0" v-if="entry.parentID">Cockpit ID: {{ entry.parentID }}</p>

          <!-- name -->
          <p class="text-h3 mt-3">
            {{ getLabel }}
            <EntryMetaInfoEdit v-model='entry.meta'
                               v-if='$settings.getValue("accounting.enabled")'
                               :template='templates.find(t => t._id === entry.templateID)'
                               @save='saveMeta'
            />
          </p>

          <div class="mt-2">

            <!-- OLD IMAGES
            <ImageGallery :entry="entry" :is-locked="isLocked">
                <template v-slot:default="{ on, attrs }">
                    <v-btn depressed rounded color="orange darken-2" class="mr-3 my-1" dark v-bind="attrs" v-on="on">
                        <v-icon left>image</v-icon>
                        Bilder
                    </v-btn>
                </template>
            </ImageGallery>
            -->

            <!-- IMAGES -->
            <v-btn depressed rounded color="orange darken-2" class="mr-3 my-1" dark
                   @click="scrollToImages()">
              <v-icon left>image</v-icon>
              Bilder
            </v-btn>

            <!-- IXEdit -->
            <InDesignEditorDialog v-if="entry.templateID && !entryType.disableIXEdit && !(entryLanguages.length > 1)"
                                  :entry="entry"
                                  class="my-1"
                                  :is-locked="isLocked"
                                  @save="handleIXEditSave"
            />

            <!-- COMET PREVIEW -->
            <CometPreview v-if="entry.templateID" :entry-id="entryId"
                          :languages="entryLanguages.map(l => l.code)" ref="cometPreview">
              <template v-slot:default="{ on, attrs }">
                <v-btn rounded depressed color="blue" dark v-bind="attrs" v-on="on" class="mr-3 my-1">
                  <v-icon left>remove_red_eye</v-icon>
                  {{ entryLanguages.length > 1 ? 'Gesamtvorschau' : 'Vorschau' }}
                </v-btn>
              </template>
            </CometPreview>

            <!-- MAIL -->
            <MailAssistant
                v-if="entry.templateID && (($settings.getValue('mail.acquisition.active') && $settings.getValue('status.acquisition.enabled')) || $settings.getValue('mail.confirmation.active'))"
                :entry="entry">
              <template v-slot:default="{ on, attrs }">
                <v-btn rounded depressed color="black lighten-1" class="mr-3 my-1" dark v-bind="attrs" v-on="on">
                  <v-icon left>mail</v-icon>
                  Akquisen/Freigaben
                </v-btn>
              </template>
            </MailAssistant>

            <!-- SYNC -->
            <!-- v-btn rounded depressed color="success" dark  class="mr-3 mb-3" v-if="$permGuard.chP('actions.sync_entry')">
              <v-icon left>sync</v-icon>
              Synchronisieren
            </v-btn-->

            <!-- Knowledge Graph LINK -->
            <v-btn v-if="entry.parentID" depressed rounded color="primary" dark class="mr-3 my-1"
                   :href="`${cockpitAPI}/cockpit/frontend/themes/${$router.currentRoute.params.projectID}/entity/${ 'thing' }/${entry.parentID}`"
                   target="_blank">
              <v-icon left>open_in_browser</v-icon>
              Im Graph öffnen
            </v-btn>

            <!-- VERSIONS -->
            <v-btn rounded color="grey" depressed dark class="mr-3 my-1" router append to="history" v-if='!minimal'>
              <v-icon left>history</v-icon>
              Versionierung
            </v-btn>

          </div>

          <v-spacer class="py-3"></v-spacer>

          <v-row>

            <v-col cols="12" sm="6" md="4">
              <!-- Status -->
              <div class="" style="max-width: 500px; cursor: pointer !important;">

                <v-select
                    dense
                    outlined
                    label="Status"
                    @change="saveStatus"
                    v-model="entry.status"
                    :items="statusOptions"
                    chips
                    :loading="loadingStatus"
                    color=""
                    item-text="label"
                    :menu-props="{ maxHeight: 500 }"
                    item-value="value"
                    persistent-hint
                    :hint="isLocked ? 'Datensatz kann in diesem Status nicht editiert werden.' : ''"
                >
                  <template v-slot:selection="data">
                                        <span :class="data.item.lock ? 'warning--text' : ''">
                                            <v-icon color="warning" v-if="data.item.lock">
                                                lock
                                            </v-icon>
                                            <span class="pl-1">{{ data.item.label }}</span>
                                        </span>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar right size="28" tile>
                      <v-icon v-if="data.item.lock" :color="data.item.color">lock</v-icon>
                      <v-icon v-else :color="data.item.color">circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.label"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>

              </div>
            </v-col>

          </v-row>
          <v-row class='my-0'>

            <v-col cols="12" sm="6" md="4">
              <!-- Template -->
              <div style="max-width: 500px;">
                <v-select
                    dense
                    outlined
                    @change="saveTemplate"
                    :disabled="isLocked || !$permGuard.chP('entries.c')"
                    :items="templateOptionsEnum"
                    v-model="entry.templateID"
                    item-value="_id"
                    :item-text="templateName"
                    label="Template"
                >
                  <template v-slot:selection="data">
                    <div class="pb-2" :class="{ 'pt-2': !data.item.id }">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                      <img v-if="data.item.id" style="height: 70px;"
                           :src="`https://cc.we2p.de/${ $settings.getValue('cometConnection.client') }/INSTANCE1/pageitems/preview/${ data.item.id }.gif`">
                    </div>
                  </template>
                  <template v-slot:item="data">
                    <div class="pb-2" :class="{ 'pt-2': !data.item.id }">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                      <img v-if="data.item.id" style="height: 70px;"
                           :src="`https://cc.we2p.de/${ $settings.getValue('cometConnection.client') }/INSTANCE1/pageitems/preview/${ data.item.id }.gif`">
                    </div>
                  </template>
                </v-select>
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <!-- Type -->
              <div style="max-width: 500px;">
                <v-select
                    dense
                    outlined
                    @change="saveType"
                    :disabled="isLocked || !$permGuard.chP('entries.c')"
                    :items="entryTypes"
                    v-model="entry.type"
                    label="Kategorie des Eintrags"
                    item-value="_id"
                    item-text="name"
                ></v-select>
              </div>
            </v-col>

          </v-row>

        </div>

      </v-sheet>

      <v-sheet class="px-2 py-2 mt-5">
        <div class="mx-4 my-4 pb-3">
          <p class="text-uppercase text-caption">Ansicht</p>
          <v-btn-toggle v-model="editingMode" mandatory>
            <template v-for="mode in editingModes">
              <v-btn :value="mode.value" :key="mode.value" v-if="mode.condition()" outlined>
                <span>{{ mode.text }}</span>
                <!--v-icon class="ml-2">
                    {{ mode.icon }}
                </v-icon-->
              </v-btn>
            </template>
          </v-btn-toggle>

          <!-- languages -->
          <div class="mt-4" v-if="isMultilangProject">
            <p class="text-uppercase text-caption mb-2 mt-5">Übersetzungen</p>
            <template v-for="(lang,index) in entryLanguages">
              <v-chip :class="`pl-4 mr-3 ${ entryLanguages.length > 1 ? 'pr-0' : ''}`" :key="index">
                <!--v-avatar class="mr-2" size="28">
                    <v-img :src="`https://webimages.we2p.de/static/flags/svg/${ lang.code.slice(-2) }.svg`"></v-img>
                </v-avatar-->
                <span>{{ (lang.lang || {}).text }}</span>
                <v-btn fab color="error"
                       style="transform: scale(0.8)" depressed x-small
                       class="ml-3"
                       :key="index + '__delete'"
                       v-if="entryLanguages.length > 1"
                       @click="removeTranslation(lang.code)"
                >
                  <v-icon>remove</v-icon>
                </v-btn>
              </v-chip>
            </template>
            <v-btn fab color="success" @click="newTranslationDialog = true" depressed x-small>
              <v-icon>add</v-icon>
            </v-btn>
          </div>

          <v-dialog v-model="newTranslationDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <v-row class="py-2">
                                <span class="ml-2 mt-3">
                                    Neue Übersetzung
                                </span>
                  <v-spacer></v-spacer>
                  <!-- close -->
                  <v-btn @click="newTranslationDialog = false" fab color="error" depressed icon small>
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-select :items="projectLanguages.filter(pL => !entryLanguages.find(eL => eL.code === pL.value))"
                          placeholder="Sprache wählen..."
                          outlined
                          v-model="newTranslationLanguage"
                          label="Sprache"
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn color="success" class="mb-3" depressed v-if="newTranslationLanguage"
                       @click="addTranslation(newTranslationLanguage)">
                  Hinzufügen
                </v-btn>
                <v-spacer/>
              </v-card-actions>
            </v-card>
          </v-dialog>


        </div>

      </v-sheet>

      <v-sheet class="px-2 py-5 mt-5">

        <!-- TRANSLATE -->
        <v-row v-if="editingMode === 'translate'">
          <v-col :cols="6"
                 v-for="(column,index) in (entryLanguages.length > 1 ? [0,1] : [0])"
                 :key="index"
          >
            <!-- LANG SWITCH -->
            <div class="mt-5" v-if="isMultilangProject">
              <v-row class="mx-4">
                <LangSwitch :available="entryLanguages.map(l => l.code)" v-model="columns[index]['lang']"/>
                <!-- language status -->
                <v-select :items="langStatusOptions"
                          style="max-width: 250px"
                          class="ml-3"
                          label="Status"
                          outlined
                          :value="(entryLanguages.find(eL => eL.code === columns[index].lang) || {}).status || 'in_progress'"
                          @input="setTranslationStatus(columns[index].lang, $event)"
                          :append-outer-icon="(entryLanguages.find(eL => eL.code === columns[index].lang) || {}).status === 'locked' ? 'lock' : ''"
                ></v-select>
              </v-row>
              <v-row class="mx-4">
                <!-- comet preview -->
                <CometPreview v-if="entry.templateID" :entry-id="entryId"
                              :languages="[columns[index]['lang']]" ref="cometPreview">
                  <template v-slot:default="{ on, attrs }">
                    <v-btn color="blue"
                           depressed dark rounded
                           v-bind="attrs" v-on="on" class="my-auto mr-5">
                      <v-icon class="mr-2">preview</v-icon>
                      <span>Vorschau</span>
                    </v-btn>
                  </template>
                </CometPreview>
                <InDesignEditorDialog v-if="entry.templateID && !entryType.disableIXEdit"
                                      :entry="entry"
                                      :language="columns[index]['lang']"
                                      :is-locked="(entryLanguages.find(eL => eL.code === columns[index].lang) || {}).status === 'locked'"
                                      @save="handleIXEditSave"
                />
              </v-row>
            </div>

            <v-form ref="FormValidation" v-model="dataValid">
              <EntryEditForm ref="EntryEditForm"
                             :language="columns[index]['lang']"
                             :hide-non-multilang="false"
                             v-model="entry.content"
                             :columns="columns"
                             :entry="entry"
                             :entity="entity"
                             :definition="entryTypeDefinition"
                             :is-locked="isColumnLocked(index)"
                             @saveEntity="saveContent"
                             @updateEntity="entity = $event"
                             @importItinerary="requestImportItinerary()"
              />
            </v-form>
          </v-col>
          <v-col cols="6" v-if="entryLanguages.length < 2">
            <v-container class="my-10 mx-auto text-center">
              <p class="text-h6">Jetzt übersetzen</p>
              <v-btn fab color="success" large @click="newTranslationDialog = true">
                <v-icon>add</v-icon>
              </v-btn>
            </v-container>
          </v-col>
        </v-row>

        <!-- DEFAULT FORM -->
        <v-row v-else>
          <v-col cols="12">

            <!-- LANG SWITCH -->
            <div class="mt-5" v-if="isMultilangProject">
              <v-row class="mx-4">
                <LangSwitch :available="entryLanguages.map(l => l.code)" v-model="columns[0]['lang']"/>
                <!-- translation status -->
                <v-select :items="langStatusOptions"
                          class="ml-3"
                          label="Status"
                          outlined
                          style="max-width: 250px"
                          :value="(entryLanguages.find(eL => eL.code === columns[0].lang) || {}).status || 'in_progress'"
                          @input="setTranslationStatus(columns[0].lang, $event)"
                          :append-outer-icon="(entryLanguages.find(eL => eL.code === columns[0].lang) || {}).status === 'locked' ? 'lock' : ''"
                ></v-select>
              </v-row>
              <v-row class="mx-4">
                <!-- comet preview -->
                <CometPreview v-if="entry.templateID" :entry-id="entryId"
                              :languages="[columns[0]['lang']]" ref="cometPreview">
                  <template v-slot:default="{ on, attrs }">
                    <v-btn color="blue" depressed
                           dark rounded v-bind="attrs" v-on="on" class="my-auto mr-5">
                      <v-icon class="mr-2">preview</v-icon>
                      <span>Vorschau</span>
                    </v-btn>
                  </template>
                </CometPreview>
                <!-- IXEdit -->
                <InDesignEditorDialog v-if="entry.templateID && !entryType.disableIXEdit"
                                      :entry="entry"
                                      :language="columns[0]['lang']"
                                      :is-locked="(entryLanguages.find(eL => eL.code === columns[0].lang) || {}).status === 'locked'"
                                      @save="handleIXEditSave"
                />
              </v-row>
            </div>

            <v-form ref="FormValidation" v-model="dataValid">
              <EntryEditForm ref="EntryEditForm"
                             :language="columns[0]['lang']"
                             v-model="entry.content"
                             :entry="entry"
                             :entity="entity"
                             :columns="columns.slice(0,1)"
                             :definition="entryTypeDefinition"
                             :synced-mode="syncedMode"
                             :is-locked="isColumnLocked(0)"
                             @saveEntity="saveContent"
                             @updateEntity="entity = $event"
                             @importItinerary="requestImportItinerary()"
              />
            </v-form>

          </v-col>
        </v-row>
      </v-sheet>

      <!-- MEDIA COLLECTION PLUGIN -->
      <v-sheet id="images" class="px-2 py-2 mt-5">
        <div class="mx-4 my-4 pb-3">
          <p class="text-uppercase text-caption mb-0">Bilder</p>

          <div id="venus-media-collection__plugin"
               :data-settings='mediaCollectionPluginSettings'
          >
          </div>
        </div>
      </v-sheet>
    </template>

    <!-- SAVE BUTTON -->
    <v-btn fab x-large fixed :color="getSaveColor" top right @click="saveContent"
           style="top: 80px;backdrop-filter: blur(5px);" :disabled="!saveRequired">
      <v-icon v-if="!syncing && !saving && saveRequired" color="white">
        save
      </v-icon>
      <v-icon v-if="!syncing && !saving && !saveRequired" color="white">
        check
      </v-icon>
      <v-progress-circular indeterminate v-if="saving || syncing"/>
    </v-btn>

    <!-- SAVE BUTTON (cockpit) -->
    <v-btn class="my-auto ml-2" fixed top right :loading="savingEntity" v-if="syncedMode && savingEntity"
           style="top: 160px; right: 30px;" fab small :color="'success'">
      <v-icon v-if="!savingEntity" color="white">
        save
      </v-icon>
      <!--v-progress-circular indeterminate v-if="savingEntity"/-->
    </v-btn>

    <v-dialog v-model="loadingStatus" max-width="600" persistent>
      <v-card>
        <!-- LOADER -->
        <v-card class="text-center py-5">
          <v-progress-circular class="my-5"
                               :size="50"
                               :width="4"
                               color="grey"
                               indeterminate
          ></v-progress-circular>
          <p class="text-center">Status wird aktualisiert.</p>
        </v-card>
      </v-card>

    </v-dialog>

    <!-- itinerary import -->
    <DeleteConfirmation :hidden="true"
                        ref="importItineraryDialog"
                        msg="Falls Sie neue Streckenpunkte im Streckeneditor erstellt/verknüpft haben, sollten diese in den PublicationPlanner importiert werden. Streckenpunkte jetzt synchronisieren?"
                        title="Streckenpunkte aktualisieren?"
                        @yes="execImportItinerary(false)">
    </DeleteConfirmation>

    <!-- description re-import -->
    <DeleteConfirmation :hidden="true"
                        ref="importDescriptionDialog"
                        msg="Falls Sie etwas an den Texten geändert haben, sollten diese in den PublicationPlanner importiert werden. Texte jetzt synchronisieren?"
                        title="Texte aktualisieren?"
                        @yes="execImportDescription(false)">
    </DeleteConfirmation>

    <!-- itinerary force save -->
    <DeleteConfirmation :hidden="true" ref="itineraryForceSaveDialog"
                        msg="Es bestehen noch ungespeicherte Änderungen, die zum Forfahren gespeichert werden müssen. Sollen diese jetzt gespeichert werden?"
                        title="Jetzt speichern?" @yes="execImportItinerary(true)">
    </DeleteConfirmation>

    <!-- description force save -->
    <DeleteConfirmation :hidden="true" ref="descriptionForceSaveDialog"
                        msg="Es bestehen noch ungespeicherte Änderungen, die zum Forfahren gespeichert werden müssen. Sollen diese jetzt gespeichert werden?"
                        title="Jetzt speichern?" @yes="execImportDescription(true)">
    </DeleteConfirmation>

    <BackToTopButton/>

  </div>
</template>

<script>

import _ from 'lodash';
import axios from "axios";

import {mapActions} from 'vuex';
import EntryEditForm from "../../components/entries/EntryEditForm";
import CometPreview from "../../components/entries/CometPreview";
import TranslationService from "../../services/TranslationService";
import EntryStatusService from "../../services/EntryStatusService";

import MailAssistant from "../../components/entries/MailAssitant";
import SocketAdapter from "../../services/socket-adapter";

import DownloadService from "@/services/DownloadService";

import BlockUnsavedExitMixin from "../../components/mixins/block-unsaved-exit";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
import LangSwitch from "@/components/utility/LangSwitch";
import InDesignEditorDialog from "@/components/entries/fullscreen/InDesignEditorDialog";
import BasicDataProvider from "@/services/shared/utility/basic-data-provider";
import EntryMetaInfoEdit from '../../components/entries/EntryMetaInfoEdit';
import BackToTopButton from "@/components/utility/BackToTopButton.vue";

export default {
  name: 'EntryEdit',
  components: {
    BackToTopButton,
    EntryMetaInfoEdit,
    InDesignEditorDialog,
    LangSwitch,
    DeleteConfirmation,
    MailAssistant,
    CometPreview,
    EntryEditForm
  },
  props: [
    "entryId",
    "minimal"
  ],
  mixins: [
    BlockUnsavedExitMixin
  ],
  data() {
    return {
      newTranslationLanguage: "",
      newTranslationDialog: false,
      editingModes: [
        {
          value: "default",
          text: "Standard-Ansicht",
          icon: "edit",
          condition: () => {
            return true;
          }
        },
        {
          value: "translate",
          text: "Übersetzen-Ansicht",
          icon: "translate",
          condition: () => {
            return this.isMultilangProject;
          }
        },
        {
          value: "graph_sync",
          text: "Knowledge-Graph-Ansicht",
          icon: "sync",
          condition: () => {
            return this.$permGuard.chP('actions.entry_entity_sync_mode') && this.entry.parentID;
          }
        }
      ],
      columns: [
        {
          lang: "de-DE",
        },
        {
          lang: "de-DE",
        },
      ],
      entry: {},
      entity: {},
      initialEntity: {},
      initialContent: {},
      dataValid: true,
      loadingEntry: false,
      loadingEntryType: false,
      loadingTemplates: false,
      loadingStatus: false,
      confirmationLoading: {},
      compiledContent: {},
      templates: [],
      entryTypes: [],
      invalidSaveDialog: {
        opened: false,
      },
      switchWarnDialog: {
        opened: false,
        oldType: '',
      },
      saving: false,
      syncing: false,
      savingEntity: false,

      mediaCollectionPluginSettings: undefined
    }
  },
  computed: {
    editingMode: {
      get() {
        let mode = this.$settings.getUserSettings('entry_detail_editmode');
        if (!mode || !this.editingModes.find(e => e.value === mode)?.condition) {
          return "default";
        }
        return mode;
      },
      set(mode) {
        this.$settings.setUserSettings('entry_detail_editmode', mode);
      }
    },
    isMultilangProject: function () {
      return this.$settings?.getValue('language.available')?.length > 1;
    },
    langStatusOptions: function () {
      return EntryStatusService.getLangStatusArray().map((s) => {
        return {
          ...s,
          text: s.label
        };
      })
    },
    entryLanguages: function () {

      // default
      let languages = [
        {
          code: "de-DE",
          status: "in_progress",
        }
      ];

      // if entry has configured languages
      if (this.entry?.languages?.length > 0) {
        languages = this.entry?.languages;
      }

      // map to enrich data
      return languages.map((language) => {
        return {
          ...language,
          lang: BasicDataProvider.getAllLanguages().find((l) => l.value === language.code) || {},
        }
      })

    },
    projectLanguages: function () {
      return BasicDataProvider.getAllLanguages(this.$settings.getValue('language.available'));
    },
    syncedMode: {
      get() {
        return this.editingMode === "graph_sync"
      }
      /*get: function() {
          return Boolean(this.$settings.getUserSettings(
              `entry_syncedMode`
          ));
      },
      set: function(value) {
          this.$settings.setUserSettings(
              `entry_syncedMode`,
              value
          );
      }*/
    },
    statusOptions: function () {
      return EntryStatusService.getStatusArray(this.entry.status).filter((s) => !s.hidden);
    },
    getSaveColor: function () {

      let color = 'success';
      if (this.saveRequired) color = 'warning';
      if (!this.dataValid) color = '#f03800';

      return color;
    },
    saveEntityRequired: function () {
      if (!this.entity || _.isEqual(this.initialEntity, this.entity)) {
        return false;
      }
      return true;
    },
    /**
     * compare initial entry with current (modified) entry to see if a save is required
     */
    saveRequired: function () {
      if (!this.entry?.content) {
        return false;
      }
      if (_.isEqual(this.initialContent, this.entry?.content) && !this.saveEntityRequired) {
        return false;
      } else {
        console.log("mismatch when checking save require", _.cloneDeep(this.initialContent), _.cloneDeep(this.entry?.content));
        let diff = _.reduce(this.initialContent, (result, value, key) => {
          return _.isEqual(value, this.entry?.content[key]) ?
              result : result.concat(key);
        }, []);
        console.log("mismatching values are ", diff)
        return true;
      }
    },
    reactiveEntry: function () {
      return this.$store.getters['entries/allEntries']?.find(
          e => e._id.toString() === this.entryId
      ) || {};
    },
    isLocked: function () {
      return this.statusOptions.find(s => s.value === this.entry.status)?.lock;
    },
    cockpitAPI: function () {
      return process.env.VUE_APP_COCKPIT_URL || "https://c2.we2p.de";
    },
    templateOptionsEnum: function () {
      return [
        ...this.templateOptions,
        {
          _id: "",
          name: "Kein Template",
          imageCount: 0,
        }
      ]
    },
    templateOptions() {
      let currentType = this.entryTypes.find(x => x._id === this.entry.type);

      if (currentType !== undefined) {
        return this.templates.filter(template => currentType.templates.includes(template._id));
      } else {
        return [];
      }
    },
    entryTypeDefinition() {
      if (typeof this.entry.type !== "undefined" && this.entryTypes.find(x => x._id === this.entry.type)) {
        let currentType = this.entryTypes.find(x => x._id === this.entry.type);
        return currentType?.['properties'];
      } else {
        return [];
      }
    },
    entryType() {
      if (typeof this.entry.type !== "undefined" && this.entryTypes.find(x => x._id === this.entry.type)) {
        return this.entryTypes.find(x => x._id === this.entry.type);
      } else {
        return [];
      }
    },
    getLabel() {
      if (typeof this.entry.content !== 'undefined' && (this.entry.content.name || '' !== '')) {
        return TranslationService.getValue(this.entry.content.name, 'de-DE');
      } else {
        return `Eintrag (${this.entry._id})`
      }
    },
    mediaCollectionUrl() {
      return process.env.VUE_APP_MEDIA_COLLECTION_API_URL || 'https://dev-media.we2p.de'
    }
  },
  methods: {
    ...mapActions("entries", ['createEntry', 'fetchEntriesByCatalog']),
    isColumnLocked(column) {
      return this.isLocked || (this.entryLanguages.find(eL => eL.code === this.columns[column].lang) || {}).status === 'locked';
    },
    setTranslationStatus(lang, status) {
      let index = this.entry.languages?.findIndex(l => l.code === lang);

      if (index === -1) {
        this.addTranslation(lang, status);
      } else {
        this.entry.languages[index]['status'] = status;
        this.saveLanguages();
      }
    },
    addTranslation(lang, status = "in_progress") {
      if (this.entry.languages?.length === 0 && lang !== "de-DE") {
        this.entry.languages = [{
          code: "de-DE",
          status: "in_progress"
        }];
      }
      this.entry.languages.push({
        code: lang,
        status
      });

      // automatically set second column to new lang
      this.columns[1]["lang"] = lang;

      this.newTranslationDialog = false;

      this.saveLanguages();

    },
    removeTranslation(lang) {
      let index = this.entry.languages?.findIndex(l => l.code === lang);
      this.entry.languages?.splice(index, 1);
      this.columns[1]['lang'] = this.entryLanguages.at(-1)?.code || "de-DE";

      this.saveLanguages();
    },
    requestImportItinerary() {
      if (this.entry.parentID) {
        this.$refs['importItineraryDialog'].open();
      }
    },
    requestImportDescription() {
      if (this.entry.parentID && this.$settings.getValue('graph.ixedit_desc_sync.enabled')) {
        this.$refs['importDescriptionDialog'].open();
      }
    },
    async handleIXEditSave() {
      this.entry.__v++;
      await this.loadEntity();
      this.requestImportDescription();
    },
    async execImportDescription(forcedSave) {

      if (forcedSave) {
        await this.$store.dispatch('entities/fetch', {id: this.entry?.parentID, flush: true});
        await this.saveContent();
      }

      // todo if forced save fails, this should also fail
      if (this.saveRequired && !forcedSave) {
        this.$refs['descriptionForceSaveDialog'].open();
      } else {
        this.syncing = true;
        // todo - get real proposal from backend to check if something actually changed and ensure integrity
        axios.post(`/api/entrysync/strategy/${this.$router.currentRoute.params.catalogID}`, {
          "syncProposal": {
            "time": 0,
            "toCreate": [],
            "toUpdate": [
              {
                "entryId": this.entry._id.toString(),
                "entityId": this.entry.parentID,
                "name": this.entry.name,
                "status": this.entry.status,
                "typeId": this.entry.type,
              },
            ],
            "blockedUpdate": [],
            "alreadyUpdate": [],
            "noMatch": []
          },
          "options": {
            propertyWhitelist: [
              "description",
            ]
          },
          "agree": true,
          "create": {
            "ignore": true,
            "ids": []
          },
          "update": {
            "ignore": false,
            "ids": [
              this.entry._id.toString(),
            ]
          }
        }).then((res) => {
          this.loadEntry().then(() => {
            this.loadEntity();
          })
          this.$notify({group: 'main', type: 'success', duration: 2000, title: 'Texte aktualisiert', text: ''})
        }).catch((e) => {
          console.log(e);
          this.$notify({group: 'main', type: 'error', duration: -1, title: 'Sync fehlgeschlagen!', text: ''})
        }).finally(() => {
          this.syncing = false;
        });
      }
    },
    async execImportItinerary(forcedSave) {

      if (forcedSave) {
        await this.saveContent();
      }

      // if forced save fails, this should also fail
      if (this.saveRequired) {
        this.$refs['itineraryForceSaveDialog'].open();
      } else {
        this.syncing = true;
        // todo - get real proposal from backend to check if something actually changed and ensure integrity
        axios.post(`/api/entrysync/strategy/${this.$router.currentRoute.params.catalogID}`, {
          "syncProposal": {
            "time": 0,
            "toCreate": [],
            "toUpdate": [
              {
                "entryId": this.entry._id.toString(),
                "entityId": this.entry.parentID,
                "name": this.entry.name,
                "status": this.entry.status,
                "typeId": this.entry.type,
              },
            ],
            "blockedUpdate": [],
            "alreadyUpdate": [],
            "noMatch": []
          },
          "options": {
            propertyWhitelist: [
              "itinerary",
            ]
          },
          "agree": true,
          "create": {
            "ignore": true,
            "ids": []
          },
          "update": {
            "ignore": false,
            "ids": [
              this.entry._id.toString(),
            ]
          }
        }).then((res) => {
          this.loadEntry();
          this.$notify({group: 'main', type: 'success', duration: 2000, title: 'Streckenpunkte aktualisiert', text: ''})
        }).catch((e) => {
          console.log(e);
          this.$notify({group: 'main', type: 'error', duration: -1, title: 'Sync fehlgeschlagen!', text: ''})
        }).finally(() => {
          this.syncing = false;
        });
      }
    },
    async loadEntry() {
      return new Promise((resolve, reject) => {
        // load entry
        this.loadingEntry = true;
        this.$store.dispatch('entries/fetchSingleEntry', {id: this.entryId}).then(() => {
          this.entry = this.$store.getters['entries/allEntries']?.find(e => e._id.toString() === this.entryId);
          this.initialContent = _.cloneDeep(this.entry?.content);

          // prepare columns
          if (this.entry.languages?.length > 0) {
            this.columns[0]['lang'] = this.entryLanguages.at(0)?.code || "de-DE";
          }
          if (this.entry.languages?.length > 1) {
            this.columns[1]['lang'] = this.entryLanguages.at(-1)?.code || "de-DE";
          }

          resolve();
        }).catch((e) => {
          reject();
        }).finally(() => {
          this.loadingEntry = false;
        });

      })
    },
    async loadEntity() {
      return new Promise((resolve, reject) => {

        // load entry
        if (this.entry?.parentID) {
          this.$store.dispatch('entities/fetch', {id: this.entry?.parentID, flush: true}).then(() => {
            this.entity = this.$store.getters['entities/getAll'].find(e => e._id.toString() === this.entry?.parentID);
            this.initialEntity = _.cloneDeep(this.entity);
            resolve()
            // this.loadingEntity = false;
          }).catch((e) => {
            console.log(e);
            this.$notify({
              group: 'main',
              type: 'info',
              duration: 10000,
              title: "Cockpit-Datensatz konnte nicht geladen werden.",
              text: "Vermutlich ist der Datensatz im Cockpit gelöscht worden. Dies ist nur ein Hinweis."
            });
            reject();
          })
        }

      })
    },
    templateName: item => item.name,
    resetTypeSwitch() {
      let old = this.switchWarnDialog.oldType;
      // reset oldType so the dialog doesnt trigger again;
      this.switchWarnDialog.oldType = '';
      this.entry.type = old;
      this.switchWarnDialog.opened = false;
    },

    /**
     * @deprecated
     * @returns {Promise<void>}
     */
    /*async compileContent() {
        this.compiledContent = _.cloneDeep(await this.$refs.EntryEditForm.save());
        console.log("compiling content... ", this.$refs.FormValidation)
        this.$refs?.FormValidation?.map((ref) => {
            console.log('validate: ', ref.validate?.());
        })
    },*/

    // SAVE
    async saveType() {
      let saveData = _.cloneDeep(this.entry);
      delete saveData.content;
      delete saveData.languages;
      delete saveData.status;
      delete saveData.templateID;
      delete saveData.meta;
      await this.saveRequest(saveData, 'Typ geändert.', 'Typ-Änderung fehlgeschlagen!')
    },
    // SAVE
    async saveLanguages() {
      let saveData = _.cloneDeep(this.entry);
      delete saveData.content;
      delete saveData.status;
      delete saveData.templateID;
      delete saveData.type;
      delete saveData.meta;
      await this.saveRequest(saveData, 'Sprachen gespeichert.', 'Sprachen konnten nicht geändert werden!')
    },
    async saveTemplate() {
      let saveData = _.cloneDeep(this.entry);
      delete saveData.content;
      delete saveData.languages;
      delete saveData.status;
      delete saveData.type;
      delete saveData.meta;
      await this.saveRequest(saveData, 'Template gespeichert.', 'Template-Änderung fehlgeschlagen!')
    },
    async saveMeta() {
      let saveData = _.cloneDeep(this.entry);
      delete saveData.content;
      delete saveData.templateID;
      delete saveData.languages;
      delete saveData.status;
      delete saveData.type;
      await this.saveRequest(saveData, 'Infos aktualisiert.', 'Speichern der Info fehlgeschlagen!')
    },
    async saveStatus() {

      this.loadingStatus = true;

      let status = this.statusOptions.find(o => o.value === this.entry?.status);

      let saveData = _.cloneDeep(this.entry);
      delete saveData.content;
      delete saveData.templateID;
      delete saveData.languages;
      delete saveData.type;


      if (status?.value === "confirmation_requested" || status?.value === "confirmed") {

        let failed = await axios.post('/api/comet/confirmation/preview?type=pdf&entryId=' + this.entry?._id?.toString(), {
          languages: this.entryLanguages.map(l => l.code) || ["de-DE"],
        }, {
          timeout: 120 * 1000,
        }).then(async (res) => {
          if (res.data) {
            res.data.map((url) => {
              DownloadService.downloadWithAxios(url, `freigabe_${this.entry._id}.pdf`, false);
            })
            this.$notify({
              group: 'main',
              type: 'success',
              duration: 2000,
              title: 'Freigabe-Dateien erstellt!',
              text: ''
            })
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              duration: 5000,
              title: 'Freigabe-Dateien konnten nicht erstellt werden!',
              text: ''
            })
            this.loadingStatus = false;
            return "failed";
            // failed
          }
        }).catch((e) => {
          console.log(e);
          this.loadingStatus = false;
          this.$notify({
            group: 'main',
            type: 'error',
            duration: 5000,
            title: 'Freigabe-Dateien konnten nicht erstellt werden!',
            text: ''
          })
          return "failed";
        });

        if (failed === "failed") {
          // refresh to display previous status
          this.$store.dispatch('entries/fetchSingleEntry', {id: this.entry._id.toString()});
          // prevents status update if confirmation failed
          return;
        }

      }

      await this.saveRequest(saveData, 'Status aktualisiert.', 'Status-Änderung fehlgeschlagen!', "/status")

      // update ui of media-collection-plugin
      window.dispatchEvent(new CustomEvent('venus__media_collection_plugin__update_config', {
        detail: this.generateMediaCollectionPluginConfig()
      }));

      this.loadingStatus = false;

    },

    async saveEntity() {

      this.savingEntity = true;

      // save entity
      this.$store.dispatch('entities/update', {id: this.entry.parentID, entity: this.entity}).then((res) => {
        this.entity.__v++;
        this.initialEntity = _.cloneDeep(this.entity);
        this.$notify({group: 'main', type: 'success', duration: 2000, title: 'Graph-Entität gespeichert!'})
      }).catch((e) => {
        this.entity = _.cloneDeep(this.initialEntity);
        console.error(e);
        this.$notify({group: 'main', type: 'error', duration: 5000, title: 'Speichern im Graph fehlgeschlagen!'})
      }).finally(() => {
        this.savingEntity = false;
      })


    },

    async saveContent() {

      if (this.saveEntityRequired) {
        await this.saveEntity();
      }

      if (!this.saveRequired) return;

      // block save if is locked
      if (this.isLocked) {
        this.$notify({
          group: 'main',
          type: 'warning',
          duration: 5000,
          title: 'Datensatz gesperrt!',
          text: 'Ändern Sie zunächst den Status des Datensatzes um zu speichern.'
        })
        return false;
      }

      // open invalid save warning, continue if saved while warning already open
      if (!this.dataValid) {
        if (!this.invalidSaveDialog.opened) {
          this.invalidSaveDialog.opened = true;
          return;
        } else {
          this.invalidSaveDialog.opened = false;
        }
      }

      // set new content
      let saveData = _.cloneDeep(this.entry);

      // remove properties that are saved separately
      delete saveData.status;
      delete saveData.type;
      delete saveData.languages;
      delete saveData.templateID;

      await this.saveRequest(saveData, 'Speichern erfolgreich.', 'Speichern fehlgeschlagen!').then(() => {
        this.initialContent = _.cloneDeep(this.entry?.content);
        return true;
      }).catch((e) => {
        console.log(e);
        return false;
      });

    },
    async saveRequest(saveData, successMsg, errorMsg, specialEndpoint = '') {
      this.saving = true;
      await axios.put(`/api/entries/${this.entry._id}${specialEndpoint}`, saveData).then((res) => {
        this.$notify({group: 'main', type: 'success', duration: 1000, title: successMsg});

        // notify other instances to update entry
        SocketAdapter.socket().emit('entries:refresh_single', {
          entryID: this.entry._id,
          roomName: `project:${this.$router.currentRoute.params.projectID}`
        });
        this.$store.dispatch('entries/fetchSingleEntry', {id: this.entry._id.toString()});
        this.$emit('save');
      }).catch((e) => {
        this.$notify({group: 'main', type: 'error', duration: -1, title: errorMsg, text: e.toString()});
        console.error(e);
      }).finally(() => {
        this.saving = false;
      });
    },
    validateForm() {
      console.log("compiling content... ", this.$refs.FormValidation)
      this.$refs?.FormValidation?.validate?.();
    },
    scrollToImages() {
      document.getElementById('images').scrollIntoView();
    },
    generateMediaCollectionPluginConfig() {
      const config = this.mediaCollectionPluginSettings = {
        "mediaCollection": this.mediaCollectionUrl,
        "system": "publication-planner",
        "project": this.$router.currentRoute.params.projectID,
        "id": this.entryId,
        "parent": this.entry.parentID,
        "style": {
          'background': false
        },
        "auth": {
          "token": localStorage.getItem('cockpit_token') ?? localStorage.getItem('auth') ?? localStorage.getItem('venus__media_collection_plugin')
        }
      };

      if (this.isLocked) {
        config.functions = [
          'download',
          'listing_bar'
        ];
      }

      return config;
    }
  },
  watch: {
    'entry.content': {
      handler() {
        this.validateForm();
      },
      deep: true
    },
    // watch for entry was saved
    reactiveEntry(newVal) {
      this.entry.status = newVal.status;
      this.entry.templateID = newVal.templateID;
      this.entry.languages = newVal.languages;
      this.entry.__v = newVal.__v;
    },
    'entry.type'(newVal, oldVal) {
      if (this.switchWarnDialog.oldType && (this.switchWarnDialog.oldType !== '')) {
        this.switchWarnDialog.opened = true;
        this.switchWarnDialog.oldType = oldVal;
      }
    }
  },
  created() {
    // load entry
    this.loadEntry().then(() => {
      if (this.entry.parentID) {
        this.$refs?.FormValidation?.map((ref) => {
          ref.validate?.();
        })
        this.loadEntity();
      }

      // inject media-collection-plugin script
      this.mediaCollectionPluginSettings = JSON.stringify(this.generateMediaCollectionPluginConfig());

      const scriptTag = document.createElement('script');
      //scriptTag.setAttribute('src', 'http://localhost:8081/js/app.js'); // development only
      scriptTag.setAttribute('src', 'https://cdn.venus.bayern/media-collection-plugin/master/app');
      scriptTag.setAttribute('id', 'venus-media-collection-plugin-script');
      document.getElementsByTagName('head').item(0).appendChild(scriptTag);
    });

    // load entrytype definition
    this.loadingEntryType = true;

    // other entrytypes
    axios.get(`/api/entrytypes/?projectID=${this.$router.currentRoute.params.projectID}&channelID=${this.$router.currentRoute.params.channelID}`, {}).then((res) => {
      this.entryTypes = res.data;
      this.loadingEntryType = false;
    }).catch((e) => {
      console.error(e);
      this.loadingEntryType = false;
    });


    // load templates
    this.loadingTemplates = true;

    axios.get(`/api/templates/?projectID=${this.$router.currentRoute.params.projectID}&channelID=${this.$router.currentRoute.params.channelID}`, {}).then((res) => {
      this.templates = res.data;
      this.loadingTemplates = false;
    }).catch((e) => {
      console.error(e);
      this.loadingTemplates = false;
    });
  }
}
</script>

