<template>

    <v-container>

        <template>
            <v-card>
                <v-card-title>
                    Datenbestand
                    <v-spacer></v-spacer>
                    <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Suchen"
                            single-line
                            hide-details
                    ></v-text-field>
                </v-card-title>

                <v-btn @click="syncAll" color="success" :loading="isSyncing" rounded depressed class="ma-4">
                    Alle an Onlim Senden
                </v-btn>

                <v-data-table
                        :items-per-page="100"
                        :loading="loading"
                        :loading-text="'Wird geladen...'"
                        :headers="headers"
                        :items="tableData"
                        :search="search"
                >
                    <template v-slot:item.action="props">
                        <v-btn :loading="syncing.includes(props.item.entityID)" class="mr-3 my-2" :color="getActionColor(props.item.action)" small depressed rounded @click="singleSync(props.item.entityID)">
                            {{ props.item.action }}
                        </v-btn>
                    </template>
                    <template v-slot:item.inOnlim="props">
                        <v-icon v-if="props.item.inOnlim" color="success">check</v-icon>
                    </template>
                </v-data-table>
            </v-card>

        </template>

    </v-container>

</template>

<script>

    import axios from 'axios';
    import _ from 'lodash';
    import TranslationService from "../../services/TranslationService";

    export default {
        name: "SyncView",
        data() {
            return {
                isSyncing: false,
                syncing: [],
                headers: [
                    { text: 'Aktion', value: 'action', width: "120" },
                    { text: 'Name', value: 'name'},
                    { text: 'CockpitID', value: 'entityID', width: "300" },
                    { text: 'in Onlim?', value: 'inOnlim', width: "120" },
                    // { text: 'OriginalID', value: 'parentID' },
                    // { text: 'Template', value: 'templateID' },
                ],
                status: {
                    relations: [],
                },
                search: '',
                loading: false,
            }
        },
        computed: {
            tableData() {
                return this.status.relations?.map?.((r) => {
                    return {
                        action: r.action,
                        name: this.translate(r.entity?.name),
                        entityID: r.entity ? r.entity._id : '',
                        inOnlim: r.onlimEntity ? 'ja' : '',
                        isSyncing: r.isSyncing || false,
                    }
                })
            }
        },
        methods: {
            getActionColor(action) {
                switch (action) {
                    case 'create':
                        return 'success'
                    case 'update':
                        return 'info'
                    case 'delete':
                        return 'error'
                }
            },
            translate(data) {
                return TranslationService.getValue(data, 'de-DE');
            },
            singleSync(id) {
                this.syncing.push(id);

                axios.post(`/api/onlim/sync/${id}?projectID=${this.$router.currentRoute.params.projectID}&channelID=${this.$router.currentRoute.params.channelID}`).then((res) => {
                    if (res.data.status === 'success') {

                        console.log("wurde an onlim gesendet", res.data.content);

                        axios.get(`/api/onlim/status/${id}?projectID=${this.$router.currentRoute.params.projectID}&channelID=${this.$router.currentRoute.params.channelID}`).then((res) => {
                            const index = this.status.relations.findIndex(r => r.entity?._id === res.data.entity?._id);
                            this.status.relations.splice(index, 1, res.data);
                            this.syncing = this.syncing.filter(x => x !== res.data.entity?._id);
                        }).catch((e) => {
                            console.log(e);
                        }).finally(() => {
                            this.syncing = this.syncing.filter(x => x !== res.data.entity?._id);
                        })

                    } else {

                        console.error('error beim senden an onlim');
                        console.log('versucht zu übertragen: ', res.data.content);
                        console.log('antwort von onlim: ', res.data.reason);

                        this.syncing = this.syncing.filter(x => x !== id);

                    }
                }).catch((e) => {
                    console.log(e);
                })
            },
            syncAll() {
                this.isSyncing = true;
                axios.post(`/api/onlim/sync`, {
                    projectID: this.$router.currentRoute.params.projectID,
                    channelID: this.$router.currentRoute.params.channelID,
                }).then((res) => {

                }).catch((e) => {
                    console.log(e);
                }).finally(() => {
                    this.isSyncing = false;
                })
            }
        },

        created() {

            this.loading = true;
            axios.get(`/api/onlim/status?projectID=${this.$router.currentRoute.params.projectID}&channelID=${this.$router.currentRoute.params.channelID}`).then((res) => {
                this.status = res.data;
            }).finally(() => {
                this.loading = false;
            })

        }

    }
</script>

<style scoped>



</style>