<template>

  <div>

    <v-menu bottom offset-y v-model="open" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-avatar size="40">
            <v-avatar color="var(--v-primary-base)" style="position: absolute">
              <span v-if="idToken.initials">{{ idToken.initials }}</span>
              <v-icon v-else>person</v-icon>
            </v-avatar>
            <img v-if="!open" style="position: absolute" :src="user.profile_img_blank" alt="profile image">
          </v-avatar>
        </v-btn>
      </template>

      <v-card min-width="380" style="border-left: var(--v-primary-base) 4px solid">
        <div class="py-4 px-5"
             :style="`background-image: url('${user.profile_img}'); background-position: right; background-size: contain`">
          <!--v-avatar size="40" class="mr-4">
              <img :src="user.profile_img" alt="profile image"/>
          </v-avatar-->
          <p class="text-h6 mb-0" style="max-width: calc(100% - 100px)">
            {{ idToken.name }}
          </p>
          <p class="mb-1 text-caption" style="max-width: calc(100% - 100px)">
            {{ idToken.mail }}
          </p>
          <p v-if="idToken.firmation || idToken.company" class="mb-0 grey--text" style="max-width: calc(100% - 100px)">
            {{ idToken.firmation || idToken.company }}
          </p>
        </div>

        <v-list class="py-0">
          <v-divider></v-divider>
          <!--v-list-item :href="profilePageLink" target="_blank">
            <v-list-item-title>Einstellungen</v-list-item-title>
            <v-list-item-icon>
              <v-icon>palette</v-icon>
            </v-list-item-icon>
          </v-list-item-->
          <v-list-item :href="profilePageLink" target="_blank">
            <v-list-item-title>Profil bearbeiten</v-list-item-title>
            <v-list-item-icon>
              <v-icon>manage_accounts</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="$store.dispatch('auth/switchAccount')">
            <v-list-item-title>Konto wechseln</v-list-item-title>
            <v-list-item-icon>
              <v-icon>switch_account</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item @click="$store.dispatch('auth/logout')">
            <v-list-item-title>Abmelden</v-list-item-title>
            <v-list-item-icon>
              <v-icon color="error">logout</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>


      </v-card>

    </v-menu>

    <!-- icon -->
    <span>
            <!--v-btn
                fab
                small
                depressed
                class="mr-4"
                color="error"
                v-if="user.actingAs"
                @click="$store.dispatch('auth/actAs', { userID: '' })"
            >
                <v-icon>person_off</v-icon>
            </v-btn>
            <v-avatar size="40" class="mr-4" v-else-if="user">
                <img :src="user.profile_img" alt="profile image"/>
            </v-avatar-->
        </span>

    <!-- name -->
    <!--span>
        <span
            class="text-button mr-3 d-none d-md-inline-block"
            v-if="user.actingAs"
        >"{{ user.actingAs.name }}"</span
        >
        <span class="text-button mr-3 d-none d-md-inline-block" v-else-if="user">
            {{ user.name }}
        </span>
    </span-->

    <!-- logout -->
    <!--span>
        <v-btn fab small text @click="$store.dispatch('auth/logout')">
            <v-icon>logout</v-icon>
        </v-btn>
    </span-->

  </div>

</template>

<script>
import gravatar from "gravatar";

export default {
  name: "CurrentUser",
  props: [],
  data() {
    return {
      open: false,
    }
  },
  computed: {
    user: function () {
      let user = this.$store.getters['auth/getUser'];
      console.log(user);
      user.profile_img = gravatar.url(user.mail, {
        default: 'robohash',
        protocol: 'https',
        size: '256'
      });
      user.profile_img_blank = gravatar.url(user.mail, {
        default: 'blank',
        protocol: 'https',
      });
      return user;
    },
    profilePageLink: function () {
      return process.env.VUE_APP_VENUS_OAUTH + "/profile";
    },
    idToken: function () {
      let token = this.$store.getters['auth/getIdToken'];
      return JSON.parse(decodeURIComponent(atob(token.split('.')[1].replace('-', '+').replace('_', '/')).split('').map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join('')));
    }
  }
}
</script>

<style scoped>

</style>
