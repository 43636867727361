import axios from 'axios';
import router from '../../router';
import Vue from 'vue';

const entrypoint = 'channels';

const store = {
    state: {
        status: '',
        data: {},
        current: '',
    },

    getters: {
        getAll: (state) => state.data,
        getStatus: (state) => state.status,
        getCurrent: (state) =>
            state.data[router.currentRoute.params.projectID]?.find(
                (p) => p.id === router.currentRoute.params.channelID
            ),
    },

    actions: {
        setCurrent({ commit }, id) {
            commit('set_current', id);
        },
        async flush({ commit }) {
            commit('flush');
        },
        async fetchForProject({ commit, getters }, { projectID }) {
            return new Promise((resolve, reject) => {
                if (
                    (Object.hasOwnProperty.call(getters.getAll, projectID) &&
                        getters.getAll[projectID].length > 0) ||
                    !projectID
                ) {
                    // channels for this project already fetched
                    resolve();
                    return;
                }

                commit('request_sent');

                axios
                    .get(`/api/v2/${entrypoint}/project/${projectID}`)
                    .then((res) => {
                        commit('request_success', {
                            data: res.data,
                            projectID,
                        });
                        resolve();
                    })
                    .catch((e) => {
                        console.log(e);
                        commit('request_error');
                        reject(e);
                    });
            });
        },
    },

    mutations: {
        set_current(state, id) {
            state.current = id;
        },
        flush(state) {
            state.data = {};
        },
        request_sent(state) {
            state.status = 'loading';
        },
        request_success(state, data) {
            state.status = 'success';
            Vue.set(state.data, data.projectID, data.data);
        },
        request_error(state) {
            state.status = 'error';
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
