<template>
    <div>

        <p class="text-h6 mb-4 grey--text">Templates</p>

        <v-container class="my-5">

            <div class="my-5 text-center" v-if="getAll.length === 0">
                <v-icon size="128" class="mb-4 grey--text">view_quilt</v-icon>
                <p class="text-h4 my-5">Noch keine Templates.</p>
            </div>

            <v-card flat tile class="mb-4" v-if="getAll.length > 0">


                <template v-for="(template, index) in getAll">
                    <v-list-item style="height: 100px" :key="template._id" :index="index">
                        <template>
                            <v-list-item-avatar tile width="70">
                                <span class="grey--text headline">{{ template.id }}</span>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title v-text="template.name" />

                                <v-list-item-subtitle
                                        class="grey--text"
                                        v-text="`${template.imageCount || 'Keine'} ${ template.imageCount === 1 ? 'Bild' : 'Bilder' }`"
                                ></v-list-item-subtitle>
                                <v-list-item-subtitle v-if='template.price'>Anzeigenpreis: {{ parseFloat(template.price).toFixed(2).toString().replace('.', ',') }} €</v-list-item-subtitle>
                                <v-list-item-subtitle>Größe: {{ getSizeDescription(template) }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <img style="max-height: 100%" :src="`https://cc.we2p.de/${ $settings.getValue('cometConnection.client') }/INSTANCE1/pageitems/preview/${ template.id }.gif`">


                            <v-list-item-action class="ml-10">
                                <div>
                                    <TemplateEdit v-if="$permGuard.chP('templates.u')" :template="template"/>
                                    <DeleteConfirmation v-if="$permGuard.chP('templates.d')" @yes="removeTemplate(template._id)"/>
                                </div>

                            </v-list-item-action>

                        </template>
                    </v-list-item>

                    <v-divider v-if="index < getAll.length - 1" :key="index"></v-divider>
                </template>


            </v-card>

        </v-container>

    </div>

</template>

<script>

    import _ from 'lodash';
    import { mapGetters, mapActions } from 'vuex';
    import TemplateEdit from "./TemplateEdit";
    import DeleteConfirmation from "../utility/DeleteConfirmation";

    export default {
        name: 'TemplatesList',
        components: {
            DeleteConfirmation,
            TemplateEdit
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters("templates", ['getAll']),
        },
        methods: {
            ...mapActions("templates", ['add', 'update', 'remove', 'fetchAll']),
            getSizeDescription(t) {
                console.log("size",t.size);
                return this.$store.getters['templates/getOptions'].find(o => o.value?.numerator === t.size?.numerator && o.value?.denominator === t.size?.denominator)?.description || "keine Angabe";
            },
            removeTemplate(id) {
                this.remove(id);
            }
        },
        created() {
            this.fetchAll({
                projectID: this.$router.currentRoute.params.projectID,
                channelID: this.$router.currentRoute.params.channelID
            });
        }
    }
</script>

<style scoped>
    .content {
        padding: 30px;
        margin: 30px;
    }
</style>
