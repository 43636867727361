<template>
    <div>


        <v-card style="max-height: 80vh; position: relative; overflow-y: scroll" elevation='0'>

            <v-text-field
                          v-model="search"
                          prepend-icon="search"
                          class="ml-5"
            ></v-text-field>

            <v-list subheader>
                <v-list-item-group v-if="contacts.length > 0">

                    <template v-for="(contact,index) in contacts">
    
                        <v-list-item
                            :key="contact._id"
                            router :to="{
                            name: 'ContactEdit',
                            params: {
                                contactID: contact._id,
                            }
                        }"
                        >
                            <v-list-item-avatar>
                                <v-icon>
                                    account_circle
                                </v-icon>
                            </v-list-item-avatar>
        
                            <v-list-item-content>
                                <v-list-item-title v-if='getDisplayName(contact)'>{{ getDisplayName(contact) }}</v-list-item-title>
                                <v-list-item-title v-else>
                                    <span class='blue--text'>Neuer Kontakt</span>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if='contact.organization && getDisplayName(contact) !== contact.organization'>
                                    {{ contact.organization }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
    
    
                        </v-list-item>
    
                        <v-divider inset :key='index' v-if='index < contacts.length-1'></v-divider>
                        
                    </template>

                </v-list-item-group>
                <div v-else>
                    <p class='mt-4 ml-7 grey--text'>Keine Kontakte gefunden.</p>
                </div>
            </v-list>

        </v-card>
        

    </div>
</template>

<script>

    import _ from "lodash";
    import Chat from "../utility/Chat";
    import axios from "axios";
    import UserService from "../../services/UserService";
    import contactUtils from '../mixins/contact-utils';


    export default {
        name: 'ContactList',
        components: {

        },
        mixins: [
            contactUtils,
        ],
        computed: {
            contacts: function() {
                return this.$store.getters['contacts/getAll']
                    .filter(c => c.projects?.includes(this.$router.currentRoute.params.projectID))
                    .filter(c => this.getDisplayName(c).toLowerCase().includes(this.search.toLowerCase()))
                    .sort((a, b) => this.getDisplayName(a)?.localeCompare(this.getDisplayName(b))) || [];
            }
        },
        methods: {},
        data() {
            return {
                search: "",
            }
        },
        created() {
            this.$store.dispatch('contacts/fetchByProject');
        }
    }
</script>
