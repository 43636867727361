<template>
    <div class="onlimchatbot">
        <h1>Onlim Chatbot Interface</h1>
        <p>für Channel <strong v-if="currentChannel.labels !== undefined">{{ currentChannel.labels['de-DE'] }}</strong> <span class="info--text">[{{currentChannel.id}}]</span></p>
        <p>Hier können Einstellungen und Informationen zu Übertragung der Daten an den Chatbot eingesehen werden.</p>
        <v-row>
            <v-col v-for="(menuitem, index) in menu" :index="index" :item="menuitem" :key="index" sm12 md6 lg4 xl3>
                <v-card outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4"></div>
                            <v-list-item-title class="headline mb-1">
                                {{ menuitem.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ menuitem.description }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon size="64">{{ menuitem.icon }}</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                    <v-card-actions>
                        <v-btn text rounded router :to="`${menuitem.path}`" append>
                            bearbeiten
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- Chat /-->

    </div>
</template>

<script>

    import { mapGetters, mapActions } from "vuex";
    import Chat from "../../components/utility/Chat";

    export default {
        name: 'PrintSuite',
        components: {
            // Chat
        },
        data() {
            return {
                menu: [
                    {
                        title: "Datenbestand",
                        description: "",
                        path: "sync",
                        icon: "cached",
                    },
                    {
                        title: "Ereignis-Protokoll",
                        description: "",
                        path: "logs",
                        icon: "grading",
                    },
                    {
                        title: "Einstellungen",
                        path: "settings",
                        icon: "settings",
                    }
                ]
            }
        },
        computed: {
            ...mapGetters(['currentChannel']),
        },
        methods: {

        },
        created() {

        }
    }
</script>
