<template>
    <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn fab fixed right bottom color="green" dark v-bind="attrs" v-on="on">
                <v-icon>add</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Neue Produktion anlegen</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Name *" required v-model="catalog.name"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <small>* Pflichtfelder</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text v-on:click="dialog = false">Abbrechen</v-btn>
                <v-btn color="success" depressed v-on:click="addCatalog(catalog)">Erstellen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>

    export default {
        name: "CatalogCreate",
        data() {
            return {
                dialog: false,
                catalog: {},
            }
        },
        methods: {
            open() {
                this.dialog = false;
            },
            async addCatalog(catalog) {
                await this.$store.dispatch('addCatalog', {
                    channelID: this.$router.currentRoute.params.channelID,
                    projectID: this.$router.currentRoute.params.projectID,
                    ...catalog
                });
                this.catalog = {};
                this.dialog = false;
            },
        }
    }

</script>

<style scoped>

</style>
