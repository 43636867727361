<template>
    <div>

        <v-navigation-drawer fixed color="red" width="400">
            <p class="white--text caption mt-5 text-uppercase ml-4 mb-0">Katalog</p>
            <p class="text-h5 white--text ml-4">{{ catalog.name }}</p>


            <v-sheet class="mx-5 pa-5" max-height="calc(100% - 400px)" style="overflow-y: scroll">
                <draggable v-model="entries"
                           v-bind="dragOptions"
                           @start="drag = true"
                           @end="drag = false"
                           :group="{ name: 'entries', pull: 'clone', put: false }"
                           :clone="cloneEntry"
                           @change="log"
                >
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <div v-for="entry in entries" class="py-2 px-2" :key="entry._id">
                            <p class="ma-0">
                                <strong>{{ (entry.content.name.find((l) => l.lang === 'de-DE') || {}).value }}</strong>
                            </p>
                            <p class="ma-0" v-if="pages.filter(p => p.content.findIndex(row => row.id === entry._id.toString()) !== -1).length !== 0">
                                <v-icon small class="mr-2" color="green">check</v-icon>
                                <small>bereits Im Katalog</small>
                            </p>
                        </div>
                    </transition-group>
                </draggable>
            </v-sheet>

        </v-navigation-drawer>

        <div style="height: 100%; width: calc(100% - 400px); margin-left: 400px">

            <v-row class="pt-5 px-5" style="padding-bottom: 300px">

                <!-- pages -->
                <template v-for="(page, index) in pages">
                    <v-sheet v-if="index === 0" class="my-5 ml-5" elevation="0" :height="pageHeight" :width="pageWidth" :key="index + '__page_zero'">
                    </v-sheet>
                    <div :key="index">
                        <v-sheet elevation="5" :class="'mt-5 pa-1 ' + (index % 2 !== 0 ? 'ml-5' : '')" :height="pageHeight" :width="pageWidth">
                            <draggable v-model="pages[index].content"
                                       :emptyInsertThreshold="dragThreshold"
                                       v-bind="dragOptions"
                                       @start="drag = true"
                                       @end="drag = false"
                                       :group="'entries'"
                                       @change="log"
                                       class="dragArea mx-auto"
                                       :style="pages[index].content.length === 0 ? 'border: 1px black dotted; height: 0px; width: 0px; margin-top: ' + dragThreshold + 'px;' : ''"
                            >
                                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                    <div v-for="entry in pages[index].content" :key="entry.id">
                                        <v-img :src="getPreviewURL"
                                               @contextmenu="showContext($event, index)"
                                        ></v-img>
                                        <v-menu
                                            v-model="show[index]"
                                            :position-x="x"
                                            :position-y="y"
                                            absolute
                                            offset-y
                                        >
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-title>Delete</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </transition-group>
                            </draggable>
                        </v-sheet>
                        <p class="my-2 text-center">
                            - {{ index + 1 }} -
                        </p>
                    </div>

                </template>

            </v-row>

            <div style="position: fixed; right: 10px; bottom: 20px; width: 200px">
                <v-row>
                    <v-icon class="mr-2 my-auto">
                        zoom_in
                    </v-icon>
                    <v-slider
                        hide-details
                        class="my-auto"
                        min="0.5"
                        max="3"
                        v-model="zoomModifier"
                        step="0.05"
                        thumb-label
                    ></v-slider>
                </v-row>
            </div>

        </div>

    </div>
</template>

<script>

    import Vue from 'vue';
    import axios from 'axios';
    import LoadingSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
    import draggable from 'vuedraggable';

    export default {
        name: 'CatalogPlannerEditor',
        props: [
            "catalogId"
        ],
        components: {
            draggable
        },
        data() {
            return {
                panOptions: {
                    minZoom: 0.5,
                    maxZoom: 1.5,
                    initialX: 300,
                    initialY: 500,
                    initialZoom: 0.5
                },
                show: {},
                sort: [
                    {
                        _id: "1",
                        name: "lame"
                    }
                ],
                drag: false,
                loading: false,
                failed: false,
                dialog: false,
                baseHeight: 300,
                zoomModifier: 1,
                pageAspectRatio: Math.sqrt(2),
            }
        },
        computed: {
            dragThreshold: function () {
                return this.pageHeight/3;
            },
            getPreviewURL: function() {
                return "https://cc.we2p.de/demo_pub_planner/spool/705318.jpeg"
            },
            pageHeight: function() {
                return this.baseHeight * this.zoomModifier;
            },
            pageWidth: function() {
                return (this.baseHeight * this.zoomModifier) / this.pageAspectRatio;
            },
            pages: function() {
                return [
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    },
                    {
                        content: [],
                    }
                ];
            },
            catalog: function() {
                return this.$store.getters['currentCatalog'] || {};
            },
            entries: function () {
                return this.$store.getters['entries/allEntries'].map((e) => {
                    return {
                        ...e,
                    }
                });
            },
            dragOptions() {
                return {
                    animation: 0,
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            onInit(panzoomInstance, id) {
                panzoomInstance.setTransformOrigin(null);
            },
            cloneEntry({ _id }) {
                return {
                    id: _id.toString(),
                }
            },
            log: function(evt) {
                window.console.log(evt);
            },
            showContext(e, index) {
                e.preventDefault()
                this.showMenu = false
                this.x = e.clientX
                this.y = e.clientY
                this.$nextTick(() => {
                    Vue.set(this.show, index, true);
                })
            },
        },
        created() {
            this.$store.dispatch('fetchCurrentCatalog', this.catalogId);
            this.$store.dispatch('entries/fetchEntriesByCatalog', this.catalogId);
        },
    }
</script>

<style scoped>

.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    width: 300px;
    height: 300px;
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

</style>
