<template>
  <div>
    <LabelComponent :definition="definition" :is-locked="isLocked"/>
    <div v-if="isRTE" :style="true ? 'filter:invert(0.9)' : ''">
      <ckeditor
          :editor="editor"
          v-model="processedValue"
          :config="editorConfig"
          @input="change($event)"
      ></ckeditor>
      <v-row class="mt-0">
        <v-spacer></v-spacer>
        <country-flag style="margin-top: -6px; filter:invert(1.1)" :country="flag" size="normal"></country-flag>
      </v-row>
    </div>
    <v-textarea
        v-else-if="isTextarea"
        dense filled
        :value="processedValue"
        @input="change($event)"
        hide-details="auto"
    >
      <template slot="append-outer">
        <country-flag style="margin-top: -6px" :country="flag" size="normal"></country-flag>
      </template>
    </v-textarea>
    <v-text-field
        v-else
        dense filled
        :value="processedValue"
        @input="change($event)"
        hide-details="auto"
    >
      <template slot="append">
        <country-flag style="margin-top: -10px" :country="flag" size="normal"></country-flag>
      </template>
    </v-text-field>
  </div>
</template>

<script>

import _ from 'lodash';
import TranslationService from "../../../services/TranslationService";
import LabelComponent from "./style-components/CockpitLabelComponent";

import FormComponentMixin from "../../mixins/form-component"
import CockpitFormComponentMixin from "../../mixins/cockpit-form-component"
import FormRuleProvider from "@/services/form-rule-provider";

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "CockpitTextComponent",
  components: {
    LabelComponent
  },
  props: [
    'value',
    'definition',
    'isLocked'
  ],
  mixins: [
    CockpitFormComponentMixin
  ],
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'italic', '|', 'link']
      },
    }
  },
  computed: {
    flag: function () {
      // TODO: change to new one
      return this.$store.getters['general/language'];
    },
    isRTE: function () {
      return !!this.cpDefinition.rich_text;
    },
    // check if the text should be handled as multi line text to mimic cockpit handling
    isTextarea: function () {
      // return this.cpDefinition.name.includes('description') || this.cpDefinition.name.includes('Description');
      // cockpit doesnt display textarea anymore
      return false;
    },
    processedValue: {
      get: function () {
        return TranslationService.getValue(this.value, this.$store.getters['general/language']);
      },
      set: function (value) {
        // silence is golden
      }
    }
  },
  methods: {
    change(event) {
      this.$emit('input', TranslationService.setValue(this.value, event, this.$store.getters['general/language']))
    },
    async save() {
      return this.value;
    }
  }
}
</script>

<style scoped>
.ck.ck-editor {
  color: #000 !important;
}
</style>
