import axios from 'axios';
import router from "../../router";
import Vue from 'vue';

const store = {

    state: {
        status: '',
        cronjobs: [],
        running: {},
    },

    getters: {
        getStatus: (state) => state.status,
        getAll: (state) => state.cronjobs,
        getRunning: (state) => state.running
    },

    actions: {
        async fetchAll({ commit }) {
            commit('start_loading');
            await axios.get(`/api/cronjobs`).then((response) => {
                commit('end_loading');
                commit('set_data', response.data?.data);
            });
        },
        async run({ commit }, { id }) {
            commit('start_running', id);
            await axios.post(`/api/cronjobs/execute/${ id }`).then((response) => {

            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                commit('end_running', id);
            });
        },
    },
    mutations: {
        set_data(state, cronjobs) {
            state.cronjobs = cronjobs || [];
        },
        start_loading(state) {
            state.status = "loading";
        },
        end_loading(state) {
            state.status = "success";
        },
        start_running(state, id) {
            Vue.set(state.running, id, true);
        },
        end_running(state, id) {
            state.running[id] = false;
        }
    }

}


export default {
    ...store,
    namespaced: true
}
