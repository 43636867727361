<template>
    <div class="entrytypes">
        <EntryTypeList/>
        <EntryTypeCreate/>
    </div>
</template>

<script>

    import EntryTypeList from '../../components/entrytypes/EntryTypeList';
    import EntryTypeCreate from "../../components/entrytypes/EntryTypeCreate";

    export default {
        name: 'EntryTypes',
        components: {
            EntryTypeCreate,
            EntryTypeList
        }
    }
</script>
