import _ from "lodash";

const mixin = {

    computed: {
        /**
         * load the cockpit configuration for this field
         */
        cpDefinition: function() {
            return this.$store.getters['properties/getAll']?.find(p => p.name === this.definition.linkName) || {};
        }
    },

}

export default mixin;
