<template>

    <div>
        <v-menu
            v-model="menu"
            offset-y
            nudge-bottom="10"
            :close-on-content-click="false"
            transition="slide-y-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="white"
                       depressed
                       fab x-small class="mr-5"
                       v-bind="attrs"
                       v-on="on"
                >
                    <v-badge color="error"
                             :value="unreadNotifications.length"
                             :content="unreadNotifications.length"
                    >
                        <v-icon color="grey darken-4" size="24">
                            {{ unreadNotifications.length > 0 ? "notifications_active" : "notifications" }}
                        </v-icon>
                    </v-badge>
                </v-btn>
            </template>

            <v-card min-width="380">

                <v-progress-linear indeterminate
                                   v-if="$store.getters['notifications/getAll'] === 'loading'"
                ></v-progress-linear>
                <v-list max-width="700" three-line :key="key">
                    <template v-if="$store.getters['notifications/getAll'] !== 'loading'">
                        <template v-for="(item, index) in notifications">
                            <v-list-item :key="item._id.toString()">
                                <v-list-item-avatar color="success" v-if="item.type === 'success'">
                                    <v-avatar>
                                        <v-icon color="white">check</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-avatar color="error" v-else-if="item.type === 'error'">
                                    <v-avatar>
                                        <v-icon color="white">close</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-avatar color="info" v-else>
                                    <v-avatar>
                                        <v-icon color="white">info</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.title }}
                                        <v-badge color="info" class="ml-2 mb-2" dot :value="!item.read"></v-badge>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="my-1">
                                        <strong>{{ timeAgo.format(new Date(item.createdAt), 'round') }}</strong>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ item.message }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn depressed color="error" icon
                                           @click="$store.dispatch('notifications/remove', { id: item._id.toString() })"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider
                                v-if="index < notifications.length - 1"
                                :key="index"
                            ></v-divider>
                        </template>
                    </template>
                </v-list>

                <div v-if="notifications.length === 0" class="text-center py-5">
                    <v-icon size="48" class="py-5" color="success">done_all</v-icon>
                    <p class="py-5">Keine Benachrichtigungen.</p>
                </div>

            </v-card>
        </v-menu>
    </div>

</template>

<script>

import TimeAgo from 'javascript-time-ago';
import de from 'javascript-time-ago/locale/de.json';
TimeAgo.addLocale(de);


export default {
    name: "Notifications",
    data() {
        return {
            menu: false,
            key: 0,
            timeAgo: new TimeAgo('de-DE')
        }
    },
    computed: {
        notifications: function() {
            return this.$store.getters['notifications/getAll'] || [];
        },
        unreadNotifications: function () {
            return this.notifications.filter(n => !n.read) || [];
        }
    },
    watch: {
        menu:  {
            handler()  {
                this.unreadNotifications.map((n) => {
                    this.$store.dispatch("notifications/update", {
                        id: n._id.toString(),
                        payload: {
                            read: true
                        }
                    })
                })
            }
        }
    },
    created() {
        this.$store.dispatch('notifications/fetchAll');
        setInterval(() => { this.key++ }, 1000);
    }
}
</script>

<style scoped>

</style>
