import store from "../store";
import router from "../router";
import {hasOwnProperty} from "luxon/src/impl/util";

class PermissionGuard {

    static hasPermission(permission) {

        let permissions = store.getters['user/getPermissions'];

        // check if user is me
        if (process.env.NODE_ENV !== 'development' && permission === "DEV" && !['mk@venus-werbung.de', 'sp@venus-werbung.de'].includes(store.getters['auth/getUser']?.mail)) {
            return false;
        }

        // check if user is super-admin
        if (permissions?.superAdmin === true) return true;


        let keys = permission.split('.');
        let result = permissions;

        for (const i in keys) {
            const key = keys[i];
            if (result) {
                if (result[key]) {
                    result = result[key];
                } else if (result['*']) {
                    result = result['*'];
                } else {
                    result = null;
                }
            } else {
                break;
            }
        }

        return Boolean(result);

    }

    // shorthand for project/channel specific permission
    static chP(permission) {
        return this.hasPermission(`projects.${router.currentRoute.params.projectID}.channels.${router.currentRoute.params.channelID}.${permission}`)
    }

}

export default PermissionGuard;
