<template>
    <div class="my-5">
        <v-divider/>
    </div>
</template>

<script>

    import _ from 'lodash';
    import LabelComponent from "../LabelComponent";

    export default {
        name: "DividerComponent",
        components: {

        },
        props: [
            'definition',
            'content'
        ],
        data() {
            return {
                data: _.cloneDeep(this.content)
            }
        },
    }
</script>

<style scoped>

</style>