import axios from 'axios';
import router from "../../router";

const entrypoint = 'entrytypes';

const state = {
    data: [],
};

const getters = {
    getAll: (state) => state.data,
};

const actions = {
    async fetchAll({ commit }) {
        const response = await axios.get(`/api/${entrypoint}/?projectID=${ router.currentRoute.params.projectID }&channelID=${ router.currentRoute.params.channelID }`);
        commit('setAll', response.data);
    },
    async add({ commit, dispatch }, object) {
        const response = await axios.post(`/api/${entrypoint}/`, object);
        await dispatch('fetchAll', {projectID: object.projectID, channelID: object.channelID});
    },
    async update({ commit, dispatch, rootState }, object) {
        const response = await axios.put(`/api/${entrypoint}/${object._id}/`, object);
    },
    async remove({ commit, dispatch, rootState }, id) {
        const response = await axios.delete(`/api/${entrypoint}/${id}/`);
        await dispatch('fetchAll', {projectID: router.currentRoute.params.projectID, channelID: router.currentRoute.params.channelID});
    },
};

const mutations = {
    setAll: ((state, objects) => {
        state.data = objects;
    }),
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
