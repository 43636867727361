class BasicDataProvider {
    static getPictoFonts() {
        return [
            {
                value: 'tinpi',
                text: 'Tinpi',
            },
            {
                value: 'tinpi-inverted',
                text: 'Tinpi (invertiert)',
            },
            {
                value: 'fontawesome-light',
                text: 'Fontawesome (light)',
            },
            {
                value: 'fontawesome-regular',
                text: 'Fontawesome (regular)',
            },
            {
                value: 'fontawesome-thin',
                text: 'Fontawesome (thin)',
            },
            {
                value: 'fontawesome-solid',
                text: 'Fontawesome (solid)',
            },
            {
                value: 'fontawesome-brands',
                text: 'Fontawesome (brands)',
            },
            {
                value: 'artprint-pictos',
                text: 'Artprint Piktos',
            },
            {
                value: 'artprint-pictos-2',
                text: 'Artprint Piktos (2)',
            },
            {
                value: 'camping-pictos',
                text: 'Camping Piktos',
            },
            {
                value: 'ctz-pictos',
                text: 'CTZ Piktos',
            },
            {
                value: 'mobil-nrw-orange',
                text: 'Mobil NRW (orange)',
            },
            {
                value: 'mobil-nrw-anthrazit',
                text: 'Mobil NRW (anthrazit)',
            },
            {
                value: 'mobil-nrw-schwarz',
                text: 'Mobil NRW (schwarz)',
            },
            {
                value: 'trail-pictos-base',
                text: 'Tour Piktos (Grundlinie)',
            },
            {
                value: 'trail-pictos-full',
                text: 'Tour Piktos (Voll)',
            },
            {
                value: 'uadb-pictos',
                text: 'UadB Piktos',
            },
        ];
    }

    static getSalutations() {
        return [
            {
                text: 'Frau',
                value: 'frau',
            },
            {
                text: 'Herr',
                value: 'herr',
            },
            {
                text: 'Firma',
                value: 'firma',
            },
            {
                text: 'Familie',
                value: 'familie',
            },
        ];
    }

    static getAllLanguages(filter = []) {
        // http://www.lingoes.net/en/translator/langcode.htm
        const languages = [
            {
                value: 'de-DE',
                text: 'Deutsch',
            },
            {
                value: 'de-AT',
                text: 'Deutsch (Österreich)',
            },
            {
                value: 'de-CH',
                text: 'Deutsch (Schweiz)',
            },
            {
                value: 'en-EN',
                text: 'Englisch',
            },
            {
                value: 'en-US',
                text: 'Englisch (AE)',
            },
            {
                value: 'en-GB',
                text: 'Englisch (BE)',
            },
            {
                value: 'fr-FR',
                text: 'Französisch',
            },
            {
                value: 'cs-CZ',
                text: 'Tschechisch',
            },
            {
                value: 'pl-PL',
                text: 'Polnisch',
            },
            {
                value: 'it-IT',
                text: 'Italienisch',
            },
            {
                value: 'es-ES',
                text: 'Spanisch',
            },
            {
                value: 'nl-NL',
                text: 'Niederländisch',
            },
            {
                value: 'fi-FI',
                text: 'Finnisch',
            },
            {
                value: 'nb-NO',
                text: 'Norwegisch',
            },
            {
                value: 'sv-SE',
                text: 'Schwedisch',
            },
            {
                value: 'da-DK',
                text: 'Dänisch',
            },
            {
                value: 'hr-HR',
                text: 'Kroatisch',
            },
            {
                value: 'sl-SI',
                text: 'Slovenisch',
            },
            {
                value: 'pt-PT',
                text: 'Portugiesisch',
            },
        ];

        if (filter.length > 0) {
            return languages.filter((l) => filter.includes(l.value));
        } else {
            return languages;
        }
    }
}

export default BasicDataProvider;
