import { render, staticRenderFns } from "./InfoComponent.vue?vue&type=template&id=7bfe07c8&scoped=true"
import script from "./InfoComponent.vue?vue&type=script&lang=js"
export * from "./InfoComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bfe07c8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/print-suite/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7bfe07c8')) {
      api.createRecord('7bfe07c8', component.options)
    } else {
      api.reload('7bfe07c8', component.options)
    }
    module.hot.accept("./InfoComponent.vue?vue&type=template&id=7bfe07c8&scoped=true", function () {
      api.rerender('7bfe07c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/entries/formComponents/style-components/InfoComponent.vue"
export default component.exports