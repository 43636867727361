import TranslationService from './TranslationService';

class TableDataHelper {
    static convertToTableFormat(value, propName, definition, lang = 'de-DE') {
        let propDefinition;

        if (typeof definition !== 'undefined' && definition !== null) {
            propDefinition = definition.find((x) => x.name === propName);
        }

        if (typeof propDefinition !== 'undefined') {
            switch (propDefinition.type) {
                case 'table':
                    if (value.length > 1) {
                        return 'Tabelle - ' + value.length + ' Einträge';
                    } else if (value.length > 0) {
                        return 'Tabelle - 1 Eintrag';
                    }
                    return '-';
                case 'input':
                case 'textarea':
                    return TranslationService.getValue(value, lang);
                case 'enumeration':
                    return value
                        .map((v) => {
                            return (
                                propDefinition.config?.values?.find(
                                    (x) => x.value === v
                                )?.label?.[lang] || v
                            );
                        })
                        .join(', ');
                case 'address': {
                    let address = [
                        value?.city + ' ' + value?.zip,
                        value?.street,
                        value?.country,
                    ];
                    return address.filter(Boolean).join(', ');
                }
                default:
                    return value;
            }
        }

        // return value if no other transformation applies (important for "index")
        return value;
    }
}

export default TableDataHelper;
