<template>
    <div class="projects">
        <!--div class="my-5" v-if="project"-->
        <p class="text-h5">Projekte</p>

        <v-row>

            <v-col style="max-width: 450px">
                <ProjectList />
            </v-col>

            <v-col style="max-height: 80vh;" v-if="$router.currentRoute.params.projectID">
                <v-card class="overflow-y-auto pa-5" style="max-height: 80vh;">
                    <router-view :key="$router.currentRoute.params.projectID"></router-view>
                </v-card>
            </v-col>

        </v-row>

        <!--v-text-field v-model="project.name" class="ml-5" label="Name" style="max-width: 800px;" filled/-->



        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab v-on="on" v-bind="attrs" fixed bottom right color="success">
                    <v-icon>
                        add
                    </v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-toolbar elevation="0">
                    <v-toolbar-title>Neues Projekt</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab depressed text color="error" @click="dialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-form ref="createForm"
                            v-model="valid"
                    >
                        <v-text-field :rules="nameRules" v-model="projectName" label="Name" :hint="pI"></v-text-field>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        text
                        :loading="loading"
                        @click="create"
                    >
                        Erstellen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import ProjectList from "../../../components/users/ProjectList";

export default {
    name: 'Projects',
    components: {
        ProjectList,
    },
    data() {
        return {
            loading: false,
            valid: false,
            dialog: false,
            pI: "",
            users: [],
            projectName: "",
            search: "",
            nameRules: [
                v => !!v || 'Name verpflichtend!',
                v => this.$store.getters['projects/getAll'].findIndex((project) => this.getIdentifier(v) === project.identifier) === -1 || "Projekt existiert bereits!",
            ],
            headers: [
                { text: 'Aktionen', value: 'controls', sortable: false, width: "100" },
                { text: 'Name', value: 'name'},
                { text: 'Login-Name', value: 'login'},
                { text: 'Firma', value: 'firmation'},
            ]
        }
    },
    computed: {

    },
    methods: {
        create() {
            this.$refs.createForm.validate();
            if (!this.valid) {
                return;
            }
            this.loading = true;
            this.$store.dispatch('projects/add', { payload: { name: this.projectName, disabled: false } }).then((res) => {
                this.dialog = false;
                this.projectName = "";
                this.$router.push({
                    name: 'AdminProjectEdit',
                    params: {
                        projectID: res._id.toString(),
                    }
                })
            }).finally(() => {
                this.loading = false;
            })
        },
        getIdentifier(name) {
            const whiteSpaceRegEx = /\s+/g;
            const identRegEx = /[^a-z-]/g;

            this.pI = name
                .toLowerCase()
                .replaceAll('ä', 'ae')
                .replaceAll('ö', 'oe')
                .replaceAll('ü', 'ue')
                .replaceAll('ß', 'ss')
                .trim()
                .replaceAll(whiteSpaceRegEx, ' ')
                .replaceAll(' ', '-')
                .replaceAll(identRegEx, '')
            return this.pI;
        }
    },
    created() {

    }
}
</script>
