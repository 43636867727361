<template>

    <v-dialog v-model="dialog" persistent max-width="800px">

        <template v-slot:activator="{ on, attrs }">
            <v-btn fab fixed right bottom color="green" dark v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>

                <v-card-title>
                    <span class="headline mt-5">Neues Template</span>
                </v-card-title>
                <v-card-text>

                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Name" :rules="rules" required v-model="template.name"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="ID" :rules="rules" required v-model="template.id"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field type="number" label="Anzahl Bilder" v-model="template.imageCount"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field style='max-width: 150px;' append-outer-icon='euro' type="number" label="Anzeigenpreis" v-model="template.price"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="dialog = false">Abbrechen</v-btn>
                    <v-btn color="success" depressed @click="submit" :disabled="!valid">Erstellen</v-btn>
                </v-card-actions>

            </v-card>
        </v-form>

    </v-dialog>

</template>

<script>

    import {mapActions} from "vuex";

    export default {
        name: "TemplateCreate",
        data() {
            return {
                valid: true,
                dialog: false,
                template: this.initialTemplate(),
                rules: [
                    value => (!!value) || 'Erforderliches Feld.'
                ]
            }
        },
        methods: {
            initialTemplate: () => {
                return {
                    imageCount: 0
                }
            },
            ...mapActions("templates", ['add']),
            async submit() {
                await this.validate();
                if(this.valid) {
                    this.template = {
                        ...this.template,
                        projectID: this.$router.currentRoute.params.projectID,
                        channelID: this.$router.currentRoute.params.channelID
                    }
                    console.log(this.template);
                    await this.add(this.template);
                    this.template = this.initialTemplate();
                    this.dialog = false;
                }
            },
            validate () {
                this.$refs.form.validate()
            },
        }
    }

</script>

<style scoped>

</style>
