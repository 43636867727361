<template>
    <v-dialog
            v-model="dialog"
            persistent
            scrollable
            max-width="800px"
    >

        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs">
                <v-btn class="mr-3" fab color="success" depressed x-small router append v-on="on" v-bind="attrs">
                    <v-icon color="white">sync</v-icon>
                </v-btn>
            </slot>
        </template>

        <v-card>
            <v-card-title class="pt-5">
                <span class="headline">
                    Status Migration
                    <v-icon class="ml-2">
                        nat
                    </v-icon>
                </span>
            </v-card-title>

            <v-card-text class="mt-5">
                <p>Ändert den Status aller Einträge in dieser Auflage nach den unten definierten Regeln:</p>
                <v-row v-for="(rule, index) in rules" :key="index">
                    <v-col>
                        <v-select v-model="rule.from"
                                  class="my-0 py-0"
                                  :items="statusList"
                                  item-value="value"
                                  item-text="label"
                        ></v-select>
                    </v-col>
                    <v-col cols="1" class="text-center">
                        <v-icon>east</v-icon>
                    </v-col>
                    <v-col>
                        <v-select v-model="rule.to"
                                  class="my-0 py-0"
                                  :items="targetStatusList"
                                  item-value="value"
                                  item-text="label"
                        ></v-select>
                    </v-col>
                    <v-btn v-if="index !== 0" color="error" fab x-small depressed class="my-3" @click="removeRow(index)">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn fab depressed small color="success" @click="addRow">
                        <v-icon>add</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card-text>

            <v-card-actions class="pb-4">
                <v-btn color="error" text @click="dialog = false">
                    Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="convert">
                    Konvertieren
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import ProposalSpinner from 'epic-spinners/src/components/lib/TrinityRingsSpinner';
    import StrategySpinner from 'epic-spinners/src/components/lib/SpringSpinner';
    import axios from "axios";
    import { DateTime } from 'luxon';
    import _ from 'lodash';
    import TranslationService from "../../services/TranslationService";
    import EntryStatusService from "@/services/EntryStatusService";

    export default {
        name: "StatusRuleBuilder",
        components: {

        },
        props: [],
        data() {
            return {
                dialog: false,
                rules: _.cloneDeep(this.initialRules),
                initialRules: [
                    {
                        from: "new",
                        to: "acquisition_sent"
                    }
                ]
            }
        },
        computed: {
            statusList: function() {
                return EntryStatusService.getStatusArray();
            },
            targetStatusList: function() {
                return this.statusList.filter(status => !status.lock);
            }
        },
        watch: {
            dialog: function (newVal, oldVal){
                if (newVal) {
                    this.rules = _.cloneDeep(this.initialRules);
                }
            }
        },
        methods: {
            addRow() {
                this.rules.push({})
            },
            removeRow(index) {
                this.rules.splice(index, 1)
            },
            convert() {
                this.$emit('convert', { rules: this.rules });
                this.dialog = false;
            }
        },
    }

</script>

<style scoped>

</style>
