import axios from 'axios';

const entrypoint = 'entities';

const store = {

    state: {
        status: '',
        data: [],
        current: '',
        loading: [],
    },

    getters: {
        getAll: (state) => state.data,
        getStatus: (state) => state.status,
        getLoading: (state) => state.loading,
    },

    actions: {
        async fetch({ commit, getters }, { id, flush }) {

            return new Promise((resolve, reject) => {

                commit('request_sent', id);

                // check if already cached
                if ( !getters['getAll'].find(e => e._id.toString() === id) || flush) {

                    axios.get(`/api/${entrypoint}/${id}`).then((res) => {
                        commit('request_success', res.data);
                        resolve();
                    }).catch((e) => {
                        console.log(e);
                        commit('request_error', id);
                        reject(e);
                    });

                }

            });

        },
        async update({ commit, getters }, { id, entity, options }) {

            return new Promise((resolve, reject) => {

                axios.put(`/api/${ entrypoint }/${ id }`, {
                    entity,
                    options
                }, {
                    timeout: 30 * 1000,
                }).then((res) => {
                    resolve(res);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                });

            });

        },
        async create({ commit, getters }, { entity, options }) {

            return new Promise((resolve, reject) => {

                axios.post(`/api/${ entrypoint }`, {
                    entity,
                    options
                }, {
                    timeout: 30 * 1000,
                }).then((res) => {
                    resolve(res.data.data.entity);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                });

            });

        },
    },

    mutations: {
        request_sent(state, id) {
            state.status = 'loading';
            state.loading.push(id);
        },
        request_success(state, data) {

            const oldIndex = state.data.findIndex(e => e._id.toString() === data._id.toString());
            if (oldIndex !== -1) {
                state.data.splice(oldIndex, 1, data);
            } else {
                state.data.push(data);
            }

            state.status = 'success';

            const index = state.loading.indexOf(data._id.toString());
            if (index > -1) {
                state.loading.splice(index, 1);
            }

        },
        request_error(state, id){
            state.status = 'error';
            const index = state.loading.indexOf(id);
            if (index > -1) {
                state.loading.splice(index, 1);
            }
        },
    }

}


export default {
    ...store,
    namespaced: true
}
