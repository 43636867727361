<template>

    <div class="my-5" v-if="project">

        <v-row class="mx-2 mb-5">
            <v-spacer></v-spacer>
            <v-btn class="mr-3" color="success" depressed fab small @click="save" :loading="$store.getters['projects/getStatus'] === 'loading'">
                <v-icon>
                    save
                </v-icon>
            </v-btn>
            <DeleteConfirmation  @yes="remove">
            </DeleteConfirmation>
        </v-row>


      <v-text-field v-model="project.name" class="ml-5" label="Name" style="max-width: 800px;" filled/>

    </div>


</template>

<script>
import _ from "lodash";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";

export default {
    name: "ProjectEdit",
    components: {
        DeleteConfirmation
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        projectID: function() {
            return this.$router.currentRoute.params.projectID;
        },
        project: {
            get() {
                return this.$store.getters['projects/getAll'].find(r => r._id.toString() === this.projectID);
            },
            set() {

            }
        }
    },
    watch: {
        project: {
            handler(val, oldVal) {
              console.log(oldVal,val)
              //  if (oldVal && val) this.save();
            },
            deep: true,
        }
    },
    methods: {
        clone() {
            this.$store.dispatch('projects/add', {
                project: {
                    ...this.project,
                    name: this.project.name + " - Kopie",
                    _id: undefined
                }
            }).then(() => {
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "Rolle kopiert." });
            });
        },
        remove() {
            this.loading = true;
            this.$store.dispatch('projects/remove', { id: this.projectID }).then(() => {
                this.loading = false;
                this.$router.push({
                    name: "AdminProjects"
                })
            });
        },
        save: _.debounce(function() {
            this.$store.dispatch('projects/update', { id: this.projectID, payload: this.project }).then(() => {
                this.loading = false;
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "gespeichert" });
            })
        }, 100)
    }
}
</script>

<style scoped>

</style>
