import axios from 'axios';
import router from "../../router";

const store = {

    state: {
        appVersion: process.env.VERSION || '1.0.0',
        appEnvironment: process.env.NODE_ENV || 'unknown',
        language: 'de-DE'
    },

    getters: {
        appVersion: state => state.appVersion,
        appEnvironment: state => state.appEnvironment,
        language: state => state.language,
    },
    actions: {
        setLanguage({ commit }, lang) {
            commit('set_language', lang)
        }
    },
    mutations: {
        set_language(state, lang){
            state.language = lang;
        },
    }

}

export default {
    ...store,
    namespaced: true
}
