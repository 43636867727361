<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked" />
        <v-row>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            dense
                            filled
                            :readonly="isLocked"
                            label="Straße und Hausnummer"
                            :value="data.street"
                            @input="data = { ...data, street: $event }"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4">
                        <v-text-field
                            dense
                            filled
                            :readonly="isLocked"
                            label="PLZ"
                            :value="data.zip"
                            @input="data = { ...data, zip: $event }"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4">
                        <v-text-field
                            dense
                            filled
                            :readonly="isLocked"
                            label="Ort"
                            :value="data.city"
                            @input="data = { ...data, city: $event }"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4">
                        <v-text-field
                            dense
                            filled
                            :readonly="isLocked"
                            label="Ortsteil"
                            :value="data.urbanDistrict"
                            @input="data = { ...data, urbanDistrict: $event }"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            dense
                            filled
                            :readonly="isLocked"
                            label="Land"
                            :value="data.country"
                            @input="data = { ...data, country: $event }"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="my-0">
                            <v-spacer />
                            <v-btn
                                :loading="loadingCoords"
                                @click="generateCoords"
                                depressed
                                class="mx-auto"
                                color="grey"
                                rounded
                            >
                                <v-icon class="mr-2">sync</v-icon>
                                Koordinaten erzeugen
                            </v-btn>
                            <v-spacer />
                        </v-row>
                    </v-col>
                    <!-- LAT -->
                    <v-col cols="12" sm="6" v-if="isShowMap">
                        <v-text-field
                            dense
                            filled
                            :readonly="isLocked"
                            label="Latitude"
                            :value="data.latitude"
                            @input="data = { ...data, latitude: $event }"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <!-- LNG -->
                    <v-col cols="12" sm="6" v-if="isShowMap">
                        <v-text-field
                            dense
                            filled
                            :readonly="isLocked"
                            label="Longitude"
                            :value="data.longitude"
                            @input="data = { ...data, longitude: $event }"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" v-if="isShowMap">
                <!-- MAP -->
                <div
                    :id="mapId"
                    v-if="mapId"
                    style="width: 100%; height: 100%"
                ></div>
            </v-col>
        </v-row>

        <v-btn
            rounded
            :disabled="isLocked"
            depressed
            color="success"
            class="mt-3"
            @click="checkAddress"
            :loading="loadingValidation"
        >
            <v-icon class="mr-2" small> beenhere </v-icon>
            Adresse validieren
        </v-btn>

        <!-- ADDRESS CORRECT -->
        <v-snackbar v-model="snackbarCorrect" :timeout="3000">
            Adresse plausibel!
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="success"
                    text
                    v-bind="attrs"
                    @click="snackbarCorrect = false"
                    >OK</v-btn
                >
            </template>
        </v-snackbar>

        <!-- ADDRESS NOT FOUND -->
        <v-snackbar v-model="snackbarNotFound" :timeout="3000">
            Keine Adresse zum Vergleich gefunden!
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="error"
                    text
                    v-bind="attrs"
                    @click="snackbarNotFound = false"
                    >OK</v-btn
                >
            </template>
        </v-snackbar>

        <!-- ADDRESS VALIDATION -->
        <v-dialog v-model="dialog" persistent max-width="500">
            <v-card>
                <v-card-title class="headline">
                    Adresse korrigieren?
                </v-card-title>
                <v-card-text>
                    <p class="text-h6 font-weight-bold mb-2">Ihre Eingabe:</p>
                    <p class="my-0">{{ data.street || 'STRASSE???' }}</p>
                    <p class="my-0">
                        {{ data.zip || 'PLZ???' }} {{ data.city || 'ORT???' }}
                    </p>
                    <p class="my-0">{{ data.country || 'LAND???' }}</p>
                    <p class="mt-4 text-h6 font-weight-bold mb-2">
                        Gefundene Adresse:
                    </p>
                    <p class="my-0">
                        <span
                            :class="
                                alternativeAddress.street ? 'warning--text' : ''
                            "
                            >{{
                                alternativeAddress.street ||
                                data.street ||
                                'STRASSE???'
                            }}</span
                        >
                    </p>
                    <p class="my-0">
                        <span
                            :class="
                                alternativeAddress.zip ? 'warning--text' : ''
                            "
                            >{{
                                alternativeAddress.zip || data.zip || 'PLZ???'
                            }}</span
                        >&nbsp;
                        <span
                            :class="
                                alternativeAddress.city ? 'warning--text' : ''
                            "
                            >{{
                                alternativeAddress.city || data.city || 'ORT???'
                            }}</span
                        >
                    </p>
                    <p class="my-0">
                        <span
                            :class="
                                alternativeAddress.country
                                    ? 'warning--text'
                                    : ''
                            "
                            >{{
                                alternativeAddress.country ||
                                data.country ||
                                'LAND???'
                            }}</span
                        >
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" text @click="dialog = false">
                        Nein
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="useAlternativeAddress">
                        Ja
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import TranslationService from '../../../services/TranslationService';
import LabelComponent from './LabelComponent';

import { CoreMap, Marker } from '@venus-gmbh/map-core';
import FormComponentMixin from '../../mixins/form-component';

export default {
    name: 'AddressComponent',
    components: {
        LabelComponent,
    },
    props: ['value', 'definition', 'isLocked'],
    mixins: [],
    data() {
        return {
            map: null,
            mapId: '',
            marker: null,
            alternativeAddress: {},
            snackbarNotFound: false,
            snackbarCorrect: false,
            dialog: false,
            location: {},
            loadingValidation: false,
            loadingCoords: false,
        };
    },
    created() {
        this.mapId = 'map-' + _.uniqueId();
    },
    mounted() {
        this.map = new CoreMap({
            container: this.mapId,
            appearance: {
                primary: '#ff0000',
                secondary: '#0000ff',
            },
            style: 'https://tiles.venus.bayern/styles/venus-basic/style.json',
            center: [12.687807338799326, 48.91628287280801], // starting position [lng, lat]
            zoom: 3, // starting zoom //8
        });

        if (this.data.latitude && this.data.longitude) {
            this.setMarker();
        }
    },
    computed: {
        data: {
            get() {
                return this.value || {};
            },
            set(data) {
                this.$emit('input', data);
            },
        },
        isShowMap() {
            return Boolean(process.env.VUE_APP_SHOW_ADDRESS_MAP === 'y');
        },
    },
    methods: {
        setMarker() {
            if (!this.marker) {
                this.marker = new Marker({
                    lngLat: {
                        lng: this.data.longitude,
                        lat: this.data.latitude,
                    },
                    color: '#a10025',
                    draggable: true,
                }).addTo(this.map.getMapLibreInstance());
                this.map.getMapLibreInstance().flyTo({
                    center: [this.data.longitude, this.data.latitude],
                    zoom: 18,
                    duration: 1200,
                });
                this.marker.on('dragend', (e) => {
                    this.data = {
                        ...this.data,
                        latitude: this.marker.getLngLat().lat,
                        longitude: this.marker.getLngLat().lng,
                    };
                });
            }
        },
        removeMarker() {
            if (this.marker) {
                this.marker.remove();
                this.marker = null;
            }
        },
        positionChanged(event) {
            this.data = {
                ...this.data,
                latitude: event.latlng.lat,
                longitude: event.latlng.lng,
            };
            this.location = event.location;
        },
        async save() {
            return _.cloneDeep(this.data);
        },
        useAlternativeAddress() {
            this.data = _.defaultsDeep(this.alternativeAddress, this.data);
            this.alternativeAddress = {};
            this.dialog = false;
        },
        async generateCoords() {
            this.loadingCoords = true;
            this.alternativeAddress = {};

            await axios
                .get(
                    'https://nominatim.vns.services/search.php' +
                        '?street=' +
                        (this.data.street || '') +
                        '&city=' +
                        (this.data.city || '') +
                        '&country=' +
                        (this.data.country || '') +
                        '&postalcode=' +
                        (this.data.zip || '') +
                        '&polygon_geojson=1' +
                        '&format=json' +
                        '&addressdetails=1'
                )
                .then((res) => {
                    let nominatimAddresses = res.data;

                    if (nominatimAddresses.length === 0) {
                        this.snackbarNotFound = true;
                    } else {
                        let address = nominatimAddresses[0];
                        this.data = {
                            ...this.data,
                            latitude: address.lat,
                            longitude: address.lon,
                        };
                    }
                })
                .finally(() => {
                    this.loadingCoords = false;
                });
        },
        async checkAddress() {
            this.loadingValidation = true;
            this.alternativeAddress = {};

            await axios
                .get(
                    'https://nominatim.openstreetmap.org/search.php' +
                        '?street=' +
                        (this.data.street || '') +
                        '&city=' +
                        (this.data.city || '') +
                        '&country=' +
                        (this.data.country || '') +
                        '&postalcode=' +
                        (this.data.zip || '') +
                        '&polygon_geojson=1' +
                        '&format=json' +
                        '&addressdetails=1'
                )
                .then((res) => {
                    let nominatimAddresses = res.data;

                    if (nominatimAddresses.length === 0) {
                        this.snackbarNotFound = true;
                    } else {
                        let newAddress = nominatimAddresses[0];

                        if (
                            (newAddress.address.road ||
                                newAddress.address.village) +
                                ' ' +
                                (newAddress.address.house_number ||
                                    this.data.street.substr(
                                        this.data.street.lastIndexOf(' ') + 1,
                                        this.data.street.length
                                    )) !==
                            this.data.street
                        ) {
                            this.alternativeAddress.street =
                                newAddress.address.road +
                                ' ' +
                                (newAddress.address.house_number ||
                                    this.data.street.substr(
                                        this.data.street.lastIndexOf(' ') + 1,
                                        this.data.street.length
                                    ));
                        }

                        if (newAddress.address.postcode !== this.data.zip) {
                            this.alternativeAddress.zip =
                                newAddress.address.postcode;
                        }

                        if (
                            (newAddress.address.town ||
                                newAddress.address.city ||
                                this.data.city) !== this.data.city
                        ) {
                            this.alternativeAddress.city =
                                newAddress.address.town ||
                                newAddress.address.city;
                        }

                        if (newAddress.address.country !== this.data.country) {
                            this.alternativeAddress.country =
                                newAddress.address.country;
                        }

                        if (_.isEqual(this.alternativeAddress, {})) {
                            this.snackbarCorrect = true;
                        } else {
                            this.dialog = true;
                        }
                    }
                })
                .finally(() => {
                    this.loadingValidation = false;
                });
        },
    },
    watch: {
        'data.latitude': {
            handler(val, oldVal) {
                console.log('latitude changed');
                if (!oldVal && this.data.longitude) {
                    this.setMarker();
                } else if (!val) {
                    this.removeMarker();
                } else {
                    this.removeMarker();
                    this.setMarker();
                }
            },
            deep: true,
        },
        'data.longitude': {
            handler(val, oldVal) {
                console.log('longitude changed');
                if (!oldVal && this.data.latitude) {
                    this.setMarker();
                } else if (!val) {
                    this.removeMarker();
                } else {
                    this.removeMarker();
                    this.setMarker();
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
@import '../../../../src/assets/styles/maplibre-gl.css';
</style>
