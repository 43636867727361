<template>
    <div class="users">

        <p class="text-h5">Importe</p>
        <p class="body-1 my-5">Hier können die über den Publication Planner registrierten Importe verwaltet werden.</p>

        <v-text-field
                v-model="search"
                class="my-4"
                append-icon="mdi-magnify"
                label="Suchen"
                single-line
                hide-details
                style="max-width: 300px;"
        ></v-text-field>

        <v-data-table
                :headers="headers"
                :items="tableData"
                sort-by="name"
                :items-per-page="100"
                class="elevation-1"
                :loading="$store.getters['users/getStatus'] === 'loading'"
                :search="search"
        >
            <template v-slot:item.controls="props">
                <!--v-btn class="mr-3 my-2" :to="`${props.item._id}`" fab depressed x-small router append>
                    <v-icon>edit</v-icon>
                </v-btn-->
                <v-btn class="mr-3 my-2"
                       color="success" fab depressed x-small
                       @click="triggerImport(props.item._id.toString())"
                       title="Jetzt ausführen"
                >
                    <v-icon>play_arrow</v-icon>
                </v-btn>
                <v-btn class="mr-3 my-2"
                       color="success" fab depressed x-small
                       @click="triggerImport(props.item._id.toString(), { debug: true })"
                       title="Mit Cache ausführen"
                >
                    <v-icon>bug_report</v-icon>
                </v-btn>
                <ImportForm :value='imports.find(i => i._id.toString() === props.item._id.toString())'></ImportForm>
                <DeleteConfirmation @yes='$store.dispatch("imports/remove", { id: props.item._id.toString() })'
                                    title='Import entfernen?'
                                    msg='Der Import wird für immer und ewig gelöscht (das ist sehr lange)!'
                >
                    <template v-slot:default="{ on, attrs }">
                        <v-btn v-on='on' v-bind='attrs' depressed x-small color='error' fab class='ml-3'>
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </template>
                </DeleteConfirmation>
            </template>
            <template v-slot:item.feedUrl="props">
                <a target="_blank" :href="`${ props.item.feedUrl }`">
                    {{ props.item.feedUrl }}
                </a>
            </template>
            <template v-slot:item.prev="props">
                <span>
                    <v-icon class="mr-1" small>history</v-icon>
                    <template v-if='props.item.lastSuccess'>
                        <v-menu bottom min-width="200px" rounded offset-y nudge-left="12px" nudge-bottom="4px" open-on-hover transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <span v-on='on'>{{ props.item.lastSuccess }}</span>
                            </template>
                            <v-card class="py-3 px-3">
                                <p class='my-0 font-weight-bold'>Kurzübersicht:</p>
                                <p class='my-0'>Erstellt: {{ props.item.stats.created }}</p>
                                <p class='my-0'>Aktualisiert: {{ props.item.stats.updated }}</p>
                                <p class='my-0'>Gelöscht: {{ props.item.stats.deleted }}</p>
                            </v-card>
                        </v-menu>
                    </template>
                    <template v-else>
                        <span>keine</span>
                    </template>
                </span>
            </template>
            <template v-slot:item.projectIdentifiers="props">
                <template v-if='props.item.projectIdentifiers.length === 1'>
                    <span>{{ props.item.projectIdentifiers[0] }}</span>
                </template>
                <template v-else>
                    <v-menu bottom min-width="200px" rounded offset-y nudge-left="12px" nudge-bottom="4px" open-on-hover transition="slide-y-transition">
                        <template v-slot:activator="{ on }">
                            <span v-on='on'>{{ props.item.projectIdentifiers.length }} Projekte</span>
                        </template>
                        <v-card class="py-1 pl-3 pr-5">
                            <template v-for="(project,index) in props.item.projectIdentifiers">
                                <span :key="index">{{ project }}<br></span>
                            </template>
                        </v-card>
                    </v-menu>
                </template>
            </template>
            <template v-slot:item.next="props">
                <span>
                    <v-icon class="mr-1" small>update</v-icon>
                    <span v-if='props.item.active'>{{ props.item.next }}</span>
                    <span v-else>inaktiv</span>
                </span>
            </template>
            <template v-slot:item.active="props">
                <v-btn depressed fab x-small icon @click='toggleActiveState(props.item._id.toString())'>
                    <v-icon v-if="props.item.active" color="success" size='26'>check</v-icon>
                    <v-icon v-else color="error" size='26'>close</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        
        <ImportForm :create='true' :value='{ cron: "0 0 * * *" }'></ImportForm>

    </div>
</template>

<script>

    import parser from 'cron-parser';
    import ImportForm from '../../components/import/ImportForm';
    import DeleteConfirmation from '../../components/utility/DeleteConfirmation';
    import axios from 'axios';
    import { DateTime } from 'luxon';

    export default {
        name: 'Import',
        components: {
            DeleteConfirmation,
            ImportForm,
            // Chat
        },
        data() {
            return {
                search: "",
                running: [],
                headers: [
                    { text: 'Aktionen', value: 'controls', sortable: false, width: "200" },
                    { text: 'Aktiv', value: 'active'},
                    { text: 'Projekt', value: 'projectIdentifiers'},
                    { text: 'Schema', value: 'importSchema'},
                    { text: 'Source', value: 'source'},
                    { text: 'Letzte Ausführung', value: 'prev'},
                    { text: 'Nächste Ausführung', value: 'next'},
                    { text: 'Cronjob', value: 'cron'},
                    { text: 'Feed', value: 'feedUrl'},
                ]
            }
        },
        computed: {
            imports: function() {
                return this.$store.getters['imports/getAll'];
            },
            tableData: function() {
                return this.imports.map((cron) => {
        
                    let next = parser.parseExpression(cron.cron).next();
                    // let prev = parser.parseExpression(cron.cron).prev();
        
                    return {
                        ...cron,
                        // prev: `${ prev.getDate() }.${ prev.getMonth()+1 }.${ prev.getFullYear() } ${ ("0" + prev.getHours().toString()).slice(-2) }:${ ("0" + prev.getMinutes().toString()).slice(-2) }`,
                        next: `${ next.getDate() }.${ next.getMonth()+1 }.${ next.getFullYear() } ${ ("0" + next.getHours().toString()).slice(-2) }:${ ("0" + next.getMinutes().toString()).slice(-2) }`,
                        lastSuccess: cron.stats?.lastSuccess ? DateTime.fromISO(cron.stats.lastSuccess).toFormat('dd.MM.yyyy HH:mm') : null,
                    }
                });
            }
        },
        methods: {
            toggleActiveState(id) {
                let item = this.imports.find(i => i._id.toString() === id);
                this.$store.dispatch('imports/update', {
                    payload: {
                        ...item,
                        active: !item.active,
                    },
                    id: item._id.toString(),
                })
            },
            triggerImport(id, options = {}) {
                axios.post(`/api/v2/imports/trigger/${id}`, options);
            }
        },
        created() {
            this.$store.dispatch('imports/fetchAll');
            // this.$store.dispatch('projects/fetchAll')
        }
    }
</script>
