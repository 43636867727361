<template>
    <div class="printsuite">
        <v-btn
            class="mb-5 mt-2"
            text
            depressed
            router
            :to="`/projects/${$router.currentRoute.params.projectID}/channels`"
        >
            <v-icon class="mr-3">arrow_back</v-icon>
            alle Channels
        </v-btn>

        <h1 class="display-1 mt-4 font-weight-bold">{{ channelName }}</h1>
        <!--p>für Channel <strong v-if="currentChannel.labels !== undefined">{{ currentChannel.labels['de-DE'] }}</strong> <span class="info--text">[{{currentChannel.id}}]</span></p-->
        <p class="mb-5">
            Hier können Sie alle print-relevanten Datensätze und Einstellungen
            pflegen.
        </p>

        <!-- todo check if has entrytypes or templates -->
        <template
            v-if="
                !loadingCatalogs &&
                $permGuard.chP('actions.import_settings') &&
                $settings.getValue('noSettingsYet')
            "
        >
            <p class="mt-5 pt-5 caption">
                Für diesen Kanal gibt es noch keine Versionen/Einstellungen.
            </p>
            <v-btn
                class="success mb-5"
                depressed
                rounded
                x-large
                @click="configCloneDialog = true"
            >
                Einstellungen importieren?
            </v-btn>
            <v-spacer class="py-5" />
        </template>

        <v-dialog v-model="configCloneDialog" max-width="700px">
            <v-card>
                <v-card-title>
                    <span>Einstellungen importieren</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        fab
                        text
                        small
                        @click="configCloneDialog = false"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <h5>Hinweis:</h5>
                    <ul class="mb-4">
                        <li>Eintragstypen werden kopiert</li>
                        <li>Templates werden kopiert</li>
                        <li>
                            Einstellungen werden <strong>überschrieben</strong>
                        </li>
                    </ul>
                    <p>
                        von welchem Channel sollen die Einstellungen übernommen
                        werden?
                    </p>
                    <v-autocomplete
                        :items="processedChannels"
                        v-model="sourceChannelID"
                        placeholder="Channel wählen..."
                        item-value="id"
                        item-text="label"
                    ></v-autocomplete>
                    <v-btn
                        rounded
                        color="info"
                        depressed
                        :disabled="!sourceChannelID"
                        @click="cloneConfig(sourceChannelID)"
                        :loading="loadingCloneConfig"
                    >
                        Importieren
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-row>
            <v-col
                v-for="(menuitem, index) in allowedMenu"
                :index="index"
                :item="menuitem"
                :key="index"
                cols="12"
                sm="12"
                md="6"
                lg="4"
                xl="3"
            >
                <v-card outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4"></div>
                            <v-list-item-title class="headline mb-1">
                                {{ menuitem.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{
                                menuitem.description
                            }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon size="64">{{ menuitem.icon }}</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                    <v-card-actions>
                        <v-btn
                            text
                            rounded
                            router
                            :to="`${menuitem.path}`"
                            append
                        >
                            bearbeiten
                        </v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>

                    <!-- catalog quick links -->
                    <v-list
                        v-if="
                            menuitem.path === 'catalogs' &&
                            $store.getters['allCatalogs'].length > 0
                        "
                    >
                        <v-subheader> Schnellauswahl: </v-subheader>
                        <template
                            v-for="(catalog, catalogIndex) in $store.getters[
                                'allCatalogs'
                            ]"
                        >
                            <v-list-item
                                :key="catalogIndex"
                                router
                                append
                                :to="`catalogs/${catalog._id.toString()}`"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="primary--text">
                                        {{ catalog.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-icon>launch</v-icon>
                            </v-list-item>
                            <v-divider
                                v-if="
                                    catalogIndex <
                                    $store.getters['allCatalogs'].length - 1
                                "
                                :key="catalogIndex + '__divider'"
                            ></v-divider>
                        </template>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <!--Chat /-->
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'PrintSuite',
    components: {
        // Chat
    },
    data() {
        return {
            sourceChannelID: '',
            configCloneDialog: false,
            loadingCloneConfig: false,
            loadingCatalogs: false,
            loadingChannels: false,
            catalogs: [],
            menu: [
                {
                    title: 'Versionen',
                    description: 'Versionen erstellen und verwalten',
                    path: 'catalogs',
                    icon: 'menu_book',
                    perm: 'catalogs.r',
                },
                {
                    title: 'Eintragstypen',
                    description: 'Arten der Einträge definieren',
                    path: 'entrytypes',
                    icon: 'format_list_bulleted',
                    perm: 'entrytypes.r',
                },
                {
                    title: 'Templates',
                    description: 'Definiert Layout-Varianten für Eintragstypen',
                    path: 'templates',
                    icon: 'view_quilt',
                    perm: 'templates.r',
                },
                /*{
                        title: "E-Mail Templates",
                        description: "E-Mail Templates konfigurieren",
                        path: "mailtemplates",
                        icon: "mail",
                        perm: "mail_templates.r",
                    },*/
                {
                    title: 'Einstellungen',
                    description: '',
                    path: 'settings',
                    icon: 'settings',
                    perm: 'settings.r',
                },
            ],
        };
    },
    computed: {
        processedChannels: function () {
            let channels = this.channels.map((c) => {
                return {
                    ...c,
                    label: c.labels['de-DE'] + ' [' + c.id + ']',
                };
            });
            return channels.filter(
                (c) =>
                    c._id.toString() !==
                    this.$router.currentRoute.params.channelID
            );
        },
        allowedMenu: function () {
            return this.menu.filter((m) => this.$permGuard.chP(m.perm));
        },
        channelName: function () {
            return this.$store.getters['channels/getAll']?.[
                this.$router.currentRoute.params.projectID
            ]?.find((p) => p.id === this.$router.currentRoute.params.channelID)
                .labels?.['de-DE'];
        },
        channels: function () {
            return this.$store.getters['channels/getAll']?.[
                this.$router.currentRoute.params.projectID
            ];
        },
    },
    methods: {
        cloneConfig(id) {
            this.sourceChannelID = '';
            this.loadingCloneConfig = true;
            axios
                .post(`/api/channels/cloneconfig`, {
                    targetChannelID: this.$router.currentRoute.params.channelID,
                    sourceChannelID: id,
                    projectID: this.$router.currentRoute.params.projectID,
                })
                .then(() => {
                    this.configCloneDialog = false;
                    this.$notify({
                        group: 'main',
                        type: 'success',
                        duration: 2000,
                        title: 'Config kopiert.',
                    });
                    // re-fetch settings
                    this.$store.dispatch('printsettings/fetchSettings', {
                        projectID: this.$router.currentRoute.params.projectID,
                        channelID: this.$router.currentRoute.params.channelID,
                    });
                })
                .catch((e) => {
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        duration: -1,
                        title: 'Fehlgeschlagen.',
                        text: e.toString(),
                    });
                    console.log(e);
                })
                .finally(() => {
                    this.loadingCloneConfig = false;
                });
        },
    },
    created() {
        this.loadingCatalogs = true;
        this.$store.dispatch('fetchCatalogsByChannel').finally(() => {
            this.loadingCatalogs = false;
        });
    },
};
</script>
