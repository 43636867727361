<template>
    <div>
        <CockpitLabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-row style="max-width: 1000px;">
            <v-col cols="12">
                <v-text-field dense filled
                              :readonly="isLocked"
                              label="Straße und Hausnummer"
                              :value="data.street"
                              @input="data = { ...data, street: $event }"
                              hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-text-field dense filled
                              :readonly="isLocked"
                              label="PLZ"
                              :value="data.zip"
                              @input="data = { ...data, zip: $event }"
                              hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-text-field dense filled
                              :readonly="isLocked"
                              label="Ort"
                              :value="data.city"
                              @input="data = { ...data, city: $event }"
                              hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-text-field dense filled
                              :readonly="isLocked"
                              label="Ortsteil"
                              :value="data.urbanDistrict"
                              @input="data = { ...data, urbanDistrict: $event }"
                              hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field dense filled
                              :readonly="isLocked"
                              label="Land"
                              :value="data.country"
                              @input="data = { ...data, country: $event }"
                              hide-details="auto"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-btn rounded :disabled="isLocked" depressed color="success" class="mt-3" @click="checkAddress" :loading="loadingValidation">
            <v-icon class="mr-2" small>
                beenhere
            </v-icon>
            Adresse validieren
        </v-btn>

        <!-- ADDRESS CORRECT -->
        <v-snackbar v-model="snackbarCorrect" :timeout="3000">
            Adresse plausibel!
            <template v-slot:action="{ attrs }">
                <v-btn color="success" text v-bind="attrs" @click="snackbarCorrect = false">OK</v-btn>
            </template>
        </v-snackbar>

        <!-- ADDRESS NOT FOUND -->
        <v-snackbar v-model="snackbarNotFound" :timeout="3000">
            Keine Adresse zum Vergleich gefunden!
            <template v-slot:action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="snackbarNotFound = false">OK</v-btn>
            </template>
        </v-snackbar>

        <!-- ADDRESS VALIDATION -->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">
                    Adresse korrigieren?
                </v-card-title>
                <v-card-text>
                    <p class="text-h6 font-weight-bold mb-2">Ihre Eingabe:</p>
                    <p class="my-0">{{ data.street || "STRASSE???" }}</p>
                    <p class="my-0">{{ data.zip || "PLZ???" }} {{ data.city || "ORT???"  }}</p>
                    <p class="my-0">{{ data.country || "LAND???" }}</p>
                    <p class="mt-4 text-h6 font-weight-bold mb-2">Gefundene Adresse:</p>
                    <p class="my-0">
                        <span :class="alternativeAddress.street ? 'warning--text' : ''">{{ alternativeAddress.street || data.street || "STRASSE???" }}</span>
                    </p>
                    <p class="my-0">
                        <span :class="alternativeAddress.zip ? 'warning--text' : ''">{{ alternativeAddress.zip || data.zip || "PLZ???" }}</span>&nbsp;
                        <span :class="alternativeAddress.city ? 'warning--text' : ''">{{ alternativeAddress.city || data.city || "ORT???" }}</span>
                    </p>
                    <p class="my-0">
                        <span :class="alternativeAddress.country ? 'warning--text' : ''">{{ alternativeAddress.country || data.country || "LAND???" }}</span>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" text @click="dialog = false">
                        Nein
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="useAlternativeAddress">
                        Ja
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>

</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import TranslationService from "../../../services/TranslationService";
import CockpitLabelComponent from "@/components/entries/cockpitFormCompontents/style-components/CockpitLabelComponent";

import CockpitFormComponentMixin from "@/components/mixins/cockpit-form-component";


export default {
    name: "CockpitAddressComponent",
    components: {
        CockpitLabelComponent
    },
    props: [
        'value',
        'definition',
        'isLocked'
    ],
    mixins: [
        CockpitFormComponentMixin
    ],
    data() {
        return {
            alternativeAddress: {},
            snackbarNotFound: false,
            snackbarCorrect: false,
            dialog: false,
            loadingValidation: false,
        }
    },
    computed: {
        data: {
            get() {
                return this.value || {};
            },
            set(data) {
                this.$emit('input', data);
            }
        }
    },
    methods: {
        /*async save() {
            return _.cloneDeep(this.data);
        },*/
        useAlternativeAddress() {
            this.$emit('input',  _.defaultsDeep(this.alternativeAddress, this.data));
            this.alternativeAddress = {};
            this.dialog = false;
        },
        async checkAddress() {

            this.loadingValidation = true;
            this.alternativeAddress = {};

            await axios.get(
                'https://nominatim.openstreetmap.org/search.php' +
                '?street=' + (this.data.street || '') +
                '&city=' + (this.data.city || '') +
                '&country=' + (this.data.country || '') +
                '&postalcode=' + (this.data.zip || '') +
                '&polygon_geojson=1' +
                '&format=json' +
                '&addressdetails=1'
            ).then((res) => {

                let nominatimAddresses = res.data;

                if (nominatimAddresses.length === 0) {
                    this.snackbarNotFound = true;
                } else {

                    let newAddress = nominatimAddresses[0];

                    if (((newAddress.address.road || newAddress.address.village) + " " + (newAddress.address.house_number || this.data.street.substr(this.data.street.lastIndexOf(' ')+1, this.data.street.length))) !== this.data.street) {
                        this.alternativeAddress.street = (newAddress.address.road + " " + (newAddress.address.house_number || this.data.street.substr(this.data.street.lastIndexOf(' ')+1, this.data.street.length)));
                    }

                    if (newAddress.address.postcode !== this.data.zip) {
                        this.alternativeAddress.zip = newAddress.address.postcode;
                    }

                    if ((newAddress.address.town || newAddress.address.city || this.data.city) !== this.data.city) {
                        this.alternativeAddress.city = (newAddress.address.town || newAddress.address.city);
                    }

                    if (newAddress.address.country !== this.data.country) {
                        this.alternativeAddress.country = newAddress.address.country;
                    }


                    if (_.isEqual(this.alternativeAddress, {})) {
                        this.snackbarCorrect = true;
                    } else {
                        this.dialog = true;
                    }

                }
            }).finally(() => {
                this.loadingValidation = false;
            })

        }
    }
}
</script>

<style scoped>

</style>
