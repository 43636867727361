<template>
    <div>

        <v-text-field
            v-model="search"
            style="max-width: 800px;"
            label="Berechtigungen durchsuchen"
            prepend-icon="search"
            hide-details
            clearable
            class="mb-4"
        ></v-text-field>

        <v-treeview :items="resolvedSchema"
                    :value="value"
                    @input="$emit('input', $event)"
                    :search="search"
                    :open-all="open"
                    expand-icon="expand_more"
                    selectable
                    dense
                    selected-color="success"
                    off-icon="clear"
                    on-icon="check"
        >

        </v-treeview>

        <v-spacer class="my-5"></v-spacer>

        <code v-text="role"></code>


    </div>
</template>

<script>

    import _ from "lodash";
    import Chat from "../utility/Chat";
    import axios from "axios";
    import UserService from "../../services/UserService";

    export default {
        name: 'PermissionTree',
        props: [
            "value"
        ],
        components: {
            // Chats
        },
        computed: {
            open: function() {
                return Boolean(!this.search)
            },
            resolvedSchema: function() {
                return [this.toTree(this.schema, "alle")];
            },
        },
        methods: {
            toTree(children, name, full_name = "") {
                if (typeof schema === "boolean") {
                    return {
                        id: full_name ? (full_name + "." + name) : name,
                        name: this.translations[full_name ? (full_name + "." + name) : name] || name,
                    };
                } else {
                    return {
                        id: full_name ? (full_name + "." + name) : name,
                        name: this.translations[full_name ? (full_name + "." + name) : name] || name,
                        children: Object.keys(children)?.map((key) => {
                            return this.toTree(children[key], key, full_name ? (full_name + "." + name) : name);
                        })
                    };
                }
            }
        },
        data() {
            return {
                search: "",
                role: {},
                schema: {
                    actions: {
                        import_settings: true,
                        sync_catalog: {
                            u: true,
                            c: true,
                        },
                        sync_entry: false,
                        get_xml: false,
                    },
                    entries: {
                        c: true,
                        r: true,
                        u: true,
                        d: true,
                    },
                    templates: {
                        c: false,
                        r: true,
                        u: false,
                        d: false,
                    },
                    settings: {
                        c: false,
                        r: false,
                        u: false,
                        d: false,
                    },
                    catalogs: {
                        c: false,
                        r: true,
                        u: true,
                        d: false,
                    },
                    entrytypes: {
                        c: false,
                        r: false,
                        u: false,
                        d: false,
                    }
                },
                translations: {
                    "alle": "Alle",
                    "alle.actions": "Aktionen",
                    "alle.actions.sync_entry": "Einzel-Sync",
                    "alle.actions.get_xml": "XMLs ansehen",
                    "alle.actions.sync_catalog": "Import",
                    "alle.actions.sync_catalog.u": "neue anlegen",
                    "alle.actions.sync_catalog.c": "UPDATE",
                    "alle.entries": "Einträge",
                    "alle.entries.c": "Erstellen",
                    "alle.entries.r": "Ansehen",
                    "alle.entries.u": "Bearbeiten",
                    "alle.entries.d": "Löschen",
                    "alle.templates.c": "",
                    "alle.templates.r": "",
                    "alle.templates.u": "",
                    "alle.templates.d": "",
                    "alle.settings.c": "",
                    "alle.settings.r": "",
                    "alle.settings.u": "",
                    "alle.settings.d": "",
                    "alle.catalogs.c": "",
                    "alle.catalogs.r": "",
                    "alle.catalogs.u": "",
                    "alle.catalogs.d": "",
                    "alle.entrytypes.c": "",
                    "alle.entrytypes.r": "",
                    "alle.entrytypes.u": "",
                    "alle.entrytypes.d": ""
                },
            }
        },
        created() {

        }
    }
</script>

<style>
.v-treeview-node__checkbox {
    color: red !important;
}
</style>
