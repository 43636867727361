import PlainRouterView from "../../views/PlainRouterView";
import OnlimChatbot from "../../views/OnlimChatbot/OnlimChatbot";
import SyncView from "../../views/OnlimChatbot/SyncView";

const routes = {
    path: "chatbot_onlim",
    component: PlainRouterView,
    name: "OnlimChatbot",
    children: [
        {
            path: "",
            component: OnlimChatbot
        },
        {
            path: "sync",
            component: PlainRouterView,
            children: [
                {
                    path: "",
                    component: SyncView,
                },
            ]
        },
        {
            path: "logs",
            component: PlainRouterView,
            children: [
                {
                    path: "",
                    component: PlainRouterView,
                }
            ]
        },
        {
            path: "settings",
            component: PlainRouterView,
            children: [
                {
                    path: "",
                    component: PlainRouterView,
                }
            ]
        }
    ]
};

export default routes