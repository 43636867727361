import axios from 'axios';
import router from '../../router';

const api = '/api/v2/groups';

const store = {
    state: {
        status: '',
        groups: [],
    },

    getters: {
        getStatus: (state) => state.status,
        getAll: (state) => state.groups,
    },

    actions: {
        async fetchGroups({ commit }) {
            commit('start_loading');
            await axios.get(`${api}`).then((response) => {
                commit('end_loading');
                commit('set_groups', response.data);
            });
        },
        async addGroup({ commit, dispatch }, { group }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .post(`${api}`, group)
                    .then(async (res) => {
                        await dispatch('fetchGroups');
                        resolve(res.data);
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
        async updateGroup({ commit, dispatch }, { group, id }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .put(`${api}/${id}`, group)
                    .then(async (res) => {
                        resolve();
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
        async removeGroup({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .delete(`${api}/${id}`)
                    .then(async (res) => {
                        await dispatch('fetchGroups');
                        resolve();
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
    },

    mutations: {
        set_groups(state, groups) {
            state.groups = groups || [];
        },
        start_loading(state) {
            state.status = 'loading';
        },
        end_loading(state) {
            state.status = 'success';
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
