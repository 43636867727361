<template>
    <v-dialog
        v-model="dialog"
        max-width="800px"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs">
                <v-btn fab fixed right top x-large style="top: 80px" color="green" dark v-bind="attrs" v-on="on">
                    <v-icon>add</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>

            <v-card-title>
                <v-row class="py-2">
                    <span class="ml-2 mt-3">
                        Neuen Eintrag anlegen
                    </span>
                    <v-spacer></v-spacer>
                    <!-- close -->
                    <v-btn @click="dialog = false" fab color="error" depressed icon small>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>

            <v-card flat>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-select :items="entryTypes"
                                          label="Eintrags-Typ"
                                          :loading="entryTypesLoading"
                                          required
                                          item-value="_id"
                                          placeholder="Eintrags-Typ wählen..."
                                          item-text="name"
                                          v-model="entryType"
                                ></v-select>

                                <v-text-field label="Name" required v-model="name"></v-text-field>

                                <v-autocomplete :items="entityTypes"
                                                label="Typ im Knowledge Graph"
                                                :loading="entityTypesLoading"
                                                required
                                                item-value="value"
                                                item-text="label"
                                                placeholder="Typ wählen..."
                                                v-model="entityType"
                                                v-if="createInCockpit && $settings.getValue('general.create.mode') > 1"
                                ></v-autocomplete>

                                <v-checkbox label="Im Knowledge Graph anlegen"
                                            v-if="$settings.getValue('general.create.mode') === 2"
                                            v-model="createInCockpit"
                                            hint="Einen Datensatz im Knowledge Graph anlegen und als Original-Datensatz des Eintrags verlinken"
                                            persistent-hint
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" depressed @click="create(entry)">Erstellen</v-btn>
                </v-card-actions>
            </v-card>

            <DeleteConfirmation :hidden="true" ref="redirectDialog" msg="Wollen Sie zur Bearbeitungsmaske des eben angelegten Datensatzes wechseln?" title="Jetzt bearbeiten?" @yes="redirectEdit">
                <template/>
            </DeleteConfirmation>


        </v-card>
    </v-dialog>

</template>

<script>

import EntriesSync from "./EntriesSync";
import _ from 'lodash';
import { mapActions } from "vuex";
import axios from "axios";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
const plainAxios = axios.create();

export default {
    name: "EntryCreate",
    components: {
        DeleteConfirmation
    },
    data() {
        return {
            entryTypesLoading: false,
            entityTypesLoading: false,
            entryType: "",
            entityType: "",
            entryTypes: [],
            entityTypes: [],
            createInCockpit: true,
            dialog: false,
            entry: {},
            tab: null,
            name: "",
            lastCreatedRedirectID: "",
        }
    },
    watch: {
        entryType: function(newValue) {
            // load all possible cockpit entity-typings if user selects a entry-type
            this.entityTypesLoading = true;

            let selectedEntryType = this.entryTypes?.find(e => e._id.toString() === this.entryType);
            if (selectedEntryType) {
                plainAxios.get(`https://datamodel.we2p.de/api/typeinfo/children?set_types=${ selectedEntryType.type }`).then((res) => {
                    this.entityTypes =
                        // get possible entityTypes and translate
                        this.$store.getters['properties/getAll']
                            ?.find(p => p.name === 'types')?.["value"]
                            ?.filter(v => res.data.includes(v.label))
                            ?.map((v) => {
                                return {
                                    value: v.label,
                                    label: v.description['de-DE'] + " (" + v.label + ")"
                                }
                            });

                }).finally(() => {
                    this.entityTypesLoading = false;
                })
            }

        }
    },
    methods: {
        ...mapActions("entries", ['addEntry']),
        redirectEdit() {
            this.$router.push({
                name: "EntryEdit",
                params: {
                    entryId: this.lastCreatedRedirectID
                }
            });
        },
        /**
         * creates a dataset. is also used by the clone function inside EntryList
         * @param entry
         * @param options
         * @returns {Promise<void>}
         */
        async create(entry, options = {}) {

            if (_.isEmpty(options)) {
                options = {
                    createInCockpit: Boolean((this.createInCockpit && this.$settings.getValue('general.create.mode') === 2) || this.$settings.getValue('general.create.mode') === 3),
                    entityType: this.entityType,
                }
            }

            await this.addEntry({
                entry: {
                    catalogID: this.$store.state.catalogs.currentCatalog._id,
                    type: this.entryType,
                    content: {
                        name: [
                            {
                                lang: "de-DE",
                                value: this.name
                            }
                        ],
                    },
                    ...entry
                },
                options,
            }).then((res) => {
                this.lastCreatedRedirectID = res.entry._id;
                this.entry = {};
                this.dialog = false;
                this.$refs['redirectDialog'].open();
                this.$notify({
                    group: 'main',
                    duration: 2000,
                    type: 'success',
                    title: 'Datensatz erstellt',
                });
            }).catch((e) => {
                let msg = "";
                switch (e) {
                    case "entity_corrupt":
                        msg = "Graph hat keinen gültigen Datensatz geliefert.";
                        break;
                    default:
                        console.error(e);
                }
                this.$notify({
                    group: 'main',
                    duration: -1,
                    type: 'error',
                    title: 'Erstellen fehlgeschlagen!',
                    text: msg,
                })
            });


        },
    },
    created() {

        // entrytypes
        axios.get(`/api/entrytypes/?projectID=${ this.$router.currentRoute.params.projectID }&channelID=${ this.$router.currentRoute.params.channelID }`, {}).then((res) => {
            this.entryTypes = res.data;
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            this.loadingEntryTypes = false;
        });

    }
}

</script>

<style scoped>



</style>
