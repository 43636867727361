<template>
    <div class="contacts">
        
        <v-row class='mx-0 my-0'>
            
            <v-btn color='info' depressed @click='downloadCSV' :loading='downloadingCSV'>
                <span>CSV exportieren</span>
                <v-icon class='ml-2'>
                    download
                </v-icon>
            </v-btn>
            
        </v-row>

        <v-row class='mt-0'>

            <v-col style="max-width: 450px; position: relative">
                <ContactList />
                <v-btn fab absolute color="success" style='right: -15px; top: 32px;' @click="createContact()">
                    <v-icon>
                        add
                    </v-icon>
                </v-btn>
            </v-col>

            <v-col style="max-height: 80vh;" v-if="$router.currentRoute.params.contactID">
                <v-card class="overflow-y-auto pa-5" style="max-height: 80vh;" elevation='0'>
                    <router-view :key="$router.currentRoute.params.contactID"></router-view>
                </v-card>
            </v-col>

        </v-row>

    </div>
</template>

<script>

    import ContactList from '../../components/users/ContactList';
    import DownloadService from '../../services/DownloadService';

    export default {
        name: 'Contacts',
        components: {
            ContactList
            // Chat
        },
        data() {
            return {
                loading: false,
                users: [],
                search: "",
                downloadingCSV: false,
            }
        },
        computed: {

        },
        methods: {
            createContact() {
                this.$store.dispatch('contacts/add', { payload: { projects: [ this.$router.currentRoute.params.projectID ] } }).then((res) => {
                    this.$router.push({
                        name: "ContactEdit",
                        params: {
                            contactID: res._id.toString(),
                        }
                    })
                })
            },
            downloadCSV() {
                this.downloadingCSV = true;
                DownloadService.downloadWithAxios(`/api/v2/contacts/csv?projectId=${ this.$router.currentRoute.params.projectID }`, 'kontakte.csv', true).then(() => {
                
                }).finally(() => {
                    this.downloadingCSV = false;
                });
            }
        },
        created() {

        }
    }
</script>
