<template>
    <div class="catalogs">
        <v-btn
            class="mb-5 mt-2"
            text
            depressed
            router
            :to="`/projects/${$router.currentRoute.params.projectID}/channels/${$router.currentRoute.params.channelID}/print/catalogs`"
        >
            <v-icon class="mr-3">arrow_back</v-icon>
            alle Versionen
        </v-btn>

        <p class="text-body-1 grey--text mt-4 mb-0 text-lowercase">
            {{ currentCatalog._id }}
        </p>
        <p class="text-h3">
            {{ currentCatalog.name }}
            <!-- info dialog -->
            <v-dialog
                v-model="infoDialog"
                width="1000"
                v-if="$permGuard.chP('entries.u')"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="black"
                        class="white--text"
                        fab
                        depressed
                        small
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon> edit_note </v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title class="text-h5 mb-0">
                        Informationen
                    </v-card-title>

                    <v-card-text>
                        <v-row class="mt-5">
                            <v-col cols="12" sm="6" class="pt-0 mt-0">
                                <v-text-field
                                    class="text--h3 pt-0"
                                    label="Name"
                                    :value="currentCatalog.name"
                                    :loading="loading"
                                    @input="updateName"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- info -->
                        <v-row v-if="advancedCatalogMetaInfo">
                            <v-col
                                cols="12"
                                sm="4"
                                class="pt-0 mt-0"
                                v-for="(prop, index) in advancedMetaData"
                                :key="index"
                            >
                                <v-text-field
                                    :label="prop.label"
                                    class="text--h3 pt-0"
                                    v-model="
                                        (currentCatalog.info || {})[prop.key]
                                    "
                                    :loading="loading"
                                    @input="update(prop.key, $event)"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- STATUS -->
                        <!-- p class="text-overline grey--text mt-4 mb-0">Status</p>
                        <CatalogWorkflow :catalog="currentCatalog"/-->

                        <span class="text-overline grey--text"
                            >Ansprechpartner</span
                        >
                        <v-row class="mt-2">
                            <v-col cols="12" sm="6" class="pt-0">
                                <span class="mb-0">VENUS GmbH</span>
                                <UserSelect
                                    :uuid="currentCatalog.assignedAdmin"
                                    @change="updateAssignedAdmin"
                                />
                            </v-col>
                            <v-col cols="12" sm="6" class="pt-0">
                                <span class="mb-0">Kunde</span>
                                <UserSelect
                                    :uuid="currentCatalog.assignedCustomer"
                                    @change="updateAssignedCustomer"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="infoDialog = false"
                            >OK</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </p>

        <!-- Import -->
        <CatalogSync
            v-if="
                $permGuard.chP('entries.c') &&
                $permGuard.chP('actions.sync_catalog')
            "
            @synced="
                fetchEntriesByCatalog($router.currentRoute.params.catalogID)
            "
            :catalogId="this.$router.currentRoute.params.catalogID"
        >
            <template v-slot:default="{ on, attrs }">
                <v-btn
                    rounded
                    depressed
                    color="success"
                    v-on="on"
                    v-bind="attrs"
                >
                    Import
                </v-btn>
            </template>
        </CatalogSync>

        <!-- Billing Download -->
        <v-btn
            rounded
            depressed
            color="orange"
            class="ml-3"
            v-if="
                $permGuard.chP('entries.r') &&
                $settings.getValue('accounting.enabled')
            "
            :loading="downloadingBilling"
            @click="downloadBillingCSV"
        >
            <span class="white--text">Rechnungsinfos</span>
            <v-icon color="white" class="ml-1">receipt_long</v-icon>
        </v-btn>

        <DeleteConfirmation
            @yes="exportToGraph"
            v-if="$permGuard.chP('super_admin')"
            title="Daten wirklich in Graph schreiben?"
        >
            <template v-slot:default="{ on, attrs }">
                <v-btn
                    depressed
                    class="ml-3"
                    v-on="on"
                    v-bind="attrs"
                    rounded
                    color="error"
                    :loading="loadingGraphExport"
                >
                    Graph-Export
                </v-btn>
            </template>
        </DeleteConfirmation>

        <!-- XML DOWNLOAD -->
        <v-menu offset-y v-if="$permGuard.chP('actions.get_xml')">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :loading="downloadingXML"
                    color="primary"
                    depressed
                    dark
                    rounded
                    class="ml-3"
                    v-bind="attrs"
                    v-on="on"
                    >XML<v-icon>expand_more</v-icon></v-btn
                >
            </template>
            <v-list>
                <!-- XML Download --->
                <v-list-item @click="downloadExport">
                    <v-list-item-title>Download (Alle)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadExport({ onlyConfirmed: true })">
                    <v-list-item-title
                        >Download (Freigegeben)</v-list-item-title
                    >
                </v-list-item>
                <!-- XML ONLINE -->
                <v-list-item
                    :href="`/api/export/catalog/${
                        currentCatalog._id
                    }?format=xml&languages=${availableLanguages.join(',')}`"
                    target="_blank"
                >
                    <v-list-item-title>Ansehen (Alle)</v-list-item-title>
                </v-list-item>
                <v-list-item
                    :href="`/api/export/catalog/${
                        currentCatalog._id
                    }?format=xml&statusPolicy=confirmed&languages=${availableLanguages.join(
                        ','
                    )}`"
                    target="_blank"
                >
                    <v-list-item-title>Ansehen (Freigegeben)</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- CATALOG -->
        <v-btn
            v-if="
                $permGuard.chP('actions.generate_catalog') &&
                !$settings.getValue('cometConnection.sorting.enabled')
            "
            depressed
            class="ml-3"
            rounded
            color="secondary"
            @click="generateCometCatalog"
            :loading="loadingCometCatalog"
        >
            Gesamtdokument
            <v-icon class="ml-2">send_and_archive</v-icon>
        </v-btn>

        <!-- STATUS CONVERT -->
        <StatusRuleBuilder
            @convert="convertEntryStatus"
            v-if="$permGuard.chP('actions.convert_status')"
        >
            <template v-slot:default="{ on, attrs }">
                <v-btn
                    class="ml-3"
                    rounded
                    depressed
                    color="info"
                    v-on="on"
                    v-bind="attrs"
                >
                    Status konvertieren
                    <v-icon class="ml-2">nat</v-icon>
                </v-btn>
            </template>
        </StatusRuleBuilder>

        <div
            class="my-5 py-5"
            style="max-width: 1200px"
            v-if="loadingGraphExport"
        >
            <p class="mt-1 mb-1">Datensätze werden zurückgeschrieben...</p>
            <v-progress-linear rounded color="success" indeterminate>
            </v-progress-linear>
            <!--p class="text-center">89 / 206</p-->
        </div>

        <!-- SORT -->
        <v-menu
            offset-y
            nudge-bottom="5"
            v-if="
                $permGuard.chP('entries.c') &&
                $settings.getValue('cometConnection.sorting.enabled')
            "
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    class="ml-3 white--text"
                    rounded
                    color="purple"
                    v-on="on"
                    v-bind="attrs"
                >
                    Sortierung
                    <v-icon class="ml-2">sort</v-icon>
                </v-btn>
            </template>
            <v-list max-width="500" class="pa-0" width="300">
                <template
                    v-for="(item, index) in currentCatalog.sortDefinitions"
                >
                    <v-list-item
                        dense
                        :key="index"
                        append
                        :to="`sort/${item.id}`"
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="
                                    item.name || 'Sortierung ' + (index + 1)
                                "
                            ></v-list-item-title>
                            <v-list-item-subtitle
                                >({{ item.id }})</v-list-item-subtitle
                            >
                        </v-list-item-content>
                        <!--v-list-item-action>
                            <v-row>
                                <v-btn fab x-small depressed color="primary" class="mr-2" append :to="`sort/${ item.id }`">
                                    <v-icon>sort</v-icon>
                                </v-btn>
                                <v-btn fab x-small depressed color="error" class="mr-2" @click.native.stop="deleteSort(index)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action-->
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                </template>
                <v-row class="ma-0">
                    <v-btn
                        rounded
                        small
                        depressed
                        color="success"
                        append
                        :to="`sort/${Math.round(Math.random() * 100000000)}`"
                        class="my-2 ml-2"
                        >Neu</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-row>
            </v-list>
        </v-menu>

        <v-divider class="my-6"></v-divider>

        <apexchart
            height="150"
            width="500"
            type="donut"
            :options="chartOptions"
            :series="statusStats"
            v-if="allEntries.length > 0"
        ></apexchart>

        <div class="mt-5">
            <p class="text-h5">Einträge:</p>
            <EntryList ref="chart" />
        </div>

        <EntryCreate
            ref="entryCreate"
            v-if="
                $settings.getValue('general.create.mode') > 0 &&
                $permGuard.chP('entries.c')
            "
        />

        <DeleteConfirmation
            :hidden="true"
            ref="redirectDialog"
            msg="Wollen Sie zur Bearbeitungsmaske des eben angelegten Datensatzes wechseln?"
            title="Jetzt bearbeiten?"
        >
            <template />
        </DeleteConfirmation>

        <v-snackbar v-model="snackbar" :timeout="2000">
            Gespeichert!
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="success"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    OK
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Vue from 'vue';

import { mapGetters, mapActions } from 'vuex';
import colors from 'vuetify/es5/util/colors';

import EntryList from '../../components/entries/EntryList';
import EntryCreate from '../../components/entries/EntryCreate';

import CatalogWorkflow from '../../components/catalogs/CatalogWorkflow';
import UserSelect from '../../components/users/UserSelect';
import debounce from 'debounce';
import DownloadService from '../../services/DownloadService';

import router from '../../router';
import CatalogSync from '../../components/catalogs/CatalogSync';
import VueApexCharts from 'vue-apexcharts';
import EntryStatusService from '../../services/EntryStatusService';
import axios from 'axios';
import StatusRuleBuilder from '@/components/catalogs/StatusRuleBuilder';
import DeleteConfirmation from '@/components/utility/DeleteConfirmation';

export default {
    name: 'Catalog',
    components: {
        DeleteConfirmation,
        StatusRuleBuilder,
        CatalogSync,
        EntryList,
        EntryCreate,
        // CatalogWorkflow,
        UserSelect,
        apexchart: VueApexCharts,
    },
    data() {
        return {
            labels: [],
            labelColors: ['#ff0000'],
            loadingCometCatalog: false,
            loadingGraphExport: false,
            loading: false,
            downloadingBilling: false,
            infoDialog: false,
            chartStatus: [],
            snackbar: false,
            downloadingXML: false,
            advancedMetaData: [
                {
                    label: 'Verlagsnummer Kompass',
                    key: 'publisherNumberKompass',
                },
                {
                    label: 'Verlagsnummer White-Label',
                    key: 'publisherNumberWhiteLabel',
                },
                {
                    label: 'ISBN-Nummer',
                    key: 'isbn',
                },
                {
                    label: 'Auflage',
                    key: 'edition',
                },
                {
                    label: 'Veröffentlichungsjahr',
                    key: 'yearOfPublication',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('entries', ['allEntries']),
        ...mapGetters(['currentCatalog']),
        availableLanguages: function () {
            return this.$settings.getValue('language.available').length > 0
                ? this.$settings.getValue('language.available')
                : ['de-DE'];
        },
        chartOptions: function () {
            return {
                labels: this.labels,
                chart: {
                    type: 'donut',
                    events: {
                        dataPointSelection: (e, chart, opts) => {
                            if (this.$refs['chart']) {
                                this.$refs['chart'].setFilter(
                                    this.chartStatus[
                                        opts.selectedDataPoints?.[0]?.[0]
                                    ]
                                );
                            }
                        },
                    },
                },
                colors: this.labelColors,
                fill: {
                    colors: this.labelColors,
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    position: 'right',
                    offsetY: 0,
                },
            };
        },
        statusStats: function () {
            const statusList = EntryStatusService.getStatusArray();

            let statusLabels = [];
            let series = statusList.map((status) => {
                let count = this.allEntries.filter(
                    (entry) => entry.status === status.value
                ).length;
                if (count > 0)
                    statusLabels.push({
                        value: status.value,
                        count: count,
                    });
                return count;
            });

            this.setLabels(statusLabels);

            return series.filter(Boolean);
        },
        advancedCatalogMetaInfo: function () {
            return process.env.VUE_APP_ADVANCED_CATALOG_META_INFO;
        },
    },
    methods: {
        ...mapActions('entries', ['createEntry', 'fetchEntriesByCatalog']),
        ...mapActions(['fetchCurrentCatalog', 'updateCatalog']),
        downloadBillingCSV() {
            this.downloadingBilling = true;
            const catalogID = this.$router.currentRoute.params.catalogID;
            DownloadService.downloadWithAxios(
                `/api/v2/catalogs/${catalogID}/csv`,
                'rechnungs_infos.csv',
                true
            ).finally(() => {
                this.downloadingBilling = false;
            });
        },
        exportToGraph() {
            this.loadingGraphExport = true;
            axios
                .post('/api/entrysync/export', {
                    catalogID: this.$router.currentRoute.params.catalogID,
                })
                .finally(() => {
                    this.loadingGraphExport = false;
                });
        },
        async downloadExport(options) {
            this.downloadingXML = true;
            const catalogID = this.$router.currentRoute.params.catalogID;
            console.log(typeof catalogID);
            DownloadService.downloadWithAxios(
                `/api/export/catalog/${catalogID}?format=xml&languages=${this.availableLanguages.join(
                    ','
                )}${options.onlyConfirmed ? '&statusPolicy=confirmed' : ''}`,
                'Katalog.xml'
            ).then(() => {
                this.downloadingXML = false;
            });
        },
        /**
         * converts status of all entries according to rules
         * @param rules
         */
        convertEntryStatus(rules) {
            console.log(rules);

            axios
                .post(`/api/catalogs/statusrules`, {
                    catalogID: this.$router.currentRoute.params.catalogID,
                    rules: rules.rules,
                })
                .then(() => {
                    this.$notify({
                        group: 'main',
                        type: 'success',
                        duration: 2000,
                        title: 'Status konvertiert.',
                    });
                })
                .catch((e) => {
                    console.log(e);
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        duration: 4000,
                        title: 'Fehler bei Status Konvertieruung.',
                        text: e.toString(),
                    });
                })
                .finally(() => {
                    this.fetchEntriesByCatalog(
                        this.$router.currentRoute.params.catalogID
                    );
                });
        },
        generateCometCatalog() {
            this.loadingCometCatalog = true;

            axios
                .post('/api/comet/catalog', {
                    catalogID: this.currentCatalog._id.toString(),
                })
                .then((res) => {
                    const title = 'Der Katalog wird nun generiert...';
                    const text =
                        'Sie erhalten eine E-Mail, wenn die Generierung abgeschlossen ist.';
                    this.$notify({
                        group: 'main',
                        type: 'info',
                        duration: 10000,
                        title,
                        text,
                    });
                })
                .catch(() => {
                    const title =
                        'Der Katalog konnte nicht generiert werden...';
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        duration: -1,
                        title,
                    });
                })
                .finally(() => {
                    this.loadingCometCatalog = false;
                });
        },
        setLabels(labels) {
            const statusList = EntryStatusService.getStatusArray();
            // map to label and color
            this.labels = labels.map(
                (x) =>
                    statusList.find((y) => y.value === x.value)?.label +
                    ` (${x.count})`
            );
            this.chartStatus = labels;
            this.labelColors = labels.map(
                (x) => statusList.find((y) => y.value === x.value)?.color
            );
        },
        updateName(name) {
            let catalog = this.currentCatalog;
            this.loading = true;
            catalog['name'] = name;
            this.updateCatalog(catalog).then(() => {
                this.snackbar = true;
                this.loading = false;
            });
        },
        update(key, value) {
            let catalog = this.currentCatalog;
            if (!catalog.info) catalog.info = {};
            catalog.info[key] = value;
            this.loading = true;
            this.updateCatalog(catalog).then(() => {
                this.snackbar = true;
                this.loading = false;
            });
        },
        deleteSort(index) {
            this.currentCatalog.sortDefinitions.splice(index, 1);
            this.updateCatalog(this.currentCatalog).then(() => {
                this.snackbar = true;
            });
        },
        updateAssignedCustomer(uuid) {
            let catalog = this.currentCatalog;
            catalog['assignedCustomer'] = uuid || -1;
            this.updateCatalog(catalog).then(() => {
                this.snackbar = true;
            });
        },
        updateAssignedAdmin(uuid) {
            let catalog = this.currentCatalog;
            catalog['assignedAdmin'] = uuid || -1;
            this.updateCatalog(catalog).then(() => {
                this.snackbar = true;
            });
        },
    },
    created() {
        this.fetchCurrentCatalog(
            this.$router.currentRoute.params.catalogID
        ).then(() => {
            this.fetchEntriesByCatalog(
                this.$router.currentRoute.params.catalogID
            );
            console.log('fetched entries in Catalog.vue');
        });
        this.updateName = debounce(this.updateName, 500);
        this.update = debounce(this.update, 800);
    },
};
</script>
