<template>

    <div class="my-5" v-if="contact">

        <v-row class="mx-2 mb-5">
            <v-spacer></v-spacer>
            <DeleteConfirmation  @yes="remove">
            </DeleteConfirmation>
        </v-row>
        
        <p class='text-h4 font-weight-bold'>{{ getDisplayName(contact, "Neuer Kontakt") }}</p>
        
        <ContactEditForm v-model='contact'/>
        
    </div>

</template>

<script>
import _ from "lodash";
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
import UserImage from "@/components/users/UserImage";
import ContactEditForm from '../../components/users/ContactEditForm';
import contactUtils from '../../components/mixins/contact-utils';

export default {
    name: "ContactEdit",
    components: {
        ContactEditForm,
        DeleteConfirmation,
    },
    mixins: [
        contactUtils,
    ],
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        contactID: function() {
            return this.$router.currentRoute.params.contactID;
        },
        contact: {
            get() {
                return this.$store.getters['contacts/getAll'].find(c => c._id.toString() === this.contactID);
            },
            set(val) {
                if (val) this.save();
            }
        },
    },
    watch: {
        contact: {
            handler(val, oldVal) {
                if (oldVal && val) this.save();
            },
            deep: true,
        }
    },
    methods: {
        remove() {
            this.loading = true;
            this.$store.dispatch('contacts/remove', { id: this.contactID }).then(() => {
                this.loading = false;
                this.$router.push({
                    name: "Contacts"
                })
            });
        },
        save: _.debounce(function() {
            this.$store.dispatch('contacts/update', { id: this.contactID, payload: this.contact }).then(() => {
                this.loading = false;
                this.$notify({group: 'main', type: 'success', duration: 1000, title: "Kontakt gespeichert." });
            })
        }, 1000)
    },
    created() {

    }
}
</script>

<style>
</style>
