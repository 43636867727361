<template>

    <div>

        <v-container class="mb-5 mt-3">

            <v-text-field
                    label="Suchen"
                    prepend-inner-icon="search"
                    v-model="searchTerm"
                    autofill="off"
                    style="max-width: 500px;"
            ></v-text-field>

            <div class="my-5 text-center" v-if="(channels.length === 0 || filteredChannels.length === 0) && $store.getters['channels/getStatus'] !== 'loading'">
                <v-icon size="128" class="mb-4 grey--text">menu_book</v-icon>
                <p class="text-h4 my-5">Keine Channels gefunden.</p>
            </div>

            <template v-if="$store.getters['channels/getStatus'] === 'loading'">
                {{ $store.getters['channels/getStatus'] }}
                <v-row class="mt-5 pt-5">
                    <v-col v-for="index in 4" :key="index" cols="12" sm="6" md="4" lg="3" xl="2">
                        <v-skeleton-loader
                                           max-width="400"
                                           type="card"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
            </template>

            <template>

                <div v-for="(channelGroup, index) in channelGroups" :key="index" class="pt-3">

                    <template v-if="(filteredChannels.filter(x => (x.channelType || []).includes(channelGroup.type))).length > 0">
                        <p class="text-h4 font-weight-bold mt-5">{{ channelGroup.label }}</p>
                        <v-row>

                            <v-col v-for="(channel, index) in (filteredChannels.filter(x => (x.channelType || []).includes(channelGroup.type)))" :item="channel" :index="index" :key="channel._id" cols="12" sm="6" md="4" lg="3" xl="2">
                                <router-link router :to="getLink(channel)" :style="channelGroup.active ? 'text-decoration: none' : 'text-decoration: none; pointer-events: none'">
                                    <v-card elevation="0">
                                        <v-img :src="`https://webimages.we2p.de/static/pp/${ environment }/channel_${$router.currentRoute.params.projectID}_${channel.id}_thumbnail.jpg`"
                                               gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                               class="white--text align-end"
                                               height="200px"
                                        >
                                            <v-card-title>
                                                <span style="word-break: normal">{{ (channel.labels || {})['de-DE'].replace('Print: ', '') }}</span>
                                            </v-card-title>
                                        </v-img>

                                        <v-card-actions>
                                            <v-btn text>
                                                <v-icon>subdirectory_arrow_right</v-icon>
                                                <span v-if="channelGroup.active">öffnen</span>
                                                <span v-else>nicht verfügbar</span>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </router-link>
                            </v-col>

                        </v-row>
                    </template>

                </div>

                <div v-if="otherChannels.length > 0">
                    <p class="text-h4 font-weight-bold mt-5">Sonstige</p>
                    <v-row>

                        <v-col v-for="(channel, index) in otherChannels" :item="channel" :index="index" :key="channel._id" cols="12" sm="6" md="4" lg="3" xl="2">
                            <router-link router :to="getLink(channel)" style="text-decoration: none; pointer-events: none">
                                <v-card>
                                    <v-img :src="`https://webimages.we2p.de/static/pp/${ environment }/channel_${$router.currentRoute.params.projectID}_${channel.id}_thumbnail.jpg`"
                                           gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                           class="white--text align-end"
                                           height="200px"
                                    >
                                        <v-card-title>
                                            <span style="word-break: normal">{{ (channel.labels || {})['de-DE'] }}</span>
                                        </v-card-title>
                                    </v-img>
                                    <v-card-actions>
                                        <v-btn text>
                                            <v-icon>subdirectory_arrow_right</v-icon>
                                            <span>NICHT VERFÜGBAR</span>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </router-link>
                        </v-col>
                    </v-row>
                </div>


            </template>

        </v-container>

    </div>

</template>

<script>

    import Vue from 'vue';
    import axios from 'axios';
    import _ from 'lodash';

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ChannelGrid',
        components: {
        },
        data() {
            return {
                loading: false,
                searchTerm: "",
                channelGroups: [
                    {
                        label: "Print",
                        type: "print",
                        active: true,
                    },
                    {
                        label: "Web",
                        type: "modules",
                        active: false,
                    },
                    {
                        label: "Wanderpass",
                        type: "hiking_pwa",
                        active: false,
                    },
                    {
                        label: "Onlim (Chatbot / DACH KG)",
                        type: "chatbot_onlim",
                        active: true,
                    },
                    {
                        label: "Open Data",
                        type: "open_data",
                        active: false,
                    }
                ]
            }
        },
        computed: {
            //...mapGetters(['allChannels', 'currentChannel', 'currentProject']),
            channels: function() {
                return this.$store.getters['channels/getAll'][this.$route.params.projectID] || [];
            },
            filteredChannels: function() {
                if (this.searchTerm !== '') {
                    return this.channels.filter((x) => ((x.labels || [])['de-DE'] || "").toLowerCase().includes(this.searchTerm.toLowerCase()));
                } else {
                    return this.channels;
                }
            },
            environment: function () {
                return process.env.NODE_ENV;
            },
            otherChannels: function() {
                return this.filteredChannels.filter((x) => {
                    return _.intersection(x.channelType, this.channelGroups.map((x) => x.type)).length === 0;
                });
            }
        },
        methods: {
            //...mapActions(['fetchChannelsByProject', 'setCurrentChannel']),
            getLink(channel) {
                if (channel['channelType'] !== undefined && channel['channelType'].length === 1) {
                    return "/projects/" + this.$router.currentRoute.params.projectID + "/channels/" + channel.id + "/" + channel['channelType'][0];
                } else {
                    return "/projects/" + this.$router.currentRoute.params.projectID + "/channels/" + channel.id;
                }
            }
        },
        created() {

        }
    }
</script>

<style scoped>

</style>
