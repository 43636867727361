<template>
  <div class="catalogs">

      <v-btn class="mb-5 mt-2" text depressed router :to="`/projects/${$router.currentRoute.params.projectID}/channels/${$router.currentRoute.params.channelID}/print`">
          <v-icon class="mr-3">arrow_back</v-icon>
          dashboard
      </v-btn>

    <CatalogList></CatalogList>

  </div>
</template>

<script>

import CatalogList from '../../components/catalogs/CatalogList';

export default {
  name: 'Catalogs',
  components: {
    CatalogList
  }
}
</script>
