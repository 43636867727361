<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-checkbox dense
                    filled
                    :readonly="isLocked"
                    :input-value="data"
                    @change="$emit('input', Boolean($event))"
                    hide-details="auto"
        ></v-checkbox>
    </div>
</template>

<script>

    import _ from 'lodash';
    import TranslationService from "../../../services/TranslationService";
    import LabelComponent from "./style-components/CockpitLabelComponent";

    import FormComponentMixin from "../../mixins/form-component";

    export default {
        name: "CockpitBooleanComponent",
        components: {
            LabelComponent
        },
        mixins: [
            FormComponentMixin
        ],
        props: [
            'definition',
            'value',
            'isLocked'
        ],
        data() {
            return {

            }
        },
        computed: {
            data: function() {
                return Boolean(this.value);
            }
        },
        methods: {
            async save() {

            }
        }
    }
</script>

<style scoped>

</style>
