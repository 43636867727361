<template>
    <div class="catalogs">
        <v-btn
            class="mb-5 mt-2"
            text
            depressed
            router
            :to="`/projects/${$router.currentRoute.params.projectID}/channels/${$router.currentRoute.params.channelID}/print/catalogs/${$router.currentRoute.params.catalogID}`"
        >
            <v-icon class="mr-3">arrow_back</v-icon>
            zurück
        </v-btn>

        <!-- warning when switching typings -->
        <v-dialog v-model="printBlockersDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">Achtung!</v-card-title>
                <v-card-text>
                    <p>
                        Bevor der Katalog für die Produktion generiert werden
                        kann müssen folgende Probleme behoben werden:
                    </p>
                    <v-list subheader two-line dense>
                        <v-list-item
                            v-for="(blocker, index) in printBlockers"
                            :key="index"
                        >
                            <v-list-item-avatar>
                                <v-icon class="warning" color="white"
                                    >warning</v-icon
                                >
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="blocker.name"
                                ></v-list-item-title>

                                <v-list-item-subtitle
                                    v-text="blocker.reason"
                                ></v-list-item-subtitle>
                            </v-list-item-content>

                            <!--v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                                </v-btn>
                            </v-list-item-action-->
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green"
                        @click="printBlockersDialog = false"
                        text
                        >OK</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <p class="text-h3">{{ currentCatalog.name }}</p>

        <!-- XML DONWLOAD -->
        <v-menu offset-y v-if="$permGuard.chP('actions.get_xml')">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :loading="downloadingXML"
                    color="primary"
                    depressed
                    dark
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    >XML<v-icon>expand_more</v-icon></v-btn
                >
            </template>
            <v-list>
                <!-- XML Download --->
                <v-list-item @click="downloadExport">
                    <v-list-item-title>Download (Alle)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadExport({ onlyConfirmed: true })">
                    <v-list-item-title
                        >Download (Freigegeben)</v-list-item-title
                    >
                </v-list-item>
                <!-- XML ONLINE -->
                <v-list-item
                    :href="`/api/export/catalog/${currentCatalog._id}?format=xml&sortBy=${this.$router.currentRoute.params.sortID}`"
                    target="_blank"
                >
                    <v-list-item-title>Ansehen (Alle)</v-list-item-title>
                </v-list-item>
                <v-list-item
                    :href="`/api/export/catalog/${currentCatalog._id}?format=xml&statusPolicy=confirmed&sortBy=${this.$router.currentRoute.params.sortID}`"
                    target="_blank"
                >
                    <v-list-item-title>Ansehen (Freigegeben)</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- CATALOG -->
        <v-btn
            v-if="$permGuard.chP('actions.generate_catalog')"
            depressed
            class="ml-3"
            rounded
            color="secondary"
            @click="generateCometCatalog"
            :loading="loadingCometCatalog"
        >
            Gesamtdokument
            <v-icon class="ml-2">send_and_archive</v-icon>
        </v-btn>

        <!-- GESAMTVORSCHAU -->
        <CometPreview :urls="allPreviews">
            <template v-slot:default="{ on, attrs }">
                <v-btn
                    v-on="on"
                    :bind="attrs"
                    depressed
                    class="ml-3"
                    rounded
                    color="info"
                    :disabled="allPreviews.length < 1"
                >
                    Gesamtvorschau
                    <v-icon class="ml-2">remove_red_eye</v-icon>
                </v-btn>
            </template>
        </CometPreview>

        <!-- DELETE -->
        <DeleteConfirmation @yes="deleteSort">
            <template v-slot:default="{ on, attrs }">
                <v-btn
                    depressed
                    class="ml-3"
                    rounded
                    color="error"
                    v-on="on"
                    v-bind="attrs"
                >
                    Löschen
                </v-btn>
            </template>
        </DeleteConfirmation>

        <v-row :class="`my-5 sort-ui ${fullscreen ? 'fullscreen' : ''}`">
            <v-col cols="5" style="position: relative">
                <v-sheet class="pa-5 sort-sheet">
                    <!-- fullcreen button -->
                    <v-text-field
                        class="my-4 text-h6"
                        :value="name || 'Sortierung'"
                        @input="name = $event"
                    >
                    </v-text-field>
                    <v-checkbox
                        v-model="hideTemplates"
                        label="Templates ausblenden"
                    ></v-checkbox>
                    <template
                        v-if="$store.getters['entries/getStatus'] !== 'error'"
                    >
                        <SortDefinition
                            @move="checkSortDefinition"
                            @entrySave="handleEntrySave"
                            :sort-definition="sortDefinition"
                            @save="save"
                            :hide-templates="hideTemplates"
                        />
                    </template>
                    <template v-else>
                        <v-progress-linear indeterminate></v-progress-linear>
                    </template>
                </v-sheet>
                <v-btn
                    fab
                    small
                    color="grey"
                    style="position: absolute; top: 20px; right: 0"
                    depressed
                    @click="fullscreen = !fullscreen"
                >
                    <v-icon color="white">{{
                        fullscreen ? 'fullscreen_exit' : 'fullscreen'
                    }}</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="7" style="position: relative">
                <v-text-field
                    v-model="search"
                    class="mr-3 pl-3 mt-3"
                    prepend-icon="search"
                    style="
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 400px;
                        backdrop-filter: blur(10px);
                    "
                ></v-text-field>

                <EntryCreate
                    ref="entryCreate"
                    v-if="$settings.getValue('general.create.mode') > 0"
                >
                    <template v-slot:default="{ on, attrs }">
                        <v-btn
                            fab
                            depressed
                            style="
                                position: absolute;
                                bottom: 15px;
                                right: 15px;
                            "
                            color="green"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
                </EntryCreate>

                <v-sheet class="pa-5 sort-sheet">
                    <p class="my-4 text-h6">Verfügbare Einträge</p>
                    <p class="text-caption">
                        <v-icon class="mr-2">info</v-icon>
                        <span
                            >Ziehen Sie die Flaggen in die linke Spalte, um den
                            Eintrag einzusortieren.</span
                        >
                    </p>
                    <v-data-table
                        :items="tableData"
                        :items-per-page="-1"
                        :search="search"
                        :loading="isEntriesLoading"
                        disable-pagination
                        :headers="headers"
                        item-key="_id"
                    >
                        <template v-slot:item.controls="props">
                            <v-dialog
                                persistent
                                max-width="1800"
                                scrollable
                                v-model="openDialogs[props.item._id]"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        depressed
                                        x-small
                                        text
                                        fab
                                        v-on="on"
                                        v-bind="attrs"
                                        class="ml-2"
                                    >
                                        <v-icon> edit </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar elevation="1">
                                        <v-card-title class="headline">
                                            Eintrag bearbeiten
                                        </v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mr-0"
                                            color="red"
                                            icon
                                            @click="
                                                openDialogs[
                                                    props.item._id
                                                ] = false
                                            "
                                        >
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <EntryEdit
                                            @save="$emit('handleEntrySave')"
                                            :minimal="true"
                                            :entry-id="props.item._id"
                                        ></EntryEdit>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </template>

                        <template v-slot:item.languages="props">
                            <span
                                v-if="
                                    (
                                        $settings.getValue(
                                            'language.available'
                                        ) || []
                                    ).length
                                "
                            >
                                <draggable
                                    class="dragArea"
                                    @end="checkSortDefinition"
                                    :list="
                                        props.item.languages.map((l) => {
                                            return { ...l, entry: props.item };
                                        })
                                    "
                                    :group="{
                                        name: 'g1',
                                        pull: 'clone',
                                        put: false,
                                    }"
                                    :options="{
                                        swapThreshold: 0.8,
                                        ghostClass: 'ghost',
                                    }"
                                    :clone="cloneLanguage"
                                >
                                    <template
                                        v-for="(lang, index) in props.item
                                            .languages"
                                    >
                                        <v-avatar
                                            :key="index"
                                            size="28"
                                            class="mx-1 my-2 v-btn--outlined"
                                            style="cursor: grab"
                                        >
                                            <v-img
                                                :src="`https://webimages.we2p.de/static/flags/svg/${lang.code.slice(
                                                    -2
                                                )}.svg`"
                                            ></v-img>
                                            <v-icon
                                                color="success"
                                                size="24"
                                                v-if="
                                                    flatSort.find(
                                                        (sort) =>
                                                            sort._id ===
                                                                props.item._id.toString() &&
                                                            sort.lang ===
                                                                lang.code
                                                    )
                                                "
                                                style="
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 50%;
                                                    transform: translate(
                                                        -50%,
                                                        -50%
                                                    );
                                                    background-color: rgba(
                                                        255,
                                                        255,
                                                        255,
                                                        0.6
                                                    );
                                                "
                                                >check</v-icon
                                            >
                                        </v-avatar>
                                    </template>
                                </draggable>
                            </span>
                        </template>
                        <template v-slot:item.templateID="props">
                            <span>{{
                                (
                                    $store.getters['templates/getAll'].find(
                                        (t) =>
                                            t._id.toString() ===
                                            props.item.templateID
                                    ) || {}
                                ).name
                            }}</span>
                        </template>
                        <template v-slot:item.type="props">
                            <span>{{
                                (
                                    $store.getters['entrytypes/getAll'].find(
                                        (t) =>
                                            t._id.toString() === props.item.type
                                    ) || {}
                                ).name
                            }}</span>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-col>
        </v-row>

        <!-- SAVE BUTTON -->
        <v-btn
            fab
            x-large
            fixed
            :color="'success'"
            top
            right
            @click="save"
            style="top: 80px; backdrop-filter: blur(5px); z-index: 25"
        >
            <v-icon v-if="!saving" color="white"> save </v-icon>
            <v-progress-circular indeterminate v-if="saving" />
        </v-btn>
        <!-- test -->
    </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import axios from 'axios';
import debounce from 'debounce';

import DownloadService from '../../services/DownloadService';
import EntryStatusService from '../../services/EntryStatusService';
import EntryList from '@/components/entries/EntryList';
import TranslationService from '../../services/TranslationService';
import SortDefinition from '../../components/catalogs/SortDefinition';
import store from '@/store';
import DeleteConfirmation from '../../components/utility/DeleteConfirmation';
import draggable from 'vuedraggable';
import CometPreview from '../../components/entries/CometPreview';
import EntryCreate from '../../components/entries/EntryCreate';
import md5 from 'md5';
import { v4 as uuidv4 } from 'uuid';
import EntryEdit from './EntryEdit';

export default {
    name: 'Catalog',
    components: {
        EntryEdit,
        EntryCreate,
        CometPreview,
        DeleteConfirmation,
        SortDefinition,
        draggable,
    },
    data() {
        return {
            name: '',
            openDialogs: {},
            printBlockers: [], // array of issues to be resolved
            printBlockersDialog: false, // dialog for displaying "print blockers"
            labels: [],
            labelColors: ['#ff0000'],
            loadingCometCatalog: false,
            loadingGraphExport: false,
            loading: false,
            chartStatus: [],
            fullscreen: false,
            sortDefinition: [],
            snackbar: false,
            search: '',
            saving: false,
            downloadingXML: false,
            lengthOfSortDefinition: 0,
        };
    },
    computed: {
        ...mapGetters('entries', ['allEntries', 'isEntriesLoading']),
        ...mapGetters(['currentCatalog']),
        flatSort: function () {
            let result = [];
            for (let def of this.sortDefinition) {
                result = result.concat(this.flatten(def));
            }
            console.log(result);
            return result;
        },
        hideTemplates: {
            get() {
                return (
                    this.$settings.getUserSettings(
                        'catalog_sort_hide_templates'
                    ) ?? false
                );
            },
            set(mode) {
                this.$settings.setUserSettings(
                    'catalog_sort_hide_templates',
                    mode
                );
            },
        },
        allPreviews: function () {
            let previews = [];

            console.log('typof', typeof this.sortDefinition);

            return _.flatten(
                this.sortDefinition?.map(
                    (s) => s.printStatus?.buildPaths || []
                ) || []
            );
        },
        headers: function () {
            return [
                // filterable entryID
                { text: 'EntryID', value: '_id', align: ' d-none' },
                { text: 'CockpitID', value: 'parentID', align: ' d-none' },

                {
                    text: 'Sprachen',
                    value: 'languages',
                    sortable: false,
                    width: '160',
                },

                { text: 'Name', value: 'name', width: '200' },
                {
                    text: 'Aktionen',
                    value: 'controls',
                    sortable: false,
                    width: '100',
                },
                {
                    text: 'Eintragstyp',
                    value: 'type',
                    sortable: true,
                    width: '200',
                },
                {
                    text: 'Template',
                    value: 'templateID',
                    sortable: true,
                    width: '200',
                },
                {
                    text: 'Status',
                    value: 'status',
                    sortable: false,
                    width: '200',
                },
            ];
        },
        tableData: function () {
            return this.allEntries.map((entry) => {
                return {
                    ...entry,
                    name: TranslationService.getValue(
                        entry.content?.name,
                        'de-DE'
                    ),
                    languages:
                        entry.languages?.length > 0
                            ? entry.languages
                            : [{ code: 'de-DE' }],
                    status: EntryStatusService.getTranslation(entry.status),
                };
            });
        },
        statusStats: function () {
            const statusList = EntryStatusService.getStatusArray();

            let statusLabels = [];
            let series = statusList.map((status) => {
                let count = this.allEntries.filter(
                    (entry) => entry.status === status.value
                ).length;
                if (count > 0)
                    statusLabels.push({
                        value: status.value,
                        count: count,
                    });
                return count;
            });

            this.setLabels(statusLabels);

            return series.filter(Boolean);
        },
    },
    methods: {
        ...mapActions('entries', ['createEntry', 'fetchEntriesByCatalog']),
        ...mapActions(['fetchCurrentCatalog']),
        handleEntrySave() {
            this.checkSortDefinition();
        },
        deleteSort() {
            let catalog = this.currentCatalog;
            let index = catalog.sortDefinitions?.findIndex(
                (d) =>
                    d.id.toString() === this.$router.currentRoute.params.sortID
            );
            catalog.sortDefinitions?.splice(index, 1);
            this.updateCatalog(catalog).then(() => {
                this.snackbar = true;
                this.$router.push({
                    name: 'Catalog',
                });
            });
        },
        save() {
            this.saving = true;
            let catalog = this.currentCatalog;
            let index = catalog.sortDefinitions?.findIndex(
                (d) =>
                    d.id.toString() === this.$router.currentRoute.params.sortID
            );

            console.log(this.sortDefinition.length);

            if (index === -1) {
                if (!catalog.sortDefinitions) {
                    catalog.sortDefinitions = [];
                }
                catalog.sortDefinitions.push({
                    definition: this.sortDefinition,
                    name: this.name || 'Neue Sortierung',
                    id: this.$router.currentRoute.params.sortID,
                });
            } else {
                console.log('index', index);
                catalog.sortDefinitions[index] = {
                    definition: this.sortDefinition,
                    name: this.name,
                    id: this.$router.currentRoute.params.sortID,
                };
            }
            console.log(catalog.sortDefinitions[index]);
            this.$store
                .dispatch('updateCatalog', catalog)
                .then(() => {
                    this.snackbar = true;
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        generateSortID() {
            return uuidv4();
        },
        /**
         * generate object to be cloned to sort definition
         * @param item
         * @returns {{children: *[], name: (*|string), _id: string, lang}}
         */
        cloneLanguage(item) {
            console.log('drag-item: ', item);
            let code = item?.code || 'de-DE';
            let entry = item?.entry;
            return {
                _id: entry._id.toString(),
                lang: code || 'de-DE',
                id: this.generateSortID(), // used to tie built documents to exactly this entry "reference"
                templateID: entry.templateID,
                name: TranslationService.getValue(
                    entry.content?.name,
                    code || 'de-DE'
                ),
                printStatus: {
                    built: false,
                    buildTime: null,
                    buildChildren: [],
                    buildHash: '',
                    buildPaths: [],
                },
                page: {},
                children: [],
            };
        },
        flatten(sortDef) {
            let result = [sortDef];
            if (sortDef.children.length > 0) {
                for (let child of sortDef.children) {
                    result = result.concat(this.flatten(child));
                }
            }
            return result;
        },
        async downloadExport(options) {
            this.downloadingXML = true;
            const catalogID = this.$router.currentRoute.params.catalogID;
            DownloadService.downloadWithAxios(
                `/api/export/catalog/${catalogID}?format=xml&sortBy=${
                    this.$router.currentRoute.params.sortID
                }${options.onlyConfirmed ? '&statusPolicy=confirmed' : ''}`,
                'Katalog.xml'
            ).then(() => {
                this.downloadingXML = false;
            });
        },
        generateCometCatalog() {
            this.checkSortDefinition();

            if (this.printBlockers.length > 0) {
                this.printBlockersDialog = true;
                return;
            }

            this.loadingCometCatalog = true;

            axios
                .post('/api/comet/catalog', {
                    catalogID: this.currentCatalog._id.toString(),
                    options: {
                        languages:
                            this.$settings.getValue('language.available') || [],
                        sortBy: this.$router.currentRoute.params.sortID,
                        sortLevels: 1,
                    },
                })
                .then((res) => {
                    const title = 'Der Katalog wird nun generiert...';
                    const text =
                        'Sie erhalten eine E-Mail, wenn die Generierung abgeschlossen ist.';
                    this.$notify({
                        group: 'main',
                        type: 'info',
                        duration: 10000,
                        title,
                        text,
                    });
                })
                .catch(() => {
                    const title =
                        'Der Katalog konnte nicht generiert werden...';
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        duration: -1,
                        title,
                    });
                })
                .finally(() => {
                    this.loadingCometCatalog = false;
                });
        },
        setLabels(labels) {
            const statusList = EntryStatusService.getStatusArray();
            // map to label and color
            this.labels = labels.map(
                (x) =>
                    statusList.find((y) => y.value === x.value)?.label +
                    ` (${x.count})`
            );
            this.chartStatus = labels;
            this.labelColors = labels.map(
                (x) => statusList.find((y) => y.value === x.value)?.color
            );
        },
        generateBuildHash(sortItem) {
            let hashes = [];
            let entry = this.$store.getters['entries/allEntries'].find(
                (e) => e._id.toString() === sortItem._id
            );
            if (entry) {
                hashes.push(md5(JSON.stringify(entry)));
                for (const item of sortItem.children) {
                    hashes.push(this.generateBuildHash(item));
                }
            }
            return md5(JSON.stringify(hashes));
        },
        checkSortDefinition() {
            this.printBlockers = [];

            console.log('schecking');

            let pageProgress = 0;

            for (let sortDefinition of this.sortDefinition || []) {
                sortDefinition = this.checkSortItem(
                    sortDefinition,
                    0,
                    pageProgress
                );
                pageProgress += sortDefinition.page?.progress || 0;
            }

            console.log();
        },
        checkSortItem(item, level, pageProgress = 0) {
            if (!item.id) {
                item.id = this.generateSortID();
            }

            // get linked entry
            let entry = this.$store.getters['entries/allEntries']?.find(
                (e) => e._id === item._id
            );

            // update template
            item.templateID = entry?.templateID;
            item.name =
                TranslationService.getValue(entry?.content?.name, item.lang) ||
                '???';

            let template =
                this.$store.getters['templates/getAll'].find(
                    (t) => t._id.toString() === item.templateID
                ) || {};

            item.page = {
                progress:
                    template.size?.numerator ??
                    1 / (template.size?.denominator || 1),
                firstPage:
                    Math.floor(pageProgress) +
                    parseInt(
                        this.$settings.getValue(
                            'cometConnection.firstPageWithEntries'
                        )
                    ),
            };

            console.log(
                'hash ',
                item.currentHash,
                ' -> ',
                this.generateBuildHash(item)
            );

            item.currentHash = this.generateBuildHash(item);

            if (
                level === 0 &&
                item.currentHash !== item.printStatus?.buildHash
            ) {
                this.printBlockers.push({
                    name: item.name || '???',
                    item,
                    reasonType: 'not_rendered',
                    reason: 'Keine aktuelle Freigabe generiert.',
                });
            }

            return {
                ...item,
                children:
                    item.children.length > 0
                        ? item.children.map((child) =>
                              this.checkSortItem(child, level + 1)
                          )
                        : [],
            };
        },
    },
    watch: {
        sortDefinition: {
            handler() {
                if (
                    this.lengthOfSortDefinition !== this.sortDefinition.length
                ) {
                    this.checkSortDefinition();
                    this.lengthOfSortDefinition = this.sortDefinition.length;
                }
            },
            deep: true,
        },
    },
    created() {
        this.fetchCurrentCatalog(
            this.$router.currentRoute.params.catalogID
        ).then(() => {
            this.fetchEntriesByCatalog(
                this.$router.currentRoute.params.catalogID
            ).then(() => {
                this.sortDefinition =
                    this.currentCatalog.sortDefinitions?.find(
                        (c) => c.id === this.$router.currentRoute.params.sortID
                    )?.definition || [];
                this.checkSortDefinition();
                this.name = this.currentCatalog.sortDefinitions?.find(
                    (c) => c.id === this.$router.currentRoute.params.sortID
                )?.name;
            });
        });
        this.$store.dispatch('templates/fetchAll');
        this.$store.dispatch('entrytypes/fetchAll');
    },
};
</script>

<style>
.sortable-ghost {
    height: 5px !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: dodgerblue !important;
    overflow: hidden;
}
.sortable-ghost * {
    background: transparent !important;
    margin: 0 !important;
}

.sort-sheet {
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    overscroll-behavior: contain;
    transition: width 300ms ease-in-out, height 300ms ease-in-out;
}

.sort-ui {
    transition: width 300ms ease-in-out, height 300ms ease-in-out;
}

.sort-ui.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: lightgrey;
    z-index: 20;
    margin: 0 !important;
}

.fullscreen .sort-sheet {
    max-height: calc(100vh - 30px);
}
</style>
