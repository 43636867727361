<template>
    <div>


        <v-card class="overflow-y-auto" style="max-height: 80vh;">

            <v-text-field
                          v-model="search"
                          prepend-icon="search"
                          class="ml-5"
            ></v-text-field>

            <v-list
                subheader
            >
                <v-list-item-group v-if="groups.length > 0">

                    <v-list-item
                        v-for="group in groups"
                        :key="group._id"
                        router :to="{
                            name: 'AdminGroupEdit',
                            params: {
                                groupID: group._id,
                            }
                        }"
                    >
                        <v-list-item-avatar>
                            <v-icon>
                                group
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="group.name"></v-list-item-title>
                            <v-list-item-subtitle>{{ (group.users || []).length }} Mitglied{{ (group.users || []).length !== 1 ? 'er' : '' }}</v-list-item-subtitle>
                        </v-list-item-content>


                    </v-list-item>

                </v-list-item-group>

            </v-list>

        </v-card>

    </div>
</template>

<script>

    import _ from "lodash";
    import Chat from "../utility/Chat";
    import axios from "axios";
    import UserService from "../../services/UserService";


    export default {
        name: 'GroupList',
        components: {

        },
        computed: {
            groups: function() {
                return this.$store.getters['groups/getAll'].filter((r) => {
                    return r.name?.toLowerCase().includes(this.search.toLowerCase()) ?? true;
                }).sort((a, b) => a.name?.localeCompare(b.name)) || [];
            }
        },
        methods: {

        },
        data() {
            return {
                search: "",
            }
        },
        created() {
            this.$store.dispatch('groups/fetchGroups');
        }
    }
</script>
