<template>

    <div>

        <p class="text-h6 mb-4 grey--text">Eintragstypen</p>

        <v-container class="my-5">

            <div class="my-5 text-center" v-if="getAll.length === 0">
                <v-icon size="128" class="mb-4 grey--text">category</v-icon>
                <p class="text-h4 my-5">Noch keine Eintragstypen.</p>
            </div>

            <v-card flat tile class="mb-4" v-if="getAll.length > 0">


                <template v-for="(item, index) in getAll">
                    <v-list-item :key="item._id" :index="item._id">
                        <template>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.name }}
                                    <span v-if="item.disableIXEdit" class="text-caption warning--text">
                                        <v-icon class="ml-4" size="18">info</v-icon>
                                        IXEdit deaktiviert
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <div>
                                    <v-btn fab depressed class="ml-3" light color="lightgray" small router :to="item._id" append>
                                        <v-icon color="black">
                                            edit
                                        </v-icon>
                                    </v-btn>
                                    <v-btn fab depressed class="ml-3" light color="info" small @click="duplicateItem(item)">
                                        <v-icon color="white">
                                            content_copy
                                        </v-icon>
                                    </v-btn>
                                    <DeleteConfirmation @yes="removeItem(item._id)"/>
                                </div>

                            </v-list-item-action>
                        </template>
                    </v-list-item>

                    <v-divider v-if="index < getAll.length - 1" :key="index"></v-divider>
                </template>


            </v-card>

        </v-container>

    </div>

</template>

<script>

import Vue from 'vue';

import { mapGetters, mapActions } from 'vuex';
import DeleteConfirmation from "../utility/DeleteConfirmation";

export default {
    name: 'EntryTypeList',
    components: {
        DeleteConfirmation
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters("entrytypes", ['getAll']),
    },
    methods: {
        ...mapActions("entrytypes", ['add', 'remove', 'fetchAll']),
        removeItem(id) {
            this.remove(id);
        },
        async duplicateItem(item) {

            item['name'] += ' (Kopie)';
            delete item['_id'];

            await this.add({
                projectID: this.$router.currentRoute.params.projectID,
                channelID: this.$router.currentRoute.params.channelID,
                ...item
            });

        }
    },
    created() {
        this.fetchAll({
            projectID: this.$router.currentRoute.params.projectID,
            channelID: this.$router.currentRoute.params.channelID
        });
    }
}
</script>

<style scoped>
.content {
    padding: 30px;
    margin: 30px;
}
</style>
