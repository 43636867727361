import Dashboard from "../../views/Admin/Dashboard";
import PlainRouterViewWithBack from "../../views/PlainRouterViewWithBack";
import PlainRouterView from "../../views/PlainRouterView";
import MailTemplates from "../../views/PrintSuite/MailTemplates";
import Users from "../../views/Admin/users/Users";
import Settings from "../../views/Admin/Settings";
import Roles from "../../views/Admin/roles/Roles";
import Groups from "../../views/Admin/groups/Groups";
import UserEdit from "../../views/Admin/users/UserEdit";
import Entities from "@/views/Graph/Entities";


const routes = [
    {
        path: "",
        name: "GraphEntities",
        component: Entities,
    },
    {
        path: "users",
        name: "AdminUsers",
        component: PlainRouterView,
        children: [
            {
                path: "",
                name: "AdminUsersList",
                component: Users,
            },
            {
                path: ":userID",
                name: "AdminUserEdit",
                component: UserEdit,
            },
        ]
    },
    {
        path: "roles",
        name: "AdminRoles",
        component: Roles,
    },
    {
        path: "groups",
        name: "AdminGroups",
        component: Groups,
    },
    {
        path: "settings",
        name: "AdminSettings",
        component: Settings,
    },
];

export default routes;
