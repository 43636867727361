<template>

    <div>

        <sort-definition-nested @move="move" @entrySave='$emit("entrySave")' :items="sortDefinition" @save="save" :hide-templates="hideTemplates"></sort-definition-nested>

    </div>


</template>

<script>

import vue from 'vue';
import SortDefinitionNested from "../../components/catalogs/SortDefinitionNested";
import DeleteConfirmation from "../utility/DeleteConfirmation";

export default {
    name: 'SortDefinition',
    components: {
        SortDefinitionNested
    },
    props: [
        'sortDefinition',
        'hideTemplates'
    ],
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        move() {
            console.log("moving")
            this.$emit('move');
        },
        save() {
            this.$emit('save');
        },
        /**
         * clears sort definition
         */
        clearAll() {
            //this.$emit('input', []);
        },
    },
    created() {

    }
}
</script>

<style scoped>

</style>
