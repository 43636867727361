<script src='../../../../server/src/models/mongoose/Entry.js'></script>
<template>

    <div>
        <v-select label='Anrede'
                  style='max-width: 250px'
                  v-model='contact.salutation'
                  :items='salutations'></v-select>
    
        <v-row>
            <v-col>
                <v-text-field label='Nachname' v-model='contact.lastName'>
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field label='Vorname' v-model='contact.firstName'>
                </v-text-field>
            </v-col>
        </v-row>
    
        <v-text-field label='Firma/Organisation' v-model='contact.organization' prepend-inner-icon='business'
        ></v-text-field>
    
        <v-text-field label='E-Mail' v-model='contact.email' prepend-inner-icon='mail'
        ></v-text-field>
    
        <v-text-field label='Telefon' v-model='contact.phone' prepend-inner-icon='phone'
        ></v-text-field>
    
        <p class='caption mt-8 font-weight-bold'>Adresse:</p>
        
        <v-row>
            <v-col cols='12' class='py-0'>
                <v-text-field label='Straße und Hausnummer' v-model='contact.address.street'
                ></v-text-field>
            </v-col>
            <v-col cols='12' md='3' lg='2' class='py-0'>
                <v-text-field label='PLZ' v-model='contact.address.zip'
                ></v-text-field>
            </v-col>
            <v-col cols='12' md='9' lg='5' class='py-0'>
                <v-text-field label='Ort' v-model='contact.address.city'
                ></v-text-field>
            </v-col>
            <v-col cols='12' md='6' lg='5' class='py-0'>
                <v-text-field label='Land' v-model='contact.address.country'
                ></v-text-field>
            </v-col>
        </v-row>
        
        
        <p class='caption mt-8 font-weight-bold'>Rechnungsinfos:</p>
        
        <v-text-field label='Bank' v-model='contact.bank'
        ></v-text-field>
    
        <v-text-field label='IBAN' :rules='isIBAN' v-model='contact.iban'
        ></v-text-field>
    
        <v-text-field label='BIC' v-model='contact.bic'
        ></v-text-field>
        
    </div>
    
</template>

<script>
import iban from 'iban';
import ContactUtils from '../mixins/contact-utils';

export default {
    name: 'ContactEditForm',
    mixins: [
        ContactUtils
    ],
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    salutation: "",
                    organization: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    address: {
                        street: "",
                        zip: "",
                        city: "",
                        country: "",
                    }
                }
            }
        },
    },
    computed: {
        isIBAN: function() {
            return [
                v => (iban.isValid(v) || !v) ||
                    'Inkorrekte IBAN'
            ]
        },
        contact: {
            get() {
                return this.value;
                return _.defaultsDeep(this.value, {
                    salutation: "",
                    organization: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    address: {
                        street: "",
                        zip: "",
                        city: "",
                        country: "",
                    }
                });
            },
            set(val) {
                console.log('set')
                this.$emit('input', val);
            }
        }
    },
    watch: {
        contact: {
            handler(val) {
                console.log('watch')
                this.$emit('input', val);
            },
            deep: true
        }
    }
};
</script>

<style scoped>

</style>
