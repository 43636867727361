<template>

  <div class="px-5" style="position: relative">

    <template v-if="isSyncedMode">
      <!-- knowledge graph fields background -->
      <div class="kg-background secondary "></div>
      <!-- headlines -->
      <v-row class="mb-5 mt-4">
        <v-col :cols="6">
          <h3 class="text-h4 font-weight-bold">Publication Planner</h3>
        </v-col>
        <v-col :cols="6" style="z-index: 1">
          <h3 class="text-h4 font-weight-bold ml-5">Knowledge Graph</h3>
        </v-col>
      </v-row>
    </template>


    <v-row v-for="(component, index) in definition" :key="index" style="margin: 20px 0;">


      <template v-if="typeof component !== 'undefined' && !component.hidden">

        <v-col style="z-index: 1;" :cols="isSyncedMode ? 6 : 12"
               :class="(!isMultilang(component) && hideNonMultilang) ? 'component-hidden' : ''">

          <div v-if="(!isMultilang(component) && hideNonMultilang) ? 'component-hidden' : ''">

          </div>

          <!-- PP Components -->

          <!-- input -->
          <template v-if="component.type === 'input'">
            <InputComponent :language="language" v-model="content[component.name]" :is-locked="isLocked"
                            :definition="component" :ref="component.name"/>
          </template>

          <!-- number -->
          <template v-else-if="component.type === 'number'">
            <NumberComponent :language="language" v-model="content[component.name]" :ref="component.name"
                             :definition="component" :is-locked="isLocked"/>
          </template>

          <!-- boolean -->
          <template v-else-if="component.type === 'boolean'">
            <BooleanComponent :language="language" v-model="content[component.name]" :ref="component.name"
                              :definition="component" :is-locked="isLocked"/>
          </template>

          <!-- textarea -->
          <template v-else-if="component.type === 'textarea'">
            <TextareaComponent :language="language" v-model="content[component.name]" :ref="component.name"
                               :definition="component" :is-locked="isLocked"/>
          </template>

          <!-- enumeration -->
          <template v-else-if="component.type === 'enumeration'">
            <EnumerationComponent :language="language" v-model="content[component.name]" :ref="component.name"
                                  :definition="component" :is-locked="isLocked"/>
          </template>

          <!-- address -->
          <template v-else-if="component.type === 'address'">
            <AddressComponent :language="language" v-model="content[component.name]" :is-locked="isLocked"
                              :definition="component" :ref="component.name"/>
          </template>

          <!-- table -->
          <!-- the following would link entities again -->
          <!--:entities="entityContent[component.linkName]"
                              @updateEntities="entityContent = { ...entityContent, [component.linkName]: $event }"
                              @saveEntity="saveEntity"
                               :synced-mode="syncedMode && component.linkName"
                               -->
          <template v-else-if="component.type === 'table'">
            <TableComponent :language="language"
                            :columns="columns"
                            v-model="content[component.name]"
                            :definition="component"
                            :ref="component.name"
                            :synced-mode="false"
                            :is-locked="isLocked"/>
          </template>


          <!-- NO DATA COMPONENTS -->

          <!-- trail editor -->
          <template v-else-if="component.type === 'trailEditor'">
            <TrailEditorComponent :language="language" :definition="component" :entry="entry" :is-locked="isLocked"
                                  @importItinerary="$emit('importItinerary')"/>
          </template>

          <!-- headline -->
          <template v-else-if="component.type === 'headline'">
            <HeadlineComponent :language="language" :definition="component"/>
          </template>

          <!-- divider -->
          <template v-else-if="component.type === 'divider'">
            <DividerComponent :language="language" :definition="component"/>
          </template>

          <!-- info -->
          <template v-else-if="component.type === 'info'">
            <InfoComponent :language="language" :definition="component"/>
          </template>

          <!-- unknown component -->
          <template v-else>
            <LabelComponent :definition="component"/>
            <p class="my-4 error--text">
              Keine Render-Definition für {{ component.type }}
            </p>
          </template>

        </v-col>

        <!-- SYNC BUTTONS -->
        <SyncButtons
            v-if="isSyncedMode && component.linkName && component.type !== 'table' && component.linkName !== 'types'"
            v-model="content[component.name]"
            :entry-definition="component"
            :entity-definition="$store.getters['properties/getAll'].find(p => p.name === component.linkName)"
            :entity-content="entityContent[component.linkName]"
            @updateEntity="entityContent = { ...entityContent, [component.linkName]: $event }"
            @saveEntity="saveEntity"
        ></SyncButtons>


        <!-- CP components -->
        <transition name="fade" v-if="isSyncedMode">

          <v-col v-if="$store.getters['properties/getStatus'] === 'loading' && component.linkName">
            <v-progress-linear class="my-auto" indeterminate></v-progress-linear>
          </v-col>

          <v-col v-if="component.linkName && $store.getters['properties/getStatus'] !== 'loading'" style="z-index: 1;">

            <v-sheet dark color="transparent">

              <!-- Text -->
              <template v-if="getCockpitType(component.linkName) === 'Text'">
                <CockpitTextComponent :value="entityContent[component.linkName]"
                                      @input="entityContent = { ...entityContent, [component.linkName]: $event }"
                                      :ref="`cockpit__${component.name}`"
                                      :definition="component"
                />
              </template>

              <!-- String -->
              <template v-else-if="getCockpitType(component.linkName) === 'String'">
                <CockpitStringComponent :value="entityContent[component.linkName]"
                                        @input="entityContent = { ...entityContent, [component.linkName]: $event }"
                                        :ref="`cockpit__${component.name}`"
                                        :definition="component"
                />
              </template>

              <!-- Enum -->
              <template v-else-if="getCockpitType(component.linkName) === 'Enum'">
                <CockpitEnumerationComponent :value="entityContent[component.linkName]"
                                             @input="entityContent = { ...entityContent, [component.linkName]: $event }"
                                             :ref="`cockpit__${component.name}`"
                                             :definition="component"
                />
              </template>

              <!-- Number -->
              <template
                  v-else-if="getCockpitType(component.linkName) === 'Integer' ||  getCockpitType(component.linkName) === 'Float'">
                <CockpitNumberComponent :value="entityContent[component.linkName]"
                                        @input="entityContent = { ...entityContent, [component.linkName]: $event }"
                                        :ref="`cockpit__${component.name}`"
                                        :definition="component"
                />
              </template>

              <!-- Number -->
              <template v-else-if="getCockpitType(component.linkName) === 'Boolean'">
                <CockpitBooleanComponent :value="entityContent[component.linkName]"
                                         @input="entityContent = { ...entityContent, [component.linkName]: $event }"
                                         :ref="`cockpit__${component.name}`"
                                         :definition="component"
                />
              </template>

              <!-- Address -->
              <template
                  v-else-if="getCockpitType(component.linkName) === 'ENTITYLINK' && component.linkName === 'address'">
                <CockpitAddressComponent :value="entityContent[component.linkName]"
                                         @input="entityContent = { ...entityContent, [component.linkName]: $event }"
                                         :ref="`cockpit__${component.name}`"
                                         :definition="component"
                />
              </template>

              <!-- entity link (dont show -> handled in entry table)  -->
              <template v-else-if="getCockpitType(component.linkName) === 'ENTITYLINK'">
                <v-alert type="info" class="ml-5 mt-5">
                  Tabellen können nicht live gesynct werden, sie können aber am Ende der Produktion in den
                  Graph-Datenbestand geschrieben werden. Wenden Sie sich dazu an Ihren Projektleiter!
                </v-alert>
              </template>

              <template v-else-if="component.linkName === 'types'">
                <v-alert type="info" class="ml-5 mt-5">
                  Types können nur direkt im Cockpit ergänzt/entfernt werden.
                </v-alert>
              </template>

              <!-- unknown component -->
              <template v-else>
                <CockpitLabelComponent :definition="component"/>
                <p class="my-4 error--text">
                  Keine Render-Definition für {{ getCockpitType(component.linkName) }}
                </p>
              </template>

            </v-sheet>

          </v-col>
          <!--v-col v-else :cols="3">

          </v-col-->
        </transition>


      </template>

    </v-row>

  </div>

</template>

<script>

import _ from 'lodash';
import InputComponent from "./formComponents/InputComponent";
import LabelComponent from "./formComponents/LabelComponent";
import HeadlineComponent from "./formComponents/style-components/HeadlineComponent";
import TextareaComponent from "./formComponents/TextareaComponent";
import AddressComponent from "./formComponents/AddressComponent";
import EnumerationComponent from "./formComponents/EnumerationComponent";
import DividerComponent from "./formComponents/style-components/DividerComponent";
import InfoComponent from "./formComponents/style-components/InfoComponent";
import NumberComponent from "./formComponents/NumberComponent";
import TrailEditorComponent from "./formComponents/style-components/TrailEditorComponent";
import BooleanComponent from "@/components/entries/formComponents/BooleanComponent";
import CockpitInputComponent from "@/components/entries/cockpitFormCompontents/CockpitTextComponent";
import CockpitLabelComponent
  from "@/components/entries/cockpitFormCompontents/style-components/CockpitLabelComponent";
import CockpitStringComponent from "@/components/entries/cockpitFormCompontents/CockpitStringComponent";
import TableComponent from "@/components/entries/formComponents/TableComponent";
import CockpitAddressComponent from "@/components/entries/cockpitFormCompontents/CockpitAddressComponent";
import CockpitEnumerationComponent from "@/components/entries/cockpitFormCompontents/CockpitEnumerationComponent";
import CockpitTextComponent from "@/components/entries/cockpitFormCompontents/CockpitTextComponent";
import CockpitNumberComponent from "@/components/entries/cockpitFormCompontents/CockpitNumberComponent";
import SyncButtons from "@/components/entries/formComponents/SyncButtons";
import CockpitBooleanComponent from "@/components/entries/cockpitFormCompontents/CockpitBooleanComponent";

export default {
  name: "EntryEditForm",
  components: {
    CockpitBooleanComponent,
    SyncButtons,
    CockpitNumberComponent,
    CockpitTextComponent,
    CockpitEnumerationComponent,
    CockpitAddressComponent,
    CockpitStringComponent,
    CockpitLabelComponent,

    TableComponent,

    BooleanComponent,
    TrailEditorComponent,
    NumberComponent,
    InfoComponent,
    DividerComponent,
    EnumerationComponent,
    AddressComponent,
    TextareaComponent,
    HeadlineComponent,
    LabelComponent,
    InputComponent
  },
  props: [
    "value",
    "entity",
    "definition",
    "isLocked",
    "syncedMode",
    "entry",
    "language",
    "columns",
    "hideNonMultilang"
  ],
  data() {
    return {
      content: this.value || {},
    }
  },
  watch: {
    content: {
      handler() {
        this.$emit('input', this.content);
      },
      deep: true
    },
    entityContent: {
      handler() {
        console.log("entity changed", this.entityContent);
      },
      deep: true
    },
  },
  computed: {
    isSyncedMode: function () {
      console.log("what?", this.entity)
      return Boolean(this.syncedMode && this.entity && this.entry.parentID);
    },
    languages: function () {
      // TODO: change to new one
      return this.$store.getters['general/language'];
      //return LangHelper.getEnumFriendlyItems(this.$store.getters['general/language']);
    },
    flag: function () {
      // TODO: change to new one
      return this.$store.getters['general/language'];
      // return LangHelper.getFlagByLang(this.$store.getters['general/language']);
    },
    entityContent: {
      get() {
        return this.entity || {}
      },
      set(value) {
        console.log("Entity updated in EntryEditForm");
        this.$emit('updateEntity', value)
      },
    }
  },
  methods: {
    getCockpitType(linkName) {
      return this.$store.getters['properties/getAll']?.find(p => p.name === linkName)?.original_type || '';
    },
    saveEntity() {
      this.$emit('saveEntity');
    },
    isMultilang(component) {
      if (component.type === 'input' || component.type === 'textarea') {
        return true;
      }
    }
  },
  created() {

  },
  beforeCreate: function () {
    this.$options.components.TableComponent = require('./formComponents/TableComponent.vue').default
  },
}
</script>

<style scoped lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.kg-background {
  position: absolute;
  top: 63px;
  height: calc(100% - 43px);
  width: calc(50%);
  left: calc(50%);

  opacity: 1;

  //background-image: url("https://c2.we2p.de/cockpit/assets/img/icon/we2p_logo_rgb.png");
  //background-repeat: repeat;
}

.component-hidden {
  opacity: 0;
  pointer-events: none;
}

</style>
