import axios from 'axios';
import router from '../../router';

const endpoint = '/api/v2/system/config';

const store = {
    state: {
        status: '',
        config: {},
    },

    getters: {
        getStatus: (state) => state.status,
        getConfig: (state) => state.config,
    },

    actions: {
        async fetch({ commit }) {
            return new Promise((resolve, reject) => {
                commit('config_request');
                axios
                    .get(`${endpoint}`)
                    .then((res) => {
                        if (res.data) {
                            commit('config_success', {
                                config: res.data || {},
                            });
                            resolve(res);
                        } else {
                            console.log(res);
                            commit('config_error');
                            reject('no_data');
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                        commit('config_error');
                        reject('response_error');
                    });
            });
        },
    },

    mutations: {
        config_request(state) {
            state.tatus = 'loading';
        },
        config_success(state, { config }) {
            state.status = 'success';
            state.config = config;
        },
        config_error(state) {
            state.status = 'error';
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
