const authAxios = require('axios');
const plainAxios = require('axios').create();
const Vue = require('vue');

class DownloadService {

    static forceFileDownload(response, title) {

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click();

        return true;

    }

    static async downloadWithAxios(url, title, authenticated = false) {

        // if authentication is needed, we use the default axios (authAxios)
        // otherwise a newly created axios instance is used
        let axios = authenticated ? authAxios : plainAxios;

        await axios({
            method: "get",
            url,
            responseType: 'arraybuffer',
        }).then((response) => {
            this.forceFileDownload(response, title)
        }).catch((e) => {
            alert("Download fehlgeschlagen!")
            console.log('failed to download file', e)
        })
    }

}

export default DownloadService;