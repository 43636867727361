<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="800"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="white--text" small depressed fab dark color="grey">
                    <v-icon>content_copy</v-icon>
                </v-btn>
            </template>
            <v-card>

                <v-toolbar elevation="0">
                    <v-toolbar-title class="text-h5 py-5">
                        Auflage duplizieren
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small text fab @click="dialog = false" color="error">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>



                <v-card-text v-if="!loading">
                    <v-text-field :placeholder="catalog.name + ' (Kopie)'" label="Name der neuen Auflage" v-model="newCatalogName"></v-text-field>

                    Alle Einträge in der aktuellen Auflage werden in die neue Auflage kopiert.

                    <StatusRuleBuilder v-if="false">
                        <template v-slot:default="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" small depressed color="info">
                               Status-Regeln ergänzen
                            </v-btn>
                        </template>
                    </StatusRuleBuilder>

                </v-card-text>

                <v-card-text v-if="loading">

                    <p class="my-8">Die Auflage wird dupliziert. <br>Je nach Anzahl der Einträge und Bilder kann dieser Vorgang etwas länger dauern.</p>

                    <v-progress-linear indeterminate></v-progress-linear>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="this.loading"
                        color="primary"
                        text
                        @click="clone(catalog)"
                    >
                        Duplizieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import StatusRuleBuilder from "./StatusRuleBuilder";
export default {
    name: "DuplicateCatalog",
    components: {StatusRuleBuilder},
    data() {
        return {
            dialog: false,
            loading: false,
            newCatalogName: "",
        };
    },
    props: {
        catalog: {
            type: Object,
            required: true,
        },
    },
    methods: {
        clone(catalog) {
            this.loading = true;
            this.$store.dispatch("cloneCatalog", {
                catalogId: catalog._id.toString(),
                newName: this.newCatalogName || `${catalog.name} (Kopie)`,
            }).finally(() => {
                this.loading = false;
                this.dialog = false;
            });
        },
    },
}
</script>

<style scoped>

</style>
