<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-text-field dense filled
                      hide-details="auto"
                      :readonly="isLocked"
                      type="number"
                      :value="value"
                      @input="change($event)"
        ></v-text-field>
    </div>
</template>

<script>

    import _ from 'lodash';
    import TranslationService from "../../../services/TranslationService";
    import LabelComponent from "./style-components/CockpitLabelComponent";

    import FormComponentMixin from "../../mixins/form-component";

    export default {
        name: "CockpitNumberComponent",
        components: {
            LabelComponent
        },
        mixins: [
            FormComponentMixin
        ],
        props: [
            'value',
            'definition',
            'isLocked'
        ],
        data() {
            return {

            }
        },
        computed: {

        },
        methods: {
            change(event) {
                let number = Number.parseFloat(event);
                if (Number.isNaN(number)) {
                    number = null;
                }
                this.$emit('input', number);
            },
            async save() {

                let number = Number.parseFloat(this.value);
                if (Number.isNaN(number)) {
                    number = null;
                }
                return number;

            }
        }
    }
</script>

<style scoped>

</style>
