<template>
    <div class="mb-2">

        <v-row class="mx-0 my-2">

            <!-- LABEL -->
            <p class="ma-0">
                <span class="mr-3 text-h6">{{ definition.label }}</span>
                <span class="mr-3 orange--text text-body-2" v-if="$permGuard.hasPermission('super_admin')">&lt;{{ definition.name }}&gt;</span>
            </p>
            <v-tooltip top v-if="(definition.validators || []).includes('required')">
                <template v-slot:activator="{ on, attrs }">
                    <span text class="mr-3" v-bind="attrs" v-on="on">
                        *
                    </span>
                </template>
                <span>Pflichtfeld</span>
            </v-tooltip>
            <v-tooltip top v-if="isLocked">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-3"
                            color="warning"
                            v-bind="attrs"
                            v-on="on"
                    >
                        lock
                    </v-icon>
                </template>
                <span>Datensatz ist gesperrt.</span>
            </v-tooltip>

            <v-dialog
                    v-if="definition.description"
                    v-model="dialog"
                    width="500"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text icon small v-bind="attrs" v-on="on" class="mr-3">
                        <v-icon>
                            info
                        </v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline white--text secondary">
                        <v-icon dark class="mr-4">
                            info
                        </v-icon>
                        {{ definition.label }}
                    </v-card-title>

                    <v-card-text>
                        <div class="pt-5">
                            <div>{{ definition.description }}</div>
                        </div>
                    </v-card-text>

                    <v-divider/>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                        >
                            OK
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>

            </v-dialog>

            <!-- LINK INFO -->
            <v-spacer></v-spacer>
            <v-chip class="ml-0 text-caption" v-if="definition.linkName" small rounded depressed>
                <span class="mx-2">{{ definition.linkName }}</span>
                <v-icon size="20" color="grey">
                    link
                </v-icon>
                <!--v-icon size="15">
                    {{ getLinkLockIcon(definition.linkLock) }}
                </v-icon-->
            </v-chip>

        </v-row>
    </div>
</template>

<script>

    export default {
        name: "LabelComponent",
        props: [
            'definition',
            'isLocked',
        ],
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            getRandomInt(max) {
                return Math.floor(Math.random() * Math.floor(max));
            },
            getLinkLockIcon(linkLock) {
                switch (linkLock) {
                    case 0:
                        return "sync";
                    case 1:
                        return "south";
                    case 2:
                        return "north";
                    case 3:
                        return "sync_disabled";
                    default:
                        return '';
                }
            }
        }
    }
</script>

<style scoped>

</style>
