<template>
  <div class="channels">
    <!--div class="my-5" v-if="project"-->
    <p class="text-h5">Channels</p>

    <v-row>
    </v-row>

    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab v-on="on" v-bind="attrs" fixed bottom right color="success">
          <v-icon>
            add
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Neuer Channel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab depressed text color="error" @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              text
              :loading="loading"
              @click="create"
          >
            Erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "Channels",
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    create() {

    }
  }
}
</script>

<style scoped>

</style>
