<template>

    <div class="my-auto ml-4 mr-3" v-if="$store.getters['properties/getStatus'] !== 'loading'" style="min-width: 45px;">
        <div class="text-center my-auto">
            <v-scroll-x-reverse-transition>
                <v-btn fab depressed small class="my-1" color="primary lighten-1" @click="pullFromGraph" v-if="linkStatus === 'both' || linkStatus === 'to_pp' || linkStatus === 'unknown'">
                    <v-icon color="black">west</v-icon>
                </v-btn>
            </v-scroll-x-reverse-transition>
        </div>
        <p class="text-center my-1">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        :color="linkStatusColor(linkStatus)"
                        dark
                        class="my-auto"
                        v-bind="attrs"
                        v-on="on"
                    >
                        link
                    </v-icon>
                </template>
                <span>{{ linkStatusTooltip(linkStatus) }}</span>
            </v-tooltip>
        </p>
        <div class="text-center my-auto">
            <v-scroll-x-transition>
                <v-btn fab depressed small class="my-1" color="primary lighten-1" @click="pushToGraph" v-if="linkStatus === 'both' || linkStatus === 'to_graph' || linkStatus === 'unknown'">
                    <v-icon color="black">east</v-icon>
                </v-btn>
            </v-scroll-x-transition>
        </div>
    </div>

</template>

<script>

import _ from 'lodash';
import TranslationService from "@/services/TranslationService";
import { htmlToText } from 'html-to-text';

export default {
    name: "SyncButtons",
    props: [
        "value",
        "entityContent",
        "entryDefinition",
        "entityDefinition"
    ],
    data() {
        return {

        }
    },
    asyncComputed: {
        linkStatus: {
            get() {
                return new Promise((resolve, reject) => {
                    this.convert(this.entryDefinition, this.entityDefinition, 'entry').then((res) => {
                        this.convert(this.entryDefinition, this.entityDefinition, 'entity').then((entityRes) => {

                            let entityContent = _.cloneDeep(this.entityContent);

                            // ckeditor will immediately alter the value with standardized html
                            // the following logic ensures, that this is ignored when comparing
                            if (this.entityDefinition.rich_text) {
                                entityContent = entityContent?.map((v) => {
                                    return {
                                        ...v,
                                        value: htmlToText(v.value).replaceAll('\n', '<br>')
                                    }
                                })
                            }

                            let graphToPP = !_.isEqual(res, this.value);
                            let ppToGraph = !_.isEqual(entityRes, entityContent);

                            console.log("need to be equal", entityRes, entityContent)

                            if (graphToPP && ppToGraph) {
                                if (this.entityDefinition?.rich_text) resolve("unknown");
                                resolve("both");
                            } else if (ppToGraph) {
                                if (this.entityDefinition?.rich_text) resolve("unknown");
                                resolve("to_graph");
                            } else if (graphToPP) {
                                if (this.entityDefinition?.rich_text) resolve("unknown");
                                resolve("to_pp");
                            } else {
                                resolve("none");
                            }

                        })
                    }).catch((e) => {
                        console.error(e);
                        resolve("error");
                    });
                })
            },
            default: "unknown",
        },
    },
    methods: {
        linkStatusTooltip(status) {
            switch (status) {
                case 'none':
                    return "Inhalte stimmen überein."
                case 'both':
                    return "Abweichung erkannt."
                case 'to_graph':
                    return "Abweichung erkannt [Publication Planner -> Graph]"
                case 'to_pp':
                    return "Abweichung erkannt [Graph -> Publication Planner]"
                case 'unknown':
                    return "Inhalte konnten nicht automatisch abgeglichen werden, bitte manuell prüfen."
                case 'error':
                    return "Kann nicht synchronisiert werden."
            }
        },
        linkStatusColor(status) {
            switch (status) {
                case 'both':
                case 'to_graph':
                case 'to_pp':
                    return "warning";
                case 'error':
                    return "error";
                case 'none':
                    return "success";
                default:
                    return "grey";
            }
        },
        async pushToGraph() {
            this.$emit('updateEntity', _.cloneDeep(await this.convert(this.entryDefinition, this.entityDefinition, 'entity')));
            this.$emit('saveEntity');
        },
        async pullFromGraph() {
            this.$emit('input', _.cloneDeep(await this.convert(this.entryDefinition, this.entityDefinition, 'entry')));

        },
        /**
         * converts single property from pp to graph or the other way around
         * @param entryDef
         * @param entityDef
         * @param target
         * @returns {Promise<unknown>}
         */
        convert(entryDef, entityDef, target) {
            return new Promise((resolve, reject) => {

                let entryValue = _.cloneDeep(this.value);
                let entityValue = _.cloneDeep(this.entityContent);

                if (target === 'entry') {
                    // to ENTRY value
                    if (['input', 'textarea'].includes(entryDef.type) && ['Text', 'String'].includes(entityDef?.original_type)) {
                        // TEXT - STRING
                        if (entityDef.multilanguage === 'true' /* yes, 'true' */) {
                            resolve(TranslationService.setValue(
                                entryValue,
                                htmlToText(TranslationService.getValue(entityValue, this.$store.getters['general/language'])),
                                this.$store.getters['general/language']
                            ));
                        } else {
                            resolve(TranslationService.setValue(
                                entryValue,
                                htmlToText(entityValue),
                                'de-DE'
                            ));
                        }
                    } else if (entryDef.type === 'number' && ['Integer', 'Float'].includes(entityDef?.original_type)) {
                        // INT & FLOAT
                        resolve(Number.parseInt(entityValue))
                    } else if(entryDef.type === 'address' && entityDef?.type === 'Address') {
                        // ADDRESS
                        resolve(entityValue)
                    } else if(entryDef.type === 'enumeration' && entryDef.config?.multiSelect && entityDef?.type === 'Tree') {
                        // ENUM (multi)
                        let valuesFromEntity = entityValue?.filter(v => entryDef.config?.values?.map(e => e.value).includes(v)) || [];
                        resolve(_.uniq(valuesFromEntity.concat(
                                (entryValue?.filter(v => !entityDef.values?.value?.map(eV => eV.label).includes(v)) || [])
                        )));
                        resolve(entityValue?.filter(v => entryDef.config?.values.map(e => e.value).includes(v)));
                    } else if(entryDef.type === 'enumeration' && !entryDef.config?.multiSelect && entityDef?.type === 'Enumeration') {
                        // ENUM
                        resolve(entryDef.config?.values?.find(v => entityValue?.[0] === v.value) ? [entityValue[0]] : entryValue)
                    }
                } else {
                    // to ENTITY value
                    if (['input', 'textarea'].includes(entryDef.type) && ['Text', 'String'].includes(entityDef?.original_type)) {
                        let lineBreakChar = entityDef?.rich_text ? "<br>" : " ";
                        if (entityDef.multilanguage === 'true' /* yes, 'true' */) {
                            resolve(TranslationService.setValue(
                                entityValue,
                                TranslationService.getValue(entryValue, this.$store.getters['general/language']).replaceAll('\n', lineBreakChar),
                                this.$store.getters['general/language']
                            ));
                        } else {
                            resolve(TranslationService.getValue(entryValue, 'de-DE').replaceAll('\n', lineBreakChar));
                        }
                    } else if (entryDef.type === 'number' && ['Integer', 'Float'].includes(entityDef?.original_type)) {
                        // INT & FLOAT
                        resolve(Number.parseInt(entryValue))
                    } else if(entryDef.type === 'address' && entityDef?.type === 'Address') {
                        // ADDRESS
                        resolve(entryValue);
                    } else if (entryDef.type === 'enumeration' && entryDef.config?.multiSelect && entityDef?.type === 'Tree') {
                        // ENUM (multi)
                        let valuesFromEntry = entryValue?.filter(v => entityDef.value?.map(e => e.label).includes(v)) || [];
                        resolve(_.uniq(valuesFromEntry.concat(
                                (entityValue?.filter(v => !entryDef.config?.values?.map(eV => eV.value).includes(v)) || [])
                        )));
                    } else if (entryDef.type === 'enumeration' && !entryDef.config?.multiSelect && entityDef?.type === 'Enumeration') {
                        // ENUM
                        resolve(entityDef.value?.values?.find(v => entryValue?.[0] === v.value) ? [entryValue[0]] : entityValue)
                    }

                }

                reject({
                    msg: 'could_not_convert',
                    pp: entryDef,
                    graph: entityDef,
                });

            })
        }
    }
}
</script>

<style scoped>

</style>
