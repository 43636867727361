<template>
    <div>


        <v-card class="overflow-y-auto" style="max-height: 80vh;">

            <v-text-field
                          v-model="search"
                          prepend-icon="search"
                          class="ml-5"
            ></v-text-field>

            <v-list
                subheader
            >
                <v-list-item-group v-if="roles.length > 0">

                    <v-list-item
                        v-for="role in roles"
                        :key="role._id"
                        router :to="{
                            name: 'AdminRoleEdit',
                            params: {
                                roleID: role._id,
                            }
                        }"
                    >
                        <v-list-item-avatar>
                            <v-icon>
                                checklist
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="role.name"></v-list-item-title>
                        </v-list-item-content>


                    </v-list-item>

                </v-list-item-group>

            </v-list>

        </v-card>

    </div>
</template>

<script>

    import _ from "lodash";
    import Chat from "../utility/Chat";
    import axios from "axios";
    import UserService from "../../services/UserService";


    export default {
        name: 'RoleList',
        components: {

        },
        computed: {
            roles: function() {
                return this.$store.getters['roles/getAll'].filter((r) => {
                    return r.name.toLowerCase().includes(this.search.toLowerCase());
                }).sort((a, b) => a.name.localeCompare(b.name)) || [];
            }
        },
        methods: {

        },
        data() {
            return {
                search: "",
            }
        },
        created() {
            this.$store.dispatch('roles/fetchRoles');
        }
    }
</script>
