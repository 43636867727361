/**
 Status:

 Neu / Ungeprüft
 In Arbeit

 Akquise - Versendet
 Akquise - Angenommen
 Akquise - Kein Rückmeldung

 Keine Teilnahme

 Freigabe - Versendet
 Freigabe - Angenommen
 Freigabe - Abgelehnt
 Freigabe - Automatisch Freigegeben

 --- Custom:

 Wiederholer 2019
 Wiederholer 2020
 etc.

 */

import PPSettings from '@/services/pp-settings';

class EntryStatusService {
    static getColor(value) {
        return (
            this.getStatusArray().find((x) => x.value === value)?.color ||
            '#b0b1b1'
        );
    }

    static getTranslation(value) {
        return (
            this.getStatusArray().find((x) => x.value === value)?.label ||
            'Unbekannter Status'
        );
    }

    static getLangStatusArray() {
        let statusArray;
        statusArray = [
            {
                label: 'In Bearbeitung',
                value: 'in_progress',
                color: '#0074bb',
            },
            {
                label: 'Geprüft / Gesperrt',
                value: 'locked',
                color: '#0074bb',

                lock: true,
            },
        ];
        return statusArray;
    }

    /**
     *
     * @param selectedStatus the selected status will always be emitted, even if only custom status are requested
     * @returns {(*&{disabled})[]}
     */
    static getStatusArray(selectedStatus = '') {
        let statusArray = [
            {
                label: 'Neu / Ungeprüft',
                value: 'new',
                color: '#b0b1b1',
                blackText: true,
            },
            {
                label: 'In Bearbeitung',
                value: 'in_review',
                color: '#0074bb',
            },
        ];

        if (PPSettings.getValue('status.customStatus')?.length > 0) {
            statusArray = statusArray.concat(
                PPSettings.getValue('status.customStatus').map((status) => {
                    return { ...status, custom: true };
                })
            );
            console.log(statusArray);
        }

        if (PPSettings.getValue('status.acquisition.enabled')) {
            statusArray = statusArray.concat([
                {
                    label: 'Akquise versendet',
                    value: 'acquisition_sent',
                    color: '#dc9018',
                },
                {
                    label: 'Akquise angenommen / beauftragt',
                    value: 'acquisition_accepted',
                    color: '#f1d20d',
                    blackText: true,
                },
                {
                    label: 'Akquise abgelehnt / keine Teilnahme',
                    value: 'acquisition_denied',
                    color: '#ff1600',
                },
            ]);
        }

        statusArray = statusArray.concat([
            {
                label: 'Freigabe versendet / gesperrt',
                value: 'confirmation_requested',
                color: '#146418',
                lock: true,
            },
            {
                label: 'Freigegeben / gesperrt',
                value: 'confirmed',
                color: '#8bd21e',
                blackText: true,
                lock: true,
                essential: true, // protects this status from being disabled when manual workflows are set
            },
            {
                label: 'Wiederhergestellt',
                value: 'restored',
                color: '#ff0000',
                disabled: true,
            },
        ]);

        // set all non-custom status to disabled
        if (PPSettings.getValue('status.onlyCustomWorkflows')) {
            // first disable all non-custom/non-essential
            // then remove all disabled except the current one
            statusArray = statusArray.map((status) => {
                return {
                    ...status,
                    //disabled: !status.essential && !status.custom,
                    hidden:
                        (!status.essential &&
                            !status.custom &&
                            status.value !== selectedStatus) ||
                        status.disabled,
                };
            });
        }

        return statusArray;
    }
}

export default EntryStatusService;
