<template>
    <div class="users">

        <p class="text-h5">Rollen</p>

        <v-row>

            <v-col style="max-width: 450px">
                <RoleList />
            </v-col>

            <v-col style="max-height: 80vh;" v-if="$router.currentRoute.params.roleID">
                <v-card class="overflow-y-auto pa-5" style="max-height: 80vh;">
                    <router-view :key="$router.currentRoute.params.roleID"></router-view>
                </v-card>
            </v-col>

        </v-row>

        <v-btn fab fixed bottom right color="success" @click="create()">
            <v-icon>
                add
            </v-icon>
        </v-btn>

    </div>
</template>

<script>

    import Chat from "../../../components/utility/Chat";
    import axios from "axios";
    import UserService from "../../../services/UserService";
    import RoleList from "@/components/users/RoleList";

    export default {
        name: 'Roles',
        components: {
            RoleList
            // Chat
        },
        data() {
            return {
                loading: false,
                users: [],
                search: "",
                headers: [
                    { text: 'Aktionen', value: 'controls', sortable: false, width: "100" },
                    { text: 'Name', value: 'name'},
                    { text: 'Login-Name', value: 'login'},
                    { text: 'Firma', value: 'firmation'},
                ]
            }
        },
        computed: {

        },
        methods: {
            create() {
                this.$store.dispatch('roles/addRole', { role: { name: 'Neue Rolle' } }).then((res) => {
                    this.$router.push({
                        name: 'AdminRoleEdit',
                        params: {
                            roleID: res.id
                        }
                    });
                });
            },
        },
        created() {

        }
    }
</script>
