import axios from 'axios';
import router from '../../router';

const entrypoint = 'v2/templates';

const state = {
    data: [],
    options: [
        {
            description: '6 Seiten',
            value: {
                numerator: 6,
                denominator: 1,
            },
        },
        {
            description: '5 Seiten',
            value: {
                numerator: 5,
                denominator: 1,
            },
        },
        {
            description: '4 Seiten',
            value: {
                numerator: 4,
                denominator: 1,
            },
        },
        {
            description: '3 Seiten',
            value: {
                numerator: 3,
                denominator: 1,
            },
        },
        {
            description: '2 Seiten',
            value: {
                numerator: 2,
                denominator: 1,
            },
        },
        {
            description: '1 Seite',
            value: {
                numerator: 1,
                denominator: 1,
            },
        },
        {
            description: '1/2 Seite',
            value: {
                numerator: 1,
                denominator: 2,
            },
        },
        {
            description: '1/3 Seite',
            value: {
                numerator: 1,
                denominator: 3,
            },
        },
        {
            description: '2/3 Seite',
            value: {
                numerator: 2,
                denominator: 3,
            },
        },
        {
            description: '1/4 Seite',
            value: {
                numerator: 1,
                denominator: 4,
            },
        },
        {
            description: '1/5 Seite',
            value: {
                numerator: 1,
                denominator: 5,
            },
        },
        {
            description: '2/5 Seite',
            value: {
                numerator: 2,
                denominator: 5,
            },
        },
        {
            description: '3/5 Seite',
            value: {
                numerator: 3,
                denominator: 5,
            },
        },
        {
            description: '1/6 Seite',
            value: {
                numerator: 1,
                denominator: 6,
            },
        },
        {
            description: '1/8 Seite',
            value: {
                numerator: 1,
                denominator: 8,
            },
        },
        {
            description: '1/10 Seite',
            value: {
                numerator: 1,
                denominator: 10,
            },
        },
        {
            description: '1/12 Seite',
            value: {
                numerator: 1,
                denominator: 12,
            },
        },
        {
            description: '1/13 Seite',
            value: {
                numerator: 1,
                denominator: 13,
            },
        },
        {
            description: '1/14 Seite',
            value: {
                numerator: 1,
                denominator: 14,
            },
        },
        {
            description: '1/16 Seite',
            value: {
                numerator: 1,
                denominator: 16,
            },
        },
        {
            description: '1/20 Seite',
            value: {
                numerator: 1,
                denominator: 20,
            },
        },
    ],
};

const getters = {
    getAll: (state) => state.data,
    getOptions: (state) => state.options,
};

const actions = {
    async fetchAll({ commit }) {
        const projectID = router.currentRoute.params.projectID;
        const channelID = router.currentRoute.params.channelID;
        const response = await axios.get(
            `/api/${entrypoint}/?projectID=${projectID}&channelID=${channelID}`
        );
        commit('setAll', response.data);
    },
    async add({ commit, dispatch }, object) {
        const response = await axios.post(`/api/${entrypoint}/`, object);
        await dispatch('fetchAll', {
            projectID: object.projectID,
            channelID: object.channelID,
        });
    },
    async update({ commit, dispatch, rootState }, object) {
        const response = await axios.put(
            `/api/${entrypoint}/${object._id}/`,
            object
        );
    },
    async remove({ commit, dispatch, rootState }, id) {
        const response = await axios.delete(`/api/${entrypoint}/${id}/`);
        await dispatch('fetchAll', {
            projectID: router.currentRoute.params.projectID,
            channelID: router.currentRoute.params.channelID,
        });
    },
};

const mutations = {
    setAll: (state, objects) => {
        state.data = objects;
    },
    //add: ((state, catalog) => state.catalogs.unshift(object))
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
