<template>
    <nav>
        <v-app-bar app dark :color="appMode !== 'pp' ? 'black' : 'secondary'">
            <v-btn
                class="ml-1 mr-4"
                @click="drawer = !drawer"
                fab
                small
                depressed
                color="transparent"
            >
                <v-icon>menu</v-icon>
            </v-btn>
            <span class="caption mr-2" v-if="currentProject.name && !drawer">{{
                `${currentProject.name}`
            }}</span>
            <span
                class="mr-2"
                v-if="currentProject.name && channelName && !drawer"
                >/</span
            >
            <span class="caption" v-if="channelName">{{
                `${channelName}`
            }}</span>

            <v-spacer></v-spacer>

            <!-- dark mode switch -->
            <v-img
                src="/img/logo_star_gold.svg"
                class="mr-3"
                max-height="26px"
                max-width="26px"
                v-if="!dark"
            ></v-img>
            <v-icon color="blue darken-2" class="mr-3" v-if="dark">
                bedtime
            </v-icon>
            <v-switch
                class="mr-5 mt-5"
                v-model="dark"
                autocomplete="off"
                inset
                :color="dark ? 'blue' : 'white'"
            ></v-switch>

            <!-- ticket system -->
            <TicketSystem v-if="$settings.getUserSettings('loaded')" />

            <!-- changelog -->
            <Changelog v-if="$settings.getUserSettings('loaded')" />

            <!-- latest notifications -->
            <Notifications />

            <CurrentUser />

            <!-- main loading indicator -->
            <v-progress-linear
                style="position: absolute; bottom: 0; left: 0"
                v-if="
                    $store.getters['user/getPermissionsStatus'] === 'loading' ||
                    $store.getters['user/getSettingsStatus'] === 'loading'
                "
                indeterminate
            />
        </v-app-bar>

        <!-- ADMIN -->
        <v-navigation-drawer
            app
            v-model="drawer"
            v-if="this.$route.matched.some(({ name }) => name === 'Admin')"
        >
            <v-list class="pt-0">
                <div class="app-logo-container">
                    <!-- APP SWITCH -->
                    <div class="application-switch py-4 pl-2">
                        <v-menu bottom :offset-x="true" min-width="200">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    icon
                                    class="py-4 px-a"
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>shield</v-icon>
                                </v-btn>
                            </template>
                        </v-menu>
                    </div>
                    <div class="app-logo my-auto">
                        <v-img
                            class="ml-3 we2pLogo"
                            contain
                            position="left center"
                            :src="logoURL"
                            max-width="130"
                            max-height="30"
                        ></v-img>
                        <p class="text-body-1 ml-4 mb-0">Administration</p>
                    </div>
                </div>
            </v-list>

            <!-- SIDE MENU -->
            <v-list>
                <v-list-item
                    v-for="(item, index) in adminNav"
                    :key="index"
                    :item="item"
                    :index="index"
                    class="text-decoration-none"
                    router
                    :to="`/admin${item.path}`"
                >
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            v-text="item.name"
                        ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-btn
                class="ma-5"
                v-if="false"
                rounded
                color="error"
                @click="throwError"
            >
                dont click me
            </v-btn>
        </v-navigation-drawer>

        <!-- SIDEBAR -->
        <v-navigation-drawer
            app
            v-model="drawer"
            v-else-if="this.$route.matched.some(({ name }) => name === 'Graph')"
        >
            <v-list class="pt-0">
                <div class="app-logo-container">
                    <!-- APP SWITCH -->
                    <div class="application-switch py-4 pl-2">
                        <v-menu
                            min-width="250"
                            offset-y
                            transition="scroll-x-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    icon
                                    class="py-4 px-a"
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>apps</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <!-- MANUAL -->
                                <v-list-item
                                    router
                                    :href="getAppSwitchEnv('manual', 'url')"
                                    target="_blank"
                                    v-if="
                                        getAppSwitchEnv('manual', 'hide') ===
                                        'n'
                                    "
                                >
                                    <v-list-item-avatar color="secondary">
                                        <v-icon color="white">help</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                getAppSwitchEnv(
                                                    'manual',
                                                    'name'
                                                )
                                            "
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <!-- COCKPIT -->
                                <v-list-item
                                    router
                                    :href="getAppSwitchEnv('cockpit', 'url')"
                                    target="_blank"
                                    v-if="
                                        getAppSwitchEnv('cockpit', 'hide') ===
                                        'n'
                                    "
                                >
                                    <v-list-item-avatar color="primary">
                                        <v-icon color="white"
                                            >account_tree
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                getAppSwitchEnv(
                                                    'cockpit',
                                                    'name'
                                                )
                                            "
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <!-- MEDIA COLLECTION -->
                                <v-list-item
                                    router
                                    :href="
                                        getAppSwitchEnv(
                                            'mediacollection',
                                            'url'
                                        )
                                    "
                                    target="_blank"
                                    v-if="
                                        getAppSwitchEnv(
                                            'mediacollection',
                                            'hide'
                                        ) === 'n'
                                    "
                                >
                                    <v-list-item-avatar color="secondary">
                                        <v-icon color="white"
                                            >collections
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                getAppSwitchEnv(
                                                    'mediacollection',
                                                    'name'
                                                )
                                            "
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div class="app-logo my-auto">
                        <v-img
                            class="ml-3 we2pLogo"
                            contain
                            position="left center"
                            :src="logoURL"
                            max-width="130"
                            max-height="30"
                        ></v-img>
                        <p class="text-body-1 ml-4 mb-0">Knowledge Graph</p>
                    </div>
                </div>

                <v-autocomplete
                    class="mx-4"
                    :items="$store.getters['projects/getAll']"
                    :loading="
                        $store.getters['projects/getStatus'] === 'loading'
                    "
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="Projekt"
                    v-model="currentProject"
                    hide-details
                    return-object
                ></v-autocomplete>

                <v-list-item-group
                    class="mt-2"
                    v-if="currentProject !== undefined"
                >
                    <v-list-item
                        class="text-decoration-none"
                        router
                        :to="`/projects/${currentProject.id}/channels`"
                        :exact="true"
                        color="blue"
                    >
                        <v-list-item-icon>
                            <v-icon
                                v-text="'call_split'"
                                style="transform: rotate(90deg)"
                            ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="'Alle Channels'"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <!-- SIDEBAR -->
        <v-navigation-drawer app v-model="drawer" v-else>
            <v-list class="pt-0">
                <div class="app-logo-container">
                    <!-- APP SWITCH -->
                    <div class="application-switch py-4 pl-2">
                        <v-menu min-width="250" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    fab
                                    icon
                                    class="py-4 px-a"
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>apps</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <!-- MANUAL -->
                                <v-list-item
                                    router
                                    :href="getAppSwitchEnv('manual', 'url')"
                                    target="_blank"
                                    v-if="
                                        getAppSwitchEnv('manual', 'hide') ===
                                        'n'
                                    "
                                >
                                    <v-list-item-avatar color="secondary">
                                        <v-icon color="white">help</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                getAppSwitchEnv(
                                                    'manual',
                                                    'name'
                                                )
                                            "
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <!-- COCKPIT -->
                                <v-list-item
                                    router
                                    :href="getAppSwitchEnv('cockpit', 'url')"
                                    target="_blank"
                                    v-if="
                                        getAppSwitchEnv('cockpit', 'hide') ===
                                        'n'
                                    "
                                >
                                    <v-list-item-avatar color="primary">
                                        <v-icon color="white"
                                            >account_tree
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                getAppSwitchEnv(
                                                    'cockpit',
                                                    'name'
                                                )
                                            "
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <!-- MEDIA COLLECTION -->
                                <v-list-item
                                    router
                                    :href="
                                        getAppSwitchEnv(
                                            'mediacollection',
                                            'url'
                                        )
                                    "
                                    target="_blank"
                                    v-if="
                                        getAppSwitchEnv(
                                            'mediacollection',
                                            'hide'
                                        ) === 'n'
                                    "
                                >
                                    <v-list-item-avatar color="secondary">
                                        <v-icon color="white"
                                            >collections
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                getAppSwitchEnv(
                                                    'mediacollection',
                                                    'name'
                                                )
                                            "
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <!-- LOGO -->
                    <div class="app-logo my-auto" style="width: 0">
                        <v-img
                            class="mt-3 mb-1 ml-2 mr-5 we2pLogo h-100 w-100"
                            contain
                            position="left center"
                            :src="logoURL"
                        ></v-img>
                        <p class="text-body-1 ml-4 mb-0">Publication Planner</p>
                    </div>
                </div>

                <v-autocomplete
                    class="mx-4"
                    :items="$store.getters['projects/getAll']"
                    :loading="
                        $store.getters['projects/getStatus'] === 'loading'
                    "
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="Projekt"
                    v-model="currentProject"
                    hide-details
                    return-object
                ></v-autocomplete>

                <v-list-item-group
                    class="mt-2"
                    v-if="currentProject !== undefined"
                >
                    <v-list-item
                        class="text-decoration-none"
                        router
                        :to="`/projects/${currentProject.id}/channels`"
                        :exact="true"
                        color="blue"
                    >
                        <v-list-item-icon>
                            <v-icon
                                v-text="'call_split'"
                                style="transform: rotate(90deg)"
                            ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="'Alle Channels'"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <!-- SIDE MENU -->
            <template v-for="menuGroup in menus">
                <div v-if="isPath(menuGroup.path)" :key="menuGroup.path">
                    <v-divider></v-divider>
                    <v-list flat>
                        <v-list-item-group>
                            <v-list-item
                                class="text-decoration-none"
                                :color="menuGroup.color"
                                router
                                :to="`/projects/${$router.currentRoute.params.projectID}/channels/${$router.currentRoute.params.channelID}/${menuGroup.segment}`"
                                :exact="true"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ menuGroup.home.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >{{ menuGroup.home.label }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list>
                        <template
                            v-for="(item, index) in menuGroup.content.filter(
                                (content) => $permGuard.chP(content.perm)
                            )"
                        >
                            <v-list-item
                                v-if="item.condition"
                                :key="index"
                                :item="item"
                                :index="index"
                                class="text-decoration-none"
                                :color="menuGroup.color"
                                router
                                :to="`/projects/${$router.currentRoute.params.projectID}/channels/${$router.currentRoute.params.channelID}/${menuGroup.segment}${item.path}`"
                                :exact="true"
                            >
                                <v-list-item-icon>
                                    <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="item.name"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </div>
            </template>
        </v-navigation-drawer>

        <v-btn
            bottom
            left
            depressed
            fixed
            text
            style="z-index: 6"
            v-if="
                drawer &&
                appMode !== 'admin' &&
                $permGuard.hasPermission('admin.access_backend')
            "
            router
            to="/admin/users"
        >
            <v-icon class="mr-2">settings</v-icon>
            Admin
        </v-btn>

        <v-btn
            bottom
            left
            depressed
            fixed
            text
            style="z-index: 6"
            v-if="drawer && appMode === 'admin'"
            router
            to="/projects"
        >
            <v-icon class="mr-2">login</v-icon>
            zum Frontend
        </v-btn>
    </nav>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import gravatar from 'gravatar';
import SocketAdapter from '../../services/socket-adapter';
import PPSettings from '@/services/pp-settings';
import LangSwitch from '@/components/utility/LangSwitch';
import Notifications from '@/components/users/Notifications';
import Changelog from '../users/Changelog';
import TicketSystem from '@/components/users/TicketSystem';
import CurrentUser from '@/components/navigation/CurrentUser';

export default {
    name: 'NavBar',
    components: {
        CurrentUser,
        TicketSystem,
        Changelog,
        Notifications,
    },
    props: ['appMode'],
    data() {
        return {
            drawer: true,
            project: {},
            projects: [],
            adminNav: [
                {
                    name: 'Projekte',
                    path: '/projects',
                    icon: 'business',
                },
                {
                    name: 'Channels',
                    path: '/channels',
                    icon: 'call_split',
                },
                {
                    name: 'Benutzer',
                    path: '/users',
                    icon: 'person',
                },
                {
                    name: 'Gruppen',
                    path: '/groups',
                    icon: 'people',
                },
                {
                    name: 'Rollen',
                    path: '/roles',
                    icon: 'grading',
                },
                {
                    name: 'Changelog',
                    path: '/changelog',
                    icon: 'newspaper',
                },
                {
                    name: 'Importe',
                    path: '/import',
                    icon: 'downloading',
                },
                {
                    name: 'Einstellungen',
                    path: '/settings',
                    icon: 'settings',
                },
                {
                    name: 'System',
                    path: '/system',
                    icon: 'assessment',
                },
            ],
            apps: [
                {
                    title: 'Knowledge Graph',
                    link: 'https://c2.we2p.de',
                    icon: 'schema',
                    color: 'primary',
                },
                {
                    title: 'Media Collection',
                    link: 'https://media.we2p.de',
                    icon: 'insert_photo',
                    color: '#077821',
                },
            ],
        };
    },
    methods: {
        throwError() {
            throw new Error("I said, don't click!");
        },
        isPath(path) {
            return this.$route.matched.some(({ name }) => name === path);
        },
        getAppSwitchEnv(system, property) {
            return process.env[
                `VUE_APP_APP_SWITCH_${system.toUpperCase()}_${property.toUpperCase()}`
            ];
        },
        initProject() {
            console.log('initProject() was called -> ', this.projects);
            console.log(
                'params.projectID ->',
                this.$router.currentRoute.params.projectID
            );

            if (
                !isNaN(parseFloat(this.$router.currentRoute.params.projectID))
            ) {
                console.log(
                    'projectID could be resolved from route param ->',
                    this.$router.currentRoute.params.projectID
                );
                this.currentProject = this.projects.find(
                    (x) => x.id === this.$router.currentRoute.params.projectID
                );
            } else if (
                !this.$route.matched.some(({ name }) => name === 'Admin')
            ) {
                console.log(
                    'no projectID param present in route, assuming default',
                    this.projects?.[0]
                );
                // todo should fall back to default project, but currently buggy.
                //this.currentProject = this.projects?.[0];
            }
        },
    },
    computed: {
        menus: function () {
            return {
                PrintSuite: {
                    path: 'PrintSuite',
                    color: 'primary',
                    segment: 'print',
                    home: {
                        icon: 'dashboard',
                        label: 'Dashboard',
                    },
                    content: [
                        {
                            name: 'Versionen',
                            path: '/catalogs',
                            icon: 'menu_book',
                            perm: 'catalogs.r',
                            condition: true,
                        },
                        {
                            name: 'Kontakte',
                            path: '/contacts',
                            icon: 'contacts',
                            perm: 'contacts.r',
                            condition:
                                this.$settings.getValue('accounting.enabled'),
                        },
                        {
                            name: 'Eintragstypen',
                            path: '/entrytypes',
                            icon: 'format_list_bulleted',
                            perm: 'entrytypes.r',
                            condition: true,
                        },
                        {
                            name: 'Templates',
                            path: '/templates',
                            icon: 'view_quilt',
                            perm: 'templates.r',
                            condition: true,
                        },
                        /*{
                    name: "E-Mail Templates",
                    path: "/mailtemplates",
                    icon: "mail",
                },*/
                        {
                            name: 'Einstellungen',
                            path: '/settings',
                            icon: 'settings',
                            perm: 'settings.r',
                            condition: true,
                        },
                    ],
                },
                OnlimChatbot: {
                    path: 'OnlimChatbot',
                    color: 'info',
                    segment: 'chatbot_onlim',
                    home: {
                        icon: 'home',
                        label: 'OnlimChatbot',
                    },
                    content: [
                        {
                            name: 'Datenbestand',
                            path: '/sync',
                            icon: 'cached',
                            condition: true,
                        },
                        {
                            name: 'Ereignis-Protokoll',
                            path: '/logs',
                            icon: 'grading',
                            condition: true,
                        },
                        {
                            name: 'Einstellungen',
                            path: '/settings',
                            icon: 'settings',
                            condition: true,
                        },
                    ],
                },
            };
        },
        logoURL: function () {
            if (PPSettings.getUserSettings('darkMode')) {
                return this.$store.getters['system-config/getConfig']
                    ?.appearance?.logos?.main_white;
            }
            return this.$store.getters['system-config/getConfig']?.appearance
                ?.logos?.main;
        },
        user: function () {
            let user = this.$store.getters['auth/getUser'];
            user.profile_img = gravatar.url(user.mail, {
                default: 'mp',
                protocol: 'https',
            });
            return user;
        },
        channelName: function () {
            return (
                this.$store.getters['channels/getAll']?.[
                    this.$route.params.projectID
                ]?.find((p) => p.id === this.$route.params.channelID)?.labels?.[
                    'de-DE'
                ] || ''
            );
        },
        currentProject: {
            get() {
                return this.$store.getters['projects/getCurrent'];
            },
            set(project) {
                if (
                    this.$router.currentRoute.params.projectID !== project.id &&
                    !this.$route.matched.some(({ name }) => name === 'Admin')
                ) {
                    this.$router.push(`/projects/${project.id}/channels`);
                }
                console.log('set', this.$store.getters['projects/getCurrent']);
                SocketAdapter.joinRoom(`project:${project.id}`);
            },
        },
        // dark mode toggle
        dark: {
            set: function (newVal) {
                PPSettings.setUserSettings('darkMode', newVal);
            },
            get: function () {
                return PPSettings.getUserSettings('darkMode');
            },
        },
    },
    watch: {
        dark: function (newVal) {
            this.$vuetify.theme.dark = newVal;

            window.dispatchEvent(
                new CustomEvent('venus__pp_dark_mode', {
                    detail: {
                        dark: newVal,
                    },
                })
            );
        }, // loads default project after returning from admin-backend
        appMode: function (newVal, oldVal) {
            if (newVal === 'pp' && oldVal === 'admin') {
                // this.initProject();
            }
        },
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.app-logo-container {
    display: flex;

    .application-switch {
        // padding: 0 !important;
        //position: relative;
        // flex: 0 0 50px;
        i {
            //font-size: 20px;
            //position: absolute;
            //top: 50%;
            //left: 50%;
            //transform: translate(-50%,-50%);
        }
    }

    .app-logo {
        flex: 1;
    }
}

.we2pLogo.theme--dark {
    //filter: brightness(2.5);
}

.we2pLogo {
    // animation: rgb 1s linear infinite;
}

@keyframes rgb {
    0% {
        filter: hue-rotate(0deg) brightness(1) saturate(5);
    }
    50% {
        filter: hue-rotate(180deg) brightness(1) saturate(5);
    }
    100% {
        filter: hue-rotate(360deg) brightness(1) saturate(5);
    }
}
</style>
