import _ from 'lodash';
import store from '../store';
import defaultConfig from '../config/defaultPrintConfig.json';

class PPSettings {
    static getUserSettings(key) {
        if (key === 'loaded') {
            return Boolean(!_.isEqual(store.getters['user/getSettings'], {}));
        }

        let settings = store.getters['user/getSettings'];
        return settings[key];
    }

    static setUserSettings(key, content) {
        store.dispatch('user/setSettings', { key, content });
    }

    static getValue(key) {
        let settings = store.getters['printsettings/getSettings'];

        // used to suggest settings-import

        //console.log('settings', key);

        if (_.isEmpty(settings)) {
            settings = {
                noSettingsYet: true,
            };
        }

        let keys = key.split('.');

        let result = _.defaultsDeep(settings, this.getDefaultSettings());

        for (const i in keys) {
            const key = keys[i];
            if (result) {
                result = result[key];
            } else {
                break;
            }
        }

        return result;
    }

    static getDefaultSettings() {
        return defaultConfig;
    }
}

export default PPSettings;
