<template>

    <v-select :items="languages"
              outlined
              :dense="dense"
              :style="`max-width: 250px`"
              label="Sprache"
              v-if="currentLang"
              :value="currentLang.value"
              @input="$emit('input', $event)"
    >
        <template v-slot:prepend>
            <v-avatar :size="dense ? 20 : 28">
                <img style="object-fit: cover" :src="`https://webimages.we2p.de/static/flags/svg/${ currentLang.value.slice(-2) }.svg`">
            </v-avatar>
        </template>
    </v-select>

</template>

<script>
import BasicDataProvider from "@/services/shared/utility/basic-data-provider";
import {languages} from "prismjs/components/prism-core";

export default {
    name: "LangSwitch",
    props: [
        "value",
        "available",
        "dense",
    ],
    computed: {
        languages: function () {
            let availableLanguages = BasicDataProvider.getAllLanguages();

            // filter by available for this specific switch
            if (this.available) {
                availableLanguages = availableLanguages.filter((l) => {
                    return this.available.includes(l.value);
                });
            }
            return availableLanguages;
        },
        currentLang: function() {
            return this.languages.find((l) => l.value === this.value) || { value: "de-DE", text: "Default" };
        },
    },
    created() {

    }
}
</script>

<style scoped>

</style>
