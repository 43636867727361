<template>

    <div>
        <h2 class="text-h4 my-5 font-weight-bold grey--text">Alle Datensätze</h2>

        <v-spacer class="py-5"></v-spacer>
        <EntityListFilter v-model="filter" :loading="loading"></EntityListFilter>

        <v-row class="mt-5">
            <v-btn color="success" class="my-auto ml-5 mr-3" depressed rounded large>
                <v-icon class="mr-2">add_circle</v-icon>
                <span>Neu</span>
            </v-btn>
            <v-spacer/>
            <EntityColumnConfig :properties="$store.getters['properties/getAll']" v-model="headers"/>
            <v-col cols="12" style="max-width: 500px;">
                <v-sheet class="px-4 pt-1">
                    <v-text-field prepend-icon="search"
                                  :loading="loading"
                                  v-model="filter.queryString"
                                  label="Suchen"
                    ></v-text-field>
                </v-sheet>
            </v-col>
        </v-row>

        <EntityList :total="total" :headers="headers" v-model="selectedEntities" :items="tableData" @options="updateOptions"></EntityList>

    </div>

</template>

<script>
import axios from "axios";
import EntityList from "@/components/entities/EntityList";
import TranslationService from "@/services/TranslationService";
import EntityListFilter from "@/components/entities/EntityListFilter";
import EntityColumnConfig from "@/components/entities/EntityColumnConfig";

export default {
    name: "Entities",
    components: {
        EntityColumnConfig,
        EntityList,
        EntityListFilter
    },
    data() {
        return {
            filter: {},
            selectedEntities: [],
            cancelTokens: [],
            total: 0,
            entities: [],
            options: {},
            loading: false,
            headers: [
                { text: 'Aktion', value: 'controls', width: "120" },
                { text: 'Name', value: 'name', width: "300" },
                { text: 'MongoID', value: '_id', width: "300" },
                { text: 'Typ', value: 'types', width: "200" },
            ]
        }
    },
    watch: {
        itemsPerPage: {
            handler() {
                this.getData()
            },
            deep: true,
        },
        currentProject: {
            handler () {
                this.getData()
            },
            deep: true,
        },
        options: {
            handler() {
                this.getData()
            },
            deep: true,
        },
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
    computed: {
        currentProject: function () {
            return this.$store.getters['projects/getCurrent']?.['identifier'] || 'unknown_project_identifier'
        },
        tableData: function() {
            return this.entities.map((e) => {
                return {
                    _id: e._id,
                    types: e.types.map((type) => {
                        let typeDefinitions = this.$store.getters['properties/getAll']?.find(p => p.name === "types");
                        return typeDefinitions?.value?.find(v => v.label === type)?.description?.['de-DE'] || "Unbekannter Type";
                    }),
                    name: TranslationService.getValue(e.name, 'de-DE'),
                }
            })
        }
    },
    methods: {
        updateOptions(options) {
            this.options = options;
        },
        async getData() {
            return new Promise((resolve, reject) => {

                const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                let meta = {
                    size: itemsPerPage || 100,
                    from: (itemsPerPage * (page - 1)) || 0,
                    project_identifier: this.currentProject
                }

                let sort = {
                    field: sortBy[0] || '',
                    order: sortDesc[0] || false
                }

                let filter = this.filter;

                this.callAPI(meta, sort, filter).then((res) => {
                    this.entities = res.items;
                    this.total = res.total;
                });

            });
        },
        async callAPI(meta, sort, filter) {
            return new Promise((resolve, reject) => {

                if (this.cancelTokens.length > 0) {
                    for (const tokenIndex in this.cancelTokens) {
                        this.cancelTokens[tokenIndex].cancel();
                    }
                    this.cancelTokens = [];
                }

                const source = axios.CancelToken.source();
                this.cancelTokens.push(source);

                this.loading = true;
                axios.post('/api/graphentities/', {
                    meta,
                    sort,
                    filter
                }, {
                    cancelToken: source.token,
                }).then((res) => {
                    resolve({
                        items: res.data?.data?.body,
                        total: res.data?.data?.total
                    });
                }).catch((e) => {
                    console.log(e);
                }).finally(() => {
                    this.loading = false;
                })

            });
        }
    },
    created() {
        this.getData();
        console.log("paging desired", this.$settings.getUserSettings('graph_itemsPerPage'));
    }
}
</script>

<style scoped>

</style>
