<template>

    <img :src="src" alt="profile image">

</template>

<script>
import gravatar from "gravatar";

export default {
    name: "UserImage",
    props: [
        'user'
    ],
    computed: {
        src: function () {
            return gravatar.url(this.user?.mail || '', {
                default: 'mp',
                protocol: 'https'
            });
        }
    }
}
</script>

<style scoped>

</style>
