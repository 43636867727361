<template>

    <v-dialog
            v-model="dialog"
            max-width="1000"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs">
                <v-btn class="mr-3" fab color="blue" depressed x-small router append  v-on="on" v-bind="attrs">
                    <v-icon color="white">remove_red_eye</v-icon>
                </v-btn>
            </slot>
        </template>

        <v-card>
            <v-toolbar>
                <v-card-title class="headline">
                    Print Vorschau
                </v-card-title>
                <template v-if="template && template.sideAware">
                    <v-spacer></v-spacer>
                    <v-btn-toggle
                        v-model="side"
                        borderless
                        @change="loadPreview()"
                    >
                        <v-btn value="left" :disabled="loading">
                            <span class="hidden-sm-and-down">Links</span>
                            <v-icon class="mr-0" right>
                                auto_stories
                            </v-icon>
                        </v-btn>
                        <v-btn value="right" :disabled="loading">
                            <v-icon right class="mr-2 ml-0" style="transform: scaleX(-1)">
                                auto_stories
                            </v-icon>
                            <span class="hidden-sm-and-down">Rechts</span>
                        </v-btn>
                    </v-btn-toggle>
                </template>
                <v-spacer></v-spacer>
                <!-- language selector -->
                <template v-if="previewLanguages.length > 1">
                    <v-btn fab
                           v-for="(lang,index) in previewLanguages"
                           :key="index" small depressed
                           class="mx-1" @click="carouselPage = index * (previewImages.length / previewLanguages.length)"
                           :style="(carouselPage >= (index * (previewImages.length / previewLanguages.length)) && carouselPage < (index * (previewImages.length / previewLanguages.length) + (previewImages.length / previewLanguages.length))) ? '' : 'opacity: 0.3'"
                    >
                        <v-avatar size="32">
                            <v-img :src="`https://webimages.we2p.de/static/flags/svg/${ lang.slice(-2) }.svg`"></v-img>
                        </v-avatar>
                    </v-btn>
                </template>
                <v-spacer></v-spacer>
                <v-btn class="mr-2"
                       color="primary"
                       icon
                       :href="previewInfo.links.pdf"
                       target="_blank"
                       v-if="!loading && !failed && (previewInfo.links || {}).pdf && !urls"
                >
                    <v-icon>picture_as_pdf</v-icon>
                </v-btn>
                <v-btn class="mr-2"
                       color="blue"
                       icon
                       @click="loadPreview"
                       v-if="!urls"
                >
                    <v-icon>sync</v-icon>
                </v-btn>
                <v-btn class="mr-0"
                       color="red"
                       icon
                       @click="dialog = !dialog"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <!-- SINGLE -->
                <img v-if="!loading && !failed && (previewImages.length === 1)" width="100%" :src="`${previewImages}`" alt="CometPreview">

                <!-- MULTIPLE -->
                <v-carousel v-if="!loading && !failed && (previewImages.length > 1)"
                            v-model="carouselPage"
                            height="700"
                            dark
                            show-arrows-on-hover
                >
                    <v-carousel-item v-for="(previewLink, i) in previewImages" :key="i">
                        <img style="object-fit: contain" width="100%" height="100%" :src="`${previewLink}`" alt="CometPreview">
                    </v-carousel-item>
                </v-carousel>

                <!-- FAILED -->
                <v-container class="text-center py-5" v-if="!loading && (failed || previewImages.length === 0)">
                    <p class="text-h3">Fehlgeschlagen.</p>
                    <p class="">Bitte kontaktieren Sie einen Administrator.</p>
                </v-container>

                <!-- LOADER -->
                <v-card class="text-center py-5" v-if="loading">
                    <v-progress-circular class="my-5"
                                         :size="50"
                                         :width="4"
                                         color="grey"
                                         indeterminate
                    ></v-progress-circular>
                    <p class="warning--text" v-if="loadingOvertime">Der Server ist gerade ausgelastet. Bitte haben Sie noch einen Augenblick Geduld.</p>
                </v-card>
            </v-card>
        </v-card>

    </v-dialog>

</template>

<script>

    import axios from 'axios';

    export default {
        name: 'CometPreview',
        props: [
            'entryId',
            'languages',
            'urls'
        ],
        components: {

        },
        data() {
            return {
                previewInfo: {
                    links: {
                        pdf: "",
                        jpg: [],
                    }
                },
                side: "left",
                carouselPage: 0,
                loading: false,
                loadingOvertime: false,
                failed: false,
                dialog: false,
            }
        },
        watch: {
            dialog: function (newVal, oldVal){
                // if (newVal && this.previewLink === '') { <- unwanted behaviour - should be reloaded now
                if (newVal && !this.urls) {
                    this.loadPreview();
                }
            }
        },
        computed: {
            previewLanguages: function() {
                return this.languages || [];
            },
            previewImages: function() {
                if (this.previewInfo?.links?.jpg?.length) {
                    return this.previewInfo?.links?.jpg
                }
                return this.urls || [];
            },
            template: function() {
                const entry = this.$store.getters['entries/allEntries'].find((e) => e._id.toString() === this.entryId);
                if (!entry) return null;
                return this.$store.getters['templates/getAll'].find((t) => t._id.toString() === entry.templateID) || {};
            }
        },
        methods: {
            clearPreview() {
                this.previewInfo = {};
            },

            loadPreview() {

                this.failed = false;
                this.loading = true;
                this.loadingOvertime = false;

                let overTime = setTimeout(() => {
                    this.loadingOvertime = true;
                }, 30 * 1000);


                axios.get('/api/comet/preview/?type=jpg' +
                    '&entryId=' + this.entryId +
                    '&languages=' + this.previewLanguages?.join(',') +
                    '&side=' + this.side, {
                    timeout: 120 * 1000,
                }).then((res) => {
                    this.previewInfo = res.data;
                    this.carouselPage = 0;
                }).catch(() => {
                    this.failed = true;
                }).finally(() => {
                    this.loading = false;
                    clearTimeout(overTime);
                });

            }
        },
        created() {
            // removed because it causes immense number of requests in EntryList.vue
            // this.$store.dispatch('templates/fetchAll');
            // this.$store.dispatch('entries/fetchEntriesByCatalog', this.$router.currentRoute.params.catalogID);
        }
    }
</script>

<style scoped>

</style>
