<template>

    <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn rounded v-on="on" v-bind="attrs" color="primary" @click="sendToEditor" class="mr-3 mb-3 white--text" :disabled="isLocked">
                <v-icon left>edit</v-icon>
                Editor öffnen
            </v-btn>
        </template>
        <v-card tile>
            <v-toolbar elevation="2">
                <v-toolbar-title>
                    <img :src="logoURL" height="30" class="mt-1">
                    <span class="ml-2 font-weight-light" style="vertical-align: text-bottom">Strecken-Editor</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon text color="info" class="mr-1" @click="forceReload" v-bind="attrs" v-on="on">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Editor neu laden</span>
                </v-tooltip>
                <!-- close -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <DeleteConfirmation @yes="dialog = false" msg="Nicht gespeicherte Änderungen gehen eventuell verloren." title="Wirklich beenden?">
                            <template v-slot:default="{ on, attrs }">
                                <div>
                                    <v-btn icon text class="mr-0" color="error" v-bind="attrs" v-on="on">
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </DeleteConfirmation>
                    </template>
                    <span>Editor beenden</span>
                </v-tooltip>
            </v-toolbar>

            <div style="height: calc(100vh - 80px);">
                <TrailEditor :key="componentKey" :entry="entry" @close="close" @save="$emit('save')"/>
            </div>

        </v-card>
    </v-dialog>

</template>

<script>

    import _ from 'lodash';

    import TrailEditor from "./TrailEditor";
    import BlockUnsavedExitMixin from "../mixins/block-unsaved-exit";
    import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
    import axios from "axios";

    export default {
        name: 'TrailEditorDialog',
        props: [
            'entry',
            'isLocked'
        ],
        components: {
            DeleteConfirmation,
            TrailEditor
            // LoadingSpinner
        },
        mixins: [
            BlockUnsavedExitMixin
        ],
        data() {
            return {
                componentKey: 0,
                dialog: false,
                forceReloadOnNextOpen: false,
            }
        },
        computed: {
            logoURL: function() {
                return process?.env?.VUE_APP_LOGO_URL;
            },
            saveRequired: function() {
                return this.dialog;
            }
        },
        watch: {
            dialog: function (newVal, oldVal){
                // if (newVal && this.forceReloadOnNextOpen) { <- unwanted behaviour - should be reloaded now
                if (newVal) {
                    this.forceReloadOnNextOpen = false;
                    this.forceReload();
                }
            }
        },
        methods: {
            close(data) {
                this.dialog = false;
                this.forceReloadOnNextOpen = true;
                if (!_.isEmpty(data)) {
                    this.$emit('save', data);
                }
            },
            forceReload() {
                this.componentKey++;
            },
            sendToEditor() {

            },
        },
        created() {

        }
    }
</script>

<style scoped>

</style>
