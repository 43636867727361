<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-autocomplete
                :readonly="isLocked"
                v-model="data"
                :items="(definition.config || {}).values"
                filled
                chips
                item-text="label.de-DE"
                item-value="value"
                :multiple="(definition.config || {}).multiSelect"
        >
            <template v-slot:selection="data">
                <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :close="(definition.config || {}).multiSelect && !isLocked"
                        @click="data.select"
                        @click:close="remove(data.item)"
                >
                    <v-avatar left v-if="data.item.unicode">
                        <span :style="`font-size: 17px; font-family: '${(definition.config || {}).pictoFont || 'tinpi'}';`" v-html="getUnicodeEntity(data.item.unicode)"></span>
                    </v-avatar>
                    {{ data.item.label['de-DE'] }}
                </v-chip>
            </template>
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                    <v-list-item-avatar v-if="data.item.unicode">
                        <v-avatar size="40">
                            <span :style="`font-size: 28px;font-family: '${(definition.config || {}).pictoFont || 'tinpi'}';`" v-html="getUnicodeEntity(data.item.unicode)"></span>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.label['de-DE']"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.value"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>

    import _ from 'lodash';
    import TranslationService from "../../../services/TranslationService";
    import LabelComponent from "./LabelComponent";
    import FormComponentMixin from "../../mixins/form-component";

    export default {
        name: "EnumerationComponent",
        components: {
            LabelComponent
        },
        props: [
            'value',
            'definition',
            'isLocked'
        ],
        mixins: [

        ],
        data() {
            return {

            }
        },
        computed: {
            data: {
                // getter
                get: function () {
                    if (!this.definition?.config?.multiSelect) {
                        return this.value?.[0] || undefined;
                    } else {
                        console.log(this.value);
                        return this.value || [];
                    }
                },
                // setter
                set: function (newValue) {
                    if (!this.definition?.config?.multiSelect) {
                        this.$emit('input', [newValue]);
                    } else {
                        this.$emit('input', newValue);
                    }
                }
            }
        },
        methods: {
            getUnicodeEntity(unicode) {
                return "&#x" + unicode + ";";
            },
            remove (item) {
                const index = this.data.indexOf(item.value)
                if (index >= 0) this.data.splice(index, 1)
            },
            async save() {
                return _.compact(this.data) || [];
            }
        }
    }
</script>

<style scoped>

    .font--tinpi {
        font-family: "Tinpi";
    }

</style>
