import Vuex from 'vuex';
import Vue from 'vue';

import modules from './modules'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        ...modules
    },
    actions: {
        reset({commit}) {
            // resets state of all the modules
            Object.keys(modules).forEach(moduleName => {
                commit(`${moduleName}/RESET`);
            })
        }
    },
})

export default store;
