<template>
    <v-dialog
            v-model="dialog"
            persistent
            scrollable
            max-width="800px"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs">
                <v-btn class="mr-3" fab color="success" depressed x-small router append v-on="on" v-bind="attrs">
                    <v-icon color="white">sync</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="pt-5">
                <span class="headline text-h4">
                    Import Assistent
                    <v-icon class="ml-2" size="40">
                        auto_fix_high
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text v-if="loadingProposal" class="mt-5">
                <v-row class="pt-5 mb-2">
                    <v-spacer/>
                    <ProposalSpinner :animation-duration="1200" :color="'dodgerblue'" :size="50"/>
                    <v-spacer/>
                </v-row>
                <p class="text-center mb-0 mt-4">SyncService evaluiert Daten und prüft Integrität.</p>
            </v-card-text>
            <v-card-text v-if="loadingStrategy" class="mt-5">
                <v-row class="pt-5 mb-2">
                    <v-spacer/>
                    <StrategySpinner :animation-duration="1200" :color="'red'" :size="50"/>
                    <v-spacer/>
                </v-row>
                <p class="text-center mb-0 mt-4">Sync wird ausgeführt.</p>
            </v-card-text>
            <v-card-text v-if="failedProposal && !loadingProposal" class="mt-5">
                <p class="text-center my-5 error--text">Fehlgeschlagen.</p>
            </v-card-text>
            <v-card-text v-if="!failedProposal && !loadingProposal && !loadingStrategy" class="pt-4">

                <p>generiert am {{ getDateTimeString(syncProposal.time || '1') }} Uhr</p>

                <p class="text-h6">Folgende Aktionen werden empfohlen:</p>

                <v-expansion-panels class="mb-6" multiple>

                    <!-- CREATE -->
                    <v-expansion-panel :disabled="(syncProposal.toCreate || []).length === 0" v-if="$permGuard.chP('actions.sync_catalog.c')">
                        <v-expansion-panel-header>
                            <span>
                                <v-icon color="success" class="mr-4">
                                    add
                                </v-icon>
                                <span>{{ ((syncStrategy.create || {}).ids || []).length }}/{{ (syncProposal.toCreate || []).length }} neue Einträge erstellen</span>
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <!--v-checkbox dense
                                         class="mt-0 mb-3"
                                        v-schemas="(syncStrategy.create || {}).ignore"
                                        label="Keine neuen Einträge anlegen."
                                        color="error"
                                        hide-details
                            ></v-checkbox-->
                            <div v-if="!(syncStrategy.create || {}).ignore" class="mt-2">
                                <v-icon class="mr-2">
                                    info
                                </v-icon>
                                <span>Diese Einträge werden neu angelegt.</span>
                                <div>
                                    <v-btn class="mt-3" @click="selectAll('create')">Alle auswählen</v-btn>
                                </div>
                                <v-list two-line>
                                    <template v-for="(item, index) in syncProposal.toCreate">
                                        <v-list-item :key="index">
                                            <v-list-item-action class="mr-4">
                                                <v-checkbox
                                                    v-model="(syncStrategy.create || {}).ids"
                                                    :value="item.entityId"
                                                    color="success"
                                                ></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ translate(item.name) }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ 'CockpitID: ' + item.entityId + ' -> ' + item.type }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>


                    <!-- UPDATE -->
                    <v-expansion-panel :disabled="(syncProposal.toUpdate || []).length === 0" v-if="$permGuard.chP('actions.sync_catalog.u')">
                        <v-expansion-panel-header>
                            <span>
                                <v-icon color="info" class="mr-4">
                                    sync
                                </v-icon>
                                <span>{{ ((syncStrategy.update || {}).ids || []).length }}/{{ (syncProposal.toUpdate || []).length }} bestehende Einträge aktualisieren</span>
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <!--v-checkbox dense
                                        class="mt-0 mb-3"
                                        v-schemas="(syncStrategy.update || {}).ignore"
                                        label="Keine Einträge updaten."
                                        color="error"
                                        hide-details
                            ></v-checkbox-->
                            <div v-if="!(syncStrategy.update || {}).ignore" class="mt-2">
                                <v-icon class="mr-2">
                                    info
                                </v-icon>
                                <span>Diese Einträge werden aktualisiert.</span>
                                <div>
                                    <v-btn class="mt-3" @click="selectAll('update')">Alle auswählen</v-btn>
                                </div>
                                <v-list two-line>
                                    <template v-for="(item, index) in syncProposal.toUpdate">
                                        <v-list-item :key="index">
                                            <v-list-item-action class="mr-4">
                                                <v-checkbox
                                                    v-model="(syncStrategy.update || {}).ids"
                                                    :value="item.entryId"
                                                    color="info"
                                                ></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ translate(item.name) }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ 'Eintrags-ID: ' + item.entryId + ' (' + item.type + ')' }} - <span class="info--text">Status: "{{ item.status }}"</span></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- BLOCKED (update) -->
                    <v-expansion-panel v-if="(syncProposal.blockedUpdate || []).length > 0">
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-4">
                                    lock
                                </v-icon>
                                <span>Status blockiert Update ({{ (syncProposal.blockedUpdate || []).length }} Einträge)</span>
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div>
                                <v-icon class="mr-2">
                                    info
                                </v-icon>
                                <span>Der Status des Eintrags blockiert das Update, z.B. "Freigegeben".</span>
                                <v-list two-line>
                                    <template v-for="(item, index) in syncProposal.blockedUpdate">
                                        <v-list-item :key="index">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ translate(item.name) }}</v-list-item-title>
                                                <v-list-item-subtitle>Eintrags-ID:&nbsp;{{ item.entryId + ' (' + item.type }}) | <span class="error--text">Status: "{{ item.status }}"</span></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- ALREADY UP TO DATE -->
                    <v-expansion-panel v-if="(syncProposal.alreadyUpdate || []).length > 0">
                        <v-expansion-panel-header>
                            <span>
                                <v-icon color="success" class="mr-4">
                                    check
                                </v-icon>
                                <span>{{ (syncProposal.alreadyUpdate || []).length }} sind bereits aktuell</span>
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-icon class="mr-2">
                                info
                            </v-icon>
                            <span>Diese Einträge stimmen mit den Cockpit-Daten überein.</span>
                            <v-list two-line>
                                <template v-for="(item, index) in syncProposal.alreadyUpdate">
                                    <v-list-item :key="index">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ translate(item.name) }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ 'Eintrags-ID: ' + item.entryId + ' (' + item.type + ')' }} - <span class="info--text">Status: "{{ item.status }}"</span></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>


                    <!-- NO MATCH -->
                    <v-expansion-panel v-if="(syncProposal.noMatch || []).length > 0">
                        <v-expansion-panel-header>
                            <span>
                                <v-icon color="warning" class="mr-4">
                                    warning
                                </v-icon>
                                {{ (syncProposal.noMatch || []).length }} ignorierte Datensätze
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <p>Diese Datensätze sind dem Channel zugewiesen, aber enthalten keinen we2pType, der sich auf einen Print-Type übertragen lässt.</p>

                            <v-list two-line>
                                <template v-for="(item, index) in syncProposal.noMatch">
                                    <v-list-item :key="index">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ translate(item.name) }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.entityId + ' - ' + item.types }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>

                        </v-expansion-panel-content>
                    </v-expansion-panel>


                </v-expansion-panels>

                <v-checkbox
                        v-model="syncStrategy.agree"
                        label="Ich verstehe, welche Aktionen mit dem Auslösen der Synchronisation ausgeführt werden und bin mir deren Konsequenzen bewusst."
                        color="success"
                        hide-details
                ></v-checkbox>

            </v-card-text>

            <v-card-actions>

                <v-btn color="error" text @click="dialog = false">
                    Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="executeStrategy" v-if="syncStrategy.agree && !loadingStrategy && !loadingProposal">
                    Starten
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import ProposalSpinner from 'epic-spinners/src/components/lib/TrinityRingsSpinner';
    import StrategySpinner from 'epic-spinners/src/components/lib/SpringSpinner';
    import axios from "axios";
    import { DateTime } from 'luxon';
    import _ from 'lodash';
    import TranslationService from "../../services/TranslationService";

    export default {
        name: "CatalogSync",
        components: {
            ProposalSpinner,
            StrategySpinner,
        },
        props: ['catalogId'],
        data() {
            return {
                dialog: false,
                failedProposal: false,
                failedStrategy: false,
                syncProposal: {},
                syncStrategy: {},
                loadingProposal: false,
                loadingStrategy: false,
            }
        },
        watch: {
            dialog: function (newVal, oldVal){
                if (newVal) {
                    this.loadProposal();
                }
            }
        },
        methods: {
             translate(data) {
                 return TranslationService.getValue(data, 'de-DE');
            },
            getDateTimeString(time) {
                let dt = new DateTime.fromMillis(parseInt(time));
                return dt.toLocaleString(DateTime.DATETIME_SHORT)
            },
            selectAll(actionType) {
                 this.syncStrategy[actionType].ids = this.syncProposal?.['to' + _.capitalize(actionType)]?.map((action) => {
                    if (actionType === "create") {
                        return action.entityId;
                    } else if (actionType === "update") {
                        return action.entryId;
                    }
                 }) || [];
            },
            async executeStrategy() {

                this.loadingStrategy = true;
                this.failedStrategy = false;

                await new Promise(r => setTimeout(r, 2000));

                await axios.post('/api/entrysync/strategy/' + this.catalogId, this.syncStrategy).then((res) => {
                    this.loadingStrategy = false;
                    this.syncProposal = {};
                    this.syncStrategy = {};
                    this.dialog = false;
                    this.$emit('synced');
                }).catch(() => {
                    this.syncProposal = {};
                    this.syncStrategy = {};
                    this.loadingStrategy = false;
                    this.failedStrategy = true;
                });

            },
            async loadProposal() {

                this.failedProposal = false;
                this.loadingProposal = true;
                this.syncStrategy = {};

                await new Promise(r => setTimeout(r, 2000));

                await axios.get('/api/entrysync/proposal/' + this.catalogId, {

                }).then((res) => {
                    this.loadingProposal = false;
                    this.syncProposal = res.data;
                    /**
                     * by setting the "ignore" parameter create/update can be ignored entirely. has been replaced with more flexible "ids" field.
                     * @type {{syncProposal: {}, create: {ignore: boolean, ids: *[]}, update: {ignore: boolean, ids: *[]}, agree: boolean}}
                     */
                    this.syncStrategy = {
                        syncProposal: _.cloneDeep(this.syncProposal),
                        agree: false,
                        create: {
                            ignore: false,
                            ids: [],
                        },
                        update: {
                            ignore: false,
                            ids: [],
                        }
                    }
                }).catch(() => {
                    this.loadingProposal = false;
                    this.failedProposal = true;
                });

            }
        },
    }

</script>

<style scoped>

</style>
