<template>
    <div class="mb-2 mt-1">

        <span class="text-h6 mr-3">{{ cpDefinition.label }}</span>

        <span class="mr-3 caption blue--text">{{ cpDefinition.name }}</span>
        <!--v-tooltip top v-if="isLocked">
            <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-3"
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                >
                    lock
                </v-icon>
            </template>
            <span>Datensatz ist gesperrt.</span>
        </v-tooltip-->

        <v-dialog
            v-if="cpDefinition.description"
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn text icon small v-bind="attrs" v-on="on" class="mr-3">
                    <v-icon>
                        info
                    </v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline white--text secondary">
                    <v-icon dark class="mr-4">
                        info
                    </v-icon>
                    {{ cpDefinition.label }}
                </v-card-title>

                <v-card-text>
                    <div class="pt-5">
                        <div>{{ cpDefinition.description }}</div>
                    </div>
                </v-card-text>


                <v-divider/>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import CockpitFormComponentMixin from "../../../mixins/cockpit-form-component"

export default {
    name: "CockpitLabelComponent",
    props: [
        'definition',
        'isLocked'
    ],
    data() {
        return {
            dialog: false,
        }
    },
    mixins: [
        CockpitFormComponentMixin
    ],
    methods: {
        getLinkColor() {
            const int = 0;
            switch (int) {
                case 0:
                    return "success";
                case 1:
                    return "warning";
                case 2:
                    return "error";
                default:
                    return "info";
            }
        },
        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        getLinkLockIcon(linkLock) {
            switch (linkLock) {
                case 0:
                    return "sync";
                case 1:
                    return "south";
                case 2:
                    return "north";
                case 3:
                    return "sync_disabled";
                default:
                    return '';
            }
        }
    }
}
</script>

<style scoped>

</style>
