import PlainRouterView from '../../views/PlainRouterView';
import PrintSuite from '../../views/PrintSuite/PrintSuite';
import Catalog from '../../views/PrintSuite/Catalog';
import Catalogs from '../../views/PrintSuite/Catalogs';
import EntryEdit from '../../views/PrintSuite/EntryEdit';
import EntryHistory from '../../views/PrintSuite/EntryHistory';
import Templates from '../../views/PrintSuite/Templates';
import EntryTypes from '../../views/PrintSuite/EntryTypes';
import EntryTypeEdit from '../../views/PrintSuite/EntryTypeEdit';
import Settings from '../../views/PrintSuite/Settings';
import MailTemplates from '../../views/PrintSuite/MailTemplates';
import CatalogPlanner from '@/views/PrintSuite/CatalogPlanner';
import CatalogSort from '@/views/PrintSuite/CatalogSort';
import Roles from '../../views/Admin/roles/Roles';
import ContactEdit from '../../views/PrintSuite/ContactEdit';
import Contacts from '../../views/PrintSuite/Contacts';

const routes = {
    path: 'print',
    component: PlainRouterView,
    name: 'PrintSuite',
    children: [
        {
            path: '',
            name: 'PrintSuiteDefault',
            component: PrintSuite,
        },
        {
            path: 'mailtemplates',
            component: PlainRouterView,
            children: [
                {
                    path: '',
                    component: MailTemplates,
                },
                {
                    path: ':catalogID',
                    component: Catalog,
                },
            ],
        },
        {
            path: 'catalogs',
            component: PlainRouterView,
            children: [
                {
                    path: '',
                    component: Catalogs,
                },
                {
                    path: ':catalogID',
                    component: PlainRouterView,
                    children: [
                        {
                            path: '',
                            name: 'Catalog',
                            component: Catalog,
                        },
                        {
                            path: 'planner',
                            component: CatalogPlanner,
                        },
                        {
                            path: 'sort/:sortID',
                            component: CatalogSort,
                        },
                        {
                            path: 'entries/:entryId',
                            props: true,
                            component: PlainRouterView,
                            children: [
                                {
                                    path: '',
                                    name: 'EntryEdit',
                                    component: EntryEdit,
                                },
                                {
                                    path: 'history',
                                    component: EntryHistory,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: 'templates',
            component: PlainRouterView,
            children: [
                {
                    path: '',
                    component: Templates,
                },
            ],
        },
        {
            path: 'contacts',
            name: 'Contacts',
            component: Contacts,
            children: [
                {
                    path: ':contactID',
                    name: 'ContactEdit',
                    component: ContactEdit,
                },
            ],
        },
        {
            path: 'entrytypes',
            component: PlainRouterView,
            children: [
                {
                    path: '',
                    component: EntryTypes,
                },
                {
                    path: ':entryTypeID',
                    component: EntryTypeEdit,
                },
            ],
        },
        {
            path: 'settings',
            component: PlainRouterView,
            children: [
                {
                    path: '',
                    component: Settings,
                },
            ],
        },
    ],
};

export default routes;
