<template>
    <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn fab fixed right bottom color="green" dark v-bind="attrs" v-on="on">
                <v-icon>add</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Eintragstyp erstellen</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field autofocus label="Name" required v-model="object.name"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text v-on:click="dialog = false">Abbrechen</v-btn>
                <v-btn color="success" depressed v-on:click="addEntryType(object)">Erstellen</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

</template>

<script>

    import { mapActions } from 'vuex'

    export default {
        name: "EntryTypeCreate",
        components: {

        },
        data() {
            return {
                dialog: false,
                object: {},
                tab: null,
            }
        },
        methods: {
            ...mapActions("entrytypes", ['add']),
            async addEntryType(object) {
                await this.add({
                    projectID: this.$router.currentRoute.params.projectID,
                    channelID: this.$router.currentRoute.params.channelID,
                    ...object
                });
                this.object = {};
                this.dialog = false;
            },
        }
    }

</script>

<style scoped>

</style>
