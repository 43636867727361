import _ from 'lodash';

const allRules = [
    {
        componentType: 'input',
        validators: [
            {
                label: 'Pflichtfeld',
                name: 'required',
                rules: [(v) => !!v || 'Pflichtfeld - bitte füllen.'],
            },
            {
                label: 'E-Mail',
                name: 'email',
                rules: [
                    (v) =>
                        !v ||
                        !!FormRuleProvider.validateEmail(v) ||
                        'Keine gültige E-Mail-Adresse.',
                ],
            },
        ],
    },
];

class FormRuleProvider {
    /**
     * returns the rule function, as defined by definition
     * @param definition
     * @returns {*[]}
     */
    static getRulesForComponent(definition) {
        let rules = [];

        let type = definition.type || '';
        let validators = definition.validators || [];

        console.log(definition.validators);

        let componentRules = allRules
            .filter((comp) => comp.componentType === type)
            .map((group) => {
                return group.validators
                    .filter((v) => validators.includes(v.name))
                    .map((v) => {
                        return v.rules;
                    });
            });

        console.log(_.flattenDeep(componentRules));

        return _.flattenDeep(componentRules);
    }

    static getAllRules() {
        return allRules;
    }

    static validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log(re.test(String(email).toLowerCase()));
        return re.test(String(email).toLowerCase());
    }

    static getMaxCharRule(maxChars) {
        return [
            (v) => {
                return (
                    v.length <= maxChars ||
                    `Maximal ${maxChars} Zeichen erlaubt.`
                );
            },
        ];
    }
}

export default FormRuleProvider;
