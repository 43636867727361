<template>
    <div>


        <v-card class="overflow-y-auto" style="max-height: 80vh;">

            <v-text-field v-model="search"
                          prepend-icon="search"
                          class="ml-5"
            ></v-text-field>

            <v-btn @click="clearOwnLastOpened" depressed class="ma-4">
                Eigene Aufrufzeit zurücksetzen
            </v-btn>

            <v-list
                subheader
            >
                <v-list-item-group v-if="changelogs.length > 0">

                    <v-list-item
                        two-line
                        v-for="changelog in changelogs"
                        :key="changelog._id"
                        router :to="{
                            name: 'AdminChangelogEdit',
                            params: {
                                changelogID: changelog._id,
                            }
                        }"
                    >
                        <v-list-item-avatar>
                            <v-icon>
                                checklist
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="changelog.title"></v-list-item-title>
                            <v-list-item-subtitle v-text="getDate(changelog.date) + ' - ' + (systems.find(s => changelog.system === s.value) || {}).text"></v-list-item-subtitle>
                        </v-list-item-content>


                    </v-list-item>

                </v-list-item-group>

            </v-list>

        </v-card>

    </div>
</template>

<script>

    import _ from "lodash";
    import Chat from "../utility/Chat";
    import axios from "axios";
    import UserService from "../../services/UserService";
    import {DateTime} from "luxon";

    export default {
        name: 'ChangelogList',
        components: {

        },
        computed: {
            changelogs: function() {
                return this.$store.getters['changelog/getAll'].filter((r) => {
                    return r.title?.toLowerCase().includes(this.search.toLowerCase());
                }) || [];
            }
        },
        methods: {
            clearOwnLastOpened() {
                this.$settings.setUserSettings('changelog.last_opened', (new Date("1990-01-01")).toISOString());
            },
            getDate(date) {
                return DateTime.fromISO(date).toFormat('dd.LL.yyyy')
            }
        },
        data() {
            return {
                search: "",
                systems: [
                    {
                        value: 'pp',
                        text: 'Publication Planner'
                    },
                    {
                        value: 'graph',
                        text: 'Knowledge Graph'
                    }
                ]
            }
        },
        created() {
            this.$store.dispatch('changelog/fetch');
        }
    }
</script>
