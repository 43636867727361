<template>
    <div>

        <LabelComponent :definition="definition" :is-locked="isLocked"/>

        <TrailEditorDialog :is-locked="isLocked" :entry="entry" @save="$emit('importItinerary')"/>

    </div>

</template>

<script>

    import _ from 'lodash';
    import LabelComponent from "../LabelComponent";
    import TrailEditorDialog from "../../TrailEditorDialog";

    export default {
        name: "TrailEditorComponent",
        components: {
            TrailEditorDialog,
            LabelComponent
        },
        props: [
            'definition',
            'content',
            'isLocked',
            'entry'
        ],
        data() {
            return {
                data: _.cloneDeep(this.content)
            }
        },
    }

</script>

<style scoped>

</style>
