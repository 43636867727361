<template>
  <div class="templates">
    <TemplatesList/>
    <TemplateCreate v-if="$permGuard.chP('templates.c')"/>
  </div>
</template>

<script>

  import TemplatesList from "../../components/templates/TemplatesList";
  import TemplateCreate from "../../components/templates/TemplateCreate";

  export default {
    name: 'Templates',
    components: {
      TemplateCreate,
      TemplatesList
    }
  }
</script>
