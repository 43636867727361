import _ from "lodash";
import TranslationService from "@/services/TranslationService";

// todo saved for later:
// https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components

const mixin = {

    data: function () {
        return {
            emitUpdateEvent: _.debounce(() => {
                this.$emit('change');
            }, 500)
        }
    },

    props: [
        'language'
    ],

    computed: {
        currentLang: function() {
            return this.language || "de-DE"
        },
    },

    watch: {
        /**
         * a change in variable data means that an event rises to EntryEdit.vue and triggers a re-compiling of the "to-be-saved"-object
         */
        data: {
            deep: true,
            immediate: true,
            handler(val, oldVal) {
                    this.emitUpdateEvent();
            }
        },
        value: {
            deep: true,
            immediate: true,
            handler(val, oldVal) {
                this.emitUpdateEvent();
            }
        }
    },

}

export default mixin;
