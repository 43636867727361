<template>
    <v-container>
        <v-col sm="12" style="max-width: 1400px;">

            <draggable v-model="properties" v-bind="dragOptions" group="people" handle=".handle" @start="drag=true" @end="drag=false">

                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <template v-for="(property,index) in properties">

                        <v-list-item :key="property.name" :index="index" class="mb-3 white listing-item">
                            <template>
                                <v-list-item-avatar style="cursor: grab" class="handle">
                                    <v-icon>
                                        drag_handle
                                    </v-icon>
                                </v-list-item-avatar>

                                <!-- DATA COMPONENT -->
                                <v-list-item-content v-if="isDataComponent(property)">
                                    <v-list-item-title>
                                        {{ property.label }} <span class="orange--text text-caption">&lt;{{ property.name }}&gt;</span>
                                        <v-icon v-if="property.hidden" class="ml-4">visibility_off</v-icon>
                                    </v-list-item-title>

                                    <v-list-item-subtitle
                                            v-if="property.linkName"
                                            class="grey--text"
                                    >
                                        <v-icon color="success" small class="mr-1">link</v-icon>
                                        <span>{{ property.linkName }}</span>
                                    </v-list-item-subtitle>

                                </v-list-item-content>

                                <!-- STYLE COMPONENT -->
                                <v-list-item-content v-if="!isDataComponent(property)">
                                    <v-list-item-title class="text-h5">
                                        <span :style="`color: ${(property.config || {}).color || '#2196f3'};`">{{ property.label }} <span class="grey--text darken-4 text-caption">&lt;!--{{ property.label }}--&gt;</span></span>
                                    </v-list-item-title>

                                    <v-list-item-subtitle
                                            class="grey--text"
                                    >
                                        <span>{{ property.description }}</span>
                                    </v-list-item-subtitle>

                                </v-list-item-content>

                                <v-list-item-action>
                                    <div>
                                        <!-- Table Button -->
                                        <v-dialog
                                                v-if="property.type === 'table'"
                                                v-model="dialogs[property.name]"
                                                scrollable
                                                max-width="1200px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" fab depressed class="ml-3" color="blue" small>
                                                    <v-icon color="white">
                                                        notes
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>{{ property.label }}</v-card-title>
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    <PropertyList :ref="property.name" :props="property.properties" @update="$emit('update')"/>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="success" text @click="dialogs[property.name] = false">OK</v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <!-- Edit Button -->
                                        <PropertyEdit :property="property" @done="updateProperty">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" fab depressed class="ml-3" color="lightgrey" small>
                                                    <v-icon color="dark">
                                                        tune
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                        </PropertyEdit>
                                        <!-- Delete Button -->
                                        <v-btn fab depressed class="ml-3" color="red" small @click="deleteProperty(index)">
                                            <v-icon color="white">
                                                delete
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-list-item-action>
                            </template>
                        </v-list-item>

                    </template>
                </transition-group>

            </draggable>

            <v-row>
                <v-spacer/>
                <PropertyEdit :property="{}" :create="true" @done="addProperty">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="text-left" fab color="success" v-bind="attrs" v-on="on">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </template>
                </PropertyEdit>
                <v-spacer/>
            </v-row>


        </v-col>
    </v-container>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import _ from 'lodash';
    import PropertyEdit from "./PropertyEdit";
    import draggable from "vuedraggable";
    import Vue from "vue";

    export default {
        name: "PropertyList",
        components: {
            PropertyEdit,
            draggable
        },
        emits: [
            'done'
        ],
        props: [
            "props"
        ],
        data() {
            return {
                drag: false,
                dialogs: {},
                properties: _.cloneDeep(this.props)
            }
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost",
                };
            },
        },
        watch: {
            properties: {
                handler: function (val, oldVal) {
                    // notify parent that saving is required
                    this.$emit('update');
                },
                deep: true
            }
        },
        methods: {
            isDataComponent(prop) {
                return !['divider', 'headline', 'info'].includes(prop.type);
            },
            async save() {

                let tableProps = this.properties.filter(x => x.type === "table");

                for (const propIndex in tableProps) {

                    let prop = tableProps[propIndex];

                    let index = this.properties.findIndex(x => x.name === prop.name);

                    if (typeof this.$refs[prop.name] !== "undefined") {
                        prop['properties'] = await this.$refs[prop.name][0].save();
                        this.properties.splice(index, 1, prop);
                    }

                }
                return this.properties;

            },
            addProperty(prop) {
                if (this.properties.find(x => x.name === prop.name)) {
                    alert('Ein Datenfeld mit diesem Namen existiert bereits!')
                } else {
                    this.properties.push(prop);
                    // notify parent that saving is required
                    this.$emit('update');
                }
            },
            updateProperty(newProp, oldName = "") {

                let identifier = newProp.name;

                if (oldName !== "" && oldName !== identifier) {
                    identifier = oldName;
                }

                let index = this.properties.findIndex(x => x.name === identifier);
                this.properties.splice(index, 1, newProp);

                // notify parent that saving is required
                this.$emit('update');

            },
            deleteProperty(index) {
                this.properties.splice(index, 1);
            },
        },
        async created() {
            if (typeof this.properties === "undefined") {
                this.properties = [];
            }
        }
    }

</script>

<style scoped>

    .listing-item.theme--dark {
        background-color: #222 !important;
    }

    .ghost {
        filter: brightness(0.9);
    }

</style>
