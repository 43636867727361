<template>
    <div class="users">
        <p class="text-h5">Einstellungen</p>
    </div>
</template>

<script>

    import Chat from "../../components/utility/Chat";
    import axios from "axios";
    import UserService from "../../services/UserService";

    export default {
        name: 'Settings',
        components: {
            // Chat
        },
        data() {
            return {
                loading: false,
                users: [],
                search: "",
                headers: [
                    { text: 'Aktionen', value: 'controls', sortable: false, width: "100" },
                    { text: 'Name', value: 'name'},
                    { text: 'Login-Name', value: 'login'},
                    { text: 'Firma', value: 'firmation'},
                ]
            }
        },
        computed: {

        },
        methods: {

        },
        created() {

        }
    }
</script>
