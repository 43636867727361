<template>
    <v-card class="mb-5" elevation="0">
        <v-card-title>
            <ColumnConfig
                v-if="
                    !loadingEntryType &&
                    $store.getters['user/getSettingsStatus'] === 'success'
                "
                :properties="columnProperties"
                :column-config="columnConfig"
                :ignore-fields="['name']"
                @apply="generateHeader"
            />

            <DeleteConfirmation
                v-if="$permGuard.chP('entries.d')"
                @yes="deleteSelected"
                :msg="
                    'Wollen Sie wirklich ' +
                    selectedRows.length +
                    ' Datensätze löschen? <br><br>Es werden nur die Print-Datensätze aus dieser Auflage gelöscht. Die Cockpit-Datensätze werden nicht gelöscht.'
                "
            >
                <template v-slot:default="{ on, attrs }">
                    <div>
                        <v-btn
                            color="error"
                            text
                            class="ml-3"
                            rounded
                            v-if="selectedRows.length > 0"
                            v-on="on"
                            v-bind="attrs"
                        >
                            Auswahl Löschen ({{ selectedRows.length }})
                        </v-btn>
                    </div>
                </template>
            </DeleteConfirmation>

            <!-- todo multi-download -->
            <!-- check if confirmation/acquisition is active in config -->
            <!-- check if template is set / cometConnection is active -->
            <v-btn
                color="info"
                text
                class="ml-1"
                rounded
                v-if="
                    $permGuard.chP('entries.c') &&
                    selectedRows.length > 0 &&
                    $settings.getValue('mail.acquisition.active') &&
                    $settings.getValue('status.acquisition.enabled')
                "
                @click="downloadSelectedPDFs('acquisition')"
                :loading="loading.acquisitions"
            >
                Akquisen PDF ({{ selectedRows.length }})
            </v-btn>
            <v-btn
                text
                class="ml-1"
                rounded
                v-if="
                    $permGuard.chP('entries.c') &&
                    selectedRows.length > 0 &&
                    $settings.getValue('mail.confirmation.active')
                "
                @click="downloadSelectedPDFs('confirmation')"
                :loading="loading.confirmations"
            >
                Freigabe PDF ({{ selectedRows.length }})
            </v-btn>

            <v-spacer />
            <v-text-field
                v-model="search"
                append-icon="search"
                label="Suchen"
                single-line
                hide-details
                style="max-width: 300px"
            ></v-text-field>
        </v-card-title>
        <v-row class="mx-4 mb-3 mt-1">
            <v-autocomplete
                prepend-icon="filter_alt"
                label="Status"
                class="mr-3"
                style="max-width: 450px"
                chips
                small-chips
                clearable
                deletable-chips
                multiple
                v-model="statusFilter"
                :items="statusList"
                item-value="value"
                item-text="label"
                itemm-color="color"
            ></v-autocomplete>
            <v-autocomplete
                label="Eintragstyp"
                style="max-width: 450px"
                chips
                class="mr-3"
                small-chips
                clearable
                deletable-chips
                multiple
                v-model="entryTypesFilter"
                :items="entryTypes"
                item-value="_id"
                item-text="name"
                itemm-color="color"
            ></v-autocomplete>
            <v-autocomplete
                label="Template"
                style="max-width: 450px"
                chips
                class="mr-3"
                small-chips
                clearable
                deletable-chips
                multiple
                v-model="templatesFilter"
                :items="templates"
                item-value="_id"
                item-text="name"
                itemm-color="color"
            ></v-autocomplete>
        </v-row>

        <v-data-table
            @update:items-per-page="saveItemsPerPage"
            @pagination="currentPage = $event.page"
            :page="currentPage"
            :elevation="0"
            :items-per-page="
                $settings.getUserSettings('entries_itemsPerPage') || 50
            "
            show-select
            item-key="_id"
            :loading="isEntriesLoading || loadingEntryType"
            :headers="headers"
            :items="tableData"
            :search="search"
            v-model="selectedRows"
            :footer-props="{
                'items-per-page-options': [15, 25, 50, 100],
            }"
        >
            <template v-slot:item.status="props">
                <div>
                    <v-chip
                        :color="props.item.status.color"
                        :class="
                            props.item.status.blackText
                                ? 'black--text'
                                : 'white--text'
                        "
                        small
                        >{{ props.item.status.name }}</v-chip
                    >
                </div>
            </template>
            <template v-slot:item.content.name="props">
                <span
                    v-if="typeof (props.item.content || {}).name === 'string'"
                >
                    <span class="font-weight-bold">{{
                        (props.item.content || {}).name
                    }}</span>
                    <v-tooltip right v-if="props.item.parentID">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="ml-2"
                                x-small
                                color="info"
                                v-bind="attrs"
                                v-on="on"
                            >
                                link
                            </v-icon>
                        </template>
                        <span>Mit Entität im Graph verlinkt</span>
                    </v-tooltip>
                </span>
                <span
                    v-else-if="
                        typeof (props.item.content || {}).name !==
                            'undefined' && props.item.status.type
                    "
                >
                    <v-progress-linear
                        indeterminate
                        color="grey"
                        rounded
                        height="2"
                        reverse
                    ></v-progress-linear>
                </span>
            </template>

            <template v-slot:item.languages="props">
                <span
                    v-if="
                        ($settings.getValue('language.available').length ||
                            []) > 1
                    "
                >
                    <v-avatar
                        v-for="(lang, index) in props.item.languages"
                        :key="index"
                        size="28"
                        class="mx-1 my-2 v-btn--outlined"
                    >
                        <v-img
                            :src="`https://webimages.we2p.de/static/flags/svg/${lang.code.slice(
                                -2
                            )}.svg`"
                        ></v-img>
                        <v-icon
                            color="black"
                            size="18"
                            v-if="lang.status === 'locked'"
                            style="
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                background-color: rgba(255, 255, 255, 0.6);
                            "
                            >lock</v-icon
                        >
                    </v-avatar>
                </span>
            </template>

            <template v-slot:item.controls="props">
                <v-btn
                    class="mr-3 my-2"
                    :to="`entries/${props.item._id}`"
                    fab
                    depressed
                    x-small
                    router
                    append
                >
                    <v-icon>edit</v-icon>
                </v-btn>

                <!-- Comet Preview -->
                <CometPreview
                    :key="props.item._id + '_comet'"
                    :index="props.item._id"
                    :entry-id="props.item._id"
                >
                    <template v-slot:default="{ on, attrs }">
                        <v-btn
                            :disabled="!props.item.templateID"
                            class="mr-3 my-2"
                            fab
                            color="blue"
                            depressed
                            x-small
                            router
                            append
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-icon color="white">remove_red_eye</v-icon>
                        </v-btn>
                    </template>
                </CometPreview>

                <MailAssistant
                    v-if="
                        $permGuard.chP('entries.c') &&
                        ($settings.getValue('mail.acquisition.active') ||
                            ($settings.getValue('mail.confirmation.active') &&
                                $settings.getValue(
                                    'status.acquisition.enabled'
                                )))
                    "
                    :ref="`mailAssistant__${props.item._id}`"
                    :key="props.item._id + '_mail'"
                    :index="props.item._id"
                    :entry="allEntries.find((x) => x._id === props.item._id)"
                >
                </MailAssistant>

                <!-- duplicate -->
                <DeleteConfirmation
                    @yes="cloneEntry(props.item._id)"
                    title="Eintrag für diese Publikation duplizieren?"
                    :msg="'Die entstehende Kopie ist nicht mehr mit dem Knowledge Graph verlinkt.'"
                    v-if="
                        $permGuard.chP('entries.c') &&
                        $settings.getValue('general.create.mode') > 0
                    "
                >
                    <template v-slot:default="{ on, attrs }">
                        <v-btn
                            class="mr-3 my-2"
                            v-on="on"
                            v-bind="attrs"
                            fab
                            color="grey"
                            depressed
                            x-small
                            router
                            append
                        >
                            <v-icon class="white--text">content_copy</v-icon>
                        </v-btn>
                    </template>
                </DeleteConfirmation>

                <!-- delete -->
                <DeleteConfirmation
                    v-if="$permGuard.chP('entries.d')"
                    @yes="triggerRemoveEntry(props.item._id)"
                    :msg="'Es wird nur der Print-Datensatz aus dieser Auflage gelöscht. Der Cockpit-Datensatz wird nicht gelöscht.'"
                >
                    <template v-slot:default="{ on, attrs }">
                        <v-btn
                            class="mr-3 my-2"
                            v-on="on"
                            v-bind="attrs"
                            fab
                            color="error"
                            depressed
                            x-small
                            router
                            append
                            :disabled="props.item.status.lock"
                        >
                            <v-icon v-if="!deleteInProgress[props.item._id]"
                                >delete</v-icon
                            >
                            <v-progress-circular
                                indeterminate
                                v-if="deleteInProgress[props.item._id]"
                                :size="20"
                                :width="2"
                            />
                        </v-btn>
                    </template>
                </DeleteConfirmation>
            </template>
        </v-data-table>
        <!--p class="text-right pb-2 pr-2 warning--text caption">max. Anzahl pro Seite vorübergehend auf 50 reduziert.</p-->

        <DeleteConfirmation
            :hidden="true"
            ref="redirectDialog"
            msg="Wollen Sie zur Bearbeitungsmaske des eben angelegten Datensatzes wechseln?"
            title="Jetzt bearbeiten?"
            @yes="redirectEdit"
        >
            <template />
        </DeleteConfirmation>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import _ from 'lodash';
import axios from 'axios';

import TranslationService from '../../services/TranslationService';
import EntryStatusService from '../../services/EntryStatusService';

import CometPreview from './CometPreview';
import DeleteConfirmation from '../utility/DeleteConfirmation';
import ColumnConfig from './ColumnConfig';
import MailAssistant from './MailAssitant';

export default {
    name: 'EntryList',
    components: {
        MailAssistant,
        ColumnConfig,
        DeleteConfirmation,
        CometPreview,
    },
    data() {
        return {
            cometDialog: {},
            search: '',
            selectedRows: [],
            deleteInProgress: {},
            loadingTemplates: false,
            templates: [],
            loadingEntryType: false,
            entryTypes: [],
            headers: [],
            lastCreatedRedirectID: '',
            loading: {
                acquisitions: false,
                confirmations: false,
            },
        };
    },
    computed: {
        ...mapGetters('entries', ['allEntries', 'isEntriesLoading']),
        statusFilter: {
            get: function () {
                return (
                    this.$settings.getUserSettings(
                        `pp_${this.$router.currentRoute.params.catalogID}_statusFilter`
                    ) || []
                );
            },
            set: function (value) {
                this.$settings.setUserSettings(
                    `pp_${this.$router.currentRoute.params.catalogID}_statusFilter`,
                    value
                );
            },
        },
        entryTypesFilter: {
            get: function () {
                return (
                    this.$settings.getUserSettings(
                        `pp_${this.$router.currentRoute.params.catalogID}_entryTypesFilter`
                    ) || []
                );
            },
            set: function (value) {
                this.$settings.setUserSettings(
                    `pp_${this.$router.currentRoute.params.catalogID}_entryTypesFilter`,
                    value
                );
            },
        },
        templatesFilter: {
            get: function () {
                return (
                    this.$settings.getUserSettings(
                        `pp_${this.$router.currentRoute.params.catalogID}_templatesFilter`
                    ) || []
                );
            },
            set: function (value) {
                this.$settings.setUserSettings(
                    `pp_${this.$router.currentRoute.params.catalogID}_templatesFilter`,
                    value
                );
            },
        },
        currentPage: {
            get: function () {
                return (
                    this.$settings.getUserSettings(
                        `pp_${this.$router.currentRoute.params.catalogID}_currentPage`
                    ) || 1
                );
            },
            set: function (value) {
                console.log(value);

                this.$settings.setUserSettings(
                    `pp_${this.$router.currentRoute.params.catalogID}_currentPage`,
                    value
                );
            },
        },
        columnConfig: function () {
            return (
                this.$settings.getUserSettings(
                    `${this.$router.currentRoute.params.projectID}_${this.$router.currentRoute.params.channelID}_entries_selectedColumns`
                ) || []
            );
        },
        columnProperties: function () {
            return _.flatten(this.entryTypes.map((e) => e.properties || []));
        },
        statusList: function () {
            return EntryStatusService.getStatusArray();
        },
        tableData: function () {
            let tableData = _.cloneDeep(this.allEntries);

            // FILTER
            // filter status
            if (this.statusFilter.length > 0) {
                tableData = tableData.filter((row) =>
                    this.statusFilter.includes(row.status)
                );
            }
            // filter entry types
            if (this.entryTypesFilter.length > 0) {
                tableData = tableData.filter((row) =>
                    this.entryTypesFilter.includes(row.type)
                );
            }
            // filter templates
            if (this.templatesFilter.length > 0) {
                tableData = tableData.filter((row) =>
                    this.templatesFilter.includes(row.templateID)
                );
            }

            for (const tableIndex in tableData) {
                let entry = tableData[tableIndex] || {};
                let type = entry['type'];

                // convert status to label
                entry.status = {
                    name: EntryStatusService.getTranslation(entry.status),
                    ...this.statusList.find((s) => s.value === entry.status),
                };
                entry.template =
                    this.templates.find((x) => x._id === entry.templateID)
                        ?.name || '-';

                if (typeof type !== 'undefined' && type !== '') {
                    let typeDefinition = this.entryTypes.find(
                        (x) => x._id.toString() === type
                    );
                    let propDefinitions = [];

                    if (typeof typeDefinition !== 'undefined') {
                        propDefinitions = typeDefinition.properties;
                        entry['typeName'] = typeDefinition.name;
                    }

                    if (propDefinitions.length > 0) {
                        for (const propIndex in entry['content']) {
                            // convert each property to "table format"
                            entry['content'][propIndex] =
                                this.convertToTableFormat(
                                    entry['content'][propIndex],
                                    propIndex,
                                    propDefinitions
                                );
                        }
                    }
                }
            }

            return tableData;
        },
    },
    methods: {
        ...mapActions('entries', [
            'addEntry',
            'removeEntry',
            'fetchAllEntries',
        ]),
        redirectEdit() {
            this.$router.push({
                name: 'EntryEdit',
                params: {
                    entryID: this.lastCreatedRedirectID,
                },
            });
        },
        setFilter(filter) {
            this.statusFilter = filter ? [filter.value] : [];
            console.log(this.statusFilter);
        },
        saveItemsPerPage(count) {
            this.$settings.setUserSettings('entries_itemsPerPage', count);
        },
        generateHeader(config) {
            // save to user settings
            if (config.join('|') !== this.columnConfig.join('|')) {
                this.$settings.setUserSettings(
                    `${this.$router.currentRoute.params.projectID}_${this.$router.currentRoute.params.channelID}_entries_selectedColumns`,
                    config
                );
                console.log('saved column config');
            }

            this.headers = [
                // filterable entryID
                { text: 'EntryID', value: '_id', align: ' d-none' },
                // filterable entityID
                { text: 'CockpitID', value: 'parentID', align: ' d-none' },
                { text: 'Name', value: 'content.name', width: '300' },
                {
                    text: 'Aktionen',
                    value: 'controls',
                    sortable: false,
                    width: '280',
                },
                {
                    text: 'Status',
                    value: 'status',
                    width: '200',
                    filterable: true,
                    sortable: true,
                    sort: function (a, b) {
                        if (a.name > b.name) {
                            return 1;
                        } else {
                            return -1;
                        }
                    },
                },
                { text: 'Eintragstyp', value: 'typeName', width: '200' },
                { text: 'Template', value: 'template', width: '200' },
            ];

            // if multilang project
            if (this.$settings.getValue('language.available') || []) {
                this.headers.push({
                    text: 'Languages',
                    value: 'languages',
                    width: '200',
                });
            }

            this.headers = this.headers.concat(
                config.map((column) => {
                    return {
                        text: this.columnProperties.find(
                            (prop) => prop.name === column
                        )?.label,
                        value: 'content.' + column,
                        width: '300',
                    };
                })
            );
        },
        deleteSelected() {
            this.selectedRows.forEach((r) => {
                this.triggerRemoveEntry(r._id);
            });
            this.selectedRows = [];
        },
        downloadSelectedPDFs(type) {
            let promises = [];

            if (type === 'acquisition') {
                this.loading.acquisitions = true;
            } else if (type === 'confirmation') {
                this.loading.confirmations = true;
            }

            this.selectedRows.forEach((r) => {
                let ref = this.$refs['mailAssistant__' + r._id];
                if (type === 'acquisition') {
                    promises.push(ref?.previewAcquisition(true));
                } else if (type === 'confirmation') {
                    promises.push(ref?.previewConfirmation(true));
                }
            });

            this.$notify({
                group: 'main',
                duration: 2000,
                type: 'success',
                title: 'PDFs werden generiert.',
            });

            Promise.allSettled(promises)
                .then((results) => {})
                .finally(() => {
                    if (type === 'acquisition') {
                        this.loading.acquisitions = false;
                    } else if (type === 'confirmation') {
                        this.loading.confirmations = false;
                    }
                });
        },
        triggerRemoveEntry(id) {
            this.deleteInProgress[id] = true;
            this.removeEntry(id).then(() => {
                this.deleteInProgress[id] = false;
            });
        },
        cloneEntry(id) {
            let entry = _.cloneDeep(
                this.allEntries.find((e) => e._id.toString() === id)
            );
            delete entry._id;
            delete entry.media;
            delete entry.parentID;
            entry.status = 'new';
            if (entry.content.name) {
                entry.content.name.map((v) => {
                    if (v.value) {
                        v.value += v.lang === 'de-DE' ? ' (Kopie)' : ' (Copy)';
                    }
                });
            }

            let options = {
                createInCockpit: false,
            };

            this.addEntry({ entry, options })
                .then((res) => {
                    this.lastCreatedRedirectID = res.entry._id;
                    this.entry = {};
                    this.dialog = false;
                    this.$refs['redirectDialog'].open();
                    this.$notify({
                        group: 'main',
                        duration: 2000,
                        type: 'success',
                        title: 'Datensatz dupliziert.',
                    });
                })
                .catch((e) => {
                    let msg = '';
                    switch (e) {
                        case 'entity_corrupt':
                            msg =
                                'Graph hat keinen gültigen Datensatz geliefert.';
                            break;
                        default:
                            console.error(e);
                    }
                    this.$notify({
                        group: 'main',
                        duration: -1,
                        type: 'error',
                        title: 'Duplizieren fehlgeschlagen!',
                        text: msg,
                    });
                });
        },
        convertToTableFormat(value, propName, definition) {
            let propDefinition;

            if (typeof definition !== 'undefined' && definition !== null) {
                propDefinition = definition.find((x) => x.name === propName);
            }

            if (typeof propDefinition !== 'undefined') {
                switch (propDefinition.type) {
                    case 'address': {
                        let address = [
                            value?.city + ' ' + value?.zip,
                            value?.street,
                            value?.country,
                        ];
                        return address.filter(Boolean).join(', ');
                    }
                    case 'input':
                    case 'textarea':
                        return TranslationService.getValue(value, 'de-DE');
                    case 'enumeration':
                        return (
                            value
                                .map(
                                    (x) =>
                                        propDefinition.config?.values?.find?.(
                                            (p) => p.value === x
                                        )?.label?.['de-DE'] ?? 'no label'
                                )
                                .join?.(', ') ?? ''
                        );
                    case 'table':
                        switch (value.length) {
                            case 0:
                                return 'keine Einträge';
                            case 1:
                                return '1 Eintrag';
                            default:
                                return value.length + ' Einträge';
                        }
                    default:
                        return value;
                }
            }

            // return value if no other transformation applies (important for "index")
            return value;
        },
    },
    created() {
        // load entrytype definition
        this.loadingEntryType = true;

        // other entrytypes
        axios
            .get(
                `/api/entrytypes/?projectID=${this.$router.currentRoute.params.projectID}&channelID=${this.$router.currentRoute.params.channelID}`,
                {}
            )
            .then((res) => {
                this.entryTypes = res.data;
                this.loadingEntryType = false;
                // generate table header
            })
            .catch((e) => {
                console.error(e);
                this.loadingEntryType = false;
            });

        // load templates
        this.loadingTemplates = true;

        axios
            .get(
                `/api/templates/?projectID=${this.$router.currentRoute.params.projectID}&channelID=${this.$router.currentRoute.params.channelID}`,
                {}
            )
            .then((res) => {
                this.templates = res.data;
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                this.loadingTemplates = false;
            });
    },
};
</script>

<style scoped>
.content {
    padding: 30px;
    margin: 30px;
}
</style>
