<template>
    <div>
        <LabelComponent :definition="definition" :is-locked="isLocked"/>
        <v-checkbox dense
                    style="max-width: 50px"
                    filled
                    :readonly="isLocked"
                    :input-value="data"
                    @change="$emit('input', Boolean($event))"
                    hide-details="auto"
        ></v-checkbox>
    </div>
</template>

<script>

import _ from 'lodash';
import TranslationService from "../../../services/TranslationService";
import LabelComponent from "./LabelComponent";

import FormComponentMixin from "../../mixins/form-component";

export default {
    name: "BooleanComponent",
    components: {
        LabelComponent
    },
    mixins: [
        FormComponentMixin
    ],
    props: [
        'definition',
        'value',
        'isLocked'
    ],
    data() {
        return {

        }
    },
    computed: {
        data: function() {
            return Boolean(this.value);
        }
    },
    methods: {
        async save() {

        }
    }
}
</script>

<style scoped>

</style>
