<template>

    <div>
        <v-sheet>
            <p class="ma-0 pt-4 pl-5">
                <span class="font-weight-bold">{{ total }} Treffer</span>
                <span class="ml-3" v-if="value.length > 0">({{ value.length }} ausgewählt)</span>
            </p>
        </v-sheet>
        <v-data-table :loading="loading || false" class="pt-5"
                      @update:items-per-page="saveItemsPerPage"
                      :items-per-page="itemsPerPage"
                      :items="items || []"
                      :headers="headers || []"
                      sort-by="name"
                      v-model="value"
                      :options.sync="options"
                      :server-items-length="total"
                      item-key="_id"
                      show-select
                      :footer-props="{
                    'items-per-page-options': [15, 25, 50, 100]
                  }"
        >
            <template v-slot:item.name="props">
                <span class="font-weight-bold">{{ props.item.name }}</span>
            </template>
            <template v-slot:item.types="props">
                <span class="font-weight-bold blue--text">{{ props.item.types.join(', ') }}</span>
            </template>
            <template v-slot:item.controls="props">
                <v-btn class="mr-3 my-2" :to="`entries/${props.item._id}`" fab depressed x-small router append>
                    <v-icon>edit</v-icon>
                </v-btn>
                <!--v-btn class="mr-3 my-2" :to="`entries/${props.item._id}`" fab color="success" depressed x-small router append :disabled="true">
                    <v-icon>sync</v-icon>
                </v-btn-->

                <DeleteConfirmation>
                    <template v-slot:default="{ on, attrs }">
                        <v-btn class="mr-3 my-2" v-on="on" v-bind="attrs" fab color="error" depressed x-small>
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </template>
                </DeleteConfirmation>

            </template>
        </v-data-table>
    </div>


</template>

<script>
import DeleteConfirmation from "@/components/utility/DeleteConfirmation";
export default {
    name: "EntityList",
    components: {DeleteConfirmation},
    props: [
        "value",
        "loading",
        "items",
        "headers",
        "total"
    ],
    data() {
        return {
            options: {}
        }
    },
    watch: {
        options: {
            handler() {
                this.$emit('options', this.options)
            },
            deep: true
        },
        value: {
            handler(newVal) {
                this.$emit('input', newVal)
            }
        }
    },
    computed: {
        itemsPerPage: function () {
            return this.$settings.getUserSettings('graph_itemsPerPage') || 50
        },
    },
    methods: {
        saveItemsPerPage(count) {
            this.$settings.setUserSettings('graph_itemsPerPage', count);
        },
    }
}
</script>

<style scoped>

</style>
