<template>
    <div class="catalogs" style="max-height: 100vh">
        <v-row class="overflow-hidden">
            <v-navigation-drawer
                absolute
                height="calc(100vh - 90px)"
                class="ma-3 pa-4 absolute"
                width="400"
                style="z-index: 1"
            >
                <v-btn
                    class="mb-5 mt-2"
                    text
                    color="transparent"
                    depressed
                    router
                    :to="`/projects/${$router.currentRoute.params.projectID}/channels/${$router.currentRoute.params.channelID}/print/catalogs`"
                >
                    <v-icon class="mr-3 black--text">arrow_back</v-icon>
                    <span class="black--text">alle Versionen</span>
                </v-btn>

                <p class="text-body-1 grey--text mt-4 mb-0">Katalog-Planung</p>
                <p class="text-h3">Demo Katalog</p>

                tools
            </v-navigation-drawer>

            <v-col style="height: calc(100vh - 90px)">
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn fab color="info">
                        <v-icon> east </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'CatalogPlanner',
    components: {},
    data() {
        return {
            panOptions: { minZoom: 0.5, maxZoom: 3 },
        };
    },
    computed: {},
    methods: {
        onInit: function (panzoomInstance, id) {},
    },
};
</script>

<style>
.main-content {
    background-color: #666;
    background-size: cover;
}
</style>
