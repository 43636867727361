<template>

    <v-dialog
            v-model="dialog"
            max-width="1000"
            scrollable
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs">
                <v-btn color="light" depressed rounded large v-on="on" v-bind="attrs">
                    <v-icon color="black" class="mr-2">view_column</v-icon>
                    <span>Spalten</span>
                </v-btn>
            </slot>
        </template>

        <v-card tile>
            <v-toolbar elevation="1">
                <v-card-title class="headline">
                    Spaltenkonfiguration
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn class="mr-0" color="red" icon @click="dialog = !dialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row class="mt-4">
                    <v-col>
                        <v-subheader>Ausgewählt:</v-subheader>
                        <v-card style="min-height: 100px;">
                            <v-card-text v-if="selectedColumns.length === 0">
                                Keine Spalten ausgewählt...
                            </v-card-text>
                            <draggable style="min-height: 100px;" class="list-group" :list="selectedColumns" group="columns" handle=".handle" v-bind="dragOptions" @start="drag=true" @end="drag=false">
                                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                    <template v-for="(column, index) in selectedColumns">
                                        <v-list-item :key="index" :index="index" class="listing-item">
                                            <v-list-item-avatar style="cursor: grab" class="handle">
                                                <v-icon>
                                                    drag_handle
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <div>
                                                <span>{{ column.label }}</span>
                                                <span class="info--text ml-4">{{ column.name }}</span>
                                            </div>
                                            <v-spacer/>
                                            <!-- Delete Button -->
                                            <v-btn fab depressed class="ml-3" color="red" x-small @click="unselect(index)">
                                                <v-icon color="white">
                                                    close
                                                </v-icon>
                                            </v-btn>
                                        </v-list-item>
                                    </template>
                                </transition-group>
                            </draggable>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="searchTerm" append-icon="search" label="Suchen"></v-text-field>
                        <v-card style="min-height: 100px;" class="py-3">
                            <v-card-text v-if="availableColumns.length === 0 && !searchTerm" class="text-center">
                                Bereits alle Datenfelder ausgewählt.
                            </v-card-text>
                            <v-card-text v-if="availableColumns.length === 0 && searchTerm" class="text-center">
                                Keine Suchergebnisse.
                            </v-card-text>
                            <!--draggable class="list-group" :list="availableColumns" :group="{ name: 'columns', pull: 'clone', put: false }" handle=".handle" v-bind="dragOptions" @start="drag=true" @end="drag=false">
                                <transition-group type="transition" :name="!drag ? 'flip-list' : null"-->
                                    <template v-for="(column, index) in availableColumns">
                                        <v-list-item :key="index" :index="index" class="mb-3 listing-item">
                                            <v-btn fab x-small depressed class="mr-3" color="success" @click="select(index)">
                                                <v-icon color="white">
                                                    add
                                                </v-icon>
                                            </v-btn>
                                            <div>
                                                <span>{{ column.label }}</span>
                                                <span class="info--text ml-4">{{ column.name }}</span>
                                            </div>
                                        </v-list-item>
                                    </template>
                                <!--/transition-group>
                            </draggable-->
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn depressed class="px-4" color="success" @click="apply">
                    Anwenden
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>

</template>

<script>

    import _ from 'lodash';
    import draggable from 'vuedraggable';
    import UserService from "../../services/UserService";

    export default {
        name: 'ColumnConfig',
        props: [
            'properties',
            'columnConfig',
            'ignoreFields'
        ],
        components: {
            draggable
        },
        data() {
            return {
                searchTerm: "",
                drag: false,
                dialog: false,
                dragOptions:  {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost",
                },
                selectedColumns: [],
            }
        },
        computed: {
            availableColumns: function() {

                // get rid of stylish properties (headline, divider, info) and ignoreFields
                let columns = this.properties.filter(p => !['divider', 'headline', 'info', 'trailEditor'].includes(p.type) && !this.ignoreFields?.includes(p.name));

                // get not yet selected columns
                columns = columns.filter(
                    p => !this.selectedColumns.map(col => col.name).includes(p.name)
                );

                // prevent duplicate columns from different typings
                columns = columns.filter((obj, pos, arr) => {
                    return arr.map(mapObj => mapObj['name']).indexOf(obj['name']) === pos;
                });

                // filter by searchTerm
                columns = columns.filter((column) => {
                    return this.searchTerm.toLowerCase().split(' ').every((fragment) => {
                        return (column.label.toLowerCase().indexOf(fragment) > -1) || (column.name.toLowerCase().indexOf(fragment) > -1);
                    });
                });

                return this.sortByLabel(columns);
            },
        },
        watch: {

        },
        methods: {
            init() {
                this.selectedColumns = this.columnConfig.map(c => this.properties.find(p => p.name === c)).filter(Boolean);

                // filter out unavailable columns
                // this.selectedColumns = this.selectedColumns.filter(c => )

                this.$emit('apply', this.columnConfig);
            },
            unselect(index) {
                this.selectedColumns.splice(index, 1);
            },
            select(index) {
                this.selectedColumns.unshift(this.availableColumns[index])
            },
            apply() {
                // flatten to only name, as labels could change in future
                let flattenedColumns = this.selectedColumns.map(x => x.name);

                this.$emit('apply', flattenedColumns);

                this.dialog = false;
            },
            sortByLabel(columns) {
                return columns.sort((a,b) => {
                    return a.label.localeCompare(b.label);
                })
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>
