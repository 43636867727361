/**
 * used to prevent accidental route leave or window close
 * parent component requires a property "saveRequired" that can be true or false
 */
const mixin = {

    methods: {
        isExitBlocked() {
            return !!this.saveRequired;
        },
        blockBrowserExit(e) {
            if (this.isExitBlocked()) {
                e.preventDefault();
                e.returnValue = '';
            } else {
                delete e['returnValue'];
            }
        }
    },

    /**
     * prevent leaving via VueRouter (also window.back())
     * @param to
     * @param from
     * @param next
     */
    beforeRouteLeave(to, from, next) {
        console.log("leave", this.isExitBlocked());
        if (this.isExitBlocked()) {
            const answer = window.confirm('Alle ungespeicherten Änderungen gehen verloren!')
            if (answer) {
                // important to unset onbeforeunload when route leave is successful
                window.removeEventListener('beforeunload', this.blockBrowserExit);
                next();
            } else {
                // browser history reacts before vue-router does, so if the change is prevented, we have to correct browser history with forward()
                window.history.forward();
                return;
            }
        } else {
            next();
        }
    },

    /**
     * prevent tab/browser from closing/reloading
     */
    created() {
        window.addEventListener('beforeunload', this.blockBrowserExit);
    }

}


export default mixin;
