<template>

    <div class="">

        <div class="login-bg" :style="`background-image: url('${backgroundURL}')`"></div>
        <div class="login-bg-gradient"></div>
        <p class="login-copyright font-weight-bold white--text ma-0">&copy; {{ new Date().getFullYear() }} VENUS GmbH</p>


        <div class="login-sidebar">
            <div style="position: absolute; top: 50%; transform: translateY(-50%)">
                <img :src="logoURL" class="px-10" style="width: 100%">
                <p class="text-h5 white--text mx-12" style="letter-spacing: 1px !important;">Publication Planner</p>
            </div>
            <div class="login-card pa-5">
                <v-avatar size="64" color="primary" class="login-icon-button" x-large depressed>
                    <v-icon color="white" size="34">login</v-icon>
                </v-avatar>
                <p class="text-h6 text-center mt-1 black--text" style="letter-spacing: 1px !important;">
                    Herzlich Willkommen
                </p>
                <v-btn @click="redirect" color="black" dark depressed rounded large class="login-button px-7">Anmelden mit <img src="/img/venus/logo_white.svg" height="20" class="mx-2"></v-btn>
            </div>
        </div>

    </div>
</template>

<script>
import PPSettings from "@/services/pp-settings";

export default {
  props: [
    "nextUrl",
  ],
  data() {
    return {
      snowflakes: null,
      username: "",
      password: "",
      alert: false,
      loading: false,
      nyanCounter: 1,
    }
  },
  computed: {
      logoURL: function () {
          return this.$store.getters['system-config/getConfig']?.appearance?.logos?.main_white;
      },
      backgroundURL: function () {
          return this.$store.getters['system-config/getConfig']?.appearance?.login_bg;
      },
  },
  methods: {
    redirect() {
      location.href = this.$store.getters['auth/getSwitchAccountUrl'];
    },
    async handleSubmit(e) {

      e.preventDefault();

      this.alert = false;
      this.loading = true;


      // LOGIN action
      this.$store.dispatch('auth/login', {
        username: this.username,
        password: this.password
      }).then(() => {
        if (this.$route.params.nextUrl != null) {
          this.$router.push(this.$route.params.nextUrl);
        } else {
          this.$router.push('/projects');
        }
      }).catch(err => {
        /*this.$toasted.error('Login fehlgeschlagen.', {
            position: "top-center",
            duration: 2000,
            keepOnHover: true,
        });*/
        this.alert = true;
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })

    }
  },
  created() {
    console.log('Login.vue created');
  },
  beforeDestroy() {

  }
}
</script>

<style scoped>

.outer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

.login-icon-button {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 0;
    top: 50%;
}

.login-button {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 0;
}

.login-sidebar {
    height: 100vh;
    width: 400px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--v-secondary-base);
    border-left: 8px solid var(--v-primary-base);
    animation-name: fadeInLeft;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
}

.login-card {
    position: absolute;
    bottom: 100px;
    right: 0;
    border-radius: 20px;
    transform: translateX(50%);
    background: rgba(255,255,255,0.9);
    backdrop-filter: blur(15px);
    width: 120%;
    height: 150px;
    opacity: 0;
    animation-name: fadeInLeftCustom;
    animation-duration: 0.8s;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

.login-bg {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    animation-name: zoom;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 20s;
}

.login-bg-gradient {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.login-app-title {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.login-copyright {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

@keyframes zoom {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

@keyframes fadeInLeftCustom {
    0% {
        right: 10%;
        opacity: 0;
    }
    100% {
        right: 0;
        opacity: 1;
    }
}


</style>
