import axios from 'axios';
import router from "../../router";
import Vue from "vue";

const entrypoint = 'properties';

const store = {

    state: {
        status: '',
        data: [],
    },

    getters: {
        getAll: (state) => state.data || [],
        getStatus: (state) => state.status,
    },

    actions: {
        async flush({ commit, getters }) {
            return new Promise((resolve, reject) => {
                commit('clear_all');
            });
        },
        async fetchAll({ commit, getters }) {

            return new Promise((resolve, reject) => {

                if (getters.getAll.length > 0) {
                    resolve();
                    return;
                }

                commit('request_sent');

                axios.get(`/api/${ entrypoint }/`).then((res) => {
                    commit('request_success', res.data);
                    resolve();
                }).catch((e) => {
                    console.log(e);
                    commit('request_error');
                    reject(e);
                });

            });

        },
    },

    mutations: {
        clear_all(state) {
            state.data = [];
        },
        request_sent(state){
            state.status = 'loading';
        },
        request_success(state, data) {
            state.status = 'success';
            state.data = data;
        },
        request_error(state){
            state.status = 'error';
        },
    }

}


export default {
    ...store,
    namespaced: true
}
