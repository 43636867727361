<template>
    <div class="printsuite">
    </div>
</template>

<script>

    import Chat from "../../components/utility/Chat";
    import axios from "axios";

    export default {
        name: 'Dashboard',
        components: {
            // Chat
        },
        data() {
            return {}
        },
        computed: {

        },
        methods: {

        },
        created() {

        }
    }
</script>
