<template>

    <div>
        <v-menu v-if="user"
                v-model="menu"
                bottom
                right
                transition="scale-transition"
                origin="top left"
        >
            <template v-slot:activator="{ on }">
                <v-chip pill v-on="on" class="mb-2">
                    <v-avatar left color="error">
                        <span class="white--text">{{ user.name.split(" ").map(x => x.charAt(0).toUpperCase()).slice(0, 2).join('') }}</span>
                    </v-avatar>
                    {{ user.name }}
                </v-chip>
            </template>
            <v-card width="300">
                <v-list dark>
                    <v-list-item>
                        <v-list-item-avatar color="white">
                            <v-avatar size="40">
                                <span class="black--text">{{ user.name.split(" ").map(x => x.charAt(0).toUpperCase()).slice(0, 2).join('') }}</span>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ user.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ user.firmation }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                    icon
                                    @click="menu = false"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item v-if="user.telephone" :append="false" :href="`tel:${ user.telephone }`">
                        <v-list-item-action>
                            <v-icon>phone</v-icon>
                        </v-list-item-action>
                        <v-list-item-subtitle>{{ user.telephone }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item v-if="user.mail" :href="`mailto:${ user.mail }`">
                        <v-list-item-action>
                            <v-icon>mail</v-icon>
                        </v-list-item-action>
                        <v-list-item-subtitle>{{ user.mail }}</v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
        <div v-if="!user">
            <p>-</p>
        </div>
    </div>


</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import _ from 'lodash';

    export default {
        name: "UserChip",
        props: ["user"],
        data() {
            return {
                menu: false
            }
        },
        computed: {},
        methods: {

        }
    }

</script>

<style scoped>

</style>
