<template>
    <div class="users">

        <p class="text-h5">Gruppen</p>

        <v-row>

            <v-col style="max-width: 450px">
                <GroupList />
            </v-col>

            <v-col style="max-height: 80vh;" v-if="$router.currentRoute.params.groupID">
                <v-card class="overflow-y-auto pa-5" style="max-height: 80vh;">
                    <router-view :key="$router.currentRoute.params.groupID"></router-view>
                </v-card>
            </v-col>

        </v-row>

        <v-btn fab fixed bottom right color="success" @click="create()">
            <v-icon>
                add
            </v-icon>
        </v-btn>

    </div>
</template>

<script>

    import Chat from "../../../components/utility/Chat";
    import axios from "axios";
    import UserService from "../../../services/UserService";
    import GroupList from "@/components/users/GroupList";

    export default {
        name: 'Groups',
        components: {
            GroupList
            // Chat
        },
        data() {
            return {
                loading: false,
                users: [],
                search: "",
            }
        },
        computed: {

        },
        methods: {
            create() {
                this.$store.dispatch('groups/addGroup', { group: { name: 'Neue Gruppe', users: [] } }).then((res) => {
                    this.$router.push({
                        name: 'AdminGroupEdit',
                        params: {
                            groupID: res._id.toString()
                        }
                    });
                })
            },
        },
        created() {

        }
    }
</script>
