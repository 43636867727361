import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import de from 'vuetify/es5/locale/de';
import PPSettings from '@/services/pp-settings';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    lang: {
        locales: { de },
        current: 'de',
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#111111',
                secondary: '#111111',
                'stepper-elevation': 0,
                'stepper-header-elevation': 0,
            },
            dark: {
                primary: '#111111',
                secondary: '#111111',
                'stepper-elevation': 0,
                'stepper-header-elevation': 0,
            },
        },
    },
});
