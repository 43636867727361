import axios from 'axios';
import router from '../../router';

const api = '/api/v2/contacts';

const store = {
    state: {
        status: '',
        data: [],
    },

    getters: {
        getStatus: (state) => state.status,
        getAll: (state) => state.data,
    },

    actions: {
        async fetchByProject({ commit }, { projectId } = {}) {
            commit('start_loading');
            if (!projectId) {
                projectId = router.currentRoute.params.projectID;
            }
            await axios
                .get(`${api}?projectId=${projectId}`)
                .then((response) => {
                    commit('end_loading');
                    commit('set_data', response.data);
                });
        },
        async add({ commit, dispatch }, { payload }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .post(`${api}`, {
                        salutation: '',
                        organization: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        address: {
                            street: '',
                            zip: '',
                            city: '',
                            country: '',
                        },
                        ...payload,
                    })
                    .then(async (res) => {
                        await dispatch('fetchByProject');
                        resolve(res.data);
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
        async update({ commit, dispatch }, { payload, id }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .put(`${api}/${id}`, payload)
                    .then(async (res) => {
                        resolve();
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
        async remove({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                commit('start_loading');
                axios
                    .delete(`${api}/${id}`)
                    .then(async (res) => {
                        await dispatch('fetchByProject');
                        resolve();
                    })
                    .catch((e) => {
                        reject();
                        console.error(e);
                    })
                    .finally(() => {
                        commit('end_loading');
                    });
            });
        },
    },

    mutations: {
        flush_data(state) {
            state.data = [];
        },
        set_data(state, data) {
            state.data = data || [];
        },
        start_loading(state) {
            state.status = 'loading';
        },
        end_loading(state) {
            state.status = 'success';
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
