import axios from 'axios';
import router from '../../router';

const store = {
    state: {
        status: '',
        access_token: localStorage.getItem('access_token') || '',
        id_token: localStorage.getItem('id_token') || '',
        refresh_token: localStorage.getItem('refresh_token') || '',
        cockpit_token: localStorage.getItem('cockpit_token') || '',
        user: JSON.parse(localStorage.getItem('user') || '{}') || {},

        refreshing: false,

        client_id: process.env.VUE_APP_VENUS_OAUTH_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_VENUS_OAUTH_REDIRECT_URI,
    },

    getters: {
        isLoggedIn: (state) => !!state.access_token,
        isRefreshing: (state) => state.refreshing,

        getAccessToken: (state) => state.access_token,
        getIdToken: (state) => state.id_token,
        getRefreshToken: (state) => state.refresh_token,
        getCockpitToken: (state) => state.cockpit_token,

        getAuthStatus: (state) => state.status,
        getUser: (state) => state.user,
        getClientID: (state) => state.client_id,
        getRedirectUri: (state) => state.redirect_uri,
        getOAuthUrl: () =>
            `${process.env.VUE_APP_VENUS_OAUTH ?? 'https://auth.venus.bayern'}`,
        getLoginUrl: (state, getters) =>
            `${getters.getOAuthUrl}/oauth2/authorize?client_id=${state.client_id}&redirect_uri=${state.redirect_uri}&response_type=code`,
        getSwitchAccountUrl: (state, getters) =>
            `${getters.getOAuthUrl}/oauth2/authorize?client_id=${state.client_id}&redirect_uri=${state.redirect_uri}&response_type=code&prompt=select_account`
    },

    actions: {
        // method to check if the current access token is valid
        async checkToken({commit, dispatch, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.isLoggedIn) {
                    reject(false);
                    return false;
                }

                axios
                    .post(`${getters.getOAuthUrl}/oauth2/token/introspect`, {
                        token: getters.getAccessToken,
                    })
                    .then(() => {
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        async getUserInfo({commit, dispatch, getters}) {
            return new Promise((resolve, reject) => {
                if (!getters.isLoggedIn) {
                    reject(false);
                    return false;
                }

                axios
                    .get(`${getters.getOAuthUrl}/userinfo`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        async getToken({commit, dispatch}, code) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        '/api/v2/auth/token',
                        {
                            authorization_code: code,
                        },
                        {
                            timeout: 5000,
                        }
                    )
                    .then((res) => {
                        const data = res.data.data;

                        if (data) {
                            const {access_token} = data;
                            commit('setAuthResponse', data);

                            // set jwt token for pp-backend
                            axios.defaults.headers.common['Authorization'] =
                                'Bearer ' + access_token;

                            commit('auth_success');
                            dispatch(
                                'user/fetchPermissions',
                                {},
                                {root: true}
                            );
                            dispatch('user/fetchSettings', {}, {root: true});

                            resolve(res);
                        } else {
                            commit('auth_error');
                            //localStorage.removeItem('token');
                            reject('no_token');
                        }
                    })
                    .catch(function (err) {
                        commit('logout');
                        commit('auth_error');
                        //localStorage.removeItem('token');
                        reject('wrong_credentials');
                    });
            });
        },

        async refresh({commit, dispatch, getters}) {
            commit('setRefreshing', true);

            return new Promise((resolve, reject) => {
                if (!getters.isLoggedIn) {
                    reject('not logged in');
                    return;
                }

                axios
                    .post(`${getters.getOAuthUrl}/oauth2/token`, {
                        grant_type: 'refresh_token',
                        client_id: getters.getClientID,
                        refresh_token: getters.getRefreshToken,
                    })
                    .then((response) => {
                        commit('setRefreshing', false);
                        commit('setAuthResponse', response.data);
                        resolve();
                    })
                    .catch((error) => {
                        commit('logout');
                        reject(error);
                    });
            });
        },

        switchAccount({getters}) {
            location.href = getters.getSwitchAccountUrl;
        },
        async logout({commit}) {
            return new Promise((resolve) => {
                commit('logout');

                if (router.currentRoute.name !== 'login')
                    router.push({path: '/login'});

                delete axios.defaults.headers.common['Authorization'];
                resolve();
            });
        },
    },

    mutations: {
        setAuthResponse(state, response) {
            const {
                access_token,
                id_token,
                refresh_token,
                legacy_oauth_token,
                user,
            } = response;

            localStorage.setItem('access_token', access_token);
            localStorage.setItem('id_token', id_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('cockpit_token', legacy_oauth_token);

            if (user) localStorage.setItem('user', JSON.stringify(user));

            state.access_token = access_token;
            state.id_token = id_token;
            state.refresh_token = refresh_token;
            state.cockpit_token = legacy_oauth_token;

            if (user) state.user = user;
        },
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state) {
            state.status = 'success';
        },
        auth_error(state) {
            state.status = 'error';
        },
        setRefreshing(state, refreshing) {
            state.refreshing = refreshing;
        },
        logout(state) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('id_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('cockpit_token');
            localStorage.removeItem('user');

            state.access_token = undefined;
            state.id_token = undefined;
            state.refresh_token = undefined;
            state.cockpit_token = undefined;
            state.user = undefined;
        },
    },
};

export default {
    ...store,
    namespaced: true,
};
