<template>
  <div class="notfound">
    <h1>This isn't the page you're looking for.</h1>
  </div>
</template>

<script>

  export default {
    name: 'PageNotFound',
  }

</script>